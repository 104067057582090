import { useMemo } from 'react'
import { post } from '../../lib/api'
import { projectPath } from '../ui/ProjectsContext'
import { useApp } from './use-app'

export function useOutreachSingleSendSettings() {
  const { data: outreachData, isLoading } = useApp<{ scopes: string[] }>('Outreach')

  const outreachEnabled = useMemo(() => {
    return outreachData?.connected && outreachData?.valid
  }, [outreachData])

  const canSendOutreach = useMemo(() => {
    return outreachEnabled && outreachData?.deps?.scopes?.includes('mailings.write')
  }, [outreachEnabled, outreachData])

  return { outreachEnabled, canSendOutreach, isLoading }
}

export type OutreachSingleSend = {
  subject: string
  body: string
  scheduled_at?: string
  state?: string
}

export function outreachSingleSend(profile: { email: string; account_id: string }, singleSend: OutreachSingleSend) {
  const path = projectPath(`outreach/actions/single-send`)
  return post(path, {
    single_send: { ...singleSend, profile_id: profile.email, account_id: profile.account_id }
  })
}
