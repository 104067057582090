import {
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Img,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Td,
  Text,
  Textarea,
  Th,
  Tooltip,
  useCheckboxGroup
} from '@chakra-ui/react'
import {
  IconBrandGithub,
  IconCheck,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconExternalLink,
  IconMessage,
  IconUser,
  IconUserOff
} from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { JSONTree } from 'react-json-tree'
import { toast } from 'sonner'
import dayjs from '../../../../lib/dayjs'
import { App } from '../../../../types/App'
import { DisplayColumn, PlayItem } from '../../../../types/Play'
import { useCompletePlayItems, useDismissPlayItems, useSnoozePlayItems } from '../../../data/use-plays'
import { useUrlFilters } from '../../../data/use-url-filters'
import { BulkActionBar } from '../../../ui/BulkActionBar'
import { useColumns } from '../../../ui/ColumnSelector'
import EmptyState from '../../../ui/EmptyState'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import useUpdateEffect from '../../../ui/useUpdateEffect'
import { humanize } from '../../accounts/facets/filter-cloud'
import { AccountList } from '../../icps/icp/account-list'
import { Column, ProfileList } from '../../profiles/components/profile-list'
import { AssignModal } from './AssignModal'

interface PlayItemsTableProps {
  items: PlayItem[]
  apps: App[]
  recordType: 'Profile' | 'Account'
  displayColumns: DisplayColumn[]
  status?: string | null
  assigneeId?: string | null
  hideAssigneeColumn?: boolean
  onRemoveItem?: (itemId: string, status: string) => void
  onUpdateItem?: (itemId: string, updatedItem: PlayItem) => void
  onUpdateColumns?: (columns: DisplayColumn[]) => void
}

const enrollmentColumns: Column[] = [
  {
    id: 'enrollment',
    Title: () => <Th>Enrollment Trigger</Th>,
    Cell: ({ profile }) => {
      const context = profile.item?.context
      const enrollmentData = context?.enrollment_data

      if (context?.personas) {
        return (
          <Td maxW="140px">
            <HStack spacing={1} wrap="wrap">
              {context.personas.map((p) => (
                <Tag key={p.id} size="sm" variant="subtle" colorScheme="purple">
                  <TagLeftIcon as={IconUser} />
                  <TagLabel>{p.name}</TagLabel>
                </Tag>
              ))}
            </HStack>
          </Td>
        )
      }

      if (!enrollmentData) {
        return <Td maxW="140px" />
      }

      if (enrollmentData.source === 'linkedin' && enrollmentData.trigger === 'reaction') {
        return (
          <Td maxW="140px">
            <HStack>
              {enrollmentData.reaction_type === 'LIKE' && (
                <Tooltip label="Liked">
                  <Img w="5" src="https://static.licdn.com/aero-v1/sc/h/2uxqgankkcxm505qn812vqyss" />
                </Tooltip>
              )}
              {enrollmentData.reaction_type === 'PRAISE' && (
                <Tooltip label="Praised">
                  <Img src="https://static.licdn.com/aero-v1/sc/h/cm8d2ytayynyhw5ieaare0tl3" />
                </Tooltip>
              )}

              {enrollmentData.reaction_type === 'EMPATHY' && (
                <Tooltip label="Empathized">
                  <Img src="https://static.licdn.com/aero-v1/sc/h/f58e354mjsjpdd67eq51cuh49" />
                </Tooltip>
              )}

              {enrollmentData.reaction_type === 'INTEREST' && (
                <Tooltip label="Showed interest in">
                  <Img src="https://static.licdn.com/aero-v1/sc/h/6gz02r6oxefigck4ye888wosd" />
                </Tooltip>
              )}

              {enrollmentData.reaction_type === 'APPRECIATION' && (
                <Tooltip label="Appreciated">
                  <Img src="https://static.licdn.com/aero-v1/sc/h/e1vzxs43e7ryd6jfvu7naocd2" />
                </Tooltip>
              )}

              <Text as={TextEllipsis} maxW="100%" display="inline-block" isTruncated>
                {enrollmentData.post_content ?? enrollmentData.post_slug_name}
              </Text>

              <HelpTooltip
                mode="popover"
                opacity="1"
                trigger={
                  <IconButton variant="ghost" size="sm" as={IconExternalLink} aria-label="Open post" boxSize={3} />
                }
              >
                <Stack spacing="4">
                  <Stack spacing="1">
                    <Heading size="xs">Post:</Heading>
                    <Link href={enrollmentData.post_url} isExternal fontWeight="normal" colorScheme="purple">
                      {enrollmentData.post_slug_name}
                    </Link>
                  </Stack>
                  <Stack spacing="1">
                    <Heading size="xs">Reaction:</Heading>
                    <Text>{humanize(enrollmentData.reaction_type)}</Text>
                  </Stack>
                </Stack>
              </HelpTooltip>
            </HStack>
          </Td>
        )
      }

      if (enrollmentData.source === 'linkedin' && enrollmentData.trigger === 'comment') {
        return (
          <Td maxW="140px">
            <HStack>
              <Icon as={IconMessage} boxSize={5} />
              <Text as={TextEllipsis} maxW="100%" display="inline-block" isTruncated>
                {enrollmentData.post_content ?? enrollmentData.post_slug_name}
              </Text>

              <HelpTooltip
                opacity="1"
                mode="popover"
                trigger={
                  <IconButton variant="ghost" size="sm" as={IconExternalLink} aria-label="Open post" boxSize={3} />
                }
              >
                <Stack spacing="4">
                  <Stack spacing="1">
                    <Heading size="xs">Post:</Heading>
                    <Link href={enrollmentData.post_url} isExternal fontWeight="normal" colorScheme="purple">
                      {enrollmentData.post_slug_name}
                    </Link>
                  </Stack>

                  <Stack spacing="1">
                    <Heading size="xs">Comment:</Heading>
                    <Text>{enrollmentData.comment_text}</Text>
                  </Stack>
                </Stack>
              </HelpTooltip>
            </HStack>
          </Td>
        )
      }

      if (enrollmentData.source === 'github') {
        const actionText = {
          stargazer: 'Starred',
          issue: 'Created an issue in',
          pull_request: 'Created a pull request in'
        }[enrollmentData.trigger]

        const timestamp =
          enrollmentData.trigger === 'stargazer' ? enrollmentData.starred_at : enrollmentData.created_at_github

        return (
          <Td maxW="140px">
            <HStack alignItems="center" spacing="1">
              <Text as={'span'}>{actionText}</Text>
              <HelpTooltip
                opacity="1"
                mode="popover"
                trigger={
                  <Button
                    size="sm"
                    variant="link"
                    bg="gray.100"
                    p={1}
                    px="1.5"
                    leftIcon={<IconBrandGithub size={16} />}
                    as={Link}
                    href={`https://github.com/${enrollmentData.github_repo}`}
                    isExternal
                  >
                    {enrollmentData.github_repo}
                  </Button>
                }
              >
                <Stack spacing="4">
                  <Stack spacing="1">
                    <Heading size="xs">Repository:</Heading>
                    <Link
                      href={`https://github.com/${enrollmentData.github_repo}`}
                      isExternal
                      fontWeight="normal"
                      colorScheme="purple"
                    >
                      {enrollmentData.github_repo}
                    </Link>
                  </Stack>
                  <Stack spacing="1">
                    <Heading size="xs">GitHub Profile:</Heading>
                    <Link href={enrollmentData.github_user_url} isExternal fontWeight="normal" colorScheme="purple">
                      {enrollmentData.github_user_url.split('/').pop()}
                    </Link>
                  </Stack>
                  <Stack spacing="1">
                    <Heading size="xs">When:</Heading>
                    <Text>
                      <TimeAgo time={timestamp} mode="relative" canToggle={false} />
                    </Text>
                  </Stack>
                </Stack>
              </HelpTooltip>
              <TimeAgo time={timestamp} mode="relative" canToggle={false} />
            </HStack>
          </Td>
        )
      }

      return (
        <Td minW="140px" maxW="140px">
          <Flex alignItems="center" gap={1}>
            <JSONTree data={enrollmentData} />
          </Flex>
        </Td>
      )
    }
  },
  {
    id: 'enrolled_at',
    Title: () => <Th>Enrolled At</Th>,
    Cell: ({ profile }) => {
      const enrollmentData = profile.item?.created_at
      if (!enrollmentData) {
        return null
      }

      return (
        <Td>
          <TimeAgo time={enrollmentData} mode="relative" canToggle={false} />
        </Td>
      )
    }
  }
]

export function PlayItemsTable({
  items,
  recordType,
  displayColumns,
  status,
  // TODO find a way to show the assignee column
  // hideAssigneeColumn,
  onUpdateItem,
  onRemoveItem,
  apps
  // TODO hook this up to an "add column" button in the table itself
  // onUpdateColumns
}: PlayItemsTableProps) {
  const checkboxes = useCheckboxGroup()
  const [isSnoozeModalOpen, setIsSnoozeModalOpen] = useState(false)
  const [isDismissModalOpen, setIsDismissModalOpen] = useState(false)
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)

  // include the play_item in the record (inverted) to work with the AccountList and ProfileList components
  const records = useMemo(() => {
    return items.map(({ record, ...item }) => ({ ...record, item }))
  }, [items])

  const recordsById = useMemo(() => {
    return records.reduce(
      (acc, record) => {
        acc[record.id] = record
        return acc
      },
      {} as Record<string, PlayItem['record']>
    )
  }, [records])

  // const selectedIds =  Object.keys(selectedRows).filter((id) => selectedRows[id])
  // we have to convert the selected record ids to their item ids
  const selectedIds = useMemo(() => {
    return checkboxes.value.map((recordId) => {
      return recordsById[recordId]?.item?.id
    })
  }, [checkboxes.value, recordsById])

  // reset the selected rows when the list changes
  useUpdateEffect(() => {
    checkboxes.setValue([])
  }, [items, recordType])

  const { mutate: completeMultiple, isLoading: isCompleting } = useCompletePlayItems({
    onSuccess: () => {
      selectedIds.forEach((id) => onRemoveItem?.(id, 'completed'))
      checkboxes.setValue([])
      toast.success('Items marked as complete')
    },
    onError: (error) => {
      toast.error('Failed to mark items as completed', { description: error.message })
    }
  })

  const { mutate: snoozeMultiple, isLoading: _isSnoozing } = useSnoozePlayItems({
    onSuccess: () => {
      selectedIds.forEach((id) => onRemoveItem?.(id, 'snoozed'))
      checkboxes.setValue([])
      setIsSnoozeModalOpen(false)
      toast.success('Items snoozed')
    },
    onError: (error) => {
      toast.error('Failed to snooze items', { description: error.message })
    }
  })

  const { mutate: dismissMultiple, isLoading: isDismissing } = useDismissPlayItems({
    onSuccess: () => {
      selectedIds.forEach((id) => onRemoveItem?.(id, 'dismissed'))
      checkboxes.setValue([])
      setIsDismissModalOpen(false)
      setFeedback('')
      toast.success('Items dismissed')
    },
    onError: (error) => {
      toast.error('Failed to dismiss items', { description: error.message })
    }
  })

  const aiSlugs = useMemo(() => {
    return displayColumns?.filter((c) => c.type === 'agent')?.map((c) => c.id) ?? []
  }, [displayColumns])

  const fieldColumns = useMemo(() => {
    return displayColumns?.filter((c) => c.type === 'field')?.map((c) => c.id) ?? []
  }, [displayColumns])

  const _facets = useUrlFilters({
    initialRange: 'all',
    facetCloudPath: recordType === 'Profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud'
  })

  const {
    columns,
    loadingColumns,
    onColumnChange: _onColumnChange,
    onColumnRemove: _onColumnRemove
  } = useColumns({
    data: records,
    columns: fieldColumns,
    initialColumns: fieldColumns,
    updateUrl: false
  })

  // TODO hook up the enrollment metadata column
  const includeEnrollmentColumn = useMemo(() => {
    return items.some((item) => item.context?.enrollment_data || item.context?.personas)
  }, [items])

  if (!items?.length) {
    return (
      <EmptyState
        size="sm"
        icon={IconUserOff}
        heading={status ? `No ${status} leads yet` : 'No leads yet'}
        description={status ? `There are no ${status} items in this play yet.` : 'There are no leads in this play yet.'}
      />
    )
  }

  return (
    <>
      {recordType === 'Account' ? (
        <AccountList
          apps={apps}
          accounts={records}
          selected={checkboxes.value}
          getCheckboxProps={checkboxes.getCheckboxProps}
          onSelectAll={() => {
            checkboxes.setValue((prev) => (prev.length === records.length ? [] : records.map((p) => p.id)))
          }}
          range="any"
          companyCellMode="task-mode"
          // TODO hook up the sorting
          // sortingBy={facets.sortBy}
          // onSortChange={facets.setSortBy}
          columns={columns}
          loadingColumns={loadingColumns}
          // TODO hook up the column changes
          // onColumnChange={onColumnChange}
          // onColumnRemove={onColumnRemove}
          // TODO hook up the add column button
          // canAddColumns
          aiColumns={aiSlugs}
          onCompleteItem={onRemoveItem}
          onDismissItem={onRemoveItem}
          onResetItem={onRemoveItem}
          onUpdateItem={onUpdateItem}
        />
      ) : (
        <ProfileList
          apps={apps}
          profiles={records}
          selected={checkboxes.value}
          getCheckboxProps={checkboxes.getCheckboxProps}
          onSelectAll={() => {
            checkboxes.setValue((prev) => (prev.length === records.length ? [] : records.map((p) => p.id)))
          }}
          range="any"
          personCellMode="task-mode"
          // TODO hook up the sorting
          // sortingBy={facets.sortBy}
          // onSortChange={facets.setSortBy}
          columns={columns}
          loadingColumns={loadingColumns}
          // TODO hook up the column changes
          // onColumnChange={onColumnChange}
          // onColumnRemove={onColumnRemove}
          // TODO hook up the add column button
          // canAddColumns
          playEnrollmentColumns={includeEnrollmentColumn ? enrollmentColumns : []}
          aiColumns={aiSlugs}
          onCompleteItem={onRemoveItem}
          onDismissItem={onRemoveItem}
          onResetItem={onRemoveItem}
          onUpdateItem={onUpdateItem}
        />
      )}

      <BulkActionBar selectionCount={selectedIds.length} onRemoveSelection={() => checkboxes.setValue([])}>
        {status !== 'completed' && (
          <Button
            variant="outline"
            size="sm"
            iconSpacing={1.5}
            isLoading={isCompleting}
            leftIcon={<IconCheck size={16} />}
            onClick={() => completeMultiple({ itemIds: selectedIds })}
          >
            Complete
          </Button>
        )}

        {status !== 'dismissed' && (
          <Menu>
            <MenuButton
              as={Button}
              variant="outline"
              size="sm"
              iconSpacing={1}
              rightIcon={<IconChevronDown size={14} />}
              isLoading={isDismissing}
            >
              Dismiss
            </MenuButton>
            <Portal>
              <MenuList>
                {dismissReasons.map(([label, reason]) => (
                  <MenuItem key={reason} onClick={() => dismissMultiple({ itemIds: selectedIds, reason })}>
                    {label}
                  </MenuItem>
                ))}
                <MenuDivider />
                <MenuItem onClick={() => setIsDismissModalOpen(true)}>Other</MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        )}

        <Button size="sm" variant="outline" onClick={() => setIsAssignModalOpen(true)}>
          Assign
        </Button>
      </BulkActionBar>

      {/* Snooze Modal */}
      <Modal isOpen={isSnoozeModalOpen} onClose={() => setIsSnoozeModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Snooze items until...</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <DayPicker
              mode="single"
              defaultMonth={dayjs().add(1, 'day').toDate()}
              fromDate={dayjs().add(1, 'day').toDate()}
              toDate={dayjs().add(90, 'days').toDate()}
              selected={selectedDate}
              onSelect={setSelectedDate}
              components={{
                IconLeft: () => <IconChevronLeft size={16} />,
                IconRight: () => <IconChevronRight size={16} />
              }}
              numberOfMonths={1}
            />
          </ModalBody>
          <ModalFooter>
            <Button size="sm" variant="outline" onClick={() => setIsSnoozeModalOpen(false)} mr={3}>
              Cancel
            </Button>
            <Button
              size="sm"
              variant="solid"
              colorScheme="purple"
              onClick={() => {
                if (selectedDate) {
                  const snoozeDate = dayjs(selectedDate).hour(9).minute(0).second(0)
                  snoozeMultiple({ itemIds: selectedIds, until: snoozeDate.format() })
                }
              }}
              isDisabled={!selectedDate}
            >
              Snooze
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Dismiss Modal */}
      <Modal isOpen={isDismissModalOpen} onClose={() => setIsDismissModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dismiss items</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing={4}>
              <Text fontSize="sm" color="gray.700">
                Why are you dismissing these items?
              </Text>
              <Textarea
                placeholder="Share your feedback..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                size="sm"
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" variant="outline" onClick={() => setIsDismissModalOpen(false)} mr={3}>
              Cancel
            </Button>
            <Button
              size="sm"
              variant="solid"
              colorScheme="purple"
              isLoading={isDismissing}
              onClick={() => dismissMultiple({ itemIds: selectedIds, reason: 'other', feedback })}
            >
              Dismiss
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isAssignModalOpen && (
        <AssignModal
          isOpen={isAssignModalOpen}
          onClose={() => setIsAssignModalOpen(false)}
          items={items.filter((item) => selectedIds.includes(item.id))}
          onAssign={(items) => {
            items.forEach((item) => onUpdateItem?.(item.id, item))
          }}
        />
      )}
    </>
  )
}

const dismissReasons: [string, string][] = [
  ['Wrong territory', 'not_my_territory'],
  ['Not ICP', 'not_in_icp'],
  ['Bad lead', 'bad_lead']
]
