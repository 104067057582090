import { Button, Divider, Flex, Link, Stack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Apps } from '../../../types/App'
import { Play } from '../../../types/Play'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Breadcrumb } from '../../ui/Breadcrumb'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { TopBarContent } from '../../ui/TopBarContext'
import { PlayForm } from './components/play-form'

interface Props {
  play: Play
  apps: Apps
}

export default function Edit({ play, apps }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <PageLayout>
      <TopBarContent>
        <Breadcrumb
          paths={[
            { path: projectPath('/plays'), title: 'Plays' },
            { path: projectPath(`/plays/${play.id}`), title: play.name },
            { path: projectPath(`/plays/${play.id}/edit`), title: 'Edit', isCurrentPage: true }
          ]}
        />
      </TopBarContent>

      <PageTitle>Edit Play</PageTitle>
      <Divider />
      <Stack
        as="form"
        action={projectPath(`/plays/${play.id}`)}
        method="post"
        encType="multipart/form-data"
        onSubmit={() => setIsSubmitting(true)}
      >
        <PlayForm play={play} apps={apps} />

        <Flex alignItems="center" gap={2}>
          <Button colorScheme="purple" type="submit" size="sm" minWidth="100px" isLoading={isSubmitting}>
            Save Changes
          </Button>
          <Button size="sm" variant="outline" as={Link} href={projectPath('/plays')}>
            Cancel
          </Button>
        </Flex>

        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />
      </Stack>
    </PageLayout>
  )
}
