import React, { useEffect, useMemo, useState } from 'react'

import { projectPath } from '../../../ui/ProjectsContext'

import { ConnectOauthAppDialog } from '@app/components/pages/apps/components/ConnectOauthAppDialog'

import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import type { Project } from '@app/types/Project'
import PageDescription from '../../../ui/PageDescription'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'

import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import { TimeAgo } from '@app/components/ui/TimeAgo'
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Link,
  Progress,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { IconBrandGithub, IconPlus, IconSearch, IconTrash } from '@tabler/icons-react'
import { toast } from 'sonner'
import { useDebounce } from 'use-debounce'
import { concurrentGET } from '../../../../lib/api'
import { formatNumber } from '../../../../lib/number-format'
import { useAppDep } from '../../../data/use-app-dep'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import Avatar from '../../../ui/Avatar'
import { LightBgCard } from '../../../ui/Card'
import { CardRadioGroup } from '../../../ui/CardRadioGroup'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { NumberEasing } from '../../../ui/NumberEasing'
import { usePermission } from '../../../ui/PermissionsContext'
import useUnsavedChangesPrompt from '../../../ui/useUnsavedChangesPrompt'
import { humanize } from '../../accounts/facets/filter-cloud'
import { Introduction } from '../components/Introduction'

interface RepoStats {
  repo_name: string
  stats: {
    stargazers: {
      total: number
      synced: number
      last_synced_at: string
    }
    issues: {
      total: number
      synced: number
      last_synced_at: string
    }
    pull_requests: {
      total: number
      synced: number
      last_synced_at: string
    }
    forks: {
      total: number
      synced: number
      last_synced_at: string
    }
  }
}

interface Props {
  app_id: string
  project: Project
  title: string
  description: string
  logo: string
  valid?: boolean
  connected?: boolean
  deps: {
    repo_stats: Record<string, RepoStats>
  }
  settings: {
    profile_tracking?: 'strict' | 'all'
    watched_repos?: {
      repo_name: string
      track_stargazers?: '1'
      track_issues?: '1'
      track_forks?: '1'
      track_pull_requests?: '1'
    }[]
  }
}

interface SearchResult {
  id: string
  name: string
  nameWithOwner: string
  description: string
  url: string
  stargazerCount: number
  forkCount: number
  owner: {
    __typename: 'Organization'
    login: string
    name: string
    websiteUrl: string
    email: string | null
    avatarUrl: string | null
  }
  cursor: null
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject } = usePermission({
    on: 'project',
    action: 'can_edit'
  })
  const [watchedRepos, setWatchedRepos] = useState<string[]>(
    props.settings.watched_repos?.map((r) => r.repo_name) || []
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [stats, setStats] = useState<Record<string, RepoStats>>(props.deps.repo_stats)

  const [searchQuery, setSearchQuery] = useState<string | undefined>()
  const [query] = useDebounce(searchQuery, 300)

  const { data: searchResultsData, isLoading: searchResultsLoading } = useAppDep<'search_repositories', SearchResult[]>(
    props.app_id,
    'search_repositories',
    { query },
    true,
    Boolean(query)
  )

  const hasChanges = useMemo(() => {
    const watchedRepoNamesFromSettings = (props.settings.watched_repos ?? []).map((r) => r.repo_name)
    return watchedRepos.length !== watchedRepoNamesFromSettings.length
  }, [watchedRepos, props.settings.watched_repos])

  useUnsavedChangesPrompt({ hasChanges })

  useEffect(() => {
    const shouldFetchStats = () => {
      return (
        watchedRepos.length > 0 &&
        watchedRepos.some((repo) => {
          const repoStats = stats[repo]?.stats
          if (!repoStats) return true
          return ['stargazers', 'issues', 'pull_requests'].some(
            (signal) => calculateProgress(repoStats[signal].synced, repoStats[signal].total) < 100
          )
        })
      )
    }

    const fetchStats = async () => {
      if (!shouldFetchStats()) return

      try {
        const response = await concurrentGET<Props>(window.location.toString() + '.json')
        const newStats = response.deps.repo_stats
        setStats(newStats)
      } catch (error) {
        console.error('Error fetching GitHub stats:', error)
      }
    }

    if (shouldFetchStats()) {
      const intervalId = setInterval(fetchStats, 5000)
      return () => clearInterval(intervalId)
    }
  }, [props.project.id, watchedRepos, stats])

  const removeRepo = (index: number) => {
    const updatedRepos = [...watchedRepos]
    updatedRepos.splice(index, 1)
    setWatchedRepos(updatedRepos)
  }

  const calculateProgress = (synced: number, total: number) => {
    if (total === 0) return 0
    return Number(((synced / total) * 100).toFixed(2))
  }

  const [repoSettings, setRepoSettings] = useState<
    Record<
      string,
      {
        track_stargazers?: '1'
        track_issues?: '1'
        track_pull_requests?: '1'
        track_forks?: '1'
      }
    >
  >(
    props.settings.watched_repos?.reduce(
      (acc, r) => {
        acc[r.repo_name] = r
        return acc
      },
      {} as Record<
        string,
        {
          track_stargazers?: '1'
          track_issues?: '1'
          track_pull_requests?: '1'
          track_forks?: '1'
        }
      >
    ) ?? {}
  )

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/github'),
            title: 'GitHub'
          }
        ]}
        offscreen
      />
      <HStack w="100%">
        <Box w="100%">
          <HStack>
            <HStack marginRight="auto" alignItems="center" spacing={2}>
              <Image src={props.logo} maxW="6" />
              <PageTitle>{props.title}</PageTitle>
              {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
              {props.connected && !props.valid && <Badge colorScheme="orange">Requires Reconnection</Badge>}
            </HStack>
            {props.connected && <DisconnectAppDialog appTitle="GitHub" showRemoveCachesOption={true} />}
          </HStack>

          <PageDescription>{props.description}</PageDescription>
        </Box>
      </HStack>

      <Introduction
        app="GitHub"
        icon={props.logo}
        description="Connect your GitHub account to pull in issues and pull requests from your watched repositories."
      />

      {!props.connected && (
        <LightBgCard p={5}>
          <ConnectOauthAppDialog {...props} />
        </LightBgCard>
      )}

      <Divider />

      {props.connected && (
        <form
          method="POST"
          onSubmit={(_e) => {
            setIsSubmitting(true)
          }}
        >
          <AuthenticityToken />
          <input type="hidden" name="_method" value="PUT" />

          <Stack spacing="8">
            <FormControl>
              <FormLabel fontWeight="bold" fontSize={'md'}>
                Profile Identity Tracking
              </FormLabel>

              <CardRadioGroup
                defaultValue={props.settings.profile_tracking ?? 'strict'}
                name="app_instance_settings[profile_tracking]"
                options={[
                  {
                    value: 'strict',
                    label: (
                      <HStack>
                        <Text fontWeight="medium">Strict</Text>
                        <Badge colorScheme="gray">Recommended</Badge>
                      </HStack>
                    ),
                    description: 'Only track Github profiles that can be associated to an email or company.'
                  },
                  {
                    value: 'all',
                    label: 'Track everyone',
                    description:
                      'Track all Github profiles, regardless of whether they can be associated to an email or company.'
                  }
                ]}
              />

              <FormHelperText pt="4">
                Note: Changing from Track Everyone to Strict will not remove existing profiles that could not be
                associated to an email or company. You can hide those profiles in your Lists by adding a filter.
              </FormHelperText>
            </FormControl>

            <Divider />

            <LightBgCard p={5} as={Stack} align="stretch">
              <Stack align="stretch" spacing={6}>
                <Text fontWeight="bold">Watched GitHub Repositories</Text>
                <Text fontSize="sm" color="gray.500">
                  Repositories will be watched for new issues and pull requests. You can add or remove repositories
                  below.
                </Text>

                {watchedRepos.length === 0 && (
                  <input type="hidden" name="app_instance_settings[watched_repos]" value="" />
                )}

                <Stack>
                  <ComboboxWithSearch
                    items={searchResultsData?.data?.search_repositories ?? []}
                    selectedItem={null}
                    isLoading={searchResultsLoading}
                    selectButtonRenderer={() => (
                      <HStack>
                        <IconSearch size={16} />
                        <IconBrandGithub size={16} />
                        <Text fontSize="sm">Search for public a repository...</Text>
                      </HStack>
                    )}
                    onInputValueChange={(val) => {
                      setSearchQuery(val)
                    }}
                    onChange={(repo) => {
                      if (repo) {
                        setWatchedRepos([...watchedRepos, repo.nameWithOwner])
                      }
                    }}
                    filterItem={(a, val) => a?.nameWithOwner.toLowerCase().includes(val) ?? false}
                    inputProps={{
                      onPasteCapture: (e) => {
                        const text = e.clipboardData.getData('text')
                        const match = text.match(/https:\/\/github\.com\/([^/]+)\/([^/]+)/)
                        if (match) {
                          e.preventDefault()
                          e.stopPropagation()
                          ;(e.target as HTMLInputElement).value = match[1] + '/' + match[2]
                          setSearchQuery(match[1] + '/' + match[2])
                        }
                      }
                    }}
                    itemRenderer={({ item }) => (
                      <Box
                        w="100%"
                        onClick={(e) => {
                          if (e.target instanceof HTMLAnchorElement) return

                          e.stopPropagation()
                          e.preventDefault()

                          toast.info(`Added ${item.nameWithOwner} to watch list`)

                          if (watchedRepos.includes(item.nameWithOwner)) return
                          setWatchedRepos([item.nameWithOwner, ...watchedRepos])

                          setRepoSettings({
                            ...repoSettings,
                            [item.nameWithOwner]: {
                              track_stargazers: '1',
                              track_issues: '1',
                              track_pull_requests: '1',
                              track_forks: undefined
                            }
                          })
                        }}
                      >
                        <HStack w="100%">
                          <Avatar size="xs" src={item?.owner?.avatarUrl} />
                          <Link isExternal href={item?.url} fontSize="sm">
                            {item?.nameWithOwner}
                          </Link>
                          <Flex flex="1" justifyContent="flex-end">
                            <IconButton
                              size="xs"
                              variant={'ghost'}
                              aria-label="Add repository"
                              icon={<IconPlus size={16} />}
                            />
                          </Flex>
                        </HStack>
                      </Box>
                    )}
                  />
                </Stack>

                <Divider />

                <Stack divider={<Divider />} spacing="8">
                  {watchedRepos.map((repo, index) => {
                    return (
                      <Stack key={index} align="stretch" spacing={4}>
                        <Text fontSize="sm" fontWeight="bold">
                          Repo
                        </Text>
                        <HStack>
                          <Input
                            name={`app_instance_settings[watched_repos][][repo_name]`}
                            value={repo}
                            onChange={(e) => {
                              const updatedRepos = [...watchedRepos]
                              updatedRepos[index] = e.target.value
                              setWatchedRepos(updatedRepos)
                            }}
                            title="Please enter a valid repository name in the format: owner/repo_name"
                            pattern="[A-Za-z0-9_\.\-]+\/[A-Za-z0-9_\.\-]+"
                            required
                          />
                          <IconButton
                            onClick={() => removeRepo(index)}
                            colorScheme="gray"
                            size="sm"
                            variant={'ghost'}
                            icon={<IconTrash size={16} />}
                            aria-label="Remove repository"
                          />
                        </HStack>
                        <FormControl
                          as={Stack}
                          align="start"
                          spacing={1}
                          pt="2"
                          borderWidth={'thin'}
                          rounded="md"
                          p="4"
                          py="2"
                        >
                          <FormLabel pt="0" mt="0">
                            Signals:
                          </FormLabel>
                          <Checkbox
                            isChecked={repoSettings[repo]?.track_stargazers === '1'}
                            onChange={(e) => {
                              setRepoSettings({
                                ...repoSettings,
                                [repo]: {
                                  ...repoSettings[repo],
                                  track_stargazers: e.target.checked ? '1' : undefined
                                }
                              })
                            }}
                            name={`app_instance_settings[watched_repos][][track_stargazers]`}
                            value={'1'}
                          >
                            Stargazers
                          </Checkbox>
                          <Checkbox
                            isChecked={repoSettings[repo]?.track_issues === '1'}
                            onChange={(e) => {
                              setRepoSettings({
                                ...repoSettings,
                                [repo]: {
                                  ...repoSettings[repo],
                                  track_issues: e.target.checked ? '1' : undefined
                                }
                              })
                            }}
                            name={`app_instance_settings[watched_repos][][track_issues]`}
                            value={'1'}
                          >
                            Issues
                          </Checkbox>
                          <Checkbox
                            isChecked={repoSettings[repo]?.track_pull_requests === '1'}
                            onChange={(e) => {
                              setRepoSettings({
                                ...repoSettings,
                                [repo]: {
                                  ...repoSettings[repo],
                                  track_pull_requests: e.target.checked ? '1' : undefined
                                }
                              })
                            }}
                            name={`app_instance_settings[watched_repos][][track_pull_requests]`}
                            value={'1'}
                          >
                            Pull Requests
                          </Checkbox>

                          <Checkbox
                            onChange={(e) => {
                              setRepoSettings({
                                ...repoSettings,
                                [repo]: { ...repoSettings[repo], track_forks: e.target.checked ? '1' : undefined }
                              })
                            }}
                            isChecked={repoSettings[repo]?.track_forks === '1'}
                            name={`app_instance_settings[watched_repos][][track_forks]`}
                            value={'1'}
                          >
                            Forks
                          </Checkbox>

                          <FormHelperText pt="2" pb="2" fontSize={'xs'}>
                            Choose which signals you want to track for this repository. <br />
                            Koala will automatically create Track Events for each signal it can tie an identity to.
                          </FormHelperText>
                        </FormControl>
                        {stats[repo] && (
                          <Box p={4} bg="gray.100" borderRadius="md">
                            <HStack alignItems="center" mb="2">
                              <Text fontSize="sm" fontWeight="bold">
                                Sync Progress:
                              </Text>
                              <HelpTooltip>
                                <Stack fontSize="xs">
                                  <Heading size="xs">Sync Progress</Heading>
                                  <Text>Koala will sync all watched repositories once per hour.</Text>
                                  <Text>
                                    This may take a while depending on the number of watched repositories and the number
                                    of signals to sync.
                                  </Text>
                                </Stack>
                              </HelpTooltip>
                            </HStack>
                            <TableContainer>
                              <Table size="sm" variant="simple">
                                <Thead fontWeight={'semibold'}>
                                  <Tr>
                                    <Th>Signal</Th>
                                    <Th isNumeric>Synced</Th>
                                    <Th isNumeric>Total</Th>
                                    <Th isNumeric>Last Synced</Th>
                                    <Th isNumeric>Progress</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {['stargazers', 'issues', 'pull_requests', 'forks'].map((signal) => {
                                    const repoStats = stats[repo].stats[signal]
                                    const progress = calculateProgress(repoStats.synced, repoStats.total)

                                    return (
                                      <Tr key={signal} fontSize="xs">
                                        <Td fontSize="xs">{humanize(signal)}</Td>
                                        <Td fontSize="xs" isNumeric>
                                          <NumberEasing
                                            value={repoStats.synced}
                                            speed={1000}
                                            decimals={0}
                                            ease="quadOut"
                                            transitionWidth
                                            render={(n) => Math.floor(n).toLocaleString()}
                                          />
                                        </Td>

                                        <Td fontSize="xs" isNumeric>
                                          {formatNumber(repoStats.total)}
                                        </Td>
                                        <Td fontSize="xs" isNumeric>
                                          {repoStats.last_synced_at && <TimeAgo time={repoStats.last_synced_at} />}
                                          {!repoStats.last_synced_at && <Text fontSize="xs">--</Text>}
                                        </Td>
                                        <Td fontSize="xs">
                                          <Flex alignItems="center" justifyContent={'flex-end'} w="100%">
                                            <Progress isAnimated value={progress} size="xs" width="50px" mr={2} />
                                            <Text w="50px" fontSize="xs" textAlign="left">
                                              <NumberEasing
                                                value={progress}
                                                speed={1000}
                                                decimals={2}
                                                ease="quadOut"
                                                transitionWidth
                                                render={(n) => formatNumber(n) + '%'}
                                              />
                                            </Text>
                                          </Flex>
                                        </Td>
                                      </Tr>
                                    )
                                  })}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          </Box>
                        )}
                      </Stack>
                    )
                  })}
                </Stack>
              </Stack>

              <Text fontSize="sm" color="gray.500" pt="8">
                Note: Koala will only issue Track Events for github profiles that include emails, and actions that have
                been taken in the past 12 months.
              </Text>
            </LightBgCard>

            <Button colorScheme="purple" type="submit" w="100%" isDisabled={isSubmitting || !canEditProject}>
              Save
            </Button>
          </Stack>
        </form>
      )}
    </PageLayout>
  )
}
