import { LinkProps, Text, TextProps, Tooltip } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useOverflow } from './useOverflow'

export function TextEllipsis(
  props: TextProps &
    LinkProps & {
      maxW?: TextProps['maxW']
      value?: string
      tooltip?: boolean
    }
) {
  const { scrollRef, overflowRight } = useOverflow()

  const { tooltip, ...rest } = props

  const showTooltip = useMemo(() => {
    if (!tooltip) {
      return false
    }

    return overflowRight
  }, [tooltip, overflowRight])

  return (
    <Tooltip label={showTooltip ? props.value || props.children : undefined} fontSize="xs" openDelay={300}>
      <Text ref={scrollRef} as="span" display="inline-block" isTruncated {...rest} />
    </Tooltip>
  )
}
