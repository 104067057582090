import React, { useCallback } from 'react'
import { toast } from 'sonner'
import { PlayItem } from '../../../../types/Play'
import { useAssignPlayItem } from '../../../data/use-plays'
import { UserSelector } from '../../../ui/UserSelector'

interface AssigneeSelectProps {
  itemId: string
  userId: string | null
  onUpdate?: (itemId: string, updatedItem: PlayItem) => void
}

export function AssigneeSelect({ itemId, userId, onUpdate }: AssigneeSelectProps) {
  const assignMutation = useAssignPlayItem({
    onSuccess: (updatedItem) => {
      onUpdate?.(itemId, updatedItem)
    },
    onError: (error: any) => {
      toast.error('There was an issue assigning this lead', { description: error?.body?.message || error?.message })
    }
  })

  const handleChange = useCallback(
    (newUserId: string | null) => {
      assignMutation.mutate({ itemId, userId: newUserId })
    },
    [assignMutation, itemId]
  )

  return <UserSelector selectedUserId={userId} onChange={handleChange} allowUnassigned />
}
