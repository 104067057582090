import {
  Box,
  Button,
  Checkbox,
  Code,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconMapSearch, IconReplaceUser, IconX } from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { Apps } from '../../../../types/App'
import { AssignmentConfig, Play } from '../../../../types/Play'
import { facetQueryString, useFacets } from '../../../data/use-facets'
import { useTerritories } from '../../../data/use-territories'
import { useUsers } from '../../../data/use-users'
import { GrayCard } from '../../../ui/Card'
import { CardRadioGroup } from '../../../ui/CardRadioGroup'
import { SalesforceIcon } from '../../../ui/icons'
import { UsersAvatarGroup } from '../../../ui/UsersAvatarGroup'
import { UserSelector } from '../../../ui/UserSelector'
import { FilterPreview } from '../../accounts/components/FilterPreview'

const routingOptions = [
  {
    label: 'CRM Owner',
    value: 'crm_owner',
    icon: <SalesforceIcon size={16} />,
    description: 'Assign leads based on ownership in your CRM.'
  },
  {
    label: 'Round Robin',
    value: 'round_robin',
    icon: <IconReplaceUser size={16} />,
    description: 'Assign leads evenly among team members.'
  },
  {
    label: 'Territory',
    value: 'territory',
    icon: <IconMapSearch size={16} />,
    description: 'Assign leads to your reps based on territories.'
  }
]

const emptyObject = {}
const emptyArray = []

export const AssignmentSetup = (props: { play: Partial<Play>; apps: Apps }) => {
  const assignmentConfig = props.play?.assignment_config || ({} as AssignmentConfig)
  const [assignmentType, setAssignmentType] = useState(assignmentConfig?.assignment_type ?? 'unassigned')

  const { data: territoryData } = useTerritories()
  const [selectedTerritories, setSelectedTerritories] = useState<string[]>(assignmentConfig?.territories ?? [])

  const handleTerritorySelect = useCallback((territoryId: string) => {
    setSelectedTerritories((prev) =>
      prev.includes(territoryId) ? prev.filter((id) => id !== territoryId) : [...prev, territoryId]
    )
  }, [])

  const assignees = assignmentConfig?.assignees?.map((a) => a.id) ?? []

  const facets = useFacets({
    facet_filters: assignmentConfig?.filters || emptyObject
  })

  const filters = useMemo(
    () => facetQueryString(facets.facetFilters || assignmentConfig?.filters, ''),
    [facets.facetFilters, assignmentConfig?.filters]
  )

  const [assigneeIds, setAssigneeIds] = useState(assignees)

  const handleAssigneeChange = useCallback((index: number, userId: string | null) => {
    setAssigneeIds((prev) => {
      const newUserIds = [...prev]
      if (userId) {
        newUserIds[index] = userId
      } else {
        newUserIds.splice(index, 1)
      }
      return newUserIds
    })
  }, [])

  const handleRemoveAssignee = useCallback((index: number) => {
    setAssigneeIds((prev) => prev.filter((_, i) => i !== index))
  }, [])

  const [routingType, setRoutingType] = useState<AssignmentConfig['routing_type'] | undefined>(
    assignmentConfig?.routing_type
  )
  const selectedRoutingOption = routingOptions.find((o) => o.value === routingType)

  const users = useUsers()

  return (
    <Stack spacing="6" as={GrayCard}>
      <FormControl id="play[assignment_config][assignment_type]" isRequired>
        <FormLabel>Assignment</FormLabel>
        <RadioGroup
          defaultValue={assignmentType}
          name="play[assignment_config][assignment_type]"
          onChange={(e) => setAssignmentType(e as 'shared' | 'specific_users' | 'unassigned')}
        >
          <Stack spacing={5} direction="row">
            <Radio value="unassigned">
              <Text fontSize="sm">Unassigned</Text>
            </Radio>
            <Radio value="shared">
              <Text fontSize="sm">Everyone</Text>
            </Radio>
            <Radio value="specific_users">
              <Text fontSize="sm">Specific users</Text>
            </Radio>
          </Stack>
        </RadioGroup>

        {assignmentType === 'unassigned' && (
          <FormHelperText fontSize="13px" lineHeight={1.5}>
            Leads will not be automatically assigned to anyone. This play will not show up in anyone's inbox.
          </FormHelperText>
        )}

        {assignmentType === 'shared' && (
          <FormHelperText fontSize="13px" lineHeight={1.5}>
            Every member and admin in your workspace can be assigned leads in this play, based on how you want to route
            the leads (configured below).
          </FormHelperText>
        )}
        {assignmentType === 'specific_users' && (
          <FormHelperText fontSize="13px" lineHeight={1.5}>
            Only the users you select below can be assigned leads in this play, based on how you want to route the leads
            (configured below).
          </FormHelperText>
        )}
      </FormControl>

      {assignmentType === 'specific_users' && (
        <FormControl isRequired>
          {assigneeIds.map((id) => (
            <input type="hidden" key={id} name="play[assignment_config][assignees][][id]" value={id} />
          ))}
          <FormLabel>Selected users</FormLabel>
          <Stack spacing={2}>
            {[...assigneeIds, ''].map((assigneeId, idx) => (
              <Flex width="100%" key={`${assigneeId}:${idx}`} alignItems="center" gap={3}>
                <Box flex="1 1 auto">
                  <UserSelector
                    users={users.data?.users || emptyArray}
                    selectedUserId={assigneeId}
                    onChange={(newAssigneeId) => handleAssigneeChange(idx, newAssigneeId)}
                    popperOptions={{ matchWidth: true }}
                    variant="outline"
                  />
                </Box>
                <IconButton
                  aria-label="Remove user"
                  size="xs"
                  variant="ghost"
                  color="gray.500"
                  _hover={{ color: 'gray.800' }}
                  icon={<IconX size={16} />}
                  isDisabled={idx >= assigneeIds.length}
                  onClick={() => handleRemoveAssignee(idx)}
                />
              </Flex>
            ))}
          </Stack>
        </FormControl>
      )}

      {assignmentType !== 'unassigned' && (
        <FormControl id="play[assignment_config][filters]" isRequired>
          <FormLabel>How should we route the leads?</FormLabel>

          <Stack spacing={4}>
            {!selectedRoutingOption ? (
              <CardRadioGroup
                size="sm"
                direction="column"
                name="play[assignment_config][routing_type]"
                options={routingOptions}
                value={routingType}
                onChange={(e) => {
                  setRoutingType(e as 'crm_owner' | 'round_robin' | 'territory')
                }}
              />
            ) : (
              <Box bg="white" borderWidth="1.5px" borderRadius="md" px={3} py={3}>
                <Flex justify="space-between" align="center">
                  <Flex alignItems="center" gap={2}>
                    <Flex px={1.5}>{selectedRoutingOption?.icon}</Flex>
                    <Stack spacing={0.5}>
                      <Text fontSize="sm" fontWeight="medium" lineHeight="18px">
                        {selectedRoutingOption?.label}
                      </Text>
                      <Text fontSize="xs" color="gray.500">
                        {selectedRoutingOption?.description}
                      </Text>
                    </Stack>
                  </Flex>
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => {
                      setRoutingType(undefined)
                    }}
                  >
                    Change
                  </Button>
                </Flex>
              </Box>
            )}

            {routingType && <input type="hidden" name="play[assignment_config][routing_type]" value={routingType} />}

            {routingType === 'crm_owner' && (
              <Box pt={2}>
                <FilterPreview
                  {...facets}
                  kind="account"
                  apps={props.apps}
                  canClearFilters={false}
                  topFilters={emptyArray}
                  shouldShowIntentFilters={false}
                  shouldShow3rdPartyFilters={false}
                  shouldShowActiveVisitorsFilters={false}
                  shouldShowLastSeenFilter={false}
                  shouldShowUserAttributeFilters={false}
                  shouldShowTraits={false}
                  range={undefined}
                />

                <FormHelperText pt="2">
                  You can use ownership fields with the <Code color="pink.600">Current user</Code> value which is scoped
                  to the current viewer.
                </FormHelperText>
                <input type="hidden" name="play[assignment_config][filters]" value={filters.join('&')} />
              </Box>
            )}

            {routingType === 'territory' && (
              <Box>
                <Stack pt={2}>
                  <Text fontSize="sm" fontWeight="medium">
                    Select the territories you want to use.
                  </Text>

                  {territoryData?.territories?.map((territory) => (
                    <Flex
                      key={territory.id}
                      alignItems="center"
                      rounded="lg"
                      border="1px solid"
                      borderColor="gray.200"
                      bg="white"
                      paddingRight={3}
                    >
                      <Checkbox
                        flex="1 1 auto"
                        padding={3}
                        gap={2}
                        isChecked={selectedTerritories.includes(territory.id)}
                        onChange={() => handleTerritorySelect(territory.id)}
                      >
                        <Text fontSize="sm" fontWeight="medium">
                          {territory.name}
                        </Text>
                      </Checkbox>

                      <UsersAvatarGroup users={territory.assignees} />
                    </Flex>
                  ))}
                </Stack>

                {selectedTerritories.map((id) => (
                  <input type="hidden" key={id} name="play[assignment_config][territories][]" value={id} />
                ))}
              </Box>
            )}
          </Stack>
        </FormControl>
      )}
    </Stack>
  )
}
