import { useCreateAIAgentMetric } from '@app/components/data/use-ai-agent-metrics'
import { useAIAgents } from '@app/components/data/use-ai-agents'
import Avatar from '@app/components/ui/Avatar'
import { projectPath } from '@app/components/ui/ProjectsContext'
import { TextEllipsis } from '@app/components/ui/text-ellipsis'
import { TimeAgo } from '@app/components/ui/TimeAgo'
import { concurrentGET, post } from '@app/lib/api'
import { Account } from '@app/types/Account'
import { AIAgent } from '@app/types/AIAgent'
import { ProfileRecord as Profile } from '@app/types/Profile'
import {
  AvatarGroup,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  StackProps,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  Wrap,
  WrapItem
} from '@chakra-ui/react'
import {
  IconBolt,
  IconBrain,
  IconChevronDown,
  IconClock,
  IconEye,
  IconFlag,
  IconFlareFilled,
  IconPlayerPlay,
  IconPlayerTrackNext,
  IconSettings,
  IconThumbDown,
  IconThumbUp,
  IconX
} from '@tabler/icons-react'
import pMap from 'p-map'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getFaviconUrl } from '../../lib/favicon'
import { useCachedAgents } from '../pages/ai_agents/components/AIAgentColumns'
import { EditAgentDrawer } from '../pages/ai_agents/components/AIAgentForm'
import { AIAgentResponse } from '../pages/ai_agents/components/AIAgentResponse'
import { AIStaleState } from './AIStaleState'
import { useSearchParams } from './useSearchState'
import { agentMetricOrigin } from '@app/components/data/use-ai-agent-metrics'
import { ResearchProgress } from '../pages/ai_agents/components/ResearchProgress'
import { usePlayItemAIRecommendationsStream } from '@app/components/data/use-ai-recommendations'

interface CompanyAIProps {
  account: Account
  profile?: Profile
  target: 'companies'
}

interface PeopleAIProps {
  account?: Account
  profile: Profile
  target: 'people'
}

type AIProps = CompanyAIProps | PeopleAIProps

interface BaseAgentProps {
  agent: {
    id: string
    slug: string
    name: string
    question: string
    runFromParent?: (skipCache: boolean) => Promise<void>
  }
  runningAgents: Set<string>
}

interface CompanyAgentCardProps extends CompanyAIProps, BaseAgentProps {}
interface PeopleAgentCardProps extends PeopleAIProps, BaseAgentProps {}

type AgentCardProps = (CompanyAgentCardProps | PeopleAgentCardProps) & {
  cachedResponse?: any
  isCachedLoading?: boolean
}

function AgentCard(props: AgentCardProps) {
  const { account, profile, target, runningAgents, isCachedLoading, cachedResponse } = props
  const [isRunning, setIsRunning] = useState(isCachedLoading)
  const [response, setResponse] = useState(cachedResponse)
  const { searchParams, setSearchParam } = useSearchParams()
  const [isExpanded, setIsExpanded] = useState(
    searchParams.response_target && searchParams.response_target === props.cachedResponse?.cache_target
  )
  const [agent, setAgent] = useState(props.agent)
  const [isEditingAgent, setIsEditingAgent] = useState(false)
  const { mutateAsync: recordAgentMetric } = useCreateAIAgentMetric()

  const [progressEvents, setProgressEvents] = useState<any[]>([])
  const runId = React.useRef<string>(crypto.randomUUID())

  usePlayItemAIRecommendationsStream(runId.current, {
    onContent: (message) => {
      if (message?.content_type === 'response') {
        setProgressEvents([])
        setResponse(JSON.parse(message.content))
        setIsRunning(false)
      } else {
        setProgressEvents((prev) => [...prev, message])
      }
    },
    onTool: (message) => {
      setProgressEvents((prev) => [...prev, message])
    }
  })

  const runAgent = async (skipCache: boolean) => {
    if (runningAgents.has(agent.id)) return

    setIsRunning(true)
    setResponse(null)

    recordAgentMetric({
      assistant_id: agent.id,
      metric_type: 'run_single',
      target_type: props.profile ? 'profile' : 'account',
      target_id: props.profile ? props.profile.id : props?.account?.id
    })

    const queryParam = target === 'companies' ? `domain=${account?.domain}` : `profile_id=${profile?.id}`
    const origin = agentMetricOrigin() as string
    const path = projectPath(
      `/assistants/${agent?.slug}/run-async?${queryParam}&skip_cache=${skipCache}&origin=${origin}&run_id=${runId.current}`
    )
    await concurrentGET(path)
  }

  const runFromParent = useCallback(
    async (skipCache: boolean, origin?: string) => {
      if (response && !skipCache) return
      setIsRunning(true)
      setResponse(null)

      const queryParam = target === 'companies' ? `domain=${account?.domain}` : `profile_id=${profile?.id}`

      const path = projectPath(
        `/assistants/${agent?.slug}/run-async?${queryParam}&skip_cache=${skipCache}&origin=${origin || ''}&run_id=${runId.current}`
      )

      // Trigger the agent run
      await concurrentGET(path)
    },
    [response, agent?.slug, account?.domain, setResponse, profile?.id, target]
  )

  const isRunningAgent = useMemo(() => {
    return isRunning || runningAgents.has(agent.id)
  }, [isRunning, runningAgents, agent.id])

  useEffect(() => {
    ;(agent as any).runFromParent = runFromParent
  }, [agent, runFromParent])

  return (
    <Flex
      position="relative"
      borderWidth="1px"
      rounded="lg"
      paddingY={[2, 2.5]}
      paddingX={3}
      gap="1"
      height="100%"
      flexDirection="column"
      alignSelf="stretch"
      justifyContent="space-between"
      role="group"
    >
      <TextEllipsis fontSize="13px" color="gray.600" maxW="100%" tooltip fontWeight="semibold" pr={6}>
        {agent.name}
      </TextEllipsis>
      {(!response || isRunningAgent) && (
        <>
          <IconButton
            position="absolute"
            top={1.5}
            right={1.5}
            size="xs"
            aria-label="Run agent"
            isDisabled={!!response || isRunningAgent}
            icon={
              isRunningAgent ? (
                <Spinner size="xs" thickness="1.5px" color="gray.700" />
              ) : (
                <Icon as={IconPlayerPlay} boxSize={3.5} />
              )
            }
            onClick={() => runAgent(false)}
          />
          {isRunningAgent && progressEvents.length > 0 && (
            <Box position="relative" mt={2} mb={2} width="100%" overflow="hidden">
              <ResearchProgress toolEvents={progressEvents} onlyLast={true} />
            </Box>
          )}
        </>
      )}
      {response && !isRunningAgent && (
        <>
          <Text lineHeight={'1.2'} fontSize="13px" noOfLines={3}>
            {response.stale && <AIStaleState marginRight={1} verticalAlign="top" />}
            {response.display_answer}
          </Text>
          <Flex pt={2} mt="auto" justifyContent="space-between">
            <Tooltip label="View full report">
              <Button
                variant="outline"
                leftIcon={
                  <AvatarGroup size="16px" max={3} spacing={-1}>
                    {(response.sources || []).map((source: { url: string }) => {
                      let hostname = source.url

                      try {
                        hostname = source.url.startsWith('http')
                          ? new URL(source.url).hostname
                          : new URL(`https://${source.url}`).hostname
                      } catch (error) {
                        console.error(error)
                      }

                      return (
                        <Avatar
                          key={source.url}
                          name={hostname}
                          rounded="full"
                          src={getFaviconUrl(hostname, 16)}
                          outline="1.5px solid white"
                        />
                      )
                    })}
                  </AvatarGroup>
                }
                iconSpacing={1.5}
                size="xs"
                rounded="md"
                height={7}
                onClick={() => {
                  setSearchParam('response_target', response?.cache_target || '')
                  setIsExpanded(true)
                }}
              >
                {pluralize('source', response.sources?.length ?? 0, true)}
              </Button>
            </Tooltip>
            <Tooltip
              label={
                <HStack spacing={1}>
                  <Text>Rerun from scratch. Last run</Text>
                  <TimeAgo time={response.response_updated_at} />
                </HStack>
              }
            >
              <IconButton
                variant="outline"
                size="xs"
                icon={<Icon as={IconPlayerPlay} boxSize={3.5} />}
                onClick={() => runAgent(true)}
                aria-label="Run agent"
                visibility="hidden"
                _groupHover={{ visibility: 'visible' }}
              />
            </Tooltip>
          </Flex>

          <EditAgentDrawer
            onClose={() => setIsEditingAgent(false)}
            isOpen={isEditingAgent}
            ai_agent={agent as AIAgent}
            showTarget={false}
            showDeleteOption={false}
            onChange={(agent, skipCache) => {
              setAgent(agent as AgentCardProps['agent'])
              runAgent(skipCache)
              setIsExpanded(false)
            }}
            company={account}
            profile={profile}
          />

          {isExpanded && (
            <AgentResponseDrawer
              response={response}
              agent={agent as AIAgent}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              setIsEditingAgent={setIsEditingAgent}
              account={account}
              profile={profile}
            />
          )}
        </>
      )}
    </Flex>
  )
}

interface AgentMetrics {
  view_count: number
  usage_count: number
  feedback_count: number
  feedback_breakdown: Record<string, number>
  current_user_feedback: { feedback_type: string; comment?: string } | null
}

export function AgentResponseDrawer(props: {
  response: any
  agent: AIAgent
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
  setIsEditingAgent?: (isEditingAgent: boolean) => void
  account?: Account
  profile?: Profile
}) {
  const { response, agent, isExpanded, setIsExpanded, setIsEditingAgent } = props
  const [usageCount, setUsageCount] = useState<number | undefined>(0)
  const [viewCount, setViewCount] = useState<number>(agent.view_count || 0)
  const [feedbackCount, setFeedbackCount] = useState<number>(0)
  const [feedbackBreakdown, setFeedbackBreakdown] = useState<Record<string, number>>({})
  const [currentUserFeedback, setCurrentUserFeedback] = useState<{ feedback_type: string; comment?: string } | null>(
    null
  )
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [feedbackType, setFeedbackType] = useState<string>('')
  const [feedbackComment, setFeedbackComment] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { setSearchParam } = useSearchParams()

  const submitFeedback = async () => {
    if (!feedbackType) return

    setIsSubmitting(true)
    try {
      const targetParams = props.profile
        ? { target: 'profile', target_id: props.profile!.id }
        : { target: 'account', target_id: props.account!.id }

      const res = await post<{ status: 'success' | 'error'; feedback_count: number; errors?: string }>(
        projectPath(`/assistants/${agent.id}/record-feedback`),
        {
          feedback_type: feedbackType,
          comment: feedbackComment,
          ...targetParams
        }
      )

      if (res.status === 'success') {
        await fetchMetrics()
      }

      setShowFeedbackModal(false)
      setFeedbackType('')
      setFeedbackComment('')
    } finally {
      setIsSubmitting(false)
    }
  }

  const fetchMetrics = useCallback(async () => {
    try {
      const queryParams = props.profile
        ? `target=profile&target_id=${props.profile!.id}`
        : `target=account&target_id=${props.account!.id}`

      const res = await concurrentGET<AgentMetrics>(projectPath(`/assistants/${agent.id}/metrics?${queryParams}`))
      setViewCount(res.view_count)
      setUsageCount(res.usage_count)
      setFeedbackCount(res.feedback_count)
      setFeedbackBreakdown(res.feedback_breakdown)
      setCurrentUserFeedback(res.current_user_feedback)
    } catch (error) {
      console.error('Failed to fetch agent metrics:', error)
    }
  }, [agent.id, props.account, props.profile])

  useEffect(() => {
    if (isExpanded && agent.id) {
      fetchMetrics()
    }
  }, [isExpanded, agent.id, fetchMetrics])

  return (
    <>
      <Drawer
        size="lg"
        isOpen={isExpanded}
        placement="right"
        onClose={() => {
          setSearchParam('response_target', undefined)
          setIsExpanded(false)
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <IconButton
            icon={<Icon as={IconX} />}
            variant="ghost"
            position="absolute"
            right={2}
            top={2}
            onClick={() => {
              setSearchParam('response_target', undefined)
              setIsExpanded(false)
            }}
            aria-label="Close drawer"
          />
          <DrawerBody py={6} pb={16}>
            <Stack spacing={8} px="2">
              <Stack>
                <Box>
                  <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="semibold">
                    Question
                  </Text>
                  <Text fontSize="md" color="gray.700" fontWeight="semibold">
                    {agent.question}
                  </Text>
                </Box>
              </Stack>
              <AIAgentResponse response={response} isLoading={false} agent={agent} />
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <HStack width="100%" justifyContent="space-between">
              <HStack>
                {setIsEditingAgent && (
                  <Button variant="ghost" size="xs" onClick={() => setIsEditingAgent(true)}>
                    Edit Agent
                  </Button>
                )}
              </HStack>
              <HStack>
                <Tooltip label="Number of times this report has been excuted">
                  <HStack spacing={1} color="gray.500">
                    <Icon as={IconBolt} size={16} />
                    <Text fontSize="sm">{usageCount || 0}</Text>
                  </HStack>
                </Tooltip>
                <Tooltip label="Number of times viewed">
                  <HStack spacing={1} color="gray.500">
                    <Icon as={IconEye} size={16} />
                    <Text fontSize="sm">{viewCount || 0}</Text>
                  </HStack>
                </Tooltip>

                <HStack spacing={2}>
                  <Tooltip label="Send a feedback">
                    <HStack spacing={1} color="gray.500" cursor="pointer" onClick={() => setShowFeedbackModal(true)}>
                      <Icon as={IconFlag} size={16} />
                      <Text fontSize="sm">{feedbackCount || 0}</Text>
                    </HStack>
                  </Tooltip>
                </HStack>
              </HStack>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal isOpen={showFeedbackModal} onClose={() => setShowFeedbackModal(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Provide Feedback</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              {currentUserFeedback && (
                <Box>
                  <Text mb={2} fontWeight="medium">
                    Your Previous Feedback
                  </Text>
                  <Box p={3} borderWidth="1px" borderRadius="md" borderColor="gray.200">
                    <HStack align="flex-start" spacing={3}>
                      <Icon
                        as={
                          currentUserFeedback.feedback_type === 'helpful'
                            ? IconThumbUp
                            : currentUserFeedback.feedback_type === 'not_helpful'
                              ? IconThumbDown
                              : currentUserFeedback.feedback_type === 'incorrect'
                                ? IconX
                                : currentUserFeedback.feedback_type === 'outdated'
                                  ? IconClock
                                  : IconBrain
                        }
                        color={
                          currentUserFeedback.feedback_type === 'helpful'
                            ? 'green.500'
                            : currentUserFeedback.feedback_type === 'not_helpful'
                              ? 'red.500'
                              : currentUserFeedback.feedback_type === 'incorrect'
                                ? 'orange.500'
                                : currentUserFeedback.feedback_type === 'outdated'
                                  ? 'yellow.500'
                                  : 'purple.500'
                        }
                        boxSize={5}
                        mt={1}
                      />
                      <Box>
                        <Text fontWeight="medium" textTransform="capitalize">
                          {currentUserFeedback.feedback_type.replace('_', ' ')}
                        </Text>
                        {currentUserFeedback.comment && (
                          <Text fontSize="sm" color="gray.600" mt={1}>
                            {currentUserFeedback.comment}
                          </Text>
                        )}
                      </Box>
                    </HStack>
                  </Box>

                  <Box mt={4}>
                    <Text fontWeight="medium" mb={3}>
                      All Feedback
                    </Text>
                    <Wrap spacing={2}>
                      {Object.entries(feedbackBreakdown)
                        .filter(([_, count]) => count > 0)
                        .map(([type, count]) => (
                          <WrapItem key={type}>
                            <HStack
                              spacing={1.5}
                              px={2}
                              py={1}
                              borderRadius="full"
                              borderWidth="1px"
                              borderColor={
                                type === 'helpful'
                                  ? 'green.200'
                                  : type === 'not_helpful'
                                    ? 'red.200'
                                    : type === 'incorrect'
                                      ? 'orange.200'
                                      : type === 'outdated'
                                        ? 'yellow.200'
                                        : 'purple.200'
                              }
                              bg={
                                type === 'helpful'
                                  ? 'green.50'
                                  : type === 'not_helpful'
                                    ? 'red.50'
                                    : type === 'incorrect'
                                      ? 'orange.50'
                                      : type === 'outdated'
                                        ? 'yellow.50'
                                        : 'purple.50'
                              }
                            >
                              <Icon
                                as={
                                  type === 'helpful'
                                    ? IconThumbUp
                                    : type === 'not_helpful'
                                      ? IconThumbDown
                                      : type === 'incorrect'
                                        ? IconX
                                        : type === 'outdated'
                                          ? IconClock
                                          : IconBrain
                                }
                                color={
                                  type === 'helpful'
                                    ? 'green.500'
                                    : type === 'not_helpful'
                                      ? 'red.500'
                                      : type === 'incorrect'
                                        ? 'orange.500'
                                        : type === 'outdated'
                                          ? 'yellow.500'
                                          : 'purple.500'
                                }
                                boxSize={3.5}
                              />
                              <Text fontSize="sm" textTransform="capitalize">
                                {type.replace('_', ' ')}
                              </Text>
                              <Text fontSize="sm" fontWeight="medium" color="gray.600">
                                {count}
                              </Text>
                            </HStack>
                          </WrapItem>
                        ))}
                    </Wrap>
                  </Box>
                </Box>
              )}
              {!currentUserFeedback && (
                <RadioGroup value={feedbackType} onChange={setFeedbackType}>
                  <Stack spacing={3}>
                    <Box
                      onClick={() => setFeedbackType('helpful')}
                      p={3}
                      borderWidth="1px"
                      borderRadius="md"
                      cursor="pointer"
                      position="relative"
                      _hover={{ bg: 'gray.50' }}
                      bg={feedbackType === 'helpful' ? 'blue.50' : 'white'}
                      borderColor={feedbackType === 'helpful' ? 'blue.500' : 'gray.200'}
                      boxShadow={feedbackType === 'helpful' ? '0 0 0 1px var(--chakra-colors-blue-500)' : 'none'}
                    >
                      <Radio value="helpful" position="absolute" opacity={0} />
                      <HStack align="flex-start" spacing={3}>
                        <Icon as={IconThumbUp} color="green.500" boxSize={5} mt={1} />
                        <Box>
                          <Text fontWeight="medium">Helpful</Text>
                          <Text fontSize="sm" color="gray.600">
                            The response was accurate and provided valuable insights
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box
                      onClick={() => setFeedbackType('not_helpful')}
                      p={3}
                      borderWidth="1px"
                      borderRadius="md"
                      cursor="pointer"
                      position="relative"
                      _hover={{ bg: 'gray.50' }}
                      bg={feedbackType === 'not_helpful' ? 'blue.50' : 'white'}
                      borderColor={feedbackType === 'not_helpful' ? 'blue.500' : 'gray.200'}
                      boxShadow={feedbackType === 'not_helpful' ? '0 0 0 1px var(--chakra-colors-blue-500)' : 'none'}
                    >
                      <Radio value="not_helpful" position="absolute" opacity={0} />
                      <HStack align="flex-start" spacing={3}>
                        <Icon as={IconThumbDown} color="red.500" boxSize={5} mt={1} />
                        <Box>
                          <Text fontWeight="medium">Not Helpful</Text>
                          <Text fontSize="sm" color="gray.600">
                            The response wasn't relevant or didn't address the question
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box
                      onClick={() => setFeedbackType('incorrect')}
                      p={3}
                      borderWidth="1px"
                      borderRadius="md"
                      cursor="pointer"
                      position="relative"
                      _hover={{ bg: 'gray.50' }}
                      bg={feedbackType === 'incorrect' ? 'blue.50' : 'white'}
                      borderColor={feedbackType === 'incorrect' ? 'blue.500' : 'gray.200'}
                      boxShadow={feedbackType === 'incorrect' ? '0 0 0 1px var(--chakra-colors-blue-500)' : 'none'}
                    >
                      <Radio value="incorrect" position="absolute" opacity={0} />
                      <HStack align="flex-start" spacing={3}>
                        <Icon as={IconX} color="orange.500" boxSize={5} mt={1} />
                        <Box>
                          <Text fontWeight="medium">Incorrect Information</Text>
                          <Text fontSize="sm" color="gray.600">
                            The response contains factual errors or wrong information
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box
                      onClick={() => setFeedbackType('outdated')}
                      p={3}
                      borderWidth="1px"
                      borderRadius="md"
                      cursor="pointer"
                      position="relative"
                      _hover={{ bg: 'gray.50' }}
                      bg={feedbackType === 'outdated' ? 'blue.50' : 'white'}
                      borderColor={feedbackType === 'outdated' ? 'blue.500' : 'gray.200'}
                      boxShadow={feedbackType === 'outdated' ? '0 0 0 1px var(--chakra-colors-blue-500)' : 'none'}
                    >
                      <Radio value="outdated" position="absolute" opacity={0} />
                      <HStack align="flex-start" spacing={3}>
                        <Icon as={IconClock} color="yellow.500" boxSize={5} mt={1} />
                        <Box>
                          <Text fontWeight="medium">Outdated Information</Text>
                          <Text fontSize="sm" color="gray.600">
                            The information is no longer current or needs updating
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                    <Box
                      onClick={() => setFeedbackType('hallucinating')}
                      p={3}
                      borderWidth="1px"
                      borderRadius="md"
                      cursor="pointer"
                      position="relative"
                      _hover={{ bg: 'gray.50' }}
                      bg={feedbackType === 'hallucinating' ? 'blue.50' : 'white'}
                      borderColor={feedbackType === 'hallucinating' ? 'blue.500' : 'gray.200'}
                      boxShadow={feedbackType === 'hallucinating' ? '0 0 0 1px var(--chakra-colors-blue-500)' : 'none'}
                    >
                      <Radio value="hallucinating" position="absolute" opacity={0} />
                      <HStack align="flex-start" spacing={3}>
                        <Icon as={IconBrain} color="purple.500" boxSize={5} mt={1} />
                        <Box>
                          <Text fontWeight="medium">Hallucinating</Text>
                          <Text fontSize="sm" color="gray.600">
                            The response includes made-up or fabricated information
                          </Text>
                        </Box>
                      </HStack>
                    </Box>
                  </Stack>
                </RadioGroup>
              )}
              {!currentUserFeedback && (
                <FormControl>
                  <FormLabel>Additional Comments</FormLabel>
                  <Textarea
                    value={feedbackComment}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFeedbackComment(e.target.value)}
                    placeholder="Add any additional comments..."
                  />
                </FormControl>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setShowFeedbackModal(false)}>{currentUserFeedback ? 'Close' : 'Cancel'}</Button>
            {!currentUserFeedback && (
              <Button ml={3} colorScheme="blue" onClick={submitFeedback} isDisabled={!feedbackType || isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export function AISummaryCard({ account, profile, target, ...props }: AIProps & StackProps) {
  const { data: agents, isLoading } = useAIAgents(target)
  const [isRunningAll, setIsRunningAll] = useState(false)
  const [runningAgents, setRunningAgents] = useState<Set<string>>(new Set())
  const [cachedResponses, setCachedResponses] = useState<Record<string, any>>({})
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutateAsync: recordAgentMetric } = useCreateAIAgentMetric()

  const { data: cachedAgentData, isLoading: isCachedLoading } = useCachedAgents({
    domains: account ? [account.domain] : [],
    profileIds: profile ? [profile.id] : [],
    agentSlugs: agents?.my_agents.map((a) => a.slug) || [],
    target: target
  })

  useEffect(() => {
    if (cachedAgentData && !isCachedLoading) {
      if (cachedAgentData.cached_agents.length) {
        const parsed = cachedAgentData.cached_agents.reduce(
          (acc, cache) => {
            acc[cache.agent_id] = JSON.parse(cache.response)
            return acc
          },
          {} as Record<string, any>
        )

        setCachedResponses(parsed)
      }
    }
  }, [cachedAgentData, isCachedLoading])

  const runAllAgents = async (skipCache: boolean) => {
    if (!agents?.my_agents.length) return

    setIsRunningAll(true)
    setRunningAgents(new Set(agents.my_agents.map((a) => a.id)))

    try {
      // Run agents in parallel but track individual completion
      await pMap(
        agents.my_agents,
        async (agent) => {
          try {
            await (agent as any).runFromParent?.(skipCache, agentMetricOrigin() as string)
          } finally {
            setRunningAgents((prev) => {
              const next = new Set(prev)
              next.delete(agent.id)
              return next
            })
          }
        },
        { concurrency: 10 }
      )
    } finally {
      setIsRunningAll(false)
      setRunningAgents(new Set())
    }
  }

  const trackRunMetric = useCallback(
    (metricType: string) => {
      recordAgentMetric({
        metric_type: metricType,
        target_type: profile ? 'profile' : 'account',
        target_id: profile ? profile.id : account?.id
      })
    },
    [profile, account, recordAgentMetric]
  )

  if (isLoading) {
    return <Spinner size="sm" thickness="1.5px" color="gray.500" />
  }

  if (!agents?.my_agents.length) {
    return null
  }

  return (
    <Stack spacing={4} {...props}>
      <Flex justify="space-between" align="center">
        <HStack>
          <Icon as={IconFlareFilled} boxSize={4} color="purple.500" />
          <Text fontSize="sm" fontWeight="semibold">
            AI Agents
          </Text>
        </HStack>

        <HStack spacing={2}>
          <Tooltip label="Run all agents">
            <Button
              size="xs"
              leftIcon={
                isRunningAll ? (
                  <Spinner size="xs" thickness="1.5px" color="gray.700" />
                ) : (
                  <Icon as={IconPlayerPlay} boxSize={3.5} />
                )
              }
              onClick={() => {
                runAllAgents(false)
                trackRunMetric('run_all')
              }}
              isDisabled={isRunningAll}
              variant="ghost"
            >
              {isRunningAll ? `Running ${runningAgents.size} remaining...` : 'Run All'}
            </Button>
          </Tooltip>

          <Popover placement="bottom-end" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
            <PopoverTrigger>
              <IconButton
                size="xs"
                icon={<Icon as={IconChevronDown} boxSize={3.5} />}
                colorScheme="gray"
                variant="ghost"
                isDisabled={isRunningAll}
                aria-label="Run options"
              />
            </PopoverTrigger>
            <Portal>
              <PopoverContent width="20ch">
                <PopoverArrow />
                <PopoverBody p={0}>
                  <Stack spacing={0}>
                    <Tooltip label="Run all agents that haven't run or had errors">
                      <Button
                        size="sm"
                        variant="ghost"
                        justifyContent="flex-start"
                        leftIcon={<Icon as={IconPlayerPlay} boxSize={3.5} />}
                        onClick={() => {
                          runAllAgents(false)
                          trackRunMetric('run_all')
                          onClose()
                        }}
                        isDisabled={isRunningAll}
                      >
                        Run All
                      </Button>
                    </Tooltip>
                    <Tooltip
                      label={`We'll ignore previously cached answers for all ${agents?.my_agents.length} agents`}
                    >
                      <Button
                        size="sm"
                        variant="ghost"
                        justifyContent="flex-start"
                        leftIcon={<Icon as={IconPlayerTrackNext} boxSize={3.5} />}
                        onClick={() => {
                          runAllAgents(true)
                          trackRunMetric('run_all_from_scratch')
                          onClose()
                        }}
                        isDisabled={isRunningAll}
                      >
                        Run all from scratch
                      </Button>
                    </Tooltip>
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>

          <IconButton
            size="xs"
            aria-label="Configure agents"
            variant="ghost"
            as={Link}
            href={projectPath('/settings/agents')}
            isExternal
            icon={<Icon as={IconSettings} boxSize={3.5} />}
          />
        </HStack>
      </Flex>
      <Grid gridAutoRows="auto" gridTemplateColumns={['1fr', 'repeat(auto-fit, minmax(260px, 1fr))']} gap={[2, 2, 3]}>
        {agents?.my_agents.map((agent) => (
          <AgentCard
            key={[agent.id, cachedResponses[agent.id], isCachedLoading].join('-')}
            agent={agent}
            account={account!}
            profile={profile!}
            target={target}
            runningAgents={runningAgents}
            cachedResponse={cachedResponses[agent.id]}
            isCachedLoading={isCachedLoading}
          />
        ))}
      </Grid>
    </Stack>
  )
}
