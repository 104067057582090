import { Box, Button, Flex, FormControl, FormLabel, Icon, IconButton, Stack, Text } from '@chakra-ui/react'
import {
  IconBinoculars,
  IconList,
  IconPlus,
  IconRefresh,
  IconTargetArrow,
  IconTrash,
  IconUpload,
  IconUsers
} from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Apps } from '../../../../types/App'
import { PlayConfigPreset, PlayTargetConfig, PlayTargetType, PlayTriggerConfig } from '../../../../types/Play'
import { FacetParams } from '../../../data/use-facets'
import { GrayCard } from '../../../ui/Card'
import { CardRadioGroup } from '../../../ui/CardRadioGroup'
import CircleIcon from '../../../ui/CircleIcon'
import { BuildingIcon, GitHubIcon, LinkedinIcon } from '../../../ui/icons'
import { FacetFilters } from '../../accounts'
import { validFilter } from '../../accounts/facets/categories'
import { SmartListSetup, TerritorySetup } from '../../follow_rules/components/territory-setup'
import { IntentTrend } from '../../kql_definitions/components/SelectSignalsModal'
import { TriggerMultiSelect } from '../../slack_alerts/components/DefinitionForm'
import { CSVColdSource } from './csv-cold-source'
import { GitHubColdSource } from './github-cold-source'
import { LinkedinColdSource } from './linkedin-cold-source'
import { ProspectorColdSource } from './prospector-cold-source'

interface SourceSetupProps {
  targetType?: PlayTargetType
  setTargetType?: (targetType: PlayTargetType) => void
  apps?: Apps
  onFacetsChange?: (facets: FacetParams) => void
  targetConfig?: PlayTargetConfig
  triggerConfig?: PlayTriggerConfig
  onSourceTypeChange?: (sourcePreset: PlayConfigPreset) => void
}

const enrollmentOptions = [
  {
    label: 'Whenever they match a saved list',
    icon: <CircleIcon icon={IconList} iconSize={4} bg="gray.100" color="gray.800" p={2} />,
    value: 'smart-list',
    description: 'Automatically enroll records when they are added to a specific list.'
  },
  {
    label: 'Whenever they match filter conditions',
    icon: <CircleIcon icon={IconTargetArrow} iconSize={4} bg="gray.100" color="gray.800" p={2} />,
    value: 'custom',
    description: 'Automatically enroll records when they match your specified criteria.'
  },
  {
    label: 'Manually add records from a CSV',
    value: 'csv',
    icon: <CircleIcon icon={IconUpload} iconSize={4} bg="gray.100" color="gray.800" p={2} />,
    description: 'Upload a CSV with the records you want to enroll.'
  },
  {
    label: 'Prospector Search',
    value: 'prospects',
    icon: <CircleIcon icon={IconBinoculars} iconSize={4} bg="gray.100" color="gray.800" p={2} />,
    description: `Automatically enroll prospects from Koala's contact database based on a set of filters.`
  },
  {
    label: 'LinkedIn interactions',
    value: 'linkedin',
    icon: <CircleIcon icon={LinkedinIcon} iconSize={4} bg="linkedin.50" color="linkedin.700" p={2} />,
    description: 'Automatically enroll people interacting with a LinkedIn post or page.'
  },
  {
    label: 'GitHub Repo interactions',
    value: 'github',
    icon: <CircleIcon icon={GitHubIcon} iconSize={4} bg="gray.100" color="gray.800" p={2} />,
    description: 'Automatically enroll people interacting with a GitHub repository.'
  }
]

const targetOptions = [
  {
    label: 'Companies',
    icon: <BuildingIcon boxSize={4} />,
    value: 'Account',
    description: `Enroll companies in the play`
  },
  {
    label: 'People',
    icon: <Icon as={IconUsers} boxSize={4} />,
    value: 'Profile',
    description: `Enroll people in the play`
  }
]

const isSourceCold = (source: string | undefined) =>
  source === 'csv' || source === 'prospects' || source === 'linkedin' || source === 'github'

const getOption = (value: string | undefined) => {
  return enrollmentOptions.find((opt) => opt.value === value)
}

type LogicalOperator = 'And' | 'Or'

export function SourceSetup(props: SourceSetupProps) {
  const [intentChanges, setIntentChanges] = useState<IntentTrend[]>(props.triggerConfig?.intentChanges ?? [])
  const [kqlIds, setKqlIds] = useState<string[]>(props.triggerConfig?.kqlIds ?? [])
  const [filterGroups, setFilterGroups] = React.useState<{ id: number; rules: FacetFilters }[]>([{ id: 1, rules: {} }])
  const [condition, setCondition] = React.useState<LogicalOperator>('Or')

  const targetType = useMemo(() => props.targetType ?? 'Account', [props.targetType])
  const setTargetType = props.setTargetType ?? (() => {})

  const listType = targetType

  useEffect(() => {
    const [firstKey] = Object.keys(props.targetConfig?.config?.facets ?? {})
    if (firstKey === '_or' || firstKey === '_and') {
      setCondition(firstKey.replace('_', '').toUpperCase() as LogicalOperator)
      const parsedGroups = props.targetConfig?.config?.facets?.[firstKey]?.map((group, index) => ({
        id: index + 1,
        rules: group
      }))
      setFilterGroups(parsedGroups)
    } else {
      setFilterGroups([{ id: 1, rules: props.targetConfig?.config?.facets || {} }])
    }
  }, [props.targetConfig?.config?.facets])

  const [listId, setListId] = React.useState(props.targetConfig?.config?.smart_list)
  const [source, setSource] = React.useState<PlayConfigPreset | undefined>(props.targetConfig?.source)

  useEffect(() => {
    setSource(props.targetConfig?.source)
  }, [props.targetConfig?.source])

  const formParams: PlayTargetConfig['config'] = useMemo(() => {
    if (source === 'smart-list') {
      return {
        smart_list: listId
      }
    }

    if (source === 'custom') {
      let facets: any = filterGroups?.[0]?.rules
      if (filterGroups && filterGroups.length > 1) {
        facets = { [`_${condition.toLowerCase()}`]: filterGroups!.map((group) => group.rules) }
      }
      return { facets: facets || {} }
    }

    return null
  }, [source, filterGroups, listId, condition])

  const isColdSource = isSourceCold(source)

  return (
    <Stack spacing={6} as={GrayCard}>
      {!source ? (
        <CardRadioGroup
          size="sm"
          value={source}
          direction="column"
          onChange={(e) => {
            setFilterGroups([{ id: 1, rules: {} }])
            setSource(e as PlayConfigPreset)
            props.onSourceTypeChange?.(e as PlayConfigPreset)
          }}
          options={enrollmentOptions}
        />
      ) : (
        <Box bg="white" borderWidth="1.5px" borderRadius="md" px={3} py={3}>
          <Flex justify="space-between" align="center">
            <Flex alignItems="center" gap={2}>
              <Flex px={1.5}>{getOption(source)?.icon}</Flex>
              <Stack spacing={0.5}>
                <Text fontSize="sm" fontWeight="medium" lineHeight="18px">
                  {getOption(source)?.label}
                </Text>
                <Text fontSize="xs" color="gray.500">
                  {getOption(source)?.description}
                </Text>
              </Stack>
            </Flex>
            <Button
              size="sm"
              variant="outline"
              onClick={() => {
                setSource(undefined)
              }}
            >
              Change
            </Button>
          </Flex>
        </Box>
      )}

      {source === 'custom' && (
        <>
          <FormControl>
            <FormLabel>Who do you want to target?</FormLabel>
            <CardRadioGroup
              size="sm"
              gridTemplateColumns="repeat(2, 1fr)"
              value={targetType}
              onChange={(e) => {
                setTargetType(e as 'Account' | 'Profile')
              }}
              options={targetOptions}
            />

            <input type="hidden" name="play[target_type]" value={targetType} />
          </FormControl>

          <Flex direction={'row'}>
            {filterGroups && filterGroups.length > 1 && (
              <Flex direction="column" alignItems="center" marginRight={4}>
                <Box
                  flexGrow={1}
                  borderLeft="2px"
                  width={8}
                  borderTop="2px"
                  borderTopLeftRadius="md"
                  borderColor="gray.200"
                  marginLeft="30px"
                />
                <Button
                  size="sm"
                  variant="outline"
                  bg="white"
                  alignSelf="center"
                  leftIcon={<Icon as={IconRefresh} boxSize={3.5} />}
                  iconSpacing={1}
                  onClick={() => {
                    const newCondition = condition === 'And' ? 'Or' : 'And'
                    setCondition(newCondition)
                  }}
                  minWidth="75px"
                >
                  {condition}
                </Button>
                <Box
                  flexGrow={1}
                  width={8}
                  borderLeft="2px"
                  borderBottom="2px"
                  borderBottomLeftRadius="md"
                  borderColor="gray.200"
                  marginLeft="30px"
                />
              </Flex>
            )}

            <Flex direction="column" flexGrow={1} gap={2}>
              {filterGroups?.map((group) => (
                <FilterGroup
                  key={JSON.stringify({ props, source, territoryRules: group.rules })}
                  group={group}
                  listType={listType}
                  apps={props.apps}
                  numberOfGroups={filterGroups.length}
                  onGroupChange={setFilterGroups}
                />
              ))}
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end">
            <Button
              size="sm"
              variant="ghost"
              width="fit-content"
              leftIcon={<IconPlus size={16} />}
              onClick={() => {
                setFilterGroups((prevGroups) => [
                  ...prevGroups,
                  { id: prevGroups[prevGroups.length - 1].id + 1, rules: {} }
                ])
              }}
            >
              Add Filter Group
            </Button>
          </Flex>
        </>
      )}

      {source === 'smart-list' && (
        <>
          <SmartListSetup
            listId={listId}
            allowEveryone={false}
            onListChange={(listId) => {
              setListId(listId)
            }}
            onTargetTypeChange={setTargetType}
          />
          <input type="hidden" name="play[target_type]" value={targetType} />
        </>
      )}

      {source === 'csv' && (
        <Stack>
          <CSVColdSource targetConfig={props.targetConfig} targetType={targetType} onTargetTypeChange={setTargetType} />
        </Stack>
      )}

      {source === 'prospects' && (
        <>
          <ProspectorColdSource
            targetConfig={props.targetConfig}
            targetType={targetType}
            onTargetTypeChange={setTargetType}
          />
        </>
      )}

      {source === 'linkedin' && (
        <>
          <LinkedinColdSource
            targetConfig={props.targetConfig}
            targetType={targetType}
            onTargetTypeChange={setTargetType}
          />
        </>
      )}

      {source === 'github' && (
        <>
          <GitHubColdSource
            targetConfig={props.targetConfig}
            targetType={targetType}
            onTargetTypeChange={setTargetType}
          />
        </>
      )}

      {/* show the trigger config once an enrollment source is added */}
      {!!source && !isColdSource && (
        <FormControl>
          <FormLabel>Signal triggers (optional)</FormLabel>
          <TriggerMultiSelect
            onChange={(incomingKQLIDs, _isAnyIntent, _isAnySignal, intentChanges) => {
              setIntentChanges(intentChanges)

              if (incomingKQLIDs.length === 0 && kqlIds.length === 0) {
                return
              }
              setKqlIds(incomingKQLIDs)
            }}
            intentChanges={intentChanges}
            selectedSignalIds={kqlIds}
            selectedAudienceKind={targetType.toLowerCase() as 'account' | 'profile'}
            skipForms
            skipCreation
            skipAnyIntent
          />
        </FormControl>
      )}

      <input type="hidden" name="play[target_config][source]" value={source} />
      {formParams && <input type="hidden" name="play[target_config][config]" value={JSON.stringify(formParams)} />}

      <input
        type="hidden"
        name="play[trigger_config]"
        value={JSON.stringify({
          kqlIds,
          intentChanges
        })}
      />
    </Stack>
  )
}

function filteredRules(rules: FacetFilters, listType: string) {
  if (listType === undefined) {
    return rules
  }

  if (rules === null || rules === undefined) {
    return rules
  }

  // filter out any rules where the category doesn't match
  const kind = listType.toLowerCase()
  const filteredFacets: FacetFilters = {}

  Object.keys(rules).forEach((facet) => {
    if (validFilter(kind, facet)) {
      filteredFacets[facet] = rules![facet]
    }
  })

  return filteredFacets
}

interface FilterGroupProps {
  apps?: Apps
  listType: 'Account' | 'Profile'
  group: { id: number; rules: FacetFilters }
  numberOfGroups: number
  onGroupChange: React.Dispatch<
    React.SetStateAction<
      {
        id: number
        rules: FacetFilters
      }[]
    >
  >
}

function FilterGroup(props: FilterGroupProps) {
  const { apps, listType, group, numberOfGroups, onGroupChange } = props

  const territoryRules = useMemo(() => {
    return { facets: filteredRules(group.rules, listType) }
  }, [group.rules, listType])

  return (
    <Box backgroundColor="gray.50" borderRadius="md" borderColor="border.lightest" borderWidth={1} position="relative">
      <Box p={2} paddingRight={numberOfGroups > 1 ? 8 : 2}>
        <TerritorySetup
          apps={apps}
          targetType={listType}
          territoryRules={territoryRules}
          onFacetsChange={(appliedFilters) => {
            onGroupChange((prevGroups) => {
              return prevGroups.map((g) => (g.id === group.id ? { ...g, rules: appliedFilters || {} } : g))
            })
          }}
        />
      </Box>
      {numberOfGroups > 1 && (
        <IconButton
          size="sm"
          variant="ghost"
          colorScheme="red"
          position="absolute"
          top={2}
          right={1}
          onClick={() => {
            onGroupChange((prevGroups) => prevGroups.filter((g) => g.id !== group.id))
          }}
          aria-label="Remove filter group"
        >
          <Icon as={IconTrash} size={16} />
        </IconButton>
      )}
    </Box>
  )
}
