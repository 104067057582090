import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  HTMLChakraProps,
  Icon,
  Link,
  Skeleton,
  Stack,
  Td,
  Text,
  Th
} from '@chakra-ui/react'
import { IconExternalLink, IconSend } from '@tabler/icons-react'
import dayjs from 'dayjs'
import React, { useCallback, useMemo } from 'react'
import { get } from '../../../lib/api'
import { AccountView } from '../../../types/AccountView'
import { User } from '../../../types/Invite'
import { useData } from '../../data/use-data'
import { IntentBolt } from '../../ui/IntentBolt'
import KoalaLogo from '../../ui/KoalaLogo'
import PageLayout from '../../ui/PageLayout'
import { projectPath } from '../../ui/ProjectsContext'
import { useCurrentUser } from '../../ui/UserContext'
import { accountViewPath } from '../account_views/lib/list-paths'
import { AccountList } from '../icps/icp/account-list'
import { HighlightedAccount } from '../icps/types'
import { HighlightedProfile, ProfileList } from '../profiles/components/profile-list'
import { DigestSubscription } from '../welcome/rep/rep-audiences'

type ViewSubscription = DigestSubscription & {
  account_view?: AccountView &
    (
      | {
          kind: 'account'
          search_preview: HighlightedAccount[]
        }
      | {
          kind: 'profile'
          search_preview: HighlightedProfile[]
        }
    )
}

interface Props {
  user: User
  koala_weekly: ViewSubscription[]
}

const extraColumns = [
  {
    id: 'KQLs',
    Title: (props: HTMLChakraProps<'th'>) => (
      <Th isNumeric {...props}>
        Intent Signals
      </Th>
    ),
    Cell: ({ account, ...rest }) => {
      const kqls = account?.kql_events?.filter((evt) =>
        dayjs(evt.timestamp).isAfter(dayjs().subtract(7, 'days'))
      ).length
      return (
        <Td isNumeric {...rest}>
          {kqls > 0 && <IntentBolt count={kqls} />}
        </Td>
      )
    }
  }
]

const profileExtraColumns = [
  {
    id: 'KQLs',
    Title: (props) => (
      <Th isNumeric {...props}>
        Intent Signals
      </Th>
    ),
    Cell: ({ profile, ...rest }) => {
      const kqls = profile?.kql_events?.filter((evt) =>
        dayjs(evt.timestamp).isAfter(dayjs().subtract(7, 'days'))
      ).length
      return (
        <Td isNumeric {...rest}>
          {kqls > 0 && <IntentBolt count={kqls} />}
        </Td>
      )
    }
  }
]

export function KoalaWeeklyPreview(props: Props & { preview?: boolean }) {
  const user = useCurrentUser()
  const canSendPreview = user.isInternalUser || false
  const [sending, setSending] = React.useState(false)

  const withView = useMemo(() => {
    return props.koala_weekly
      .filter((sub) => sub.account_view)
      .sort((a, b) => {
        if (a.kind === 'all_accounts_weekly') {
          return Number.MAX_SAFE_INTEGER
        }

        return (b.account_view?.preview_counts?.all ?? 0) - (a.account_view?.preview_counts?.all ?? 0)
      })
  }, [props])

  const sendPreview = useCallback(async () => {
    setSending(true)

    if (!canSendPreview) return

    try {
      await get(projectPath(`/subscriptions/preview?to=${user.email}&email=${props.user.email}`))
    } finally {
      setSending(false)
    }
  }, [user.email, props.user.email, canSendPreview])

  return (
    <Stack spacing="8" w="100%">
      <HStack spacing={4} justifyContent="space-between">
        <KoalaLogo kind="full" size="100px" />
        {canSendPreview && (
          <Button
            size="sm"
            leftIcon={<IconSend size={14} />}
            onClick={sendPreview}
            isLoading={sending}
            isDisabled={!canSendPreview}
          >
            Send preview to {user.email}
          </Button>
        )}
      </HStack>
      <Stack spacing="0">
        <Text>Hello {props.user.name},</Text>
        <Text>
          Welcome to your{' '}
          <Link color={'blue.600'} textDecoration="underline" href={projectPath('/subscriptions/koala-weekly')}>
            Koala Weekly
          </Link>{' '}
          👋🏻
        </Text>
      </Stack>

      <Text>
        Here's a snapshot of the hottest accounts and visitors that have been active in your audiences in the last 7
        days.
      </Text>

      {props.preview && (
        <Alert status="info" variant={'left-accent'} fontSize="sm">
          <AlertIcon />
          <AlertDescription>This is a preview of what your Koala Weekly email will look like.</AlertDescription>
        </Alert>
      )}

      <Stack spacing="12" w="100%" divider={<Divider />}>
        {withView.map((sub) => {
          if (!sub.account_view) return null

          const noun = sub.account_view?.kind === 'account' ? 'accounts' : 'visitors'
          const count = sub.account_view?.preview_counts?.all ?? 0

          return (
            <Stack key={sub.id} w="100%">
              <Flex>
                <Button
                  as={Link}
                  variant="ghost"
                  isExternal
                  href={accountViewPath(sub.account_view, '?kow=1')}
                  role="group"
                  style={{
                    textDecoration: 'none'
                  }}
                  rightIcon={
                    <Icon
                      as={IconExternalLink}
                      visibility="hidden"
                      boxSize={3.5}
                      _groupHover={{
                        visibility: 'visible'
                      }}
                    />
                  }
                >
                  <HStack>
                    <Text fontSize="lg" fontWeight="semibold">
                      {sub.account_view?.name}
                    </Text>
                    <Badge rounded="full" p="1" px="4">
                      {count}
                    </Badge>
                  </HStack>
                </Button>
              </Flex>

              <Stack spacing="4" w="100%">
                {count === 0 ? (
                  <Stack bg="gray.50" p="4" spacing="1" borderLeftWidth="thick" w="100%">
                    <HStack justifyContent="space-between">
                      <Stack spacing={1}>
                        <Heading size="sm" fontWeight="medium">
                          No {noun} found
                        </Heading>
                        <Text fontSize="sm" color="gray.600">
                          Expecting to see some {noun} that had activity recently? Check that your "
                          {sub.account_view?.name}" filters are configured correctly.
                        </Text>
                      </Stack>
                    </HStack>
                  </Stack>
                ) : sub.account_view?.kind === 'account' ? (
                  <Box overflow={'auto'} maxW="100%">
                    <AccountList
                      showActions={false}
                      accounts={sub.account_view.search_preview}
                      columns={[]}
                      extraColumns={extraColumns}
                      openLinksInNewTab
                    />
                  </Box>
                ) : sub.account_view?.kind === 'profile' ? (
                  <Box overflow={'auto'} maxW="100%">
                    <ProfileList
                      profiles={sub.account_view.search_preview}
                      range={'week'}
                      playEnrollmentColumns={profileExtraColumns}
                      columns={['Company', 'FitScore', 'LastVisit']}
                    />
                  </Box>
                ) : null}

                {sub.account_view && count > 3 && (
                  <Flex justifyContent={'flex-start'}>
                    <Button
                      as={Link}
                      variant="ghost"
                      rightIcon={<IconExternalLink size={14} />}
                      color="gray.500"
                      fontSize={'sm'}
                      isExternal
                      href={accountViewPath(sub.account_view)}
                    >
                      See {count - (sub.account_view?.search_preview?.length ?? 0)} more {noun}
                    </Button>
                  </Flex>
                )}
              </Stack>
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}

export function KoalaWeeklyDrawer() {
  const res = useData<Props>('digests-preview', projectPath('/subscriptions/koala-weekly.json'))

  if (res.isLoading) {
    return (
      <Stack w="100%">
        <Skeleton h="8" />
        <Skeleton h="8" />
        <Skeleton h="8" />
      </Stack>
    )
  }

  if (res.data) {
    return <KoalaWeeklyPreview preview {...res.data} />
  }

  return null
}

export default function KoalaWeeklyV2(props: Props) {
  return (
    <PageLayout
      size="full"
      background={'gray.100'}
      css={`
        --max-container-width: 100vw;
      `}
      p="0"
      alignItems={'center'}
    >
      <Center w="100%" bg="white" maxW="900px" p="8" rounded="md">
        <KoalaWeeklyPreview {...props} />
      </Center>
    </PageLayout>
  )
}
