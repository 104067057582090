import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  Wrap
} from '@chakra-ui/react'
import {
  IconBriefcaseFilled,
  IconEdit,
  IconMapOff,
  IconMapPinFilled,
  IconMilitaryRankFilled,
  IconNoteOff,
  IconPlus,
  IconSparkles,
  IconTargetArrow,
  IconTrash,
  IconUserCircle,
  IconUserOff
} from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import { toast } from 'sonner'
import { del, post, put } from '../../../lib/api'
import { usePersonas } from '../../data/use-personas'
import { Card } from '../../ui/Card'
import { ComboboxWithSearch } from '../../ui/ComboboxWithSearch'
import { HelpTooltip } from '../../ui/HelpTooltip'
import { AiSparklesIcon } from '../../ui/icons'
import { projectPath } from '../../ui/ProjectsContext'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { useCurrentUser } from '../../ui/UserContext'
import useUpdateEffect from '../../ui/useUpdateEffect'
import { humanize } from '../accounts/facets/filter-cloud'
import { FilterState, getOperator, getValues } from '../personas/persona-filters'
import { FilterPopover } from '../prospector/components/FilterPopover'

export type PersonaFilter = {
  operator: 'must' | 'should' | 'must_not'
  values: string[]
}

export type PersonaFilters = {
  title_keywords?: PersonaFilter
  not_keywords?: PersonaFilter
  job_title_levels?: PersonaFilter
  job_title_role?: PersonaFilter
  job_title_sub_role?: PersonaFilter
  location_country?: PersonaFilter
  location_locality?: PersonaFilter
  location_region?: PersonaFilter
}

export type Persona = {
  id?: string
  name?: string
  description?: string
  creativity?: 'creative' | 'strict'
  persona_type?: 'filter' | 'ai'
  filters?: PersonaFilters
  total_count_for_companies?: number
}

export const savePersona = async (persona: Persona) => {
  const path = persona.id ? `/personas/${persona.id}` : '/personas'
  const method = persona.id ? put : post

  return method(projectPath(path), {
    persona: {
      name: persona.name,
      description: persona.description,
      creativity: persona.creativity,
      persona_type: persona.persona_type,
      filters: persona.filters ?? undefined
    }
  })
    .then((res: any) => {
      toast.success('Persona saved')
      return res.persona as Persona
    })
    .catch((err) => {
      toast.error(`Error saving persona: ${err.message}`)
    })
}

export const destroyPersona = async (persona: Persona) => {
  return del(projectPath(`/personas/${persona.id}`))
    .then(() => {
      if (persona.name && persona.description) {
        toast.success('Persona deleted')
      }
    })
    .catch((err) => {
      // ignore 404s
      if (err.statusCode === 404) {
        return
      }
      toast.error(`Error deleting persona: ${err.message}`)
    })
}

export function Personas(props: { onChange?: (personas: Persona[]) => void }) {
  const { data, isLoading: loading, refetch } = usePersonas()
  const [personas, setPersonas] = React.useState<Persona[]>(data?.personas ?? [])

  useEffect(() => {
    if (data?.personas) {
      setPersonas(data.personas ?? [])
    }
  }, [data?.personas])

  const newPersonaModal = useDisclosure()

  return (
    <Stack spacing="4" width="100%">
      {loading && <Text>Loading...</Text>}

      {personas.map((persona) => (
        <PersonaPreview
          key={persona.id}
          persona={persona}
          isEditable
          onSave={(updated) => {
            const updatedPersonas = personas.map((p) => (p.id === updated.id ? updated : p))
            setPersonas(updatedPersonas)
            props.onChange?.(updatedPersonas)
          }}
          onDelete={() => {
            const filtered = personas.filter((p) => p.id !== persona.id)
            setPersonas(filtered)
            destroyPersona(persona).then(() => {
              props.onChange?.(filtered)
            })
          }}
        />
      ))}

      <Flex justifyContent="flex-end">
        <PersonaBuilderModal
          {...newPersonaModal}
          onSave={(newPersona) => {
            refetch()
            props.onChange?.(personas.concat(newPersona))
          }}
        />

        <Button
          size="sm"
          variant="outline"
          leftIcon={<IconPlus size={14} />}
          iconSpacing={1.5}
          onClick={newPersonaModal.onOpen}
        >
          Add Persona
        </Button>
      </Flex>
    </Stack>
  )
}

interface PersonaPreviewProps {
  persona: Persona
  isEditable?: boolean
  onSave?: (persona: Persona) => void
  onDelete?: (persona: Persona) => void
}

export function PersonaPreview(props: PersonaPreviewProps) {
  const disclosure = useDisclosure()

  const filters = props.persona.filters ?? {}
  const selectedLevels = filters.job_title_levels || { values: [], operator: 'must' }
  const selectedRoles = filters.job_title_role || { values: [], operator: 'must' }
  const selectedCountries = filters.location_country || { values: [], operator: 'must' }
  const selectedCities = filters.location_locality || { values: [], operator: 'must' }
  const selectedStates = filters.location_region || { values: [], operator: 'must' }
  const selectedTitles = getValues(filters.title_keywords)
  const notKeywords = getValues(filters.not_keywords)

  return (
    <Box>
      <Card padding={4} position="relative" onDoubleClick={props.isEditable ? disclosure.onOpen : undefined}>
        <Stack spacing={5}>
          <Flex gap={4} justifyContent="space-between">
            <Flex alignItems="center" gap={2}>
              <Icon as={IconUserCircle} boxSize={4} />
              <Heading size="xs" flex="1">
                {props.persona.name}
              </Heading>
            </Flex>

            <ButtonGroup size="xs" variant={'ghost'} spacing="1">
              {props.isEditable && (
                <Button leftIcon={<IconEdit size="14" />} iconSpacing={1} onClick={disclosure.onOpen}>
                  Edit
                </Button>
              )}
              {props.isEditable && props.onDelete && (
                <Tooltip label="Delete" aria-label="Delete">
                  <IconButton
                    colorScheme="red"
                    aria-label="Delete"
                    icon={<IconTrash size="14" />}
                    onClick={() => {
                      if (!props.persona.name) {
                        return props.onDelete?.(props.persona)
                      }

                      if (confirm('Are you sure you want to delete this persona?')) {
                        props.onDelete?.(props.persona)
                      }
                    }}
                  />
                </Tooltip>
              )}
            </ButtonGroup>
          </Flex>

          {props.persona.description && props.persona.persona_type === 'ai' && (
            <Box fontSize="sm">
              <ReactMarkdown
                components={{
                  h2: ({ node, ...props }) => <Heading pt="2" size="xs" {...props} />,
                  p: ({ node, ...props }) => <Text pb="2" {...props} />,
                  li: ({ node, ...props }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style
                      }}
                    />
                  ),
                  ul: ({ node, ...props }) => (
                    <ul
                      {...props}
                      style={{
                        ...props.style,
                        paddingLeft: '12px'
                      }}
                    />
                  ),
                  // @ts-ignore - types are wrong
                  a: ({ node, ...props }) => (
                    <HStack>
                      <Link {...props} size="xs" colorScheme={'linkedin'} isExternal href={props.href} />
                    </HStack>
                  )
                }}
              >
                {props.persona.description}
              </ReactMarkdown>
            </Box>
          )}

          {props.persona.persona_type === 'filter' && (
            <>
              {selectedTitles.length > 0 && (
                <Stack flex="1" spacing={'1'}>
                  <Heading size="xs">Title Keywords:</Heading>

                  <Wrap>
                    {selectedTitles.map((title) => (
                      <Tag key={title} colorScheme="purple" size="sm">
                        {title}
                      </Tag>
                    ))}
                  </Wrap>
                </Stack>
              )}

              {notKeywords.length > 0 && (
                <Stack flex="1" spacing={'1'}>
                  <Heading size="xs">Title keywords to exclude:</Heading>

                  <HStack flexWrap={'wrap'}>
                    {notKeywords.map((keyword) => (
                      <Tag key={keyword} colorScheme="red" size="sm">
                        {keyword}
                      </Tag>
                    ))}
                  </HStack>
                </Stack>
              )}

              {selectedLevels.values.length > 0 && (
                <Stack flex="1" spacing={'1'}>
                  <Heading size="xs">Seniority:</Heading>
                  <Wrap>
                    {selectedLevels.values.map((level) => (
                      <Tag
                        key={level}
                        colorScheme={getOperator(filters.job_title_levels) === 'must_not' ? 'gray' : 'purple'}
                        size="sm"
                      >
                        {selectedLevels.operator === 'must_not' && <TagLeftIcon as={IconUserOff} />}
                        <TagLabel textDecor={selectedLevels.operator === 'must_not' ? 'line-through' : 'none'}>
                          {level}
                        </TagLabel>
                      </Tag>
                    ))}
                  </Wrap>
                </Stack>
              )}

              {selectedRoles.values.length > 0 && (
                <Stack flex="1" spacing={'0.5'}>
                  <Heading size="xs">Role:</Heading>
                  {selectedRoles.operator === 'must_not' && 'Not '}
                  <Wrap>
                    {selectedRoles.values.map((role) => (
                      <Tag key={role} colorScheme={selectedRoles.operator === 'must_not' ? 'gray' : 'purple'} size="sm">
                        {selectedRoles.operator === 'must_not' && <TagLeftIcon as={IconNoteOff} />}
                        <TagLabel textDecor={selectedRoles.operator === 'must_not' ? 'line-through' : 'none'}>
                          {role}
                        </TagLabel>
                      </Tag>
                    ))}
                  </Wrap>
                </Stack>
              )}

              {selectedCountries.values.length > 0 && (
                <Stack flex="1" spacing={'0.5'}>
                  <Heading size="xs">Country:</Heading>
                  {selectedCountries.operator === 'must_not' && 'Not '}
                  <Wrap>
                    {selectedCountries.values.map((country) => (
                      <Tag
                        key={country}
                        colorScheme={selectedCountries.operator === 'must_not' ? 'gray' : 'purple'}
                        size="sm"
                      >
                        {selectedCountries.operator === 'must_not' && <TagLeftIcon as={IconMapOff} />}
                        <TagLabel textDecor={selectedCountries.operator === 'must_not' ? 'line-through' : 'none'}>
                          {country}
                        </TagLabel>
                      </Tag>
                    ))}
                  </Wrap>
                </Stack>
              )}
              {selectedStates.values.length > 0 && (
                <Stack flex="1" spacing={'0.5'}>
                  <Heading size="xs">State/Region:</Heading>
                  {selectedStates.operator === 'must_not' && 'Not '}
                  <Wrap>
                    {selectedStates.values.map((country) => (
                      <Tag
                        key={country}
                        colorScheme={selectedStates.operator === 'must_not' ? 'gray' : 'purple'}
                        size="sm"
                      >
                        {selectedStates.operator === 'must_not' && <TagLeftIcon as={IconMapOff} />}
                        <TagLabel textDecor={selectedStates.operator === 'must_not' ? 'line-through' : 'none'}>
                          {country}
                        </TagLabel>
                      </Tag>
                    ))}
                  </Wrap>
                </Stack>
              )}
              {selectedCities.values.length > 0 && (
                <Stack flex="1" spacing={'0.5'}>
                  <Heading size="xs">City:</Heading>
                  {selectedCities.operator === 'must_not' && 'Not '}
                  <Wrap>
                    {selectedCities.values.map((country) => (
                      <Tag
                        key={country}
                        colorScheme={selectedCities.operator === 'must_not' ? 'gray' : 'purple'}
                        size="sm"
                      >
                        {selectedCities.operator === 'must_not' && <TagLeftIcon as={IconMapOff} />}
                        <TagLabel textDecor={selectedCities.operator === 'must_not' ? 'line-through' : 'none'}>
                          {country}
                        </TagLabel>
                      </Tag>
                    ))}
                  </Wrap>
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Card>

      <PersonaBuilderModal persona={props.persona} {...disclosure} onSave={props.onSave} />
    </Box>
  )
}

interface PersonaBuilderModalProps {
  persona?: Persona
  isOpen: boolean
  onClose: () => void
  onSave?: (persona: Persona) => void
}

export function PersonaBuilderModal(props: PersonaBuilderModalProps) {
  const currentUser = useCurrentUser()
  const [localPersona, setLocalPersona] = React.useState<Persona>(
    props.persona || {
      name: '',
      description: '',
      persona_type: 'filter'
    }
  )

  const disclosure = useDisclosure({
    ...props,
    onClose: () => {
      setLocalPersona(
        props.persona || {
          name: '',
          description: '',
          persona_type: 'filter'
        }
      )
      props.onClose()
    }
  })

  const onFilterChange = useCallback((updates: Record<string, FilterState>) => {
    setLocalPersona((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        ...updates
      }
    }))
  }, [])

  useUpdateEffect(() => {
    if (props.persona) {
      setLocalPersona(props.persona)
    }
  }, [props.persona])

  const [isSaving, setIsSaving] = React.useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)
  const descriptionRef = useRef<HTMLTextAreaElement>(null)
  const localFilters = localPersona.filters ?? {}
  const allowTypeChange = currentUser.isInternalUser

  // on save check validity of name and description
  const isValid = useMemo(() => {
    if (!localPersona.name?.trim()) return false

    if (localPersona.persona_type === 'ai') {
      if (!localPersona.description?.trim()) return false
    } else {
      const hasFilters = Object.values(localPersona.filters ?? {}).some((filter) => filter.values.length > 0)
      if (!hasFilters) return false
    }

    return true
  }, [localPersona])

  return (
    <Modal {...disclosure} size="lg" autoFocus={false} closeOnOverlayClick={false} isCentered>
      <ModalOverlay />
      <ModalContent ref={containerRef}>
        <ModalCloseButton />
        <ModalHeader>
          <HStack>
            <Icon as={IconUserCircle} size={14} color="purple.500" />
            <Heading fontWeight="semibold" size="sm">
              {localPersona.id ? 'Edit your Persona' : 'Describe your Persona'}
            </Heading>
          </HStack>
        </ModalHeader>

        <ModalBody>
          <Stack spacing="4" fontSize="sm">
            <FormControl>
              <Flex gap={4} alignItems="center" justifyContent="space-between" mb={1.5}>
                <FormLabel mb={0}>Persona Name</FormLabel>

                {allowTypeChange && (
                  <HStack spacing={1.5}>
                    <Select
                      size="xs"
                      rounded="lg"
                      value={localPersona.persona_type}
                      onChange={(e) => {
                        setLocalPersona((prev) => ({ ...prev, persona_type: e.target.value as 'filter' | 'ai' }))
                      }}
                    >
                      <option value="filter">Filters</option>
                      <option value="ai">AI ✨</option>
                    </Select>

                    <HelpTooltip>
                      <Stack>
                        <Heading size="xs">Choosing your Persona type</Heading>

                        <Box fontSize="xs">
                          <Flex gap={1} alignItems="center">
                            <Icon as={IconTargetArrow} boxSize={3} color="gray.600" />
                            <Text fontWeight="semibold">Filter-based</Text>
                          </Flex>
                          <Text>Create targeted personas using specific criteria with precise filters.</Text>
                        </Box>
                        <Box fontSize="xs">
                          <Flex gap={1} alignItems="center">
                            <Icon as={IconSparkles} boxSize={3} color="gray.600" />
                            <Text fontWeight="semibold">AI-based</Text>
                            <Badge variant="regular" colorScheme="amber" mr={1}>
                              Experimental
                            </Badge>
                          </Flex>
                          <Text>
                            Use natural language to find your ideal prospects with AI. More flexible, captures naunce,
                            but may include some false positives.
                          </Text>
                        </Box>
                      </Stack>
                    </HelpTooltip>
                  </HStack>
                )}
              </Flex>

              <Input
                size="sm"
                type="text"
                value={localPersona.name}
                onChange={(e) => setLocalPersona((prev) => ({ ...prev, name: e.target.value }))}
                isDisabled={isSaving}
                isRequired
                placeholder="Sales Leaders..."
                ref={nameRef}
              />
            </FormControl>

            {localPersona.persona_type === 'ai' && (
              <FormControl>
                <FormLabel>Description</FormLabel>

                <Textarea
                  rounded="md"
                  size="sm"
                  isRequired
                  isDisabled={isSaving}
                  ref={descriptionRef}
                  minH="80px"
                  rows={(localPersona.description ?? '').split('\n').length + 2}
                  value={localPersona.description}
                  onChange={(e) => setLocalPersona({ ...localPersona, description: e.target.value })}
                />
              </FormControl>
            )}

            {localPersona.persona_type === 'ai' && (
              <FormControl>
                <FormLabel fontSize="sm" fontWeight="semibold">
                  ✨ Creativity Level
                </FormLabel>
                <RadioGroup
                  as={Stack}
                  spacing="0"
                  size="sm"
                  name="creativity"
                  value={localPersona.creativity}
                  onChange={(value) => {
                    setLocalPersona((prev) => ({ ...prev, creativity: value as 'creative' | 'strict' }))
                  }}
                >
                  <Radio value="creative">
                    <HStack spacing="1">
                      <Text>Creative </Text>
                      <Text fontSize="xs" color="gray.500">
                        (more matches, potential false positives)
                      </Text>
                      <HelpTooltip size="3">
                        <Text fontSize="xs" color="gray.500">
                          The prospecting AI will look for prospects that are an exact match, plus similar prospects
                          based on role, seniority, and location.
                        </Text>
                      </HelpTooltip>
                    </HStack>
                  </Radio>
                  <Radio value="strict">
                    <HStack spacing="1">
                      <Text>Strict </Text>
                      <Text fontSize="xs" color="gray.500">
                        (fewer matches, fewer false positivies)
                      </Text>
                      <HelpTooltip size="3">
                        <Text fontSize="xs" color="gray.500">
                          The prospecting AI will look for prospects that are an exact match to your Persona only.
                        </Text>
                      </HelpTooltip>
                    </HStack>
                  </Radio>
                </RadioGroup>
              </FormControl>
            )}

            {localPersona.persona_type === 'filter' && (
              <Card padding={0}>
                <Stack divider={<Divider />} spacing={0}>
                  <Box paddingY={1.5} paddingX={1.5}>
                    <FilterPopover
                      facet="title_keywords"
                      notFacet="not_keywords"
                      inputType="keyword"
                      filters={localFilters}
                      onChange={onFilterChange}
                      icon={IconBriefcaseFilled}
                      showPreview
                      hideCounts
                      containerRef={containerRef}
                    >
                      Title Keywords
                    </FilterPopover>
                  </Box>
                  <Box paddingY={1.5} paddingX={1.5}>
                    <FilterPopover
                      filters={localFilters}
                      onChange={onFilterChange}
                      facet="job_title_levels"
                      icon={IconMilitaryRankFilled}
                      showPreview
                      hideCounts
                      containerRef={containerRef}
                    >
                      Seniority
                    </FilterPopover>
                  </Box>
                  <Box paddingY={1.5} paddingX={1.5}>
                    <FilterPopover
                      filters={localFilters}
                      onChange={onFilterChange}
                      facet="job_title_role"
                      icon={IconBriefcaseFilled}
                      showPreview
                      hideCounts
                      containerRef={containerRef}
                    >
                      Role
                    </FilterPopover>
                  </Box>
                  <Box paddingY={1.5} paddingX={1.5}>
                    <FilterPopover
                      filters={localFilters}
                      onChange={onFilterChange}
                      facet="job_title_sub_role"
                      icon={IconBriefcaseFilled}
                      showPreview
                      hideCounts
                      containerRef={containerRef}
                    >
                      Job Function
                    </FilterPopover>
                  </Box>
                  <Box paddingY={1.5} paddingX={1.5}>
                    <FilterPopover
                      filters={localFilters}
                      onChange={onFilterChange}
                      facet="location_country"
                      icon={IconMapPinFilled}
                      showPreview
                      hideCounts
                      containerRef={containerRef}
                    >
                      Country
                    </FilterPopover>
                  </Box>
                  <Box paddingY={1.5} paddingX={1.5}>
                    <FilterPopover
                      filters={localFilters}
                      onChange={onFilterChange}
                      facet="location_region"
                      icon={IconMapPinFilled}
                      showPreview
                      hideCounts
                      containerRef={containerRef}
                    >
                      State / Region
                    </FilterPopover>
                  </Box>
                  <Box paddingY={1.5} paddingX={1.5}>
                    <FilterPopover
                      filters={localFilters}
                      onChange={onFilterChange}
                      facet="location_locality"
                      icon={IconMapPinFilled}
                      showPreview
                      hideCounts
                      containerRef={containerRef}
                    >
                      City
                    </FilterPopover>
                  </Box>
                </Stack>
              </Card>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter gap={3}>
          <Button size="sm" variant="outline" onClick={disclosure.onClose} isDisabled={isSaving}>
            Cancel
          </Button>
          <Button
            size="sm"
            colorScheme="purple"
            onClick={() => {
              setIsSaving(true)
              savePersona(localPersona).then((persisted) => {
                if (persisted) {
                  props.onSave?.(persisted)
                  setIsSaving(false)
                  disclosure.onClose()
                }
              })
            }}
            isLoading={isSaving}
            isDisabled={!isValid}
          >
            Save Persona
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export interface PersonaSelectorProps {
  selectedPersona: Persona | null
  personas?: Persona[]
  onSelect: (persona: Persona | null) => void
}

export function PersonaSelector({ selectedPersona, personas, onSelect }: PersonaSelectorProps) {
  const { data, isLoading, isRefetching, refetch } = usePersonas()

  const availablePersonas = useMemo(() => {
    if (personas) {
      return personas
    }
    return data?.personas ?? []
  }, [personas, data?.personas])

  useEffect(() => {
    if (selectedPersona) {
      refetch()
    }
  }, [selectedPersona, refetch])

  return (
    <ComboboxWithSearch
      items={availablePersonas}
      selectedItem={selectedPersona}
      onChange={(persona) => {
        onSelect(persona)
      }}
      isLoading={isLoading || isRefetching}
      filterItem={(a, val) =>
        a.name?.toLowerCase().includes(val) || a.description?.toLowerCase().includes(val) || false
      }
      itemRenderer={({ item }) => {
        return (
          <HStack spacing={2.5} overflow="hidden" maxW="100%">
            {item.persona_type === 'ai' ? (
              <Icon as={AiSparklesIcon} boxSize={5} color="gray.500" />
            ) : (
              <Icon as={IconUserCircle} boxSize={5} color="gray.500" />
            )}
            <Box minW="200px">
              <Text fontSize="sm" fontWeight="medium">
                {item.name}
              </Text>
              {item.description && item.persona_type !== 'filter' && (
                <TextEllipsis fontSize="xs" color="gray.500" maxW="100%">
                  {item.description}
                </TextEllipsis>
              )}
              {item.persona_type === 'filter' && item.filters && Object.keys(item.filters).length > 0 && (
                <TextEllipsis fontSize="xs" color="gray.500" maxW="100%" tooltip>
                  {Object.entries(item.filters)
                    .map(([key, filter]) => {
                      const values = filter?.values ?? []
                      if (!values.length) return null
                      const displayKey = humanize(key)
                        .replace('Title Keywords', 'Title')
                        .replace('Job Title', '')
                        .replace('Levels', 'Seniority')
                        .replace('Location', '')
                        .replace('Not Keywords', 'NOT')
                        .trim()
                      return displayKey
                        ? `${displayKey}: ${values.map((v) => humanize(v)).join(', ')}`
                        : values.map((v) => humanize(v)).join(', ')
                    })
                    .filter(Boolean)
                    .join(' • ')}
                </TextEllipsis>
              )}
            </Box>
          </HStack>
        )
      }}
      showClearButton={false}
      selectButtonRenderer={(props) => {
        const item = props.item
        if (!item) {
          return (
            <Text fontSize="sm" color="gray.500">
              Select a Persona
            </Text>
          )
        } else {
          return (
            <HStack spacing={2.5}>
              <Icon as={IconUserCircle} boxSize={5} color="gray.500" />
              <Box>
                <Text fontSize="sm" fontWeight="medium">
                  {item.name}
                </Text>
                {item.description && item.persona_type !== 'filter' && (
                  <TextEllipsis fontSize="xs" color="gray.500" maxW="300">
                    {item.description}
                  </TextEllipsis>
                )}

                <Wrap>
                  {item.persona_type === 'filter' &&
                    item.filters &&
                    Object.keys(item.filters).length > 0 &&
                    Object.keys(item.filters)
                      .flatMap((key) => {
                        const value = item.filters?.[key]?.values ?? []
                        return value.map((v) => (
                          <Tag size="sm" fontSize="xs" color="gray.500" key={v}>
                            {key}: {v}
                          </Tag>
                        ))
                      })
                      .slice(0, 3)
                      .concat(
                        <Tag fontSize="xs" color="gray.500" key="more">
                          ...
                        </Tag>
                      )}
                </Wrap>
              </Box>
            </HStack>
          )
        }
      }}
    />
  )
}
