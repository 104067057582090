import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Stack,
  Switch,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { IconBinoculars, IconPlus, IconSparkles, IconUserCircle, IconX } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { Play } from '../../../../types/Play'
import { usePersonas } from '../../../data/use-personas'
import { PartialAccount } from '../../../ui/AccountSelector'
import { GrayCard } from '../../../ui/Card'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { AiSparklesIcon } from '../../../ui/icons'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { humanize } from '../../accounts/facets/filter-cloud'
import { Persona, PersonaBuilderModal, PersonaSelector } from '../../prospects/personas'
import { ProspectorPreview } from '../../prospects/settings'
export function ProspectingSetup({
  play,
  onTargetTypeChange
}: {
  play: Partial<Play>
  onTargetTypeChange: (targetType: Play['target_type']) => void
}) {
  const [selectedPersonaIds, setSelectedPersonaIds] = useState<string[]>(play?.prospecting_config?.personas ?? [])
  const [numProspects, setNumProspects] = useState<number>(play?.prospecting_config?.num_prospects ?? 3)
  const [limitEnrollmentToPersonas, setLimitEnrollmentToPersonas] = useState<boolean>(
    play?.prospecting_config?.limit_enrollment_to_personas ?? false
  )

  const [option, setOption] = useState<'warm_prospects' | 'cold_prospects'>(
    play?.prospecting_config?.option ?? 'warm_prospects'
  )

  const newPersonaModal = useDisclosure()

  const { data: personas, isLoading: personasLoading, refetch } = usePersonas()

  const remainingPersonas = useMemo(
    () => personas?.personas?.filter((persona) => !selectedPersonaIds.includes(persona.id!)) ?? [],
    [personas, selectedPersonaIds]
  )

  const selectedPersonas: Persona[] = useMemo(() => {
    return (
      (selectedPersonaIds
        .map((id) => personas?.personas?.find((persona) => persona.id === id))
        .filter(Boolean) as Persona[]) ?? []
    )
  }, [personas, selectedPersonaIds])

  const [isPreviewing, setIsPreviewing] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<PartialAccount | null>(null)
  const [selectedPersona, setSelectedPersona] = useState<Persona | null>(null)

  return (
    <GrayCard flexDirection="column" p={0}>
      <FormControl>
        <Flex alignItems="center" gap={4} p={6}>
          <Flex flex="none" align="center" justify="center" boxSize={6} color="gray.500">
            <IconBinoculars size={20} />
          </Flex>
          <Box flex="1 1 auto">
            <FormLabel cursor="pointer" mb={0}>
              AI Auto-Prospecting
            </FormLabel>
            <Text fontSize="13px" lineHeight="19px" color="gray.500">
              Automatically find additional prospects based on your key buyer personas
            </Text>
          </Box>
          <Switch
            isChecked={option === 'cold_prospects'}
            onChange={(e) => {
              setOption(e.target.checked ? 'cold_prospects' : 'warm_prospects')
              if (e.target.checked) {
                onTargetTypeChange('Profile')
              }
            }}
          />
        </Flex>

        <input type="hidden" name="play[prospecting_config][option]" value={option} />
      </FormControl>

      <Collapse in={option === 'cold_prospects'}>
        <Box p={6} borderTop="1px solid" borderColor="gray.200">
          {selectedPersonaIds.map((personaId) => (
            <input key={personaId} type="hidden" name="play[prospecting_config][personas][]" value={personaId} />
          ))}

          {/* Prospecting plays target people by default */}
          {selectedPersonaIds.length > 0 && <input type="hidden" name="play[target_type]" value={'Profile'} />}

          {/* loading state */}
          {personasLoading && (
            <Flex p="4">
              <Spinner size="sm" />
            </Flex>
          )}

          {!personasLoading && (
            <Stack spacing={4}>
              <Stack spacing={2}>
                <Text fontSize="xs" color="gray.600">
                  Select the personas you want to include:
                </Text>

                {/* show each persona with a small switch to include it */}
                <Flex flexDir="column" gap={2}>
                  {selectedPersonas.map((persona) => (
                    <FormControl key={persona.id}>
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        gap={2}
                        padding={1.5}
                        paddingLeft={3}
                        bg="whiteAlpha.700"
                        border="1px solid"
                        borderColor="gray.200"
                        rounded="md"
                        _hover={{ bg: 'white' }}
                      >
                        <HStack flex="1 1 auto" minW="200px" color="gray.700">
                          {persona.persona_type === 'ai' ? (
                            <Tooltip label="AI Persona">
                              <Icon as={IconSparkles} flex="none" boxSize={4} color="purple.500" />
                            </Tooltip>
                          ) : (
                            <Tooltip label="Keyword-based Persona">
                              <Icon as={IconUserCircle} flex="none" boxSize={4} />
                            </Tooltip>
                          )}

                          <Stack flex="1 1 auto" minW="200px" spacing={0}>
                            <Text fontSize="sm" fontWeight="medium" isTruncated>
                              {persona.name}
                            </Text>

                            {persona.persona_type === 'ai' && persona.description && (
                              <TextEllipsis tooltip fontSize="xs" color="gray.500" maxW="100%">
                                {persona.description}
                              </TextEllipsis>
                            )}

                            {persona.persona_type === 'filter' &&
                              persona.filters &&
                              Object.keys(persona.filters).length > 0 && (
                                <TextEllipsis fontSize="xs" color="gray.500" maxW="100%" tooltip>
                                  {Object.entries(persona.filters)
                                    .map(([key, filter]) => {
                                      const values = filter?.values ?? []
                                      if (!values.length) return null
                                      const displayKey = humanize(key)
                                        .replace('Title Keywords', 'Title')
                                        .replace('Job Title', '')
                                        .replace('Levels', 'Seniority')
                                        .replace('Location', '')
                                        .replace('Not Keywords', 'NOT')
                                        .trim()
                                      return displayKey
                                        ? `${displayKey}: ${values.map((v) => humanize(v)).join(', ')}`
                                        : values.map((v) => humanize(v)).join(', ')
                                    })
                                    .filter(Boolean)
                                    .join(' • ')}
                                </TextEllipsis>
                              )}
                          </Stack>
                        </HStack>

                        {persona.persona_type === 'ai' && (
                          <Button
                            flex="none"
                            size="xs"
                            variant="ghost"
                            leftIcon={<AiSparklesIcon size={16} />}
                            onClick={() => {
                              setSelectedPersona(persona)
                              setIsPreviewing(true)
                            }}
                            colorScheme="purple"
                          >
                            Preview
                          </Button>
                        )}

                        <IconButton
                          flex="none"
                          size="xs"
                          variant="ghost"
                          icon={<IconX size={16} />}
                          aria-label="Remove persona"
                          onClick={() => {
                            setSelectedPersonaIds((prev) => prev.filter((id) => id !== persona.id!))
                          }}
                        />
                      </Flex>
                    </FormControl>
                  ))}
                </Flex>
              </Stack>

              {remainingPersonas.length && (
                <PersonaSelector
                  selectedPersona={null}
                  personas={remainingPersonas}
                  onSelect={(persona) => {
                    if (persona?.id) {
                      setSelectedPersonaIds((prev) => [...prev, persona.id!])
                    }
                  }}
                />
              )}

              {!remainingPersonas.length && !personasLoading && (
                <Button
                  size="md"
                  variant="outline"
                  leftIcon={<IconPlus size={16} />}
                  bg="white"
                  onClick={newPersonaModal.onOpen}
                >
                  Add Persona
                </Button>
              )}

              {selectedPersonaIds.length > 0 && (
                <>
                  <Divider />
                  <Stack spacing={6}>
                    <Flex as={FormControl} alignItems="center" justifyContent="space-between" gap={4} pt="2">
                      <Box>
                        <FormLabel>Max prospects per company</FormLabel>
                        <FormHelperText>
                          How many prospects Koala should attempt to find for each company.
                        </FormHelperText>
                      </Box>

                      <NumberInput
                        size="sm"
                        bg="white"
                        rounded={'md'}
                        min={1}
                        max={10}
                        name="play[prospecting_config][num_prospects]"
                        value={numProspects}
                        onChange={(e) => {
                          setNumProspects(parseInt(e))
                        }}
                      >
                        <NumberInputField rounded="md" />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </Flex>

                    <Divider />

                    <HStack>
                      <Checkbox
                        isChecked={limitEnrollmentToPersonas}
                        onChange={(e) => {
                          setLimitEnrollmentToPersonas(e.target.checked)
                        }}
                      >
                        Limit enrollment to only people matched to the personas above
                      </Checkbox>
                      <HelpTooltip>
                        <Text>
                          If you enable this, only people who match all of the selected personas will be enrolled in the
                          play. Ignoring all other potentially warm prospects.
                        </Text>
                      </HelpTooltip>
                      {limitEnrollmentToPersonas && (
                        <input type="hidden" name="play[prospecting_config][limit_enrollment_to_personas]" value="1" />
                      )}
                    </HStack>
                  </Stack>
                </>
              )}
            </Stack>
          )}

          <PersonaBuilderModal
            {...newPersonaModal}
            onSave={(newPersona) => {
              refetch()
              setSelectedPersonaIds((prev) => [...prev, newPersona.id!])
            }}
          />

          <Drawer
            isOpen={isPreviewing}
            onClose={() => {
              setIsPreviewing(false)
            }}
            size="xl"
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Preview Prospects</DrawerHeader>
              <DrawerBody fontSize={'sm'}>
                <ProspectorPreview
                  selectedAccount={selectedAccount}
                  setSelectedAccount={setSelectedAccount}
                  selectedPersona={selectedPersona ?? undefined}
                  autoProspectingSetting={{ data_sources: [], persona_prompt: selectedPersona?.description ?? '' }}
                />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>
      </Collapse>
    </GrayCard>
  )
}
