import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { Territory } from '../../types/Territory'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface Data {
  territories: Territory[]
}

export function useTerritories() {
  const project = useCurrentProject()
  const path = projectPath('/territories')

  return useQuery<Data>(
    ['territories', { projectId: project?.id }],
    () => concurrentGET<{ territories: Territory[] }>(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}
