import { useMutation, useQuery } from 'react-query'
import { concurrentCachedGET, concurrentGET, post } from '../../lib/api'
import { PageMeta } from '../../types/PageMeta'
import { HubspotContactCache, ProfileRecord, SalesforceContactCache, SalesloftCachedPerson } from '../../types/Profile'
import { FacetFilters } from '../pages/accounts'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'
import { facetQueryString } from './use-facets'
import { Operator } from '../pages/personas/persona-filters'

interface CompanyForProspect {
  name: string
  domain: string
}

export interface Prospect {
  account_id: string
  city: string
  company_id: string
  company: CompanyForProspect
  country: string
  country_code?: string
  created_at: string
  roles: string[]
  email: null | string
  external_id: string
  first_name: string
  headline: string
  last_name: string
  id: string
  linkedin_url: string
  name: string
  project_id: string
  region: string
  seniority: string
  source: string
  title: string
  updated_at: string
  departments: string[]
  function: string[]
  subdepartments: string[]
  unlock_state: 'unlocked' | 'locked' | 'not_found' | 'auto-unlocked'
  verification_status: 'unverified' | 'verified' | 'verification_failed'

  hubspot_contact_cache?: HubspotContactCache
  salesforce_contact_cache?: SalesforceContactCache
  salesforce_lead_cache?: HubspotContactCache
  salesloft_cached_person?: SalesloftCachedPerson
  profile?: ProfileRecord

  saved?: boolean
  last_checked_at?: Date

  rank?: 'very high' | 'high' | 'medium' | 'low'
  reason?: string | React.ReactNode
  highlight?: {
    job_title_levels?: string | string[]
    job_title_roles?: string | string[]
    location?: string | string[]
    title_keywords?: string | React.ReactChild
  }

  phone_numbers?: {
    mobile?: string
    phone_numbers?: string[]
  }
  phone_state?: 'unlocked' | 'locked' | 'not_found'
}

export type ProspectFilterWithOperator = {
  operator: Operator
  values: string[]
}

export type ProspectFilter = string[] | ProspectFilterWithOperator

export type ProspectFilters = Record<string, ProspectFilter> & {
  lat_lng?: {
    range: string
  }
}

export type Highlights<K extends string> = Record<
  string,
  {
    [key in K]: string[] | undefined
  }
>

interface ProspectsResponse {
  prospects: Prospect[]
  prospect_guesses: Prospect[]
  highlights: Highlights<'job_title.analyzed' | 'full_name.analyzed'>
  unlocked: Prospect[]
  page_meta: PageMeta
  filters?: ProspectFilters
  clearbit_hints?: {
    role?: string
    seniority?: string
    geo?: {
      city: string
      country: string
      state: string
      lat?: number
      lng?: number
    }
  }
}

interface ProspectingArguments {
  page?: number
  perPage?: number
  allResults?: boolean
  seed?: number
  profile_id?: string
  persona?: string
  creativity?: 'creative' | 'strict'
  customFilters?: ProspectFilters
}

export function useProspects(domain: string, { page = 1, profile_id, seed = 1 }: ProspectingArguments) {
  const project = useCurrentProject()

  let basePath = `/prospects/${domain}/prospects.json?page=${page}`
  if (profile_id) {
    basePath = basePath.concat(`&profile_id=${profile_id}`)
  }

  const path = projectPath(basePath)

  return useQuery<ProspectsResponse>(
    [{ projectId: project?.id, domain, profile_id, page, seed }],
    () => concurrentGET<ProspectsResponse>(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}

export function useProfileProspects(
  domain: string,
  { page = 1, profile_id, seed = 1, customFilters }: ProspectingArguments
) {
  const project = useCurrentProject()

  const basePath = `/accounts/${domain}/prospects/profile.json?page=${page}&profile_id=${profile_id}`
  let path = projectPath(basePath)

  if (customFilters) {
    // convert filters to filter[key][]=value
    const filterParams = Object.entries(customFilters).map(([key, values]) => {
      if (Array.isArray(values)) {
        return values.map((value) => `filters[${key}][]=${value}`).join('&')
      }

      const withOperator = values as ProspectFilterWithOperator
      const operator = withOperator.operator === 'must_not' ? 'not' : ''
      return withOperator.values.map((value) => `filters[${key}][${operator}]=${value}`).join('&')
    })

    if (filterParams.length) {
      path = path.concat(`&${filterParams.join('&')}`)
    }
  }

  return useQuery<ProspectsResponse>(
    [{ projectId: project?.id, domain, profile_id, page, seed, customFilters }],
    () => concurrentCachedGET<ProspectsResponse>(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}

export type FilterAggs = {
  buckets: Array<{ key: string; doc_count: number }>
}

export type FilteredProspectsResponse = {
  prospects: Prospect[]
  highlights: Highlights<'job_title.analyzed' | 'full_name.analyzed'>
  page_meta: PageMeta
}

export function filteredProspectsPath(
  domains: string[] | string,
  {
    page = undefined,
    perPage = 20,
    allResults = false,
    filters = {},
    persona = undefined,
    search = undefined,
    only_saved = false,
    ids = [],
    format = 'json'
  }: ProspectingArguments & {
    filters?: FacetFilters
    search?: string
    only_saved?: boolean
    ids?: string[]
    format?: string
  }
) {
  const filterParams = facetQueryString(filters)
  const searchParams = new URLSearchParams()

  if (domains) {
    const domainsArray = Array.isArray(domains) ? domains : [domains]
    for (const domain of domainsArray) {
      searchParams.append('domains[]', domain)
    }
  }

  if (allResults) {
    searchParams.append('all_results', 'true')
  } else if (ids.length) {
    searchParams.append('ids', ids.join(','))
  } else {
    if (page) {
      searchParams.append('page', page.toString())
    }

    if (perPage) {
      searchParams.append('per_page', perPage.toString())
    }
  }

  if (persona) {
    searchParams.append('persona', persona)
  }

  if (search) {
    searchParams.append('search', search)
  }

  if (only_saved) {
    searchParams.append('saved', '1')
  }

  const parts: string[] = []

  if (searchParams.toString()) {
    parts.push(searchParams.toString())
  }

  if (filterParams.length) {
    parts.push(...filterParams)
  }

  return projectPath(`/prospects/explore.${format}?${parts.join('&')}`)
}

export function useFilteredProspects(
  domain: string[] | string,
  {
    page = 1,
    perPage = 20,
    filters = {},
    only_saved = false,
    persona = undefined,
    search = undefined
  }: ProspectingArguments & {
    filters?: FacetFilters
    only_saved?: boolean
    search?: string
  }
) {
  const project = useCurrentProject()
  const path = filteredProspectsPath(domain, { page, perPage, filters, persona, only_saved, search })

  return useQuery<FilteredProspectsResponse>(
    ['filtered-prospects', { projectId: project?.id, domain, filters, page, perPage, persona, only_saved, search }],
    () => concurrentGET<FilteredProspectsResponse>(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}

export function useFilteredProspectsByCompanyFilters({
  page = 1,
  perPage = 20,
  filters = {},
  search = undefined
}: ProspectingArguments & {
  filters?: FacetFilters
  search?: string
}) {
  const project = useCurrentProject()
  const filterParams = [...facetQueryString(filters), `page=${page}`, `per_page=${perPage}`]

  if (search) {
    filterParams.push(`search=${search}`)
  }

  const path = projectPath(`/prospects/explore-filters.json?${filterParams.join('&')}`)

  return useQuery<FilteredProspectsResponse>(
    ['filtered-prospects', { projectId: project?.id, filters, page, perPage, search }],
    () => concurrentGET<FilteredProspectsResponse>(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}

export function useProspectAggs({ filters = {}, search = undefined }: { filters?: FacetFilters; search?: string }) {
  // convert filters to filter[key][]=value
  const filterParams = Object.entries(filters).map(([key, values]) =>
    values.map((value) => `filters[${key}][]=${value}`).join('&')
  )

  const project = useCurrentProject()
  let basePath = `/personas/filters`

  if (filterParams.length) {
    basePath = basePath.concat(`&${filterParams.join('&')}`)
  }

  if (search) {
    basePath = basePath.concat(`&search=${search}`)
  }

  const path = projectPath(basePath)

  return useQuery<{
    aggs: Record<string, FilterAggs>
  }>(
    [{ projectId: project?.id, path }],
    () =>
      concurrentCachedGET<{
        aggs: Record<string, FilterAggs>
      }>(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}

export function usePersonaProspects(
  domain: string,
  personaId: string,
  { page = 1, profile_id, seed = 1, creativity }: ProspectingArguments
) {
  const project = useCurrentProject()

  let basePath = `/personas/${personaId}/prospects.json?domain=${domain}&page=${page}&seed=${seed}`
  if (profile_id) {
    basePath = basePath.concat(`&profile_id=${profile_id}`)
  }

  if (creativity) {
    basePath = basePath.concat(`&creativity=${creativity}`)
  }

  const path = projectPath(basePath)

  return useQuery<ProspectsResponse>(
    [{ projectId: project?.id, personaId, domain, profile_id, page, seed, creativity }],
    () => concurrentGET<ProspectsResponse>(path),
    {
      enabled: Boolean(project?.id)
    }
  )
}

interface SaveProspectParams {
  prospectIds: string[]
}

export function useSaveProspects() {
  return useMutation(({ prospectIds }: SaveProspectParams) => {
    const path = projectPath(`/prospects`)
    return post(path, {
      prospect_ids: prospectIds
    })
  })
}

export function useUnsaveProspects() {
  return useMutation(({ prospectIds }: SaveProspectParams) => {
    const path = projectPath(`/prospects/unsave`)
    return post(path, {
      prospect_ids: prospectIds
    })
  })
}
