import { ListItem, Text, UnorderedList } from '@chakra-ui/react'
import {
  IconCalendarTime,
  IconClick,
  IconId,
  IconTransform,
  IconTriangleSquareCircle,
  IconWorldWww
} from '@tabler/icons-react'
import React from 'react'
import { ConditionType } from '../types'

export type ConditionKind =
  | 'event'
  | 'page_view'
  | 'form_fill'
  | 'total_time'
  | 'profile_trait'
  | 'account_trait'
  | 'g2_event'
  | 'github_event'
  | 'linkedin_event'
  | 'discord_event'
  | 'intercom_event'
  | 'snowflake_event'
  | 'slack_event'

export type TraitChange = {
  was: any
  is: any
}

type DataType = 'string' | 'boolean' | 'number' | 'trait_change' | 'json_value'

export interface ConditionProperty {
  kind: ConditionKind
  subKind?: 'event-property' | 'account-event-property'
  property: string
  label?: string
  matchProperty?: (property?: string) => boolean
  defaultOperator?: Operator['name']
  dataTypes: DataType[]
  // A set of parent property requirements that must be met for this condition to be valid
  requirements?: (condition: Partial<ConditionType>) => boolean
  // Flag to indicate if this property should only appear in refinement conditions
  frequencyProp?: boolean
}

export const triggers: ConditionProperty[] = [
  { kind: 'page_view', property: 'url', dataTypes: ['string'], defaultOperator: 'contains' },
  { kind: 'page_view', property: 'path', dataTypes: ['string'], defaultOperator: 'contains' },
  { kind: 'page_view', property: 'referrer', dataTypes: ['string'] },
  {
    kind: 'page_view',
    property: 'focus_time',
    label: 'active time on page',
    defaultOperator: 'greater_than',
    dataTypes: ['number']
  },
  { kind: 'page_view', property: 'title', dataTypes: ['string'] },
  { kind: 'page_view', property: 'utm.name', dataTypes: ['string'], label: 'UTM Campaign Name' },
  { kind: 'page_view', property: 'utm.medium', dataTypes: ['string'], label: 'UTM Medium' },
  { kind: 'page_view', property: 'utm.source', dataTypes: ['string'], label: 'UTM Source' },
  { kind: 'page_view', property: 'utm.content', dataTypes: ['string'], label: 'UTM Content' },
  { kind: 'page_view', property: 'utm.term', dataTypes: ['string'], label: 'UTM Term' },
  {
    kind: 'page_view',
    property: 'count',
    label: 'performed at least',
    dataTypes: ['json_value'],
    frequencyProp: true,
    defaultOperator: 'performed_at_least',
    requirements: (condition) => ['event', 'page_view', 'form_fill'].includes(condition.parent?.kind || '')
  },
  { kind: 'form_fill', property: 'page_path', label: 'path', dataTypes: ['string'] },
  { kind: 'form_fill', property: 'page_url', label: 'url', dataTypes: ['string'] },
  { kind: 'form_fill', property: 'contains_email', label: 'has email', dataTypes: ['boolean'] },
  {
    kind: 'form_fill',
    property: 'count',
    label: 'performed at least',
    dataTypes: ['json_value'],
    frequencyProp: true,
    defaultOperator: 'performed_at_least',
    requirements: (condition) => ['event', 'page_view', 'form_fill'].includes(condition.parent?.kind || '')
  },
  { kind: 'event', property: 'event', label: 'name', dataTypes: ['string'] },
  {
    kind: 'event',
    property: 'count',
    label: 'performed at least',
    dataTypes: ['json_value'],
    frequencyProp: true,
    defaultOperator: 'performed_at_least',
    requirements: (condition) => ['event', 'page_view', 'form_fill'].includes(condition.parent?.kind || '')
  },
  {
    kind: 'event',
    subKind: 'event-property',
    property: 'data.properties',
    matchProperty: (property?: string) => property?.startsWith('data.properties') ?? false,
    label: 'event property',
    defaultOperator: 'performed_at_least',
    dataTypes: ['string', 'number', 'boolean'],
    requirements: (condition) => condition.parent?.property === 'event' && condition.parent?.kind === 'event'
  },
  {
    kind: 'total_time',
    property: 'focus_time',
    defaultOperator: 'greater_than',
    label: 'total active time',
    dataTypes: ['number']
  },
  {
    kind: 'profile_trait',
    property: 'name',
    defaultOperator: 'is',
    label: 'trait name',
    dataTypes: ['string'],
    requirements: (condition) => !condition.parent
  },
  {
    kind: 'account_trait',
    property: 'name',
    defaultOperator: 'is',
    label: 'trait name',
    dataTypes: ['string'],
    requirements: (condition) => !condition.parent
  },
  {
    kind: 'profile_trait',
    property: 'value',
    defaultOperator: 'is',
    dataTypes: ['trait_change', 'number', 'string'],
    requirements: (condition) => condition.parent?.property === 'name'
  },
  {
    kind: 'account_trait',
    property: 'value',
    defaultOperator: 'is',
    dataTypes: ['trait_change', 'number', 'string'],
    requirements: (condition) => condition.parent?.property === 'name'
  },
  { kind: 'g2_event', property: 'event', label: 'name', dataTypes: ['string'] },
  { kind: 'g2_event', property: 'source', label: 'source', dataTypes: ['string'] },
  { kind: 'g2_event', property: 'event_type', label: 'event type', dataTypes: ['string'] },
  { kind: 'g2_event', property: 'properties.url', label: 'url', dataTypes: ['string'] },
  { kind: 'g2_event', property: 'properties.title', label: 'title', dataTypes: ['string'] },
  { kind: 'g2_event', property: 'properties.user_location.country_code', label: 'country code', dataTypes: ['string'] },
  { kind: 'g2_event', property: 'properties.user_location.state_code', label: 'state code', dataTypes: ['string'] },
  { kind: 'github_event', property: 'event', label: 'name', dataTypes: ['string'] },
  { kind: 'github_event', property: 'properties.github_repo', label: 'repository', dataTypes: ['string'] },
  { kind: 'github_event', property: 'properties.pull_request_title', label: 'PR title', dataTypes: ['string'] },
  { kind: 'github_event', property: 'properties.issue_title', label: 'issue title', dataTypes: ['string'] },
  { kind: 'linkedin_event', property: 'event', label: 'name', dataTypes: ['string'] },
  { kind: 'linkedin_event', property: 'properties.subject', label: 'subject', dataTypes: ['string'] },
  { kind: 'linkedin_event', property: 'properties.open_type', label: 'open type', dataTypes: ['string'] },
  { kind: 'linkedin_event', property: 'properties.reaction_type', label: 'reaction type', dataTypes: ['string'] },
  { kind: 'linkedin_event', property: 'properties.slug_name', label: 'slug name', dataTypes: ['string'] },
  { kind: 'discord_event', property: 'event', label: 'name', dataTypes: ['string'] },
  { kind: 'discord_event', property: 'properties.server_name', label: 'server name', dataTypes: ['string'] },
  { kind: 'intercom_event', property: 'event', label: 'name', dataTypes: ['string'] },
  { kind: 'intercom_event', property: 'properties.source_type', label: 'source type', dataTypes: ['string'] },
  { kind: 'snowflake_event', property: 'event', label: 'name', dataTypes: ['string'] },
  {
    kind: 'snowflake_event',
    property: 'properties.service_connection_type',
    label: 'connection_type',
    dataTypes: ['string']
  },
  { kind: 'slack_event', property: 'event', label: 'name', dataTypes: ['string'] },
  { kind: 'slack_event', property: 'properties.channel_name', label: 'channel name', dataTypes: ['string'] },
  {
    kind: 'github_event',
    subKind: 'event-property',
    property: 'properties',
    matchProperty: (property?: string) => property?.startsWith('properties') ?? false,
    label: 'event property',
    defaultOperator: 'is',
    dataTypes: ['string', 'number', 'boolean'],
    requirements: (condition) => condition.parent?.property === 'event' && condition.parent?.kind === 'github_event'
  },
  {
    kind: 'g2_event',
    subKind: 'account-event-property',
    property: 'properties',
    matchProperty: (property?: string) => property?.startsWith('properties') ?? false,
    label: 'event property',
    defaultOperator: 'is',
    dataTypes: ['string', 'number', 'boolean'],
    requirements: (condition) => condition.parent?.property === 'event' && condition.parent?.kind === 'g2_event'
  }
]

export const signalTypes = [
  {
    label: 'Page View',
    description: 'When someone visits a specific page or set of pages on your site',
    kind: 'page_view',
    defaultProperty: 'url',
    colorScheme: 'cyan',
    icon: IconWorldWww,
    suggestions: [
      {
        name: 'Viewed Pricing',
        description: 'When someone visits your pricing page',
        conditions: [
          {
            kind: 'page_view',
            with: {
              value: ['30 seconds'],
              operator: 'greater_than',
              property: 'focus_time'
            },
            value: ['/pricing'],
            operator: 'contains',
            property: 'path'
          }
        ]
      },
      {
        name: 'Read any blog post',
        description: 'When someone reads any blog post',
        conditions: [
          {
            kind: 'page_view',
            with: {
              value: ['2 minutes'],
              operator: 'greater_than',
              property: 'focus_time'
            },
            value: ['/blog'],
            operator: 'contains',
            property: 'path'
          }
        ]
      },
      {
        name: 'Read Product Docs',
        description: 'When someone visits your documentation',
        conditions: [
          {
            kind: 'page_view',
            with: {
              value: ['30 seconds'],
              operator: 'greater_than',
              property: 'focus_time'
            },
            value: ['/docs'],
            operator: 'contains',
            property: 'path'
          }
        ]
      }
    ]
  },
  {
    label: 'Form Submission',
    description: 'When someone submits a form on a specific page',
    kind: 'form_fill',
    defaultProperty: 'page_path',
    colorScheme: 'white',
    icon: IconId,
    suggestions: [
      {
        name: 'Requested Demo',
        description: 'When someone submits a demo request form',
        conditions: [
          {
            kind: 'form_fill',
            value: ['/demo'],
            operator: 'contains',
            property: 'page_path'
          }
        ]
      },
      {
        name: 'Submitted Email',
        description: 'Anytime someone submits their email in a form',
        conditions: [
          {
            kind: 'form_fill',
            value: true,
            operator: 'is',
            property: 'contains_email'
          }
        ]
      }
    ]
  },
  {
    label: 'Total Active Session Time',
    description: 'When someone exceeds a certain amount of total active time on your site',
    kind: 'total_time',
    defaultProperty: 'focus_time',
    colorScheme: 'orange',
    icon: IconCalendarTime
    // suggestions: [
    //   {
    //     name: 'Spent 10 minutes on site',
    //     description: 'When someone spends a long time on your site',
    //     conditions: [
    //       {
    //         kind: 'total_time',
    //         value: '10 minutes',
    //         operator: 'greater_than',
    //         property: 'focus_time'
    //       }
    //     ]
    //   }
    // ]
  },
  {
    label: 'User Trait Change',
    description: 'When a visitor trait is set or changes',
    kind: 'profile_trait',
    defaultProperty: 'name',
    colorScheme: 'blue',
    icon: IconTransform
  },
  {
    label: 'Account Trait Change',
    description: 'When an account trait is set or changes',
    kind: 'account_trait',
    defaultProperty: 'name',
    colorScheme: 'purple',
    icon: IconTransform
  },
  {
    label: 'Custom Event',
    description: "When someone takes an action on your site or app that you've tracked as an event",
    kind: 'event',
    defaultProperty: 'event',
    colorScheme: 'intercom',
    icon: IconClick
  },
  {
    label: 'G2 Event',
    description: 'When an account G2 event is triggered',
    kind: 'g2_event',
    defaultProperty: 'event',
    colorScheme: 'g2',
    brandLogo: true,
    appModule: 'Apps::G2::App',
    icon: 'https://logo.clearbit.com/g2.com',
    suggestions: [
      {
        name: 'Viewed comparison page',
        description: 'When some account compared you with a competitor',
        conditions: [
          {
            kind: 'g2_event',
            value: 'Viewed comparison page',
            operator: 'is',
            property: 'event'
          }
        ]
      },
      {
        name: 'Viewed pricing page',
        description: 'When some account viewed the pricing page',
        conditions: [
          {
            kind: 'g2_event',
            value: 'Viewed pricing page',
            operator: 'is',
            property: 'event'
          }
        ]
      }
    ]
  },
  {
    label: 'GitHub Event',
    description: 'When a GitHub event is triggered for an account',
    kind: 'github_event',
    defaultProperty: 'event',
    colorScheme: 'white',
    brandLogo: true,
    appModule: 'Apps::Github::App',
    icon: 'https://logo.clearbit.com/github.com'
  },
  {
    label: 'LinkedIn Event',
    description: 'When a LinkedIn event is triggered for an account',
    kind: 'linkedin_event',
    defaultProperty: 'event',
    colorScheme: 'white',
    brandLogo: true,
    appModule: 'Apps::LinkedinXt::App',
    icon: 'https://logo.clearbit.com/linkedin.com'
  },
  {
    label: 'Discord Event',
    description: 'When a Discord event is triggered for an account',
    kind: 'discord_event',
    defaultProperty: 'event',
    colorScheme: 'white',
    brandLogo: true,
    appModule: 'Apps::Discord::App',
    icon: 'https://logo.clearbit.com/discord.com'
  },
  {
    label: 'Intercom Event',
    description: 'When an Intercom event is triggered for an account',
    kind: 'intercom_event',
    defaultProperty: 'event',
    colorScheme: 'white',
    brandLogo: true,
    icon: 'https://logo.clearbit.com/intercom.com'
  },
  {
    label: 'Snowflake Event',
    description: 'When a Snowflake event is triggered for an account',
    kind: 'snowflake_event',
    defaultProperty: 'event',
    colorScheme: 'white',
    brandLogo: true,
    appModule: 'Apps::Snowflake::App',
    icon: 'https://logo.clearbit.com/snowflake.com'
  },
  {
    label: 'Slack Event',
    description: 'When a Slack event is triggered for an account',
    kind: 'slack_event',
    defaultProperty: 'event',
    colorScheme: 'white',
    brandLogo: true,
    appModule: 'Apps::Slack::App',
    icon: 'https://logo.clearbit.com/slack.com'
  },
  {
    label: 'Multiple Events',
    description: 'Combine two or more of these events to capture complex scenarios as an Intent Signal',
    enabled: false,
    kind: 'multiple',
    colorScheme: 'pink',
    icon: IconTriangleSquareCircle
  }
]

export const kinds = Array.from(new Set(triggers.map((t) => t.kind)))

export interface Operator {
  name: string
  label: string
  supportedType?: DataType | DataType[]
  needsValue?: boolean
  info?: string | React.ReactNode
  hidden?: boolean
}

export const operators: Operator[] = [
  {
    name: 'changed',
    supportedType: 'trait_change',
    label: 'changed',
    info: 'This will match when the trait value changes from one state to another.'
  },
  { name: 'is', label: 'is', needsValue: true },
  { name: 'not', label: 'is not', needsValue: true },
  { name: 'exists', label: 'exists', supportedType: 'string', needsValue: false },
  { name: 'not_exists', label: 'does not exist', supportedType: 'string', needsValue: false },
  {
    name: 'matches',
    supportedType: 'string',
    needsValue: true,
    label: 'matches',
    info: (
      <>
        <Text>
          Supports wildcard <strong>*</strong> and <strong>or</strong> matching <strong>(one|two)</strong>.
        </Text>
        <br />
        <Text fontWeight={'semibold'}>For example:</Text>
        <UnorderedList spacing={'1.5'} pt="2">
          <ListItem>/downloads/*/(whitepaper|case-study)</ListItem>
          <ListItem>/profiles/*/details</ListItem>
          <ListItem>/docs/enterprise/*</ListItem>
          <ListItem>/(pricing|demo)</ListItem>
        </UnorderedList>
      </>
    )
  },
  { name: 'contains', supportedType: 'string', label: 'contains', needsValue: true },
  { name: 'not_contains', supportedType: 'string', label: 'does not contain', needsValue: true },
  { name: 'starts_with', supportedType: 'string', label: 'starts with', needsValue: true },
  { name: 'ends_with', supportedType: 'string', label: 'ends with', needsValue: true },
  { name: 'less_than', supportedType: 'number', label: 'less than', needsValue: true },
  { name: 'less_than_or_equal', supportedType: 'number', needsValue: true, label: 'less than or equal to' },
  { name: 'greater_than', supportedType: 'number', label: 'greater than', needsValue: true },
  { name: 'greater_than_or_equal', supportedType: 'number', label: 'greater than or equal to', needsValue: true },
  { name: 'between', supportedType: 'number', label: 'between', needsValue: true },
  {
    name: 'performed_at_least',
    supportedType: ['string', 'boolean', 'number'],
    label: 'performed at least',
    needsValue: true,
    hidden: true
  }
]
