import * as React from 'react'
import { Apps, CatalogApp } from '../../../types/App'
import { ProjectActions } from '../../../types/AppActions'
import { KQLByDay } from '../../../types/KQL'
import type { ProfileRecord } from '../../../types/Profile'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { TopBarContent } from '../../ui/TopBarContext'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { KqlDefinition } from '../kql_definitions/types'
import Profile from './components/Profile'
import { Button, Flex, Link } from '@chakra-ui/react'
import { useCurrentUser } from '../../ui/UserContext'
import { useState } from 'react'
import { useCallback } from 'react'
import { post } from '../../../lib/api'
import { toast } from 'sonner'

interface Props {
  profile: ProfileRecord
  apps: Apps
  catalog?: CatalogApp[]
  kqls_by_day: KQLByDay[]
  kql_definitions: KqlDefinition[]
  project_actions: ProjectActions
}

export default function Show(props: Props) {
  const user = useCurrentUser()

  const [reindexing, setReindexing] = useState(false)
  const reindexProfile = useCallback(() => {
    setReindexing(true)

    const path = projectPath(`/profiles/${props.profile.id}/reindex`)
    post(path).then(() => {
      toast.success('Reindexing profile in the background...', {
        position: 'bottom-right'
      })
      setReindexing(false)
    })
  }, [props.profile.id])

  return (
    <PageLayout size="full" flush flex="1 1 auto" minHeight="300px">
      <PageTitle skipRendering>{props.profile.display_name || 'Anonymous'}</PageTitle>

      <TopBarContent onlyNewNav>
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <Breadcrumb
            paths={[
              { path: projectPath('/people'), title: 'People' },
              { path: window.location.toString(), title: props.profile.display_name || 'Anonymous' }
            ]}
          />

          {user.isInternalUser && (
            <Flex alignItems="center" gap={2}>
              <Button size="sm" variant="outline" onClick={reindexProfile} isLoading={reindexing}>
                Reindex
              </Button>
              <Button size="sm" variant="outline" as={Link} isExternal href={`${window.location.toString()}/debug`}>
                Debug
              </Button>
            </Flex>
          )}
        </Flex>
      </TopBarContent>

      <Profile
        profile={props.profile}
        apps={props.apps}
        catalog={props.catalog}
        projectActions={props.project_actions}
        signalsByDay={props.kqls_by_day}
        signalDefinitions={props.kql_definitions}
      />
    </PageLayout>
  )
}
