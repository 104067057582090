import { ButtonProps, Flex, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { IconChevronDown } from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { Facet, FacetMappings } from '../..'
import { useFieldValues } from '../../../../data/use-field-values'
import FilterPopover from '../../facets/filter-popover'
import { FilterPreviewProps } from '../FilterPreview/types'
import pluralize from 'pluralize'
import { getFacetValues } from '../../../../data/use-facets'
import useLatestRef from '../../../../ui/useLatestRef'

interface Props extends FilterPreviewProps {
  facet?: string
  value: Facet
  facetMappings: FacetMappings
  isRemovable?: boolean
  colorScheme: ButtonProps['colorScheme']
  formatter?: (value: Facet) => string
  maxValuesToDisplay?: number
}

export default function Values(props: Props) {
  const { value, facet, isEditable, isRemovable, formatter, maxValuesToDisplay, ...rest } = props
  const disclosure = useDisclosure()
  const isBooleanValue = value === 'true' || value === 'false'
  const hasValues = !!facet && !isBooleanValue && isEditable
  const colorScheme = props.colorScheme ?? 'purple'
  const formatterRef = useLatestRef(formatter)

  const facetValuePath = rest.kind === 'profile' ? '/profiles/facet-values' : '/accounts/facet-values'
  const { data } = useFieldValues(facet!, facetValuePath, '', hasValues)

  const { label, overflow } = useMemo(() => {
    if (formatterRef.current) {
      return {
        label: formatterRef.current(value),
        overflow: null
      }
    }

    if (maxValuesToDisplay) {
      return formatFilterValues(value, data?.values ?? [], maxValuesToDisplay)
    }

    return {
      label: value,
      overflow: null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxValuesToDisplay, value, data?.values])

  const trigger = (
    <Flex
      as="span"
      gap={1}
      paddingX={1}
      marginRight={isRemovable ? 0 : 1}
      rounded="md"
      borderWidth="1px"
      borderColor="transparent"
      alignSelf="center"
      justifyContent="center"
      alignItems="center"
      _hover={hasValues ? { background: `${colorScheme}.100`, borderColor: 'gray.200', cursor: 'pointer' } : undefined}
      _active={hasValues ? { background: `${colorScheme}.100` } : undefined}
      transition="all 200ms cubic-bezier(0, 0, 0.2, 1)"
      onClick={disclosure.onToggle}
      isTruncated
    >
      {!!label && (
        <Text as="span" isTruncated fontWeight="medium" fontSize="sm">
          {label}
        </Text>
      )}
      {hasValues && !isRemovable && <IconChevronDown size={14} />}
    </Flex>
  )

  if (hasValues) {
    return (
      <FilterPopover
        {...disclosure}
        {...rest}
        selectedFacetKey={facet}
        width={facet === 'sources' ? 'auto' : undefined}
        placement="bottom"
      >
        {trigger}
      </FilterPopover>
    )
  }

  if (overflow) {
    return (
      <Tooltip padding="12px" label={overflow.join(', ')}>
        {trigger}
      </Tooltip>
    )
  }

  return trigger
}

type FormattedFilterValue = {
  label: string
  overflow: Array<string | number | boolean> | null
}

function formatFilterValues(
  value: Facet | string,
  buckets?: Array<{ key: string; label?: string }>,
  maxValuesToDisplay = 2
): FormattedFilterValue {
  // Grab nested values from `not`, `gte`, `lte`, `all`. For example:
  // {
  //   "not": ['a', 'b']
  // }
  let values: Array<string | number | boolean> = getFacetValues(value)

  if (typeof values === 'string') {
    values = [values]
  } else if (Array.isArray(values)) {
    // convert nested arrays (ranges) to a string
    values = values.map((val) => {
      if (Array.isArray(val)) {
        return val.join('..')
      }

      // find corresponding bucket label, if it exists
      const bucket = val && buckets?.find((b) => b.key == val)
      if (bucket) {
        return bucket.label || val
      }

      return val
    })
  }

  const isOverflowing = values.length > maxValuesToDisplay

  const label =
    values.length === maxValuesToDisplay
      ? values.join(' or ')
      : values.slice(0, maxValuesToDisplay).join(', ') +
        (values.length > maxValuesToDisplay
          ? `, or ${values.length - maxValuesToDisplay} ${pluralize('other', values.length - 2)}`
          : '')

  return {
    label,
    overflow: isOverflowing ? values.slice(maxValuesToDisplay, values.length) : null
  }
}
