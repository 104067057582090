import { DetailsCard } from '@app/components/ui/Card'
import MarkdownText from '@app/components/ui/MarkdownText'
import { post } from '@app/lib/api'
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  keyframes,
  Portal,
  SkeletonText,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import {
  IconArrowLeft,
  IconArrowRight,
  IconBrowser,
  IconClockPlay,
  IconFileTypePdf,
  IconMailPlus,
  IconMinus,
  IconSearch,
  IconSparkles,
  IconTrash,
  IconWand,
  IconX
} from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'sonner'
import { ToolUsage } from '../data/use-ai-recommendations'
import { outreachSingleSend, useOutreachSingleSendSettings } from '../data/use-outreach-single-send'
import { Toggle } from '../pages/accounts/components/Toggle'
import GoogleLogo from '../pages/devise/registrations/components/google.svg'
import { AutosizedTextarea } from './AutosizedTextarea'
import { ConfirmDialog } from './ConfirmDialog'
import { AiSparklesIcon, OutreachIcon } from './icons'
import { LoadingPhrases } from './LoadingPhrases'
import { getCurrentProject, projectPath } from './ProjectsContext'

import Autolinker from 'autolinker'
import { TextEllipsis } from './text-ellipsis'

interface EmailVariationResponse {
  email: {
    content?: string
    subject?: string
  }
}

export interface EmailProps {
  to?: string
  subject?: string
  body?: string
}

// Define a type for the settings
interface EmailSettingsType {
  tone: string
  sentences: number
  additionalNotes: string
}

// Add this interface near the top with other interfaces
interface EmailVersion {
  subject?: string
  body?: string
  isAiGenerated?: boolean
  timestamp: number
}

const pulseKeyframes = keyframes`
  0% { background-position: 100% 50% }
  50% { background-position: 0% 50% }
  100% { background-position: -100% 50% }
`

const PulsingText = ({ children }: { children: React.ReactNode }) => (
  <Text
    as="span"
    background="linear-gradient(90deg, var(--chakra-colors-gray-400) 0%, var(--chakra-colors-gray-700) 20%, var(--chakra-colors-gray-400) 40%, var(--chakra-colors-gray-400) 100%)"
    backgroundSize="300% 100%"
    animation={`${pulseKeyframes} 3s ease infinite`}
    backgroundClip="text"
    sx={{
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      display: 'inline-block'
    }}
  >
    {children}
  </Text>
)

const EmailSettings = ({
  settings,
  onSettingsChange,
  email,
  onEmailUpdate,
  accountId
}: {
  settings: EmailSettingsType
  onSettingsChange: (newSettings: EmailSettingsType) => void
  email: EmailProps
  onEmailUpdate: (updates: Partial<EmailProps>) => void
  accountId: string
}) => {
  const [wordCount, setWordCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [editableEmail, setEditableEmail] = useState<Partial<EmailProps>>({})
  const [emailVersions, setEmailVersions] = useState<EmailVersion[]>([])
  const [currentVersionIndex, setCurrentVersionIndex] = useState<number>(0)

  const MAX_WORDS = 30

  const handleSettingsChange = (field: keyof EmailSettingsType, value: string | number) => {
    const newSettings = { ...settings, [field]: value }
    onSettingsChange(newSettings)
  }

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value
    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0)
    if (words.length <= MAX_WORDS) {
      handleSettingsChange('additionalNotes', text)
      setWordCount(words.length)
    }
  }

  const handleGenerateEmail = async () => {
    setLoading(true)
    const { tone, sentences, additionalNotes } = settings
    const message = email?.body

    // Save current version before generating new one
    const currentVersion: EmailVersion = {
      subject: email.subject,
      body: email.body,
      isAiGenerated: false,
      timestamp: Date.now()
    }

    if (!emailVersions.length) {
      setEmailVersions([currentVersion])
    }

    await post<EmailVariationResponse>(projectPath('/inbox/email-variations'), {
      settings: {
        tone,
        sentences,
        message,
        additional_notes: additionalNotes,
        account_id: accountId
      }
    })
      .then((response) => {
        if (response.email.content || response.email.subject) {
          const generatedEmail = {
            body: response.email.content,
            subject: response.email.subject
          }

          // Add new AI version to history
          const newVersion: EmailVersion = {
            ...generatedEmail,
            isAiGenerated: true,
            timestamp: Date.now()
          }

          setEmailVersions((prev) => [...prev, newVersion])
          setCurrentVersionIndex(emailVersions.length)
          setEditableEmail(generatedEmail)
          setShowPreview(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleVersionChange = (index: number) => {
    setCurrentVersionIndex(index)
    const version = emailVersions[index]
    setEditableEmail({
      subject: version.subject,
      body: version.body
    })
    setShowPreview(true)
  }

  const handleEditableChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setEditableEmail((prev) => ({ ...prev, [name]: value }))
  }

  const handleConfirm = () => {
    onEmailUpdate(editableEmail)
    setShowPreview(false)
    setEditableEmail({})
  }

  const handleReject = () => {
    setShowPreview(false)
  }

  return (
    <Stack spacing={4} w="100%" position="relative" minH="100%">
      {loading && <SkeletonText noOfLines={10} />}
      {showPreview && (
        <>
          <DetailsCard p={4} borderColor="purple.500" borderWidth="1px">
            <VStack align="stretch" spacing={3}>
              <Heading size="xs" fontWeight={'semibold'}>
                AI Generated Suggestion
              </Heading>
              <FormControl>
                <FormLabel fontSize="13px">Subject</FormLabel>
                <Input
                  size="sm"
                  name="subject"
                  fontWeight="medium"
                  value={editableEmail.subject || ''}
                  onChange={handleEditableChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize="13px">Message</FormLabel>
                <AutosizedTextarea
                  size="sm"
                  name="body"
                  value={editableEmail.body || ''}
                  onChange={handleEditableChange}
                  minHeight="100px"
                />
              </FormControl>
              <HStack spacing={2} justify="flex-end">
                <Button size="sm" onClick={handleReject}>
                  Reject
                </Button>
                <Button size="sm" colorScheme="purple" onClick={handleConfirm}>
                  Accept
                </Button>
              </HStack>
            </VStack>
          </DetailsCard>
          {showPreview && emailVersions.length > 0 && (
            <Flex mb={4} justifyContent="flex-end">
              <HStack spacing={2} mb={2}>
                <Text fontSize="xs" color="gray.500">
                  Version History ({currentVersionIndex + 1}/{emailVersions.length}):
                </Text>
                <IconButton
                  size="xs"
                  isDisabled={currentVersionIndex === 0}
                  onClick={() => handleVersionChange(currentVersionIndex - 1)}
                  icon={<Icon as={IconArrowLeft} size={'12'} />}
                  aria-label="Previous"
                />
                <IconButton
                  size="xs"
                  isDisabled={currentVersionIndex === emailVersions.length - 1}
                  onClick={() => handleVersionChange(currentVersionIndex + 1)}
                  icon={<Icon as={IconArrowRight} size={'12'} />}
                  aria-label="Next"
                />
              </HStack>
            </Flex>
          )}
        </>
      )}

      <FormControl w="100%">
        <FormLabel>Choose style</FormLabel>
        <Grid templateColumns="repeat(3, 1fr)" gap={1}>
          {['professional', 'friendly', 'casual'].map((tone) => {
            return (
              <Button
                key={tone}
                onClick={() => handleSettingsChange('tone', tone)}
                isActive={settings.tone === tone}
                variant="outline"
                p={3}
                size="sm"
              >
                {tone.charAt(0).toUpperCase() + tone.slice(1)}
              </Button>
            )
          })}
        </Grid>
      </FormControl>

      <FormControl>
        <FormLabel>Number of sentences</FormLabel>
        <Slider
          value={settings.sentences || 1}
          min={1}
          max={5}
          step={1}
          onChange={(value) => handleSettingsChange('sentences', value)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Text fontSize="sm" color="gray.600" mt={1}>
          {settings.sentences} sentence{settings.sentences !== 1 ? 's' : ''}
        </Text>
      </FormControl>

      <FormControl>
        <FormLabel>Extra instructions</FormLabel>
        <Textarea size="sm" value={settings.additionalNotes} onChange={handleNotesChange} resize="none" rows={4} />
        <Text fontSize="xs" mt={1} color={wordCount >= MAX_WORDS ? 'red.500' : 'gray.600'}>
          {MAX_WORDS - wordCount} words remaining
        </Text>
      </FormControl>

      <Button
        onClick={handleGenerateEmail}
        // colorScheme="purple"
        size="md"
        variant="outline"
        loadingText="Generating..."
        isLoading={loading}
        leftIcon={<Icon as={IconSparkles} />}
      >
        Generate Email
      </Button>
    </Stack>
  )
}

interface EmailComposerProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  email?: EmailProps
  onRequestAiSuggestions?: () => void
  onRequestRewrite?: () => void
}

const EmailComposer = ({ onChange, email, onRequestAiSuggestions, onRequestRewrite }: EmailComposerProps) => {
  return (
    <Flex flex="1 0 auto" flexDirection="column" gap={4} fontSize="sm">
      <FormControl>
        <FormLabel fontSize="xs">To</FormLabel>
        <Input
          size="sm"
          fontWeight="medium"
          name="to"
          value={email?.to || ''}
          onChange={onChange}
          placeholder="recipient@example.com"
          isReadOnly
        />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="xs">Subject</FormLabel>
        <Input
          size="sm"
          fontWeight="medium"
          name="subject"
          value={email?.subject || ''}
          onChange={onChange}
          placeholder="Email subject"
        />
      </FormControl>
      <FormControl display="flex" flexDirection="column" flex="1 1 auto">
        <Flex justify="space-between" align="center" mb={1.5}>
          <FormLabel fontSize="xs" mb={0}>
            Message
          </FormLabel>
          <Flex gap={2}>
            {onRequestRewrite && (
              <Button
                size="xs"
                variant="ghost"
                colorScheme="purple"
                leftIcon={<Icon as={IconWand} boxSize={3.5} />}
                iconSpacing={1.5}
                onClick={onRequestRewrite}
                isDisabled={!email?.subject || !email?.body}
                title={!email?.subject || !email?.body ? 'Please enter both subject and message' : undefined}
              >
                Rewrite with AI
              </Button>
            )}
            {onRequestAiSuggestions && (
              <Button
                size="xs"
                variant="ghost"
                colorScheme="purple"
                leftIcon={<Icon as={IconSparkles} boxSize={3.5} />}
                iconSpacing={1.5}
                onClick={onRequestAiSuggestions}
              >
                AI suggestions
              </Button>
            )}
          </Flex>
        </Flex>
        <Textarea
          size="sm"
          name="body"
          value={email?.body || ''}
          onChange={onChange}
          placeholder="Type your message here..."
          minHeight="100px"
          flex="1 1 auto"
        />
      </FormControl>
    </Flex>
  )
}

interface EmailComposerPopupProps {
  gmailConnected: boolean
  outreachEnabled?: boolean
  email?: EmailProps
  suggestedEmails?: EmailProps[]
  stream?: string
  loadingSuggestions?: boolean
  showSuggestions?: boolean
  disabled?: boolean
  onOpen?: () => void
  onClose?: () => void
  onEmailSent?: () => void
  accountId?: string
  buttonProps?: ButtonProps
  buttonText?: string
  warningMessage?: React.ReactNode
  toolStream?: ToolUsage[]
}

export function EmailComposerPopup({
  gmailConnected = false,
  outreachEnabled = false,
  email,
  suggestedEmails,
  loadingSuggestions = false,
  showSuggestions,
  stream,
  onOpen: onOpenProp,
  onClose: onCloseProp,
  disabled,
  onEmailSent,
  accountId,
  buttonText = 'Compose email',
  buttonProps,
  warningMessage,
  toolStream
}: EmailComposerPopupProps) {
  const [sidePanel, setSidePanel] = useState<'suggestions' | 'rewrite' | null>(null)
  const [isMinimized, setIsMinimized] = useState(false)
  const [sending, setSending] = useState(false)
  const [sendingOutreach, setSendingOutreach] = useState(false)
  const { canSendOutreach } = useOutreachSingleSendSettings()

  const openSuggestionsPanel = useCallback(() => setSidePanel('suggestions'), [])
  const openRewritePanel = useCallback(() => setSidePanel('rewrite'), [])

  const includeSuggestions = showSuggestions || (suggestedEmails?.length && showSuggestions !== false)

  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {
      onOpenProp?.()

      setIsMinimized(false)

      if (includeSuggestions) {
        openSuggestionsPanel()
      }
    },
    onClose: onCloseProp
  })
  const [currentEmail, setCurrentEmail] = useState(email)
  const currentProjectSlug = getCurrentProject()?.slug
  const [settings, setSettings] = useState<EmailSettingsType>({
    tone: 'professional',
    sentences: 2,
    additionalNotes: ''
  })

  const requestGmailScope = async () => {
    try {
      const returnTo = encodeURIComponent(window.location.pathname)
      window.location.href = `/auth/gmail?project=${currentProjectSlug}&app_id=gmail&state=${returnTo}`
    } catch (error) {
      console.error('Error requesting Gmail scope:', error)
      toast.error('Failed to request Gmail permissions')
    }
  }

  const requestOutreachScope = async () => {
    try {
      const returnTo = encodeURIComponent(window.location.pathname)
      window.location.href = projectPath(`/apps/outreach?return_to=${returnTo}`)
    } catch (error) {
      console.error('Error requesting Outreach scope:', error)
      toast.error('Failed to request Outreach permissions')
    }
  }

  const handleSendOutreach = useCallback(() => {
    if (!outreachEnabled) {
      toast.error('You do not have permission to send emails with Outreach')
      setSendingOutreach(false)
      return
    }

    if (!currentEmail) {
      toast.error('Please enter a recipient email')
      setSendingOutreach(false)
      return
    }

    if (!currentEmail.subject || !currentEmail.body) {
      toast.warning('Please enter an email subject and body')
      setSendingOutreach(false)
      return
    }

    setSendingOutreach(true)
    outreachSingleSend(
      { email: currentEmail!.to!, account_id: accountId! },
      {
        subject: currentEmail!.subject!,
        body: currentEmail!.body!
      }
    )
      .then(() => {
        toast.success('Email sent!')
        onEmailSent?.()
        setSendingOutreach(false)
      })
      .catch((error) => {
        const errorMessage = error.body?.message || error.body?.error || 'An unexpected error occurred'
        toast.error('Failed to send email', { description: errorMessage })
      })
      .finally(() => {
        setSendingOutreach(false)
      })
  }, [outreachEnabled, currentEmail, accountId, onEmailSent])

  const handleSend = useCallback(async () => {
    if (!currentEmail?.subject || !currentEmail?.body) {
      toast.warning('Please enter an email subject and body')
      setSending(false)
      return
    }

    setSending(true)

    await post(projectPath('/apps/gmail'), {
      email: {
        to: currentEmail?.to,
        subject: currentEmail?.subject,
        body: currentEmail?.body
      },
      account_id: accountId
    })
      .then(() => {
        toast.success('Email sent!')
        onEmailSent?.()
        // reset state and close the composer!
        setCurrentEmail((prev) => ({ ...prev, subject: '', body: '' }))
        setSidePanel(null)
        setIsMinimized(false)
        onClose()
      })
      .catch((error) => {
        const errorMessage = error.body?.message || error.body?.error || 'An unexpected error occurred'
        toast.error('Failed to send email', { description: errorMessage })
      })
      .finally(() => {
        setSending(false)
      })
  }, [currentEmail, accountId, onEmailSent, onClose])

  const handleSettingsChange = useCallback((newSettings: EmailSettingsType) => {
    setSettings(newSettings)
  }, [])

  const handleComposerChange = useCallback((e) => {
    const { name, value } = e.target
    setCurrentEmail((prev) => ({ ...prev, [name]: value }))
  }, [])

  const handleEmailUpdate = useCallback((updates: Partial<EmailProps>) => {
    setCurrentEmail((prev) => ({ ...prev, ...updates }))
  }, [])

  const confirmReplace = useDisclosure()
  const [selectedSuggestion, setSelectedSuggestion] = useState<EmailProps | null>(null)

  const handleReplaceEmail = useCallback(
    (email: EmailProps) => {
      // if the current email is empty, set the selected suggestion
      if (!currentEmail?.subject && !currentEmail?.body) {
        setCurrentEmail(email)
        return
      }

      setSelectedSuggestion(email)
      confirmReplace.onOpen()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentEmail, confirmReplace.onOpen]
  )

  const handleConfirmReplace = useCallback(() => {
    if (selectedSuggestion) {
      setCurrentEmail(selectedSuggestion)
      setSelectedSuggestion(null)
    }
  }, [selectedSuggestion])

  const requiresConnection = useMemo(() => {
    return !gmailConnected && !outreachEnabled
  }, [gmailConnected, outreachEnabled])

  const maxH = useBreakpointValue({ base: '95vh', sm: '90vh', md: '85vh', lg: '80vh' })

  const streamRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (streamRef.current) {
      streamRef.current.scrollTop = streamRef.current.scrollHeight
    }
  }, [stream])

  const mostRecentTool = useMemo(() => {
    return toolStream?.[toolStream.length - 1]
  }, [toolStream])

  return (
    <Box>
      {requiresConnection ? (
        <HStack>
          <Button
            onClick={requestGmailScope}
            size="sm"
            variant="outline"
            leftIcon={<Image src={GoogleLogo} boxSize="16px" />}
            bg="white"
            {...buttonProps}
          >
            Connect Google Account
          </Button>
          <Button
            onClick={requestOutreachScope}
            size="sm"
            variant="outline"
            leftIcon={<Icon color="purple.500" as={OutreachIcon} boxSize="16px" />}
            bg="white"
            {...buttonProps}
          >
            Connect Outreach
          </Button>
        </HStack>
      ) : (
        <Button
          onClick={onOpen}
          title="Start a draft"
          variant="outline"
          size="sm"
          isDisabled={disabled}
          leftIcon={<Icon as={AiSparklesIcon} boxSize={4} />}
          iconSpacing={1.5}
          bg="white"
          {...buttonProps}
        >
          {buttonText}
        </Button>
      )}

      {isOpen && (
        <Portal>
          <Flex
            position="fixed"
            bottom={0}
            right={4}
            gap={2}
            height={isMinimized ? 'auto' : '700px'}
            maxHeight={maxH}
            zIndex="popover"
          >
            {!isMinimized && sidePanel === 'suggestions' && (
              <Box
                width="380px"
                height="full"
                bg="white"
                border="1px solid"
                borderColor="gray.200"
                shadow="heavy"
                roundedTop="md"
                display="flex"
                flexDirection="column"
              >
                {/* Header */}
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                  pl={4}
                  pr={2}
                  py={2}
                  borderBottomWidth="1px"
                  borderColor="gray.200"
                  bg="gray.50"
                  roundedTop="md"
                >
                  <Heading size="xs" fontWeight="semibold" display="flex" alignItems="center" gap={2}>
                    <Icon as={IconSparkles} boxSize={4} />
                    AI Suggestions
                  </Heading>

                  <Flex alignItems="center" gap={2}>
                    <IconButton
                      icon={<Icon as={IconX} boxSize={4} />}
                      size="xs"
                      variant="ghost"
                      aria-label="Close"
                      onClick={() => setSidePanel(null)}
                    />
                  </Flex>
                </Flex>

                <Stack flex="1 1 auto" p={4} overflow="auto" spacing={4}>
                  {toolStream && toolStream.length > 0 && (
                    <Toggle
                      defaultIsOpen={false}
                      title={
                        <HStack w="100%">
                          <Heading size="xs" fontWeight={loadingSuggestions ? 'semibold' : 'normal'}>
                            {loadingSuggestions ? (
                              <PulsingText>
                                {mostRecentTool?.tool_name !== 'search_engine' ? 'Reading...' : 'Researching...'}
                              </PulsingText>
                            ) : (
                              'Research'
                            )}
                          </Heading>
                          {loadingSuggestions && mostRecentTool && (
                            <HStack spacing={0.5}>
                              {mostRecentTool?.tool_name === 'search_engine' && <Icon as={IconSearch} boxSize={3} />}
                              {mostRecentTool?.tool_name === 'web_scraper' && <Icon as={IconBrowser} boxSize={3} />}
                              {mostRecentTool?.tool_name === 'pdf_reader' && <Icon as={IconFileTypePdf} boxSize={3} />}
                              <TextEllipsis maxW="150px" color="gray.500" fontSize="xs">
                                {mostRecentTool?.tool_args}
                              </TextEllipsis>
                            </HStack>
                          )}
                        </HStack>
                      }
                    >
                      <Stack
                        maxH="200px"
                        overflow="auto"
                        borderLeftWidth="4px"
                        borderColor="gray.200"
                        ml={2}
                        pl={3}
                        py={2}
                      >
                        {toolStream.map((tool) => (
                          <HStack key={tool.content} fontSize="xs">
                            {tool.tool_name === 'search_engine' && <Icon as={IconSearch} boxSize={3} />}
                            {tool.tool_name === 'web_scraper' && <Icon as={IconBrowser} boxSize={3} />}
                            {tool.tool_name === 'pdf_reader' && <Icon as={IconFileTypePdf} boxSize={3} />}

                            {tool.tool_name !== 'search_engine' && (
                              <Text
                                sx={{
                                  '.url-description': {
                                    textDecoration: 'underline',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    flex: 1,
                                    maxWidth: '100%'
                                  }
                                }}
                                isTruncated
                                dangerouslySetInnerHTML={{
                                  __html: Autolinker.link(String(tool.tool_args), {
                                    truncate: { length: 64, location: 'end' },
                                    newWindow: true,
                                    sanitizeHtml: true,
                                    stripPrefix: false,
                                    stripTrailingSlash: true,
                                    email: false,
                                    phone: false,
                                    mention: false,
                                    hashtag: false,
                                    urls: {
                                      ipV4Matches: false,
                                      tldMatches: true,
                                      schemeMatches: true
                                    },
                                    className: 'url-description'
                                  })
                                }}
                              />
                            )}
                            {tool.tool_name === 'search_engine' && (
                              <TextEllipsis maxW="100%" color="gray.500">
                                {tool.tool_args}
                              </TextEllipsis>
                            )}
                          </HStack>
                        ))}
                      </Stack>
                    </Toggle>
                  )}

                  {stream && (
                    <Toggle
                      defaultIsOpen={true}
                      title={
                        <Heading size="xs" fontWeight={loadingSuggestions ? 'semibold' : 'normal'}>
                          {loadingSuggestions ? <PulsingText>Planning...</PulsingText> : 'Email strategy'}
                        </Heading>
                      }
                    >
                      <Stack
                        borderLeftWidth="4px"
                        borderColor="gray.200"
                        ml={2}
                        px={3}
                        py={2.5}
                        maxH="300px"
                        overflow="auto"
                        ref={streamRef}
                        fontSize="xs"
                        color="gray.500"
                      >
                        <MarkdownText>
                          {stream.replace('<email_strategy>', '').replace('</email_strategy>', '')}
                        </MarkdownText>
                      </Stack>
                    </Toggle>
                  )}

                  {loadingSuggestions ? (
                    <LoadingPhrases isLoading={loadingSuggestions} />
                  ) : (
                    (suggestedEmails || []).map((email) => (
                      <DetailsCard
                        key={email.subject}
                        borderColor={selectedSuggestion?.subject == email.subject ? 'purple.500' : 'gray.200'}
                        mb={4}
                        cursor="pointer"
                        onClick={() => handleReplaceEmail(email)}
                        _hover={{
                          borderColor: 'purple.500'
                        }}
                      >
                        <Heading size="xs" fontWeight="semibold">
                          {email.subject}
                        </Heading>
                        <MarkdownText>
                          {/* convert all to markdown line breaks */}
                          {/* make sure we dont do more than 2 though */}
                          {(email.body || '').replace(/\n/, '\n\n').replace(/\n{2,}/, '  \n  \n')}
                        </MarkdownText>
                      </DetailsCard>
                    ))
                  )}
                </Stack>
              </Box>
            )}

            {!isMinimized && sidePanel === 'rewrite' && (
              <Box
                width="380px"
                height="full"
                bg="white"
                border="1px solid"
                borderColor="gray.200"
                shadow="heavy"
                roundedTop="md"
                display="flex"
                flexDirection="column"
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                  pl={4}
                  pr={2}
                  py={2}
                  borderBottomWidth="1px"
                  borderColor="gray.200"
                  bg="gray.50"
                  roundedTop="md"
                >
                  <Heading size="xs" fontWeight="semibold" display="flex" alignItems="center" gap={2}>
                    <Icon as={IconWand} boxSize={4} />
                    Rewrite with AI
                  </Heading>
                  <IconButton
                    icon={<Icon as={IconX} boxSize={4} />}
                    size="xs"
                    variant="ghost"
                    aria-label="Close"
                    onClick={() => setSidePanel(null)}
                  />
                </Flex>

                <Box flex="1 1 auto" p={4} overflow="auto">
                  <EmailSettings
                    settings={settings}
                    onSettingsChange={handleSettingsChange}
                    email={currentEmail || {}}
                    onEmailUpdate={handleEmailUpdate}
                    accountId={accountId || ''}
                  />
                </Box>
              </Box>
            )}

            <Box
              width={isMinimized ? 'auto' : '540px'}
              minWidth={isMinimized ? '300px' : 'auto'}
              maxWidth="calc(100vw - 32px)"
              height={isMinimized ? 'auto' : 'full'}
              bg="white"
              border="1px solid"
              borderColor="gray.200"
              shadow="heavy"
              roundedTop="md"
              display="flex"
              flexDirection="column"
            >
              {/* Header */}
              <Flex
                alignItems="center"
                justifyContent="space-between"
                gap={2}
                pl={4}
                pr={2}
                py={2}
                borderBottomWidth="1px"
                borderColor="gray.200"
                bg="gray.50"
                roundedTop="md"
                cursor="pointer"
                onClick={() => setIsMinimized((prev) => !prev)}
              >
                <Heading size="xs" fontWeight="semibold">
                  New message
                </Heading>

                <Flex alignItems="center" gap={2}>
                  <IconButton
                    icon={<Icon as={IconMinus} boxSize={4} />}
                    size="xs"
                    variant="ghost"
                    aria-label={isMinimized ? 'Maximize' : 'Minimize'}
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsMinimized((prev) => !prev)
                      if (!isMinimized) {
                        // Close side panels when minimizing
                        setSidePanel(null)
                      }
                    }}
                  />
                  <IconButton
                    icon={<Icon as={IconX} boxSize={4} />}
                    size="xs"
                    variant="ghost"
                    aria-label="Close"
                    onClick={(e) => {
                      e.stopPropagation()
                      onClose()
                    }}
                  />
                </Flex>
              </Flex>

              {/* Only show body and footer when not minimized */}
              {!isMinimized && (
                <>
                  {/* Existing body section */}
                  <Flex flex="1 1 auto" flexDirection="column" minHeight="200px" px={4} py={2} overflow="auto">
                    <EmailComposer
                      onChange={handleComposerChange}
                      email={currentEmail}
                      onRequestAiSuggestions={includeSuggestions ? openSuggestionsPanel : undefined}
                      onRequestRewrite={openRewritePanel}
                    />
                  </Flex>

                  {warningMessage && (
                    <Box flex="none">
                      {/* <Flex flex="none" px={4} py={2} bg="orange.100" color="orange.900"> */}
                      {warningMessage}
                    </Box>
                  )}

                  {/* Existing footer section */}
                  <Flex flex="none" px={4} py={2} borderTop="1px solid" borderColor="gray.200" gap={2}>
                    <Tooltip label="Schedule send (coming soon)">
                      <Box>
                        <Button
                          size="sm"
                          variant="outline"
                          leftIcon={<Icon as={IconClockPlay} boxSize={4} />}
                          iconSpacing={1.5}
                          isDisabled
                        >
                          Schedule send
                        </Button>
                      </Box>
                    </Tooltip>
                    <Tooltip label={gmailConnected ? undefined : 'Connect your Gmail account to send emails'}>
                      <Button
                        size="sm"
                        variant="outline"
                        leftIcon={<Image src={GoogleLogo} boxSize="16px" />}
                        onClick={() => {
                          if (!gmailConnected) {
                            return requestGmailScope()
                          }

                          handleSend()
                        }}
                        isLoading={sending}
                        isDisabled={disabled || sendingOutreach}
                      >
                        Send
                      </Button>
                    </Tooltip>

                    <Tooltip label={canSendOutreach ? undefined : 'Connect your Outreach mailbox to send emails'}>
                      <Button
                        aria-label="Send with Outreach"
                        size="sm"
                        variant="outline"
                        onClick={() => {
                          if (!canSendOutreach) {
                            return requestOutreachScope()
                          }

                          handleSendOutreach()
                        }}
                        leftIcon={<Icon as={OutreachIcon} color="purple.500" boxSize={4} />}
                        isLoading={sendingOutreach}
                        isDisabled={disabled || sending}
                      >
                        Send w/ Outreach
                      </Button>
                    </Tooltip>

                    <Tooltip label="Discard draft">
                      <IconButton
                        aria-label="Discard draft"
                        size="sm"
                        variant="ghost"
                        onClick={onClose}
                        ml="auto"
                        icon={<Icon as={IconTrash} boxSize={4} />}
                      />
                    </Tooltip>
                  </Flex>
                </>
              )}
            </Box>
          </Flex>
        </Portal>
      )}

      <ConfirmDialog
        isOpen={confirmReplace.isOpen}
        onClose={confirmReplace.onClose}
        onConfirm={handleConfirmReplace}
        title="Replace email content?"
        confirmLabel="Continue"
        isCentered
      >
        This will replace your current email content with the selected suggestion. Are you sure you want to continue?
      </ConfirmDialog>
    </Box>
  )
}
