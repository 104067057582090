import { Flex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { toast } from 'sonner'
import { PlayItem } from '../../../../types/Play'
import { useEnrichProfile, useEnrichProspect } from '../../../data/use-enrich-profile'
import { ProspectEmailCell } from '../../prospector/components/ProspectsTable'

interface WaterfallButtonProps {
  record: PlayItem['record']
  playItem: PlayItem
  itemId: string
  onSuccess?: (updatedRecord: PlayItem['record']) => void
}

export function WaterfallButton({ record, playItem, onSuccess }: WaterfallButtonProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [profile, setProfile] = useState(record)
  const [notFound, setNotFound] = useState(false)

  const enrichProspect = useEnrichProspect({
    onSuccess: (enrichedProspect) => {
      const updatedProfile = {
        ...profile,
        email: enrichedProspect.email,
        name: enrichedProspect.name,
        linkedin_url: enrichedProspect.linkedin_url,
        title: enrichedProspect.title
      }

      if (updatedProfile.email) {
        setProfile(updatedProfile)
        toast.success('Email unlocked')
        onSuccess?.(updatedProfile)
      } else {
        setNotFound(true)
      }
    },
    onSettled: () => {
      setIsLoading(false)
    }
  })

  const enrichProfile = useEnrichProfile({
    onSuccess: (profile) => {
      setProfile(profile)

      if (profile.email) {
        toast.success('Email unlocked')
        onSuccess?.(profile)
      } else {
        setNotFound(true)
      }
    },
    onSettled: () => {
      setIsLoading(false)
    }
  })

  const onUnlockEmail = React.useCallback(() => {
    if (record && record.koala_person) {
      return
    }

    if (!record) {
      return
    }

    setIsLoading(true)

    const enrichmentFn = playItem.record_type.includes('Prospect') ? enrichProspect : enrichProfile

    enrichmentFn.mutate({
      id: record.id,
      app: 'Koala',
      personKey: 'koala_person'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, enrichProfile.mutate, enrichProspect.mutate, playItem])

  return (
    <Flex role="group">
      <ProspectEmailCell
        flexDirection="row-reverse"
        prospect={profile}
        email={profile.email}
        unlockState={profile.unlock_state}
        notFound={notFound}
        verificationStatus={profile.verification_status}
        isLoading={isLoading}
        onCopyToClipboard={() => {}}
        onVerifyEmail={() => {}}
        onUnlockEmail={onUnlockEmail}
      />
    </Flex>
  )
}
