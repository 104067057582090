import AISummaryButton, { AISummaryContent, useAISummary } from '@app/components/ui/AISummaryButton'
import { AISummaryCard } from '@app/components/ui/AISummaryCard'
import {
  Box,
  Button,
  ButtonProps,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Text,
  TextProps,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconArrowLeft,
  IconBrandGithub,
  IconCalendarTime,
  IconCheck,
  IconCircleCheckFilled,
  IconClockPlay,
  IconCompass,
  IconExternalLink,
  IconLock,
  IconMail,
  IconMapPin,
  IconShare3
} from '@tabler/icons-react'
import orderBy from 'lodash/orderBy'
import truncate from 'lodash/truncate'
import ms from 'ms'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { useMountedState } from 'react-use'
import { toast } from 'sonner'
import { concurrentCachedGET, post } from '../../../../lib/api'
import dayjs, { formatDate } from '../../../../lib/dayjs'
import router from '../../../../lib/router'
import { App, Apps, CatalogApp } from '../../../../types/App'
import { Engagement } from '../../../../types/Engagement'
import { KQLByDay } from '../../../../types/KQL'
import type { ProfileRecord } from '../../../../types/Profile'
import { useAppDep } from '../../../data/use-app-dep'
import { useEnrichProfile } from '../../../data/use-enrich-profile'
import { useGeocode } from '../../../data/use-geocode'
import { useMailbox } from '../../../data/use-mailbox'
import { AddToApolloSequenceModal } from '../../../ui/AddToApolloSequenceModal'
import { AddToSalesloftCadenceModal } from '../../../ui/AddToSalesloftCadenceModal'
import { DetailsCard, LightBgCard } from '../../../ui/Card'
import { Copyable } from '../../../ui/Copyable'
import { EmailComposerPopup } from '../../../ui/EmailComposer'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { Iconify, IconifyProps } from '../../../ui/Iconify'
import { EmailCheckIcon, LinkedinIcon, OutreachIcon, SalesforceIcon } from '../../../ui/icons'
import { KoalaLogoMark } from '../../../ui/KoalaLogo'
import { LastEngagementHoverCard } from '../../../ui/LastEngagement'
import { ListsCard } from '../../../ui/ListsCard'
import { projectPath } from '../../../ui/ProjectsContext'
import StatusAvatar from '../../../ui/StatusAvatar'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { AccountUpgrade, isLimitedAccount, RedactedText, useEntitlements } from '../../../ui/useEntitlements'
import useFuzzyCrmMatches from '../../../ui/useFuzzyCrmMatches'
import { WarningMessage } from '../../../ui/WarningMessage'
import { ActivityCard } from '../../accounts/components/DetailsV2/ActivityCard'
import {
  bestEmailMatch,
  EmailSourcesToggle,
  hasStrongEmailMatch,
  sourceText
} from '../../accounts/components/DetailsV2/EmailSourcesCard'
import { TraitsCard } from '../../accounts/components/DetailsV2/TraitsCard'
import { TrafficSources } from '../../accounts/components/TrafficSources'
import { humanize } from '../../accounts/facets/filter-cloud'
import { accountPath } from '../../accounts/lib/account-path'
import CreateOutreachTaskSideSheet from '../../account_views/components/CreateOutreachTaskSideSheet'
import { ImportAccountSideSheet } from '../../account_views/components/ImportAccountSideSheet'
import {
  ImportContactsSideSheet,
  salesNavigatorPersonLink
} from '../../account_views/components/ImportContactsSideSheet'
import { MoreAppsCard } from '../../apps/components/MoreAppsCard'
import { KqlDefinition } from '../../kql_definitions/types'
import { VisitorIntentFeedV2 } from '../../profiles/components/profile-feed'
import { profilePath } from '../../profiles/lib/path'
import { ProfileTab } from '../../prospects/profile-tab'
import { AddToListModal, ApolloTaskModal } from './apollo-actions'
import { ApolloCard } from './ApolloCard'
import { ClearbitAnonymousCard, ClearbitCard } from './ClearbitCard'
import { HubspotCard } from './HubspotCard'
import { KoalaWaterfallCard } from './KoalaWaterfallCard'
import { OutreachCard } from './OutreachCard'
import { ProfileEditor } from './profile-editor'
import { SalesforceCard } from './SalesforceCard'
import { ZoominfoCard } from './ZoominfoCard'

interface Props {
  profile_id: string
  profile?: ProfileRecord
  variant?: 'drawer' | 'full'

  stacked?: boolean
  apps?: Apps
  catalog?: CatalogApp[]
  signalsByDay: KQLByDay[]
  signalDefinitions: KqlDefinition[]
  scopedTo?: 'account' | 'profile'
}

interface Res {
  profile: ProfileRecord
  apps: Apps
  kqls_by_day: KQLByDay[]
  kql_definitions: KqlDefinition[]
}

export function VisitorDetails(props: Props) {
  const [profile, setProfile] = useState<ProfileRecord | null>(props.profile ?? null)
  const [signalsByDay, setSignalsByDay] = useState<KQLByDay[]>(props.signalsByDay ?? [])
  const [signalDefinitions, setSignalDefinitions] = useState<KqlDefinition[]>(props.signalDefinitions ?? [])
  const [apps, setApps] = useState<Apps | null>(props.apps ?? null)
  const [loading, setLoading] = useState(false)
  const entitlements = useEntitlements()

  const hubspot = apps?.['Apps::Hubspot::App']
  const salesforce = apps?.['Apps::Salesforce::App']
  const clearbit = apps?.['Apps::Clearbit::App']
  const zoominfo = apps?.['Apps::Zoominfo::App']
  const apollo = apps?.['Apps::Apollo::App']
  const outreach = apps?.['Apps::Outreach::App']

  const missingApps: CatalogApp[] = (props.catalog ?? []).filter((app) => {
    return !apps?.[app.module]?.connected
  })

  const [importingSalesforceContact, setImportingSalesforceContact] = useState(false)
  const [importingSalesforceLead, setImportingSalesforceLead] = useState(false)
  const [importingHubspotContact, setImportingHubspotContact] = useState(false)
  const [importingOutreachProspect, setImportingOutreachProspect] = useState(false)
  const [enriching, setEnriching] = useState(false)

  const importing =
    importingSalesforceContact || importingSalesforceLead || importingHubspotContact || importingOutreachProspect

  const canImportToOutreach = useMemo(
    () => outreach?.connected && outreach?.actions?.['outreach_import_contacts']?.enabled,
    [outreach]
  )

  const canImportToHubspot = useMemo(
    () => hubspot?.connected && hubspot?.actions?.['hs_import_contacts']?.enabled,
    [hubspot]
  )

  const canImportToSalesforceContact = useMemo(
    () => salesforce?.connected && salesforce?.actions?.['sf_import_contacts']?.enabled,
    [salesforce]
  )

  const canImportToSalesforceLead = useMemo(
    () => salesforce?.connected && salesforce?.actions?.['sf_import_leads']?.enabled,
    [salesforce]
  )

  const onImportSalesforceContact = React.useCallback(() => {
    if (!profile) return

    if (!canImportToSalesforceContact) {
      router.visit(projectPath(`/apps/salesforce?return_to=${encodeURIComponent(window.location.pathname)}`))
      return
    }

    setImportingSalesforceContact(true)
    const path = profilePath({ id: profile.id }, '/actions/import?app=Salesforce')

    post<{ profile: ProfileRecord }>(path)
      .then((res) => {
        setProfile(res.profile)
        if (profile?.contact_urls?.salesforce) {
          toast.success(`${profile?.name} was updated in Salesforce`)
        } else {
          toast.success(`${profile?.name} was imported into Salesforce`)
        }
      })
      .catch((err) => {
        toast.error('Failed to import', {
          description: `We could not add this user to Salesforce. ${JSON.stringify(err?.body ?? {})}`
        })
      })
      .finally(() => {
        setImportingSalesforceContact(false)
      })
  }, [canImportToSalesforceContact, profile])

  const enrichProfile = useEnrichProfile({
    onSuccess: (profile) => {
      setProfile(profile)
    },
    onSettled: () => setEnriching(false)
  })

  const onEnrichKoala = React.useCallback(() => {
    if (profile && profile.koala_person) {
      return
    }

    if (!profile) {
      return
    }

    setEnriching(true)

    enrichProfile.mutate({
      id: profile.id,
      app: 'Koala',
      personKey: 'koala_person'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, enrichProfile.mutate])

  const onImportSalesforceLead = React.useCallback(() => {
    if (!profile) return

    if (!canImportToSalesforceLead) {
      router.visit(projectPath(`/apps/salesforce?return_to=${encodeURIComponent(window.location.pathname)}`))
      return
    }

    setImportingSalesforceLead(true)
    const path = profilePath({ id: profile.id }, '/actions/import?app=Salesforce&type=lead')

    post<{ profile: ProfileRecord }>(path)
      .then((res) => {
        setProfile(res.profile)
        if (profile?.contact_urls?.salesforce_lead) {
          toast.success(`${profile?.name} was updated in Salesforce`)
        } else {
          toast.success(`${profile?.name} was imported into Salesforce`)
        }
      })
      .catch((err) => {
        toast.error('Failed to import', {
          description: `We could not add this user to Salesforce. ${JSON.stringify(err?.body ?? {})}`
        })
      })
      .finally(() => {
        setImportingSalesforceLead(false)
      })
  }, [canImportToSalesforceLead, profile])

  const onImportHubspotContact = React.useCallback(() => {
    if (!profile) return

    if (!canImportToHubspot) {
      router.visit(projectPath(`/apps/hubspot?return_to=${encodeURIComponent(window.location.pathname)}`))
      return
    }

    setImportingHubspotContact(true)
    const path = profilePath({ id: profile.id }, '/actions/import?app=Hubspot')

    post<{ profile: ProfileRecord }>(path)
      .then((res) => {
        setProfile(res.profile)

        if (profile?.contact_urls?.hubspot) {
          toast.success(`${profile?.name} was updated in HubSpot`)
        } else {
          toast.success(`${profile?.name} was imported into HubSpot`)
        }
      })
      .catch((err) => {
        toast.error('Failed to import', {
          description: `We could not add this user to HubSpot. ${err?.body?.error?.message}`.trim()
        })
      })
      .finally(() => {
        setImportingHubspotContact(false)
      })
  }, [canImportToHubspot, profile])

  const onImportOutreachProspect = React.useCallback(() => {
    if (!profile) return

    if (!canImportToOutreach) {
      router.visit(projectPath(`/apps/outreach?return_to=${encodeURIComponent(window.location.pathname)}`))
      return
    }

    if (profile?.contact_urls?.outreach) {
      window.open(profile.contact_urls.outreach, '_blank')
      return
    }

    setImportingOutreachProspect(true)
    const path = profilePath({ id: profile.id }, '/actions/import?app=Outreach')

    post<{ profile: ProfileRecord }>(path)
      .then((res) => {
        setProfile(res.profile)
        toast.success(`${profile?.name} was imported into Outreach`)
      })
      .catch((err) => {
        toast.error('Failed to import', {
          description: `We could not add this user to Outreach. ${JSON.stringify(err?.body ?? {})}`
        })
      })
      .finally(() => {
        setImportingOutreachProspect(false)
      })
  }, [canImportToOutreach, profile])

  const mounted = useMountedState()

  useEffect(() => {
    if (loading || profile) {
      return
    }

    setLoading(true)
    concurrentCachedGET<Res>(profilePath({ id: props.profile_id })).then((prof) => {
      if (!mounted()) return
      setProfile(prof.profile)
      setApps(prof.apps)
      setSignalsByDay(prof.kqls_by_day ?? [])
      setSignalDefinitions(prof.kql_definitions ?? [])
      setLoading(false)
    })
  }, [loading, profile, props.profile_id, mounted])

  const isRedacted = useMemo(() => {
    if (!profile) {
      return false
    }

    return isLimitedAccount(entitlements, {
      ...profile,
      limited: profile?.limited ?? profile?.account?.limited
    })
  }, [entitlements, profile])

  const stacked = props.stacked || props.variant === 'drawer'

  return (
    <Flex
      direction={stacked ? 'column' : 'row'}
      maxHeight={stacked ? undefined : '100%'}
      gap={stacked ? [4, 4, 6, 8] : undefined}
      overflow={stacked ? undefined : 'hidden'}
      position="relative"
      width="100%"
    >
      <Stack
        spacing={4}
        flex={stacked ? '1 1 100%' : '1 1 33%'}
        minWidth={stacked ? '100%' : '340px'}
        maxWidth={stacked ? undefined : ['100%', '100%', '420px']}
        maxHeight={stacked ? undefined : '100%'}
        paddingY={stacked ? undefined : [4, 4, 6, 8]}
        paddingLeft={stacked ? undefined : [4, 4, 6, 8]}
        paddingRight={stacked ? undefined : [4, 4, 3]}
        overflow={stacked ? undefined : 'auto'}
        overscrollBehavior="contain"
        scrollBehavior="smooth"
      >
        {loading ? (
          <DetailsCard
            minWidth="300px"
            padding={0}
            {...(props.variant === 'drawer' && {
              shadow: 'none',
              border: 'none',
              bg: 'transparent',
              mt: 10
            })}
          >
            <Box
              roundedTop="inherit"
              roundedBottom="none"
              height="85px"
              bg="white"
              bgGradient={`linear(to-b, gray.100, transparent)`}
              marginBottom={-10}
              padding={4}
              {...(props.variant === 'drawer' && {
                position: 'absolute',
                zIndex: -1,
                left: -6,
                right: -6,
                top: -2
              })}
            >
              {props.variant !== 'drawer' && (
                <IconButton
                  aria-label="Back to all people"
                  as={Link}
                  variant="link"
                  color="gray.500"
                  opacity={0.8}
                  size="xs"
                  href={projectPath('/people')}
                  icon={<IconArrowLeft size={24} />}
                />
              )}
            </Box>

            <Stack
              spacing={6}
              alignItems="flex-start"
              paddingX={props.variant === 'drawer' ? 4 : [5, 5, 6]}
              paddingBottom={props.variant === 'drawer' ? 2 : [5, 5, 6]}
              paddingTop={0}
            >
              <StatusAvatar size="lg" name={''} shadow="0 0 0 4px white" />
              <Stack spacing={2.5} py={1}>
                <Skeleton height="20px" width="200px" />
                <Skeleton height="14px" width="240px" />
              </Stack>
              <Stack spacing={4}>
                <Skeleton height="15px" width="190px" />
                <Skeleton height="15px" width="200px" />
                <Skeleton height="15px" width="200px" />
              </Stack>
            </Stack>
          </DetailsCard>
        ) : (
          profile && (
            <>
              <VisitorSummaryCard
                variant={props.variant}
                apps={apps}
                profile={profile}
                isRedacted={isRedacted}
                isImporting={importing}
                onUpdateProfile={setProfile}
                onImportSalesforceContact={canImportToSalesforceContact ? onImportSalesforceContact : undefined}
                onImportSalesforceLead={canImportToSalesforceLead ? onImportSalesforceLead : undefined}
                onImportHubspotContact={canImportToHubspot ? onImportHubspotContact : undefined}
                onImportOutreachProspect={canImportToOutreach ? onImportOutreachProspect : undefined}
                onEnrichKoala={onEnrichKoala}
              />

              {isRedacted && (
                <Center bg="gray.50" py="8">
                  <LightBgCard
                    justifyContent={'center'}
                    alignItems={'center'}
                    maxW="400"
                    borderColor={'orange.400'}
                    bg="orange.50"
                  >
                    <AccountUpgrade
                      type="Visitor"
                      colorScheme={'orange'}
                      element={profile}
                      showLock
                      entitlements={entitlements}
                    />
                  </LightBgCard>
                </Center>
              )}

              {!isRedacted && (profile.email || profile.linkedin_url || profile.koala_person) && (
                <KoalaWaterfallCard
                  record={profile.koala_person}
                  domain={profile.company?.domain}
                  initiallyCollapsed={false}
                  onRequestImport={() => {
                    onEnrichKoala()
                  }}
                  isLoading={enriching}
                />
              )}

              <TraitsCard object={profile} recordType="profile" />

              <ListsCard kind="profile" recordId={profile.id} />

              {!isRedacted && clearbit && !profile.email && (
                <ClearbitAnonymousCard app={clearbit} record={profile.clearbit_reveal_cache} initiallyCollapsed />
              )}

              {!isRedacted && salesforce?.connected && (
                <SalesforceCard
                  app={salesforce}
                  record={profile.salesforce_contact_person || profile.salesforce_lead_person}
                  recordType={profile.salesforce_contact_person ? 'Contact' : 'Lead'}
                  permalink={profile.contact_urls?.salesforce || profile.contact_urls?.salesforce_lead}
                  profile={profile}
                  onRequestImportContact={canImportToSalesforceContact ? onImportSalesforceContact : undefined}
                  onRequestImportLead={canImportToSalesforceLead ? onImportSalesforceLead : undefined}
                  isImportingContact={importingSalesforceContact}
                  isImportingLead={importingSalesforceLead}
                  initiallyCollapsed
                />
              )}

              {!isRedacted && hubspot?.connected && (
                <HubspotCard
                  app={hubspot}
                  record={profile.hubspot_contact_person}
                  profile={profile}
                  recordType="Contact"
                  permalink={profile.contact_urls?.hubspot}
                  onRequestImport={canImportToHubspot ? onImportHubspotContact : undefined}
                  isImporting={importingHubspotContact}
                  initiallyCollapsed
                />
              )}

              {!isRedacted && outreach?.connected && (
                <OutreachCard
                  app={outreach}
                  record={profile.outreach_prospect_person}
                  recordType="Prospect"
                  permalink={profile.contact_urls?.outreach}
                  onRequestImport={canImportToOutreach ? onImportOutreachProspect : undefined}
                  isImporting={importingOutreachProspect}
                  initiallyCollapsed
                />
              )}

              {!isRedacted && apollo?.connected && (
                <ApolloCard
                  app={apollo}
                  profile={profile}
                  record={profile.apollo_person}
                  recordType="Prospect"
                  permalink={profile.contact_urls?.apollo_prospect}
                  initiallyCollapsed
                />
              )}

              {!isRedacted && clearbit && profile.email && (
                <ClearbitCard app={clearbit} record={profile.clearbit_person} initiallyCollapsed />
              )}

              {!isRedacted && zoominfo?.connected && (
                <ZoominfoCard app={zoominfo} record={profile.zoominfo_person} initiallyCollapsed />
              )}

              {/* if one of these apps isn't connected, we can show a cta to connect other apps */}
              {!isRedacted && <MoreAppsCard otherApps={missingApps} />}
            </>
          )
        )}
      </Stack>

      <Stack
        flex={stacked ? '1 1 100%' : '1 1 67%'}
        minWidth="320px"
        spacing={6}
        maxHeight={stacked ? undefined : '100%'}
        paddingY={stacked ? undefined : [4, 4, 6, 8]}
        // accommodate for scrollbar padding on left column
        paddingLeft={stacked ? undefined : [4, 4, 3, 5]}
        // accommodate for scrollbar padding on right column
        paddingRight={stacked ? undefined : [4, 4, 4, 6]}
        overflow={stacked ? undefined : 'auto'}
        overscrollBehavior="contain"
        scrollBehavior="smooth"
      >
        {loading ? (
          <DetailsCard width="100%" minWidth="300px" minHeight="100px" height="100%" />
        ) : (
          profile && (
            <>
              {apps && !profile.email && !profile.linkedin_url && !profile.name && profile.account?.company && (
                <ProfileTab account={profile.account} company={profile.account.company} profile={profile} apps={apps} />
              )}

              {profile?.focus_time_trend && profile.page_views_trend && (
                <ActivityCard
                  focusTrend={profile.focus_time_trend}
                  pageviewTrend={profile.page_views_trend}
                  signalsByDay={signalsByDay}
                  signalDefns={signalDefinitions}
                  colorScheme="blue"
                  stacked
                />
              )}

              {entitlements?.ai_agents_beta && profile?.display_name && (
                <Stack w="100%" borderWidth="1px" borderRadius="lg" p={4}>
                  <AISummaryCard profile={profile} target="people" />
                </Stack>
              )}

              {(profile.utm_page_views?.length ?? 0) > 0 && (
                <Stack w="100%">
                  <HStack>
                    <Heading size="xs">Recent UTM Traffic</Heading>
                    <HelpTooltip>
                      <Stack fontSize={'xs'}>
                        <Heading size="xs">UTM Traffic</Heading>
                        <Text>
                          This table shows the most recent UTM parameters for this visitor and the pages they visited.
                        </Text>

                        <Text>
                          You can see a full report of all UTM parameters by clicking on the parameter, or accessing
                          your{' '}
                          <Button
                            as={Link}
                            fontSize="xs"
                            fontWeight={'semibold'}
                            variant="link"
                            isExternal
                            href={projectPath('/utm-reports')}
                            colorScheme="purple"
                            rightIcon={<IconExternalLink size="12" />}
                          >
                            UTM Content Report
                          </Button>
                          .
                        </Text>
                      </Stack>
                    </HelpTooltip>
                  </HStack>
                  <TrafficSources hover={false} utm_page_views={profile.utm_page_views!} />
                </Stack>
              )}

              <Flex py={4}>
                <VisitorIntentFeedV2 profile={profile} scopedTo={props.scopedTo} />
              </Flex>
            </>
          )
        )}
      </Stack>
    </Flex>
  )
}

interface VisitorSummaryCardProps {
  variant?: 'drawer' | 'full'
  profile: ProfileRecord
  apps: Props['apps'] | null
  stacked?: boolean
  isRedacted?: boolean
  isImporting?: boolean
  onUpdateProfile: (data: ProfileRecord) => void
  onImportSalesforceContact?: () => void
  onImportSalesforceLead?: () => void
  onImportHubspotContact?: () => void
  onImportOutreachProspect?: () => void
  onEnrichKoala?: () => void
}

interface Action {
  app: App
  label: string
  tooltip?: string | React.ReactNode
  icon?: React.ReactElement
  onClick: (...args: any[]) => void
}

function VisitorSummaryCard({
  apps,
  variant,
  profile,
  isRedacted,
  isImporting,
  onUpdateProfile,
  onImportSalesforceContact,
  onImportSalesforceLead,
  onImportHubspotContact,
  onImportOutreachProspect,
  onEnrichKoala
}: VisitorSummaryCardProps) {
  const geocode = useGeocode({ onSuccess: onUpdateProfile })
  const status = profile.status !== 'offline' ? profile.status : undefined

  const mailbox = useMailbox()

  const lastActivity = profile.last_activity
  const lastActivityDate = profile.last_activity_date

  const hasZoominfo = useMemo(() => apps?.['Apps::Zoominfo::App']?.valid || false, [apps])
  const hasClearbit = useMemo(() => apps?.['Apps::Clearbit::App']?.valid || false, [apps])
  const hasApollo = useMemo(() => apps?.['Apps::Apollo::App']?.valid || false, [apps])
  const hasOutreach = useMemo(() => apps?.['Apps::Outreach::App']?.valid || false, [apps])
  const hasSalesloft = useMemo(() => apps?.['Apps::Salesloft::App']?.valid || false, [apps])

  const scopes = useAppDep<'scopes', string[]>('outreach', 'scopes')

  const hasSequenceScope = React.useMemo(() => {
    return scopes.data?.data.scopes.includes('sequenceStates.all')
  }, [scopes.data])

  const { allAccounts, isLoading, refetch } = useFuzzyCrmMatches({
    account: profile.account,
    app: apps?.['Apps::Outreach::App'],
    fuzzy: false
  })

  const hasAccounts = allAccounts.length > 0

  const enrichProfile = useEnrichProfile({ onSuccess: onUpdateProfile })
  const enriching = enrichProfile.isLoading

  const onEnrichApollo = React.useCallback(() => {
    if (!hasApollo) {
      router.visit(projectPath(`/apps/apollo?return_to=${encodeURIComponent(window.location.pathname)}`))
      return
    }

    if (profile && profile.apollo_person) {
      return
    }

    enrichProfile.mutate({ id: profile.id, app: 'Apollo', personKey: 'apollo_person' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, hasApollo, enrichProfile.mutate])

  const onEnrichZoominfo = React.useCallback(() => {
    if (!hasZoominfo) {
      router.visit(projectPath(`/apps/zoominfo?return_to=${encodeURIComponent(window.location.pathname)}`))
      return
    }

    if (profile && profile.zoominfo_person) {
      return
    }

    enrichProfile.mutate({ id: profile.id, app: 'Zoominfo', personKey: 'zoominfo_person' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, hasZoominfo, enrichProfile.mutate])

  const onEnrichClearbit = React.useCallback(() => {
    if (!hasClearbit) {
      router.visit(projectPath(`/apps/clearbit?return_to=${encodeURIComponent(window.location.pathname)}`))
      return
    }

    if (profile && profile.clearbit_person) {
      return
    }

    enrichProfile.mutate({ id: profile.id, app: 'Clearbit', personKey: 'clearbit_person' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, hasClearbit, enrichProfile.mutate])

  const refetchProfileData = React.useCallback(() => {
    router.visit(window.location.pathname, { fetch: true })
  }, [])

  const outreachTaskDisclosure = useDisclosure()
  const outreachSequenceDisclosure = useDisclosure()
  const apolloTaskDisclosure = useDisclosure()
  const apolloSequenceDisclosure = useDisclosure()
  const apolloListDisclosure = useDisclosure()
  const salesloftCadenceDisclosure = useDisclosure()

  const createActions =
    (onImportSalesforceContact && !profile.salesforce_contact_person && !profile.salesforce_lead_person) ||
    (onImportSalesforceLead && !profile.salesforce_lead_person && !profile.salesforce_contact_person) ||
    (onImportHubspotContact && !profile.hubspot_contact_person) ||
    (onImportOutreachProspect && !profile.outreach_prospect_person)

  const taskActions = useMemo(() => {
    const supported: Action[] = []

    if (apps?.['Apps::Apollo::App']?.connected) {
      supported.push({
        app: apps['Apps::Apollo::App'],
        icon: <Image w="4" src={apps['Apps::Apollo::App'].logo} />,
        label: 'Add Task in Apollo',
        onClick: apolloTaskDisclosure.onOpen
      })
    }

    if (apps?.['Apps::Outreach::App']?.connected) {
      supported.push({
        app: apps['Apps::Outreach::App'],
        icon: <Image w="4" src={apps['Apps::Outreach::App'].logo} />,
        label: 'Add Task in Outreach',
        onClick: outreachTaskDisclosure.onOpen
      })
    }

    return supported
  }, [apps, apolloTaskDisclosure.onOpen, outreachTaskDisclosure.onOpen])

  const hasTaskActions = taskActions.length > 0

  // lets us figure out if we should show a single button or a menu
  const sequenceActions = useMemo(() => {
    const supported: Action[] = []

    if (apps?.['Apps::Apollo::App']?.connected) {
      supported.push({
        app: apps['Apps::Apollo::App'],
        icon: <Image w="4" src={apps['Apps::Apollo::App'].logo} />,
        label: 'Add to Apollo Sequence',
        onClick: apolloSequenceDisclosure.onOpen
      })
    }

    if (apps?.['Apps::Salesloft::App']?.connected && profile.email) {
      supported.push({
        app: apps['Apps::Salesloft::App'],
        icon: <Image w="4" src={apps['Apps::Salesloft::App'].logo} />,
        label: 'Add to Salesloft',
        onClick: salesloftCadenceDisclosure.onOpen
      })
    }

    // outreach sequences require an account, but we cannot import an account without a company name or domain on our side
    if (apps?.['Apps::Outreach::App']?.connected && profile.account) {
      supported.push({
        app: apps['Apps::Outreach::App'],
        icon: <Image w="4" src={apps['Apps::Outreach::App'].logo} />,
        label: 'Add to Outreach Sequence',
        tooltip: !hasSequenceScope
          ? 'Your Workspace is missing the Sequences permission for Outreach. Clicking this button will redirect you to reconnect Outreach'
          : undefined,
        onClick: () => {
          if (!hasSequenceScope) {
            router.visit(projectPath(`/apps/outreach?return_to=${encodeURIComponent(window.location.pathname)}`))

            return
          }

          outreachSequenceDisclosure.onOpen()
        }
      })
    }

    return supported
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps, profile.account, outreachSequenceDisclosure.onOpen, hasSequenceScope, apolloSequenceDisclosure.onOpen])

  const hasSequenceActions = sequenceActions.length > 0

  const enrichmentApps = useMemo(() => {
    const filtered = ['Clearbit', 'Apollo', 'Zoominfo'].map((app) => {
      return apps?.[`Apps::${app}::App`] || { title: app, clean_module_name: app.toLowerCase(), connected: false }
    })
    return orderBy(filtered, 'connected', 'desc')
  }, [apps])

  const entitlements = useEntitlements()
  const [showAiSummary, setShowAiSummary] = useState(false)
  const { messages, eventSource: startAiSummary } = useAISummary({
    path: projectPath(`/daily_profile_feeds/${profile.id}/ai-summary`)
  })

  return (
    <>
      <DetailsCard
        padding={0}
        {...(variant === 'drawer' && {
          shadow: 'none',
          border: 'none',
          bg: 'transparent',
          mt: 10
        })}
      >
        <Box
          roundedTop="inherit"
          roundedBottom="none"
          height="85px"
          bg="white"
          bgGradient={`linear(to-b, ${profile.email ? 'purple.50' : 'gray.100'}, transparent)`}
          marginBottom={-16}
          padding={4}
          {...(variant === 'drawer' && {
            position: 'absolute',
            zIndex: -1,
            left: -6,
            right: -6,
            top: -2
          })}
        />

        <Stack
          spacing={3}
          alignItems="flex-start"
          paddingX={variant === 'drawer' ? 4 : [5, 5, 6]}
          paddingBottom={variant === 'drawer' ? 2 : [5, 5, 6]}
          paddingTop={0}
        >
          <StatusAvatar
            size="lg"
            name={isRedacted ? '' : (profile.display_name ?? profile.email)}
            status={status}
            image={profile.image}
            shadow="0 0 0 4px white"
          />

          <Stack spacing={0} pt={1.5}>
            <HStack spacing={3}>
              <Heading size="md" fontWeight="semibold">
                <RedactedText redacted={isRedacted}>{profile.display_name || 'Anonymous visitor'}</RedactedText>
              </Heading>
              {profile.linkedin_url && !isRedacted && (
                <Tooltip label="View on LinkedIn" placement="top" hasArrow arrowSize={6}>
                  <Link display="flex" alignItems="center" color="linkedin.700" isExternal href={profile.linkedin_url}>
                    <LinkedinIcon size={20} />
                  </Link>
                </Tooltip>
              )}
              <ProfileEditor profile={profile} onUpdateProfile={onUpdateProfile} />
            </HStack>

            {profile.title ? (
              <Text fontSize="15px" color="gray.700" fontWeight="medium">
                {profile.title}
                {(profile.company?.name || profile.company?.domain) && (
                  <>
                    {' at '}
                    <Link fontWeight="inherit" href={accountPath(profile)}>
                      {profile.company?.name || profile.company?.domain}
                    </Link>
                  </>
                )}
              </Text>
            ) : profile.simple_location ? (
              <Text fontSize="15px" color="gray.700" fontWeight="medium">
                {profile.simple_location}
              </Text>
            ) : null}

            {profile.initial_source && (
              <HStack spacing="1">
                <Text fontSize="12px" color="gray.600" fontWeight="medium">
                  Identified via {humanize(profile.initial_source)}
                </Text>
                <HelpTooltip trigger={<Icon as={IconCircleCheckFilled} color="purple.500" w="3" />}>
                  <Stack>
                    <Heading size="xs">Profile Source</Heading>
                    <Text>
                      This visitor profile was created via the {humanize(profile.initial_source)} integration on{' '}
                      {formatDate(new Date(profile.created_at), 'MMM d, YYYY')}.
                    </Text>
                  </Stack>
                </HelpTooltip>
              </HStack>
            )}
          </Stack>

          <Flex direction="column" rowGap={2.5} pt={2} width="100%">
            {profile.email && (
              <HStack fontSize="sm" spacing={1.5} color="gray.500" width="100%" minWidth="100px" isTruncated>
                <Box display="flex" flex="none" color="gray.500" bg="gray.100" rounded="lg" padding={1}>
                  <Tooltip
                    label={
                      hasStrongEmailMatch(profile.email_sources ?? [])
                        ? `High accuracy email match via ${sourceText(
                            bestEmailMatch(profile.email_sources ?? [], profile)?.source
                          )}`
                        : undefined
                    }
                  >
                    {hasStrongEmailMatch(profile.email_sources ?? []) ? (
                      <EmailCheckIcon color="inherit" accentColor="var(--chakra-colors-purple-500)" boxSize={4} />
                    ) : (
                      <Icon as={IconMail} color="gray.500" boxSize={4} />
                    )}
                  </Tooltip>
                </Box>
                <Stack spacing="-1.5" w="100%" mr={-1} minWidth="100px">
                  <RedactedText redacted={isRedacted} display="inline-flex" minWidth="100px" isTruncated>
                    <Copyable copyText={profile.email} minWidth="100px">
                      <TextEllipsis
                        fontSize="sm"
                        maxW="100%"
                        minWidth="100px"
                        fontWeight="medium"
                        color="gray.600"
                        lineHeight="20px"
                        tooltip
                      >
                        {profile.email}
                      </TextEllipsis>
                    </Copyable>
                  </RedactedText>

                  {Boolean(profile.email_sources) && profile.email_sources!.length > 0 && (
                    <Box w="100%">
                      <EmailSourcesToggle profile={{ email_sources: [], ...profile }} />
                    </Box>
                  )}
                </Stack>
              </HStack>
            )}
            {profile.linkedin_url && !isRedacted && (
              <IconField icon={LinkedinIcon} copyText={profile.linkedin_url}>
                <Link href={profile.linkedin_url} isExternal>
                  {truncate(
                    decodeURIComponent(profile.linkedin_url.replace(/https?:\/\/(www\.)?linkedin\.com\//, '')),
                    {
                      length: 25
                    }
                  )}
                </Link>
              </IconField>
            )}

            {profile.initial_source === 'github' && profile.github_url && (
              <IconField icon={IconBrandGithub}>
                <Link href={profile.github_url} isExternal>
                  {profile.github_url.replace('https://github.com/', '')}
                </Link>
              </IconField>
            )}

            {/* only show if we arent using it above */}
            {profile.simple_location && profile.title && (
              <IconField icon={IconMapPin}>
                <HStack>
                  <Text>{profile.simple_location}</Text>
                  {!isRedacted && !profile.simple_geo?.state && (
                    <Button
                      variant="link"
                      size="xs"
                      colorScheme="blue"
                      onClick={(e) => {
                        e.preventDefault()
                        geocode.mutateAsync({ profileId: profile.id })
                      }}
                    >
                      Show full location
                    </Button>
                  )}
                </HStack>
              </IconField>
            )}

            <IconField label="First Seen" icon={IconCalendarTime}>
              <TimeAgo time={profile.first_seen_at} format="MMM D, YYYY" fallback="-" />
            </IconField>
            <IconField label="Last Seen" icon={IconCalendarTime}>
              <TimeAgo time={profile.last_seen_at} fallback="-" />
            </IconField>
            {(profile.focus_time_trend?.month?.current?.value || 0) > 0 && (
              <IconField
                label="Activity"
                icon={IconClockPlay}
                info="Time spent actively interacting with your site in the past 30 days"
              >
                {ms(profile.focus_time_trend?.month?.current?.value || 0, { long: true })}
              </IconField>
            )}
            {profile.last_activity && messagedRecently(profile.last_activity) ? (
              <LastEngagementHoverCard engagement={profile.last_activity}>
                <IconField icon={IconShare3} copyText={false}>
                  <Flex gap={1} alignItems="center">
                    {profile.last_activity.metadata?.direction === 'inbound' ? 'Replied' : 'Sent message'}
                    <TimeAgo time={profile.last_activity.created_at} canToggle={false} />
                  </Flex>
                </IconField>
              </LastEngagementHoverCard>
            ) : profile.last_activity_date && messagedRecently(profile.last_activity_date) ? (
              <IconField icon={IconShare3} copyText={false}>
                <Flex gap={1} alignItems="center">
                  Engaged
                  <TimeAgo time={profile.last_activity_date} canToggle={false} />
                </Flex>
              </IconField>
            ) : null}

            {profile && profile.id && entitlements?.ai_agents_beta && (
              <Stack width="100%">
                <Drawer isOpen={showAiSummary} placement="left" onClose={() => setShowAiSummary(false)} size="md">
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>AI Summary</DrawerHeader>

                    <DrawerBody>
                      <AISummaryContent messages={messages} isOpen={showAiSummary} />
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>

                <AISummaryButton
                  leftText="Summarize activity"
                  summarized={showAiSummary}
                  onValueChange={(checked) => {
                    if (checked) {
                      startAiSummary()
                    }

                    setShowAiSummary(checked)
                  }}
                />
              </Stack>
            )}
          </Flex>
          <Flex
            display="grid"
            gridTemplateColumns={['100%', 'repeat(auto-fill, minmax(200px, 1fr))']}
            width="100%"
            gap={3}
            alignItems="stretch"
            pt={3}
          >
            {!profile.email && (
              <Tooltip label={!hasApollo && 'Enable the Apollo integration to unlock prefilled search'}>
                <Button
                  as={Link}
                  size="md"
                  variant="outline"
                  leftIcon={<Image w="4" src="https://asset.brandfetch.io/ideEin4YhC/id-83Yirn2.png" />}
                  href={
                    hasApollo
                      ? profilePath(profile, '/apollo-search')
                      : projectPath(`/apps/apollo?return_to=${encodeURIComponent(window.location.pathname)}`)
                  }
                  isExternal
                  onClick={() => {
                    window.ko?.track('Apollo Search', {
                      app: 'apollo'
                    })
                  }}
                >
                  Search in Apollo
                </Button>
              </Tooltip>
            )}
            {!profile.email && profile.account && (
              <Button
                as={Link}
                size="md"
                leftIcon={<IconCompass size="18" color="#0072b1" />}
                iconSpacing={1.5}
                variant="outline"
                href={salesNavigatorPersonLink(profile.account, profile)}
                isExternal
                onClick={() => {
                  window.ko?.track('Sales Navigator Search', {
                    app: 'salesnav'
                  })
                }}
              >
                Search in Sales Navigator
              </Button>
            )}
            {(profile.email || profile.linkedin_url || profile.koala_person) && (
              <>
                <Menu size="sm" closeOnSelect autoSelect={false} matchWidth>
                  <MenuButton as={Button} isLoading={enriching} justifyContent="center" variant="outline" size="md">
                    <HStack justifyContent="center" spacing={1.5}>
                      {enrichmentApps.every((a) => !a?.connected) && <IconLock size={15} />}
                      <Text lineHeight={1}>Enrich Contact…</Text>
                    </HStack>
                  </MenuButton>

                  <MenuList zIndex="popover">
                    <MenuItem
                      isDisabled={enriching || Boolean(profile.koala_person)}
                      onClick={onEnrichKoala}
                      icon={<KoalaLogoMark size="4" />}
                    >
                      <HStack justifyContent="space-between">
                        <Text>Enrich with Koala Waterfall</Text>
                        {Boolean(profile.koala_person) && <Icon as={IconCheck} color="gray.500" boxSize="5" />}
                      </HStack>
                    </MenuItem>

                    {enrichmentApps.map((app) =>
                      app.clean_module_name === 'clearbit' ? (
                        <Tooltip
                          key={app.clean_module_name}
                          label={
                            !hasClearbit && 'Enable the Clearbit integration to enrich visitor data by email and ip'
                          }
                        >
                          <MenuItem
                            isDisabled={enriching || Boolean(profile.clearbit_person)}
                            onClick={onEnrichClearbit}
                            icon={
                              <Image
                                w="4"
                                src="https://logo.clearbit.com/clearbit.com"
                                filter={hasClearbit ? undefined : 'grayscale(1)'}
                                opacity={hasClearbit ? undefined : 0.6}
                              />
                            }
                          >
                            <HStack justifyContent="space-between">
                              <Text>Enrich with Clearbit</Text>
                              {Boolean(profile.clearbit_person) && <Icon as={IconCheck} color="gray.500" boxSize="5" />}
                            </HStack>
                          </MenuItem>
                        </Tooltip>
                      ) : app.clean_module_name === 'apollo' && hasApollo ? (
                        <Tooltip
                          key={app.clean_module_name}
                          label={!hasApollo && 'Enable the Apollo integration to enrich visitors via their email'}
                        >
                          <MenuItem
                            isDisabled={enriching || Boolean(profile.apollo_person)}
                            onClick={onEnrichApollo}
                            icon={
                              <Image
                                w="4"
                                src="https://asset.brandfetch.io/ideEin4YhC/id-83Yirn2.png"
                                filter={hasApollo ? undefined : 'grayscale(1)'}
                                opacity={hasApollo ? undefined : 0.6}
                              />
                            }
                          >
                            <HStack justifyContent="space-between">
                              <Text>Enrich with Apollo</Text>
                              {Boolean(profile.apollo_person) && <Icon as={IconCheck} color="gray.500" boxSize="5" />}
                            </HStack>
                          </MenuItem>
                        </Tooltip>
                      ) : app.clean_module_name === 'zoominfo' ? (
                        <Tooltip
                          key={app.clean_module_name}
                          label={!hasZoominfo && 'Enable the ZoomInfo integration to enrich visitors via their email'}
                        >
                          <MenuItem
                            isDisabled={enriching || Boolean(profile.zoominfo_person)}
                            onClick={onEnrichZoominfo}
                            icon={
                              <Image
                                w="4"
                                src="https://companieslogo.com/img/orig/ZI-1d2e8ef0.png?t=1647839912"
                                filter={hasZoominfo ? undefined : 'grayscale(1)'}
                                opacity={hasZoominfo ? undefined : 0.6}
                              />
                            }
                          >
                            <HStack justifyContent="space-between">
                              <Text>Enrich with ZoomInfo</Text>
                              {Boolean(profile.zoominfo_person) && <Icon as={IconCheck} color="gray.500" boxSize="5" />}
                            </HStack>
                          </MenuItem>
                        </Tooltip>
                      ) : null
                    )}
                  </MenuList>
                </Menu>
                {createActions && (
                  <Menu size="sm" closeOnSelect autoSelect={false} matchWidth>
                    <MenuButton as={Button} variant="outline" size="md">
                      Add to CRM…
                    </MenuButton>
                    <MenuList zIndex="popover">
                      {/* if you already have a lead, salesforce will complain you have a dupe record in most SF instances */}
                      {onImportSalesforceContact &&
                        !profile.salesforce_contact_person &&
                        !profile.salesforce_lead_person && (
                          <MenuItem
                            isDisabled={isImporting}
                            icon={<SalesforceIcon color="salesforce" size={16} />}
                            onClick={onImportSalesforceContact}
                          >
                            Create Salesforce Contact
                          </MenuItem>
                        )}
                      {/* if you already have a contact, salesforce will complain you have a dupe record in most SF instances */}
                      {onImportSalesforceLead &&
                        !profile.salesforce_contact_person &&
                        !profile.salesforce_lead_person && (
                          <MenuItem
                            isDisabled={isImporting}
                            icon={<SalesforceIcon color="salesforce" size={16} />}
                            onClick={onImportSalesforceLead}
                          >
                            Create Salesforce Lead
                          </MenuItem>
                        )}
                      {onImportHubspotContact && !profile.hubspot_contact_person && (
                        <MenuItem
                          isDisabled={isImporting}
                          icon={<Image src="https://cdn.cdnlogo.com/logos/h/24/hubspot.svg" boxSize={4} />}
                          onClick={onImportHubspotContact}
                        >
                          Create HubSpot Contact
                        </MenuItem>
                      )}
                      {onImportOutreachProspect && !profile.outreach_prospect_person && (
                        <MenuItem
                          isDisabled={isImporting}
                          icon={<OutreachIcon color="outreach" size={16} />}
                          onClick={onImportOutreachProspect}
                        >
                          Create Outreach Prospect
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                )}
                {sequenceActions.length === 1 ? (
                  <Tooltip label={sequenceActions[0].tooltip} placement="top" hasArrow>
                    <Button
                      variant="outline"
                      size="md"
                      leftIcon={sequenceActions[0].icon}
                      onClick={sequenceActions[0].onClick}
                      isLoading={isLoading}
                    >
                      {sequenceActions[0].label}
                    </Button>
                  </Tooltip>
                ) : hasSequenceActions ? (
                  <Menu size="sm" closeOnSelect autoSelect={false} matchWidth>
                    <MenuButton as={Button} variant="outline" size="md" isLoading={isLoading || scopes.isLoading}>
                      Add to Sequence…
                    </MenuButton>
                    <MenuList zIndex="popover">
                      {hasOutreach && profile.account && (
                        <Tooltip
                          label={
                            !hasSequenceScope
                              ? 'Your Workspace is missing the Sequences permission for Outreach. Clicking this button will redirect you to reconnect Outreach'
                              : undefined
                          }
                        >
                          <MenuItem
                            icon={<OutreachIcon color="outreach" size={16} />}
                            onClick={() => {
                              if (!hasSequenceScope) {
                                router.visit(
                                  projectPath(
                                    `/apps/outreach?return_to=${encodeURIComponent(window.location.pathname)}`
                                  )
                                )

                                return
                              }

                              outreachSequenceDisclosure.onOpen()
                            }}
                          >
                            Add to Outreach Sequence
                          </MenuItem>
                        </Tooltip>
                      )}
                      {hasApollo && (
                        <MenuItem
                          icon={<Image w="4" src="https://asset.brandfetch.io/ideEin4YhC/id-83Yirn2.png" />}
                          onClick={apolloSequenceDisclosure.onOpen}
                        >
                          Add to Apollo Sequence
                        </MenuItem>
                      )}

                      {hasSalesloft && (
                        <MenuItem
                          icon={<Image w="4" src={apps?.['Apps::Salesloft::App'].logo} />}
                          onClick={salesloftCadenceDisclosure.onOpen}
                        >
                          Add to Salesloft Cadence
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                ) : null}
                {taskActions.length === 1 ? (
                  <Tooltip label={taskActions[0].tooltip} placement="top" hasArrow>
                    <Button variant="outline" size="md" leftIcon={taskActions[0].icon} onClick={taskActions[0].onClick}>
                      {taskActions[0].label}
                    </Button>
                  </Tooltip>
                ) : hasTaskActions ? (
                  <Menu size="sm" closeOnSelect autoSelect={false} matchWidth>
                    <MenuButton as={Button} variant="outline" size="md">
                      Add Task…
                    </MenuButton>
                    <MenuList zIndex="popover">
                      {hasOutreach && (
                        <MenuItem
                          icon={<OutreachIcon color="outreach" size={16} />}
                          onClick={outreachTaskDisclosure.onOpen}
                        >
                          Add Task in Outreach
                        </MenuItem>
                      )}
                      {hasApollo && (
                        <MenuItem
                          icon={<Image w="4" src="https://asset.brandfetch.io/ideEin4YhC/id-83Yirn2.png" />}
                          onClick={apolloTaskDisclosure.onOpen}
                        >
                          Add Task in Apollo
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                ) : null}
              </>
            )}

            {profile.email && mailbox.data?.connected && (
              <EmailComposerPopup
                email={{ to: profile.email }}
                gmailConnected={mailbox.data?.connected}
                accountId={profile.account_id}
                onEmailSent={refetchProfileData}
                buttonProps={{
                  size: 'md',
                  width: 'full'
                }}
                warningMessage={
                  lastActivity ? (
                    <WarningMessage>
                      Emailed with{' '}
                      <LastEngagementHoverCard engagement={lastActivity}>
                        <Text as="span" fontWeight="semibold" textDecoration="underline">
                          {profile.name || profile.email}
                        </Text>
                      </LastEngagementHoverCard>{' '}
                      <TimeAgo time={lastActivity.created_at} />. Are you sure you want to send another message?
                    </WarningMessage>
                  ) : lastActivityDate ? (
                    <WarningMessage>
                      Last touched <TimeAgo time={lastActivityDate} />. Are you sure you want to send a message?
                    </WarningMessage>
                  ) : undefined
                }
              />
            )}
          </Flex>
        </Stack>
      </DetailsCard>

      {hasOutreach && (
        <CreateOutreachTaskSideSheet
          {...outreachTaskDisclosure}
          account={profile.account || null}
          app={apps?.['Apps::Outreach::App']}
        />
      )}

      {hasOutreach &&
        outreachSequenceDisclosure.isOpen &&
        (hasAccounts ? (
          <ImportContactsSideSheet
            {...outreachSequenceDisclosure}
            account={profile.account!}
            profile={profile}
            app={apps?.['Apps::Outreach::App']}
          />
        ) : (
          <ImportAccountSideSheet
            account={profile.account!}
            app={apps?.['Apps::Outreach::App']}
            onExit={outreachSequenceDisclosure.onClose}
            onSuccess={() => {
              refetch()
            }}
          />
        ))}

      {hasApollo && <ApolloTaskModal {...apolloTaskDisclosure} profile={profile} />}
      {hasApollo && (
        <AddToApolloSequenceModal
          {...apolloSequenceDisclosure}
          app={apps?.['Apps::Apollo::App']}
          selectedProfiles={[profile]}
        />
      )}
      {hasApollo && <AddToListModal {...apolloListDisclosure} profile={profile} />}
      {hasSalesloft && (
        <AddToSalesloftCadenceModal
          {...salesloftCadenceDisclosure}
          app={apps?.['Apps::Salesloft::App']}
          selectedProfiles={[profile]}
        />
      )}
    </>
  )
}

type IconFieldProps = React.PropsWithChildren<{
  label?: string
  info?: string
  copyText?: string | false
  icon?: IconifyProps['icon'] | null
  colorScheme?: ButtonProps['colorScheme']
}>

const IconField = forwardRef<HTMLDivElement, IconFieldProps & TextProps>(function IconField(
  { children, label, icon, info, copyText, colorScheme, ...rest },
  ref
) {
  if (!children) return null

  const color = colorScheme ?? 'gray'

  return (
    <HStack fontSize="sm" spacing={1.5} color={`${color}.500`} width="100%" ref={ref}>
      {icon && (
        <Box display="flex" flex="none" color={`${color}.500`} bg={`${color}.100`} rounded="lg" padding={1}>
          <Iconify icon={icon} size={16} strokeWidth={2} />
        </Box>
      )}
      <Tooltip label={info} placement="top" hasArrow arrowSize={6}>
        <Box fontWeight="medium" cursor={info ? 'help' : undefined} color="gray.600" lineHeight="20px" {...rest}>
          {label && (
            <Text display="inline-flex" color="gray.500">
              {label}:
            </Text>
          )}{' '}
          <Copyable copyEnabled={!label && copyText !== false} copyText={copyText || undefined} maxWidth="100%">
            {children}
          </Copyable>
        </Box>
      </Tooltip>
    </HStack>
  )
})

function messagedRecently(lastActivity?: Engagement | string) {
  if (typeof lastActivity === 'string') {
    return dayjs(lastActivity).isAfter(dayjs().subtract(90, 'day'))
  }

  if (lastActivity?.created_at) {
    return dayjs(lastActivity.created_at).isAfter(dayjs().subtract(90, 'day'))
  }

  return false
}
