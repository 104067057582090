import { Divider, Flex, FormControl, FormHelperText, FormLabel, Heading, Input, Stack } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { Apps } from '../../../../types/App'
import { Play, PlayConfigPreset, PlayTargetType } from '../../../../types/Play'
import { AutosizedTextarea } from '../../../ui/AutosizedTextarea'
import { StepIcon } from '../../../ui/StepIcon'
import { AISetup } from './ai-setup'
import { AssignmentSetup } from './assignment-setup'
import { EnrichmentSetup } from './enrichment-setup'
import { ProspectingSetup } from './prospecting-setup'
import { SourceSetup } from './source-setup'

export function PlayForm(props: { play: Partial<Play>; apps: Apps }) {
  const [sourceType, setSourceType] = React.useState<PlayConfigPreset | undefined>(props.play.target_config?.source)
  const [targetType, setTargetType] = React.useState<PlayTargetType | undefined>(props.play.target_type)

  const shouldOfferProspecting = useMemo(() => {
    if (sourceType === undefined) {
      return false
    }

    // This means we're already prospecting for people
    if (sourceType === 'prospects' && targetType === 'Profile') {
      return false
    }

    return true
  }, [sourceType, targetType])

  return (
    <Stack
      spacing={8}
      divider={<Divider />}
      paddingBottom={8}
      marginBottom={8}
      borderBottom="1px solid"
      borderColor="gray.100"
    >
      <Flex alignItems="flex-start" gap={2}>
        <StepIcon step={1} />
        <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
          <Heading size="sm">Play Details</Heading>

          <FormControl>
            <Input
              size="sm"
              name="play[name]"
              defaultValue={props.play.name}
              required
              placeholder="Play name (e.g. Pricing page visitors)"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Description/Goal (optional)</FormLabel>
            <AutosizedTextarea
              size="sm"
              name="play[description]"
              defaultValue={props.play.description}
              placeholder="Research the company, then send async ballpark offer"
            />
            <FormHelperText>
              Describe the goal of the play, and overall instructions for your reps executing the play
            </FormHelperText>
          </FormControl>
        </Stack>
      </Flex>

      <Flex alignItems="flex-start" gap={2}>
        <StepIcon step={2} />
        <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
          <Heading size="sm">Select an enrollment trigger</Heading>

          <SourceSetup
            apps={props.apps}
            targetType={targetType}
            targetConfig={props.play.target_config}
            triggerConfig={props.play.trigger_config}
            onSourceTypeChange={setSourceType}
            setTargetType={setTargetType}
          />
        </Stack>
      </Flex>

      <Flex alignItems="flex-start" gap={2}>
        <StepIcon step={3} />
        <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
          <Heading size="sm">AI and Prospecting</Heading>

          <EnrichmentSetup play={props.play} />

          {shouldOfferProspecting && <ProspectingSetup onTargetTypeChange={setTargetType} play={props.play} />}

          <AISetup play={props.play} />
        </Stack>
      </Flex>

      <Flex alignItems="flex-start" gap={2}>
        <StepIcon step={4} />
        <Stack flex="1 1 auto" spacing={4} maxWidth="700px">
          <Heading size="sm">Choose who to assign this play to</Heading>
          <AssignmentSetup play={props.play} apps={props.apps} />
        </Stack>
      </Flex>
    </Stack>
  )
}
