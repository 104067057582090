import { useMutation, UseMutationOptions, useQuery } from 'react-query'
import { concurrentGET, patch, post } from '../../lib/api'
import { Play, PlayItem } from '../../types/Play'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface PlayResponse {
  play_items: PlayItem[]
  play: Play
  stats: {
    total: number
    pending: number
    completed: number
    dismissed: number
  }
}

export function usePlay(playId: string, userId?: string, status?: string) {
  const project = useCurrentProject()

  const searchParams = new URLSearchParams()
  if (userId) {
    searchParams.set('user', userId)
  }

  if (status) {
    searchParams.set('status', status)
  }

  searchParams.set('per_page', '10')

  const path = projectPath(`/plays/${playId}?${searchParams.toString()}`).toString()

  return useQuery<PlayResponse>(
    ['play', { projectId: project?.id, playId, userId: userId }],
    () => concurrentGET<PlayResponse>(path),
    {
      enabled: Boolean(project?.id),
      // Keep the previous data visible while fetching new data
      keepPreviousData: true
    }
  )
}

interface DismissItemParams {
  itemId: string
  reason: string
  feedback?: string
}

export function useDismissPlayItem(options?: UseMutationOptions<void, Error, DismissItemParams>) {
  return useMutation<void, Error, DismissItemParams>(async ({ itemId, reason, feedback }) => {
    await patch(projectPath(`/play-items/${itemId}/dismiss`), {
      reason,
      feedback: reason === 'other' ? feedback : ''
    })
  }, options)
}

interface DismissItemsParams {
  itemIds: string[]
  reason: string
  feedback?: string
}

export function useDismissPlayItems(options?: UseMutationOptions<void, Error, DismissItemsParams>) {
  return useMutation<void, Error, DismissItemsParams>(async ({ itemIds, reason, feedback }) => {
    await post(projectPath(`/play-items/dismiss`), {
      item_ids: itemIds,
      reason,
      feedback: reason === 'other' ? feedback : ''
    })
  }, options)
}

interface ResetItemParams {
  itemId: string
}

export function useResetPlayItem(options?: UseMutationOptions<void, Error, ResetItemParams>) {
  return useMutation<void, Error, ResetItemParams>(async ({ itemId }) => {
    await patch(projectPath(`/play-items/${itemId}/reset`), {})
  }, options)
}

interface SnoozeItemParams {
  itemId: string
  until: string
}

export function useSnoozePlayItem(options?: UseMutationOptions<void, Error, SnoozeItemParams>) {
  return useMutation<void, Error, SnoozeItemParams>(async ({ itemId, until }) => {
    await patch(projectPath(`/play-items/${itemId}/snooze`), {
      until
    })
  }, options)
}

interface SnoozeItemsParams {
  itemIds: string[]
  until: string
}

export function useSnoozePlayItems(options?: UseMutationOptions<void, Error, SnoozeItemsParams>) {
  return useMutation<void, Error, SnoozeItemsParams>(async ({ itemIds, until }) => {
    await post(projectPath(`/play-items/snooze`), { item_ids: itemIds, until })
  }, options)
}

interface CompleteItemParams {
  itemId: string
}

export function useCompletePlayItem(options?: UseMutationOptions<void, Error, CompleteItemParams>) {
  return useMutation<void, Error, CompleteItemParams>(async ({ itemId }) => {
    await patch(projectPath(`/play-items/${itemId}/complete`), {})
  }, options)
}

interface CompleteItemsParams {
  itemIds: string[]
}

export function useCompletePlayItems(options?: UseMutationOptions<void, Error, CompleteItemsParams>) {
  return useMutation<void, Error, CompleteItemsParams>(async ({ itemIds }) => {
    await post(projectPath(`/play-items/complete`), { item_ids: itemIds })
  }, options)
}

interface AssignItemParams {
  itemId: string
  userId: string | null
}

export function useAssignPlayItem(options?: UseMutationOptions<PlayItem, Error, AssignItemParams>) {
  return useMutation<PlayItem, Error, AssignItemParams>(async ({ itemId, userId }) => {
    const response = await patch<{ play_item: PlayItem }>(projectPath(`/play-items/${itemId}/assign`), {
      user_id: userId
    })
    return response.play_item
  }, options)
}

interface AssignMultipleItemsParams {
  itemIds: string[]
  userId: string | null
}

interface AssignMultipleItemsResponse {
  play_items: PlayItem[]
}

export function useAssignMultiplePlayItems(
  options?: UseMutationOptions<AssignMultipleItemsResponse, Error, AssignMultipleItemsParams>
) {
  return useMutation<AssignMultipleItemsResponse, Error, AssignMultipleItemsParams>(async ({ itemIds, userId }) => {
    return await patch<AssignMultipleItemsResponse>(projectPath(`/play-items/assign`), {
      item_ids: itemIds,
      user_id: userId
    })
  }, options)
}

interface UpdatePlayParams {
  playId: string
  attributes: Partial<Play>
}

export function useUpdatePlay(options?: UseMutationOptions<Play, Error, UpdatePlayParams>) {
  return useMutation<Play, Error, UpdatePlayParams>(async ({ playId, attributes }) => {
    const response = await patch<{ play: Play }>(projectPath(`/plays/${playId}`), {
      play: attributes
    })
    return response.play
  }, options)
}
