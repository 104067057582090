import React from 'react'
import { DateTime } from '@app/types/Profile'
import dayjs from '@app/lib/dayjs'
import { TextProps } from '@chakra-ui/react'
import { SyncStatusValues } from '../syncs/index'

export interface SyncDurationProps {
  start: DateTime
  stop?: DateTime | null
  status: SyncStatusValues
  textProps?: Omit<TextProps, 'children'>
}

import { Text } from '@chakra-ui/react'

export default function SyncDuration(props: SyncDurationProps) {
  const defaultTextProps = {
    fontSize: 'xs',
    color: 'gray.500'
  }

  if (props.status === 'running') {
    return (
      <Text {...defaultTextProps} {...props.textProps}>
        Running {formatDuration(dayjs().diff(dayjs(props.start)))}
      </Text>
    )
  }

  return (
    <Text {...defaultTextProps} {...props.textProps}>
      Took {formatDuration(dayjs(props.stop).diff(dayjs(props.start)))}
    </Text>
  )
}

function formatDuration(milliseconds: number): string {
  if (!milliseconds) {
    return '—'
  }

  const duration = dayjs.duration(milliseconds)
  let formatted: string
  if (duration.hours() > 0) {
    formatted = duration.format('H[h] m[m]')
  } else if (duration.minutes() > 0) {
    formatted = duration.format('m[m] s[s]')
  } else if (duration.seconds() > 0) {
    formatted = duration.format('s[s]')
  } else {
    formatted = duration.format('SSS[ms]')
  }

  return formatted
}
