import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useCheckboxGroup
} from '@chakra-ui/react'
import {
  IconAddressBook,
  IconAddressBookOff,
  IconAlertCircle,
  IconExclamationCircle,
  IconMail,
  IconMailExclamation,
  IconMailQuestion,
  IconMailSearch,
  IconPhonePlus,
  IconRefresh,
  IconRosetteDiscountCheckFilled,
  IconSparkles,
  IconUserSearch
} from '@tabler/icons-react'
import dayjs from 'dayjs'
import { isEmpty, keyBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ExternalToast, toast } from 'sonner'
import { useActionCableChannel } from '../../../../channels/use-channel'
import { post } from '../../../../lib/api'
import { Apps } from '../../../../types/App'
import { useEnrichProspects } from '../../../data/use-enrich'
import {
  filteredProspectsPath,
  Highlights,
  Prospect,
  useFilteredProspects,
  useSaveProspects,
  useUnsaveProspects
} from '../../../data/use-prospects'
import { useUrlFilters } from '../../../data/use-url-filters'
import Avatar from '../../../ui/Avatar'
import { BulkActionBar } from '../../../ui/BulkActionBar'
import { CompanyBubble } from '../../../ui/CompanyBubble'
import { Copyable } from '../../../ui/Copyable'
import { DownloadCsvIcon, DownloadCsvTooltip } from '../../../ui/DownloadCsvButtons'
import EmptyState from '../../../ui/EmptyState'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { KoalaIcon, LinkedinBoxIcon, SalesforceIcon } from '../../../ui/icons'
import { HubSpotIcon } from '../../../ui/icons/HubspotIcons'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'
import { TableFooter } from '../../../ui/TableFooter'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { useOverflow } from '../../../ui/useOverflow'
import { humanize } from '../../accounts/facets/filter-cloud'
import { accountPath } from '../../accounts/lib/account-path'
import { useEmailNotFound } from '../../profiles/components/AutoProspectCard'
import { profilePath } from '../../profiles/lib/path'
import { ActionMenu } from '../../prospects/action-menu'
import { Persona } from '../../prospects/personas'
import { BulkAddToSequenceMenu } from '@app/components/ui/BulkAddToSequenceMenu'
import { BulkAddToCrmModal } from '@app/components/ui/BulkAddToCrmModal'

interface ProspectsTableProps {
  domains: string[]
  persona?: Persona | null
  apps: Apps
}

interface EnrichmentChannelData {
  action: 'started' | 'results'
  prospect_id: string
  email?: null | string
  state?: null | 'unlocked' | 'locked' | 'not_found' | 'auto-unlocked'
  updated_at?: string
}

const rowHover = {
  bg: [undefined, 'gray.50']
}

const toastOptions: ExternalToast = {
  dismissible: true,
  closeButton: true,
  position: 'bottom-right'
}

// TODO split out the table from the data fetching
export function ProspectsTableV2(props: ProspectsTableProps) {
  const { domains, persona } = props
  const project = useCurrentProject()

  const [emailLoadingState, setEmailLoadingState] = useState({})
  const [phoneLoadingState, setPhoneLoadingState] = useState({})
  const [verificationLoadingState, setVerificationLoadingState] = useState({})
  const [emailNotFound, setEmailNotFound] = useEmailNotFound({})

  const facets = useUrlFilters({ initialRange: null })

  const filters = useMemo(() => facets.facetFilters, [facets.facetFilters])
  const search = facets.query
  const page = facets.page

  const savedProspectsSelected = window.location.pathname.endsWith('/saved')

  const prospects = useFilteredProspects(domains, {
    page,
    perPage: 20,
    persona: persona?.id,
    only_saved: savedProspectsSelected,
    filters,
    search
  })

  const [pageMeta, setPageMeta] = useState(prospects.data?.page_meta)
  const [localProspects, setLocalProspects] = useState<Prospect[]>([])
  const [highlights, setHighlights] = useState<Highlights<'job_title.analyzed' | 'full_name.analyzed'>>({})

  const checkboxes = useCheckboxGroup()
  const selected = checkboxes.value as string[]

  useEffect(() => {
    if (prospects.data && !prospects.isLoading && !prospects.isFetching) {
      setHighlights(prospects.data.highlights ?? {})
      setLocalProspects(prospects.data.prospects ?? [])
      setPageMeta(prospects.data.page_meta)
      checkboxes.setValue([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospects.data, prospects.isLoading, prospects.isFetching])

  const [refreshLoadingState, setRefreshLoadingState] = useState({})

  const onRefreshProspect = useCallback((prospect: Prospect) => {
    setRefreshLoadingState((prev) => ({ ...prev, [prospect.id]: true }))
    let toastId: string | number | undefined
    const timer = setTimeout(() => {
      toastId = toast('Refreshing prospect data...', {
        duration: 10_000,
        ...toastOptions
      })
    }, 2500)

    post<{ prospect: Prospect }>(projectPath(`/prospects/${prospect.id}/refresh`))
      .then((res) => {
        clearTimeout(timer)

        setLocalProspects((prev) => {
          return prev.map((p) => {
            if (p.id === prospect.id) {
              return {
                ...p,
                ...res.prospect
              }
            }
            return p
          })
        })

        if (toastId) {
          toast.dismiss(toastId)
          toastId = undefined
        }

        if (res.prospect) {
          toast.success('Prospect refreshed', toastOptions)
        } else {
          toast.warning(`Could not refresh data`, toastOptions)
        }
      })
      .catch((err) => {
        toast.error("Couldn't refresh prospect data: " + err.message, toastOptions)
      })
      .finally(() => {
        setRefreshLoadingState((prev) => ({ ...prev, [prospect.id]: false }))
      })
  }, [])

  const onUnlockEmail = useCallback(
    (prospect: Prospect) => {
      setEmailLoadingState((prev) => ({ ...prev, [prospect.id]: true }))
      let toastId: string | number | undefined

      const timer = setTimeout(() => {
        toastId = toast('Searching for prospect data against multiple data sources. This may take a few seconds...', {
          duration: 10_000,
          ...toastOptions
        })
      }, 2500)

      post<{ prospect: Prospect }>(projectPath(`/prospects/${prospect.id}/unlock`))
        .then((res) => {
          clearTimeout(timer)

          setLocalProspects((prev) => {
            return prev.map((p) => {
              if (p.id === prospect.id) {
                return {
                  ...p,
                  ...res.prospect
                }
              }
              return p
            })
          })

          if (toastId) {
            toast.dismiss(toastId)
            toastId = undefined
          }

          if (res.prospect.email) {
            toast.success('Prospect unlocked', toastOptions)
          } else {
            toast.warning(`Could not find email for ${humanize(res.prospect.first_name ?? '')}`, toastOptions)
            setEmailNotFound((prev) => ({ ...prev, [prospect.id]: true }))
          }
        })
        .catch((err) => {
          toast.error("Couldn't find email for prospect: " + err.message, toastOptions)
        })
        .finally(() => {
          clearTimeout(timer)
          if (toastId) {
            toast.dismiss(toastId)
            toastId = undefined
          }

          setEmailLoadingState((prev) => ({ ...prev, [prospect.id]: false }))
        })
    },
    [setEmailNotFound]
  )

  const onUnlockPhone = useCallback((prospect: Prospect) => {
    setPhoneLoadingState((prev) => ({ ...prev, [prospect.id]: true }))
    post<{ prospect: Prospect }>(projectPath(`/prospects/${prospect.id}/unlock-phone`))
      .then((res) => {
        setLocalProspects((prev) => {
          return prev.map((p) => {
            if (p.id === prospect.id) {
              return {
                ...p,
                ...res.prospect
              }
            }
            return p
          })
        })

        if (res.prospect.phone_state === 'unlocked') {
          toast.success('Phone number unlocked', toastOptions)
        } else if (res.prospect.phone_state === 'not_found') {
          toast.warning('Phone number not found', toastOptions)
        }
      })
      .catch(() => {
        toast.error('Failed to unlock phone number', toastOptions)
      })
      .finally(() => {
        setPhoneLoadingState((prev) => ({ ...prev, [prospect.id]: false }))
      })
  }, [])

  const onVerifyEmail = useCallback((prospect: Prospect) => {
    setVerificationLoadingState((prev) => ({ ...prev, [prospect.id]: true }))
    post<{ prospect: Prospect }>(projectPath(`/prospects/${prospect.id}/verify-email`))
      .then((res) => {
        if (res.prospect.verification_status === 'verified') {
          toast.success('Email verified', toastOptions)
        } else {
          toast.warning('Email could not be verified', toastOptions)
        }

        setLocalProspects((prev) => {
          return prev.map((p) => {
            if (p.id === prospect.id) {
              return {
                ...p,
                verification_status: res.prospect.verification_status
              }
            }
            return p
          })
        })
      })
      .catch(() => {
        toast.error('Failed to verify email', toastOptions)
      })
      .finally(() => {
        setVerificationLoadingState((prev) => ({ ...prev, [prospect.id]: false }))
      })
  }, [])

  const trackCopyEmail = useCallback((prospect: Prospect) => {
    window.ko?.track('Prospect Email Copied', {
      email: prospect.email,
      prospected_profile_id: prospect.id,
      company: prospect.company?.domain,
      context: 'explore'
    })
  }, [])

  const trackCopyPhone = useCallback((prospect: Prospect) => {
    window.ko?.track('Prospect Phone Copied', {
      email: prospect.email,
      prospected_profile_id: prospect.id,
      company: prospect.company?.domain,
      context: 'explore'
    })
  }, [])

  const { mutateAsync: saveProspectsAsync } = useSaveProspects()
  const { mutateAsync: unsaveProspectsAsync } = useUnsaveProspects()
  const { mutateAsync: enrichProspectsAsync } = useEnrichProspects()
  const [saving, setSaving] = useState({})

  const saveProspects = useCallback(
    async (prospectIds: string[]) => {
      const pluralized = prospectIds.length > 1 ? 'Prospects' : 'Prospect'
      try {
        setSaving((prev) => {
          const next = { ...prev }
          prospectIds.forEach((id) => {
            next[id] = true
          })
          return next
        })
        await saveProspectsAsync({ prospectIds })
        setLocalProspects((prev) => {
          return prev.map((p) => {
            if (prospectIds.includes(p.id)) {
              return {
                ...p,
                saved: true
              }
            }
            return p
          })
        })
        toast.success(`${pluralized} saved`, toastOptions)
      } catch (err) {
        toast.error(`Failed to save ${pluralized.toLowerCase()}`, toastOptions)
      } finally {
        setSaving((prev) => {
          const next = { ...prev }
          prospectIds.forEach((id) => {
            next[id] = false
          })
          return next
        })
      }
    },
    [saveProspectsAsync]
  )

  const unsaveProspects = useCallback(
    async (prospectIds: string[]) => {
      const pluralized = prospectIds.length > 1 ? 'Prospects' : 'Prospect'
      try {
        setSaving((prev) => {
          const next = { ...prev }
          prospectIds.forEach((id) => {
            next[id] = true
          })
          return next
        })
        await unsaveProspectsAsync({ prospectIds })
        setLocalProspects((prev) => {
          return prev.map((p) => {
            if (prospectIds.includes(p.id)) {
              return {
                ...p,
                saved: false
              }
            }
            return p
          })
        })
        toast.success(`${pluralized} removed from Saved Prospects`, toastOptions)
      } catch (err) {
        toast.error(`Failed to remove ${pluralized.toLowerCase()} from Saved Prospects`, toastOptions)
      } finally {
        setSaving((prev) => {
          const next = { ...prev }
          prospectIds.forEach((id) => {
            next[id] = false
          })
          return next
        })
      }
    },
    [unsaveProspectsAsync]
  )

  const hasData = useMemo(() => localProspects.length > 0, [localProspects.length])

  const noData = useMemo(
    () => !prospects.isLoading && !prospects.isFetching && localProspects.length === 0,
    [prospects.isLoading, prospects.isFetching, localProspects.length]
  )

  const hasFilters = Boolean(props.persona?.id) || !isEmpty(filters)

  const { scrollRef, overflowLeft, overflowTop } = useOverflow([hasData])

  // if the selected rows are all saved, disable the save button
  const selectedAllSaved = useMemo(() => {
    return checkboxes.value.length > 0 && checkboxes.value.every((id) => localProspects.find((p) => p.id === id)?.saved)
  }, [checkboxes.value, localProspects])

  const selectedAllEnriched = useMemo(() => {
    return (
      checkboxes.value.length > 0 &&
      checkboxes.value.every((id) => {
        const prospect = localProspects.find((p) => p.id === id)
        if (!prospect) return false
        if (prospect.email) return true
        if (['unlocked', 'auto-unlocked', 'not_found'].includes(prospect.unlock_state)) return true
        return false
      })
    )
  }, [checkboxes.value, localProspects])

  const selectedProspects = localProspects.filter((obj) => selected.includes(obj.id))

  const enrichSelected = useCallback(async () => {
    setEmailLoadingState((prev) => {
      const loaders = {}
      for (const id of checkboxes.value as string[]) {
        loaders[id] = true
      }

      return { ...prev, ...loaders }
    })

    try {
      const res = await enrichProspectsAsync({ prospectIds: checkboxes.value as string[] })
      const updates = keyBy(res.prospects, 'id')
      const completed = res.prospects
        .filter((p) => p.email && ['unlocked', 'auto-unlocked', 'not_found'].includes(p.unlock_state))
        .map((p) => p.id)

      // if we already have emails we can show them immediately
      setLocalProspects((prev) => {
        return prev.map((p) => {
          if (checkboxes.value.includes(p.id)) {
            return {
              ...p,
              ...(updates[p.id] || {})
            }
          }
          return p
        })
      })

      if (completed.length > 0) {
        setEmailLoadingState((prev) => {
          const loadingState = {}
          for (const id of completed) {
            loadingState[id] = false
          }

          return { ...prev, ...loadingState }
        })
      }
    } catch (_err) {
      toast.error('Failed to request bulk enrichment', {
        description: 'Please try again later or contact support@getkoala.com',
        ...toastOptions
      })
    }
  }, [enrichProspectsAsync, checkboxes.value])

  const onImport = useCallback((prospects) => {
    const prospectsById = keyBy(prospects, 'id')

    setLocalProspects((prev) => {
      return prev.map((p) => {
        const updated = prospectsById[p.id]
        if (updated) {
          return {
            ...p,
            ...updated
          }
        }
        return p
      })
    })
  }, [])

  useActionCableChannel<EnrichmentChannelData>('EnrichmentChannel', { company: undefined }, (data) => {
    if (data.action === 'started' && data.prospect_id) {
      setEmailLoadingState((prev) => ({ ...prev, [data.prospect_id]: true }))
    } else if (data.action === 'results' && data.prospect_id) {
      setLocalProspects((prev) => {
        return prev.map((p) => {
          if (p.id === data.prospect_id && (!p.updated_at || !data.updated_at || p.updated_at < data.updated_at)) {
            return {
              ...p,
              email: data.email ?? null,
              unlock_state: data.state!,
              updated_at: data.updated_at!
            }
          }
          return p
        })
      })
      setEmailLoadingState((prev) => ({ ...prev, [data.prospect_id]: false }))
    }
  })

  return (
    <Box display="flex" flexDirection="column" w="100%" flex="1 1 auto" maxH="100%" minH="300px">
      {hasData ? (
        <TableContainer
          ref={scrollRef}
          position="relative"
          className={`${overflowLeft ? 'scrolled' : ''} ${overflowTop ? 'scrolled-y' : ''}`.trim() || undefined}
          overflowY="auto"
          width="100%"
        >
          <Table variant="bordered" size="sm" width="100%" height="1px">
            <Thead className="sticky-header">
              <Tr height="38px">
                <Th className="sticky-column">
                  <Flex alignItems="center" gap={2}>
                    <Checkbox
                      size="md"
                      marginRight={1}
                      isChecked={checkboxes.value.length === localProspects.length}
                      onChange={() => {
                        checkboxes.setValue((prev) =>
                          prev.length === localProspects.length ? [] : localProspects.map((p) => p.id)
                        )
                      }}
                    />
                    <Text>Person</Text>
                  </Flex>
                </Th>
                <Th>Company</Th>
                <Th>Role</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
                <Th>Location</Th>
              </Tr>
            </Thead>
            <Tbody>
              {localProspects.map((prospect) => {
                const stale =
                  prospect.last_checked_at && dayjs(prospect.last_checked_at).isBefore(dayjs().subtract(120, 'days'))

                return (
                  <Tr
                    key={prospect.id}
                    role="group"
                    fontSize="sm"
                    opacity={prospects.isLoading ? 0.5 : 1}
                    bg={selected.includes(prospect.id) ? 'purple.50' : undefined}
                    _hover={selected.includes(prospect.id) ? undefined : rowHover}
                  >
                    <Td
                      className="sticky-column"
                      height="100%"
                      w="1px"
                      bg={selected.includes(prospect.id) ? 'purple.50' : undefined}
                    >
                      <Flex alignItems="center" gap={2} paddingY={1} minW="320px" maxW="400px">
                        <Checkbox size="md" marginRight={1} {...checkboxes.getCheckboxProps({ value: prospect.id })} />

                        <Avatar
                          size="sm"
                          name={prospect.name}
                          src={prospect.company ? projectPath(`/prospects/${prospect.id}/avatar`) : null}
                        />

                        <Stack spacing={0.5} flex="1" alignItems="flex-start" isTruncated>
                          <Flex alignItems="center" maxW="100%" gap={1.5}>
                            <TextEllipsis fontWeight="medium" maxW="100%" tooltip>
                              <Highlight
                                value={prospect.name}
                                highlight={highlights[prospect.external_id]?.['full_name.analyzed']?.[0]}
                                label="Matches your name search"
                              />
                            </TextEllipsis>

                            <Flex flex="none" alignItems="center" gap={1}>
                              {prospect.linkedin_url && (
                                <Tooltip label={`https://${prospect.linkedin_url.replace(/https?:\/\//, '')}`}>
                                  <Link
                                    display="flex"
                                    flex="none"
                                    alignItems="center"
                                    color="linkedin.700"
                                    isExternal
                                    href={`https://${prospect.linkedin_url.replace(/https?:\/\//, '')}`}
                                  >
                                    <LinkedinBoxIcon boxSize="18px" />
                                  </Link>
                                </Tooltip>
                              )}
                              {(prospect.salesforce_contact_cache || prospect.salesforce_lead_cache) && (
                                <Tooltip label="View in Salesforce">
                                  <Link
                                    href={
                                      prospect.salesforce_contact_cache?.permalink ??
                                      prospect.salesforce_lead_cache?.permalink
                                    }
                                    isExternal
                                  >
                                    <SalesforceIcon color="salesforce" boxSize="18px" />
                                  </Link>
                                </Tooltip>
                              )}
                              {prospect.hubspot_contact_cache &&
                                !(prospect.salesforce_contact_cache || prospect.salesforce_lead_cache) && (
                                  <Tooltip label="View in HubSpot">
                                    <Link href={prospect.hubspot_contact_cache?.permalink} isExternal>
                                      <HubSpotIcon color="hubspot" boxSize="18px" />
                                    </Link>
                                  </Tooltip>
                                )}
                              {prospect.profile && (
                                <Tooltip label="View activity in Koala">
                                  <Link href={profilePath(prospect.profile)} isExternal>
                                    <KoalaIcon color="purple.500" boxSize="18px" />
                                  </Link>
                                </Tooltip>
                              )}
                            </Flex>
                          </Flex>

                          <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
                            <Highlight
                              value={prospect.title}
                              highlight={highlights[prospect.external_id]?.['job_title.analyzed']?.[0]}
                              label="Matches your title keywords"
                            />
                          </TextEllipsis>
                        </Stack>

                        <Flex flex="none" alignItems="center" marginLeft={1} gap={1}>
                          {stale && (
                            <Flex justifyContent={'flex-end'}>
                              <Popover trigger="hover" placement="right">
                                <PopoverTrigger>
                                  <IconButton
                                    icon={<IconExclamationCircle size="16" />}
                                    aria-label="Last checked"
                                    variant="ghost"
                                    size="xs"
                                  />
                                </PopoverTrigger>
                                <Portal>
                                  <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverBody>
                                      <Stack p="4" fontSize={'xs'} spacing="4">
                                        <HStack spacing="1">
                                          <IconAlertCircle size="18" />
                                          <Heading size="xs">This profile may be outdated</Heading>
                                        </HStack>
                                        <Text>
                                          We believe this profile may be out of date based on reports from our sources.
                                        </Text>

                                        <Text>
                                          You can get the latest and most accurate information by refreshing the
                                          profile.
                                        </Text>

                                        <Text bg="blue.50" p="2" px="4">
                                          Your workspace will not be charged for this operation.
                                        </Text>

                                        <Button
                                          leftIcon={<Icon as={IconRefresh} />}
                                          colorScheme={'purple'}
                                          size="sm"
                                          isLoading={refreshLoadingState[prospect.id]}
                                          onClick={() => {
                                            onRefreshProspect(prospect)
                                          }}
                                        >
                                          Waterfall Enrich (free)
                                        </Button>
                                      </Stack>
                                    </PopoverBody>
                                  </PopoverContent>
                                </Portal>
                              </Popover>
                            </Flex>
                          )}

                          <Button
                            size="sm"
                            variant="outline"
                            colorScheme={prospect.saved ? 'gray' : 'lightPurple'}
                            onClick={prospect.saved ? undefined : () => saveProspects([prospect.id])}
                            isLoading={saving[prospect.id]}
                            minW="70px"
                          >
                            {prospect.saved ? 'Saved' : 'Save'}
                          </Button>

                          {props.apps && (
                            <Flex mr={-1}>
                              <ActionMenu
                                isIconButton
                                iconButtonProps={{ size: 'xs' }}
                                prospect={prospect}
                                domain={prospect.company?.domain}
                                isLoading={prospects.isLoading}
                                apps={props.apps}
                                persona={props.persona}
                                context="explore"
                                onEmailLoadingChange={(loading) => {
                                  setEmailLoadingState((prev) => ({ ...prev, [prospect.id]: loading }))
                                }}
                                onPhoneLoadingChange={(loading) => {
                                  setPhoneLoadingState((prev) => ({ ...prev, [prospect.id]: loading }))
                                }}
                                onChange={(updatedProspect) => {
                                  setLocalProspects((prev) => {
                                    return prev.map((p) => {
                                      if (p.id === updatedProspect.id) {
                                        return updatedProspect
                                      }
                                      return p
                                    })
                                  })
                                }}
                              />
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    </Td>
                    {prospect.company_id && prospect?.company ? (
                      <Td width="1px" minW="200px" maxW="240px" paddingLeft={[2.5, 4]}>
                        <Flex alignItems="center" gap={2.5} height="100%">
                          <CompanyBubble
                            name={prospect.company?.name}
                            domain={prospect.company?.domain}
                            href={accountPath({ company: prospect.company })}
                            target="_blank"
                            fontWeight="semibold"
                            _groupHover={{
                              background: 'rgba(255,255,255,0.65)',
                              shadow: 'sm',
                              '& .hover-icon': {
                                display: 'flex',
                                opacity: 1
                              }
                            }}
                          />
                        </Flex>
                      </Td>
                    ) : (
                      <Td color="gray.500" left={0} zIndex={1} bg="white" paddingLeft={[0, 3]}>
                        &mdash;
                      </Td>
                    )}
                    <Td>
                      <HStack fontSize="sm" color="gray.500" spacing={1} maxW="200px" isTruncated>
                        {prospect.subdepartments && (
                          <TextEllipsis maxW="100%" tooltip>
                            {humanize(prospect.subdepartments.join(', ').replace('_', ' '))}
                          </TextEllipsis>
                        )}
                        {prospect.subdepartments.length > 0 && prospect.departments.length > 0 && <Text>/</Text>}
                        {prospect.departments && (
                          <TextEllipsis maxW="100%" tooltip>
                            {humanize(prospect.departments.join(', ').replace('_', ' ') ?? '')}
                          </TextEllipsis>
                        )}
                      </HStack>
                    </Td>
                    <Td width="1px">
                      <ProspectEmailCell
                        prospect={prospect}
                        email={prospect.email}
                        unlockState={prospect.unlock_state}
                        verificationStatus={prospect.verification_status}
                        notFound={prospect.unlock_state === 'not_found' || emailNotFound[prospect.id]}
                        isLoading={emailLoadingState[prospect.id]}
                        isVerifying={verificationLoadingState[prospect.id]}
                        onCopyToClipboard={trackCopyEmail}
                        onVerifyEmail={onVerifyEmail}
                        onUnlockEmail={onUnlockEmail}
                      />
                    </Td>
                    <Td width="1px">
                      {prospect.phone_numbers && prospect.phone_numbers.mobile && (
                        <Flex minWidth="150px" maxWidth="150px" justifyContent="space-between" gap={4}>
                          <Box flex="1 1 auto" minWidth="120px" paddingRight={4}>
                            <Copyable
                              copyText={prospect.phone_numbers.mobile}
                              css={{ fontVariantNumeric: 'tabular-nums' }}
                              showToast
                              onCopyToClipboard={() => trackCopyPhone(prospect)}
                            >
                              {prospect.phone_numbers.mobile}
                            </Copyable>
                          </Box>

                          {prospect.phone_state === 'unlocked' && !prospect.profile && (
                            <Tooltip label="Enriched via Koala Waterfall">
                              <Icon as={IconSparkles} flex="none" color="gray.400" boxSize={4} />
                            </Tooltip>
                          )}
                        </Flex>
                      )}

                      {prospect.phone_state === 'not_found' ? (
                        <Flex alignItems="center" gap={1.5} color="gray.500">
                          No phone number found
                          <HelpTooltip>
                            Koala couldn't find a phone number for this prospect in any of our data sources.
                          </HelpTooltip>
                        </Flex>
                      ) : prospect.phone_state === 'locked' || prospect.phone_state === null ? (
                        <HStack>
                          <Button
                            aria-label="Find phone number"
                            size="sm"
                            variant="outline"
                            bg="white"
                            color="gray.600"
                            borderColor="gray.200"
                            _hover={{
                              color: 'purple.600',
                              borderColor: 'purple.300',
                              '& .chakra-button__icon svg': { opacity: 1 }
                            }}
                            leftIcon={<Icon as={IconPhonePlus} boxSize={3.5} opacity={0.6} />}
                            iconSpacing={1.5}
                            onClick={() => onUnlockPhone(prospect)}
                            isLoading={phoneLoadingState[prospect.id]}
                          >
                            Find phone
                          </Button>
                        </HStack>
                      ) : null}
                    </Td>
                    <Td>
                      <Text fontSize="sm" color="gray.500">
                        {[prospect.city, prospect.region, prospect.country]
                          .filter(Boolean)
                          .map((s) => humanize(s || ''))
                          .join(', ')}
                      </Text>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : noData && hasFilters ? (
        <EmptyState
          icon={IconUserSearch}
          size="sm"
          heading="No prospects found"
          description="We could not find any prospects for this company matching these filters."
          ctaText={props.persona ? undefined : 'Clear Filters'}
          onClick={() => {
            facets.clearFilters()
          }}
        />
      ) : noData && !hasFilters ? (
        <EmptyState
          icon={IconUserSearch}
          size="sm"
          heading="No prospects found"
          description={`We could not find any prospects for this company${
            props.persona ? ' matching this persona' : ''
          }.`}
        />
      ) : null}

      {hasData && pageMeta && (
        <TableFooter
          page={page}
          sticky
          scrollToTop={false}
          setPage={facets.setPage}
          pageMeta={pageMeta}
          isLoading={prospects.isLoading}
          paddingX={4}
        />
      )}

      <BulkActionBar selectionCount={checkboxes.value?.length ?? 0} onRemoveSelection={() => checkboxes.setValue([])}>
        {selectedAllSaved ? (
          <Button
            size="sm"
            variant="outline"
            leftIcon={<IconAddressBookOff size={16} />}
            iconSpacing={1.5}
            isDisabled={checkboxes.value.length === 0}
            onClick={() => unsaveProspects(checkboxes.value as string[])}
          >
            Unsave Prospects
          </Button>
        ) : (
          <Button
            size="sm"
            variant="outline"
            leftIcon={<IconAddressBook size={16} />}
            iconSpacing={1.5}
            isDisabled={checkboxes.value.length === 0 || selectedAllSaved}
            onClick={() => saveProspects(checkboxes.value as string[])}
          >
            Save Prospects
          </Button>
        )}

        {!selectedAllEnriched && (
          <Button size="sm" variant="outline" onClick={enrichSelected}>
            Enrich
          </Button>
        )}

        <BulkAddToCrmModal apps={props.apps} selectedIds={selected} recordType="profile" onImport={onImport} />

        <BulkAddToSequenceMenu
          apps={props.apps}
          selectedRecords={selectedProspects}
          recordType="ProspectedProfile"
          recordLabel="prospects"
        />

        <DownloadCsvTooltip>
          <Button
            size="sm"
            variant="outline"
            leftIcon={<DownloadCsvIcon size={16} />}
            isDisabled={!project?.can_export_data}
            iconSpacing={1.5}
            onClick={() => {
              const url = filteredProspectsPath([], {
                filters,
                format: 'csv',
                persona: persona?.id,
                search: search,
                only_saved: savedProspectsSelected,
                ids: checkboxes.value as string[],
                page: page
              })
              window.open(url)
            }}
          >
            Export
          </Button>
        </DownloadCsvTooltip>
      </BulkActionBar>
    </Box>
  )
}

function Highlight(props: { value: string | null | undefined; label?: string; highlight?: string | undefined }) {
  const parts = (props.highlight || props.value || '').split(/(<em>.*?<\/em>)/g)

  return (
    <>
      {parts.map((part, idx) => {
        const padLeft = part.startsWith(' ')
        const padRight = part.endsWith(' ')
        const key = part + idx

        if (part.startsWith('<em>') && part.endsWith('</em>')) {
          return (
            <Tooltip key={key} label={props.label || 'Matches your keywords'} hasArrow arrowSize={6} openDelay={400}>
              <Text as="span" bg="background.highlighter">
                {humanize(part.replace(/<em>|<\/em>/g, ''), false)}
              </Text>
            </Tooltip>
          )
        }

        return (
          <Text key={key} as="span">
            {padLeft && ' '}
            {humanize(part, false)}
            {padRight && ' '}
          </Text>
        )
      })}
    </>
  )
}

interface ProspectEmailCellProps {
  prospect: Prospect
  email?: string | null
  unlockState: Prospect['unlock_state']
  notFound?: boolean
  verificationStatus: Prospect['verification_status']
  isLoading?: boolean
  isVerifying?: boolean
  onCopyToClipboard: (prospect: Prospect) => void
  onVerifyEmail: (prospect: Prospect) => void
  onUnlockEmail: (prospect: Prospect) => void
  flexDirection?: BoxProps['flexDirection']
}

const verificationColor = {
  unverified: 'gray',
  verified: 'green',
  verification_failed: 'orange'
}

const verificationLabel = {
  unverified: 'This email has not been verified. Click to verify',
  verified: 'Valid Email',
  verification_failed: 'Invalid Email'
}

export function ProspectEmailCell(props: ProspectEmailCellProps) {
  const {
    email,
    notFound,
    unlockState,
    prospect,
    verificationStatus,
    isLoading,
    isVerifying,
    onCopyToClipboard,
    onVerifyEmail,
    onUnlockEmail
  } = props

  if (!email && notFound) {
    return (
      <Flex color="gray.500" gap={1.5} alignItems="center">
        No email found
        <HelpTooltip>Koala couldn't find an email for this prospect in any of our data sources.</HelpTooltip>
      </Flex>
    )
  }

  if (!email) {
    return (
      <Flex alignItems="center" gap={1} flexDirection={props.flexDirection}>
        <Button
          aria-label="Find email address"
          size="sm"
          variant="outline"
          bg="white"
          color="gray.600"
          borderColor="gray.200"
          _hover={{
            color: 'purple.600',
            borderColor: 'purple.300',
            '& .chakra-button__icon svg': { opacity: 1 }
          }}
          leftIcon={<Icon as={IconMailSearch} boxSize={3.5} opacity={0.6} />}
          iconSpacing={1.5}
          onClick={() => onUnlockEmail(prospect)}
          isLoading={isLoading}
          loadingText="Enriching..."
        >
          Find email
        </Button>
        <HelpTooltip
          visibility="hidden"
          pointerEvents="none"
          _groupHover={{ visibility: 'visible', pointerEvents: 'unset' }}
        >
          <Stack spacing="4" p="2">
            <Heading size="xs" fontWeight="semibold">
              Unlock prospect
            </Heading>
            <Text fontSize="sm" color="gray.600" whiteSpace="pre-wrap" lineHeight="1.4">
              Koala couldn't find an email for this prospect in any of our data sources. Click to search for it using
              Koala Waterfall.
            </Text>
            <Text whiteSpace={'pre-wrap'} fontSize="xs" lineHeight="1.4" p="4" bg="purple.50" color="gray.700">
              Note: We're offering unlimited Koala Waterfall enrichment credits to all beta testers of Prospecting.
            </Text>
            <Button
              size="sm"
              colorScheme={'purple'}
              onClick={() => onUnlockEmail(prospect)}
              leftIcon={<IconMailSearch size="18" />}
              isLoading={isLoading}
            >
              Find email
            </Button>
          </Stack>
        </HelpTooltip>
      </Flex>
    )
  }

  return (
    <Flex minWidth="200px" maxWidth="200px" justifyContent="space-between" gap={2} alignItems="center">
      <Box flex="1 1 auto" minWidth="120px">
        <Flex alignItems="center" gap={0.5} isTruncated>
          {prospect.profile ? (
            <Icon as={IconMail} flex="none" color="gray.500" boxSize={4} mx={1} />
          ) : verificationStatus === 'verified' ? (
            <Tooltip label="This email has been verified">
              <Icon as={IconRosetteDiscountCheckFilled} flex="none" color="green.500" boxSize={4} mx={1} />
            </Tooltip>
          ) : verificationStatus === 'verification_failed' ? (
            <Tooltip label="Could not verify this email">
              <Icon as={IconMailExclamation} flex="none" color="amber.600" boxSize={4} mx={1} />
            </Tooltip>
          ) : (
            <Tooltip label={verificationLabel[verificationStatus]}>
              <IconButton
                size="xs"
                variant="ghost"
                colorScheme={verificationColor[verificationStatus]}
                aria-label="Verify email"
                icon={<Icon as={IconMailQuestion} color="gray.400" boxSize={4} />}
                isLoading={isVerifying}
                onClick={() => {
                  if (!isVerifying) {
                    onVerifyEmail(prospect)
                  }
                }}
              />
            </Tooltip>
          )}

          <Copyable copyText={email} minWidth="100px" showToast onCopyToClipboard={() => onCopyToClipboard(prospect)}>
            <TextEllipsis fontSize="sm" maxW="220px" tooltip>
              {email}
            </TextEllipsis>
          </Copyable>
        </Flex>
      </Box>

      {verificationStatus !== 'verification_failed' &&
        (unlockState === 'unlocked' || unlockState === 'auto-unlocked') && (
          <Tooltip label="Enriched via Koala Waterfall">
            <Icon as={IconSparkles} flex="none" color="gray.400" boxSize={4} />
          </Tooltip>
        )}
    </Flex>
  )
}
