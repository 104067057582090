import { IconButton, Tooltip } from '@chakra-ui/react'
import { IconCheck } from '@tabler/icons-react'
import React from 'react'
import { toast } from 'sonner'
import { useCompletePlayItem } from '../../../data/use-plays'

interface CompleteButtonProps {
  itemId: string
  onSuccess?: (itemId: string, status: string) => void
}

export function CompleteButton({ itemId, onSuccess }: CompleteButtonProps) {
  const { mutate: completeItem, isLoading } = useCompletePlayItem({
    onSuccess: () => {
      onSuccess?.(itemId, 'completed')
    },
    onError: (error: any) => {
      toast.error('There was an issue marking this lead as complete', {
        description: error?.body?.message || error?.message
      })
    }
  })

  return (
    <Tooltip label="Mark Complete">
      <IconButton
        aria-label="Complete"
        icon={<IconCheck size={16} />}
        bg="white"
        onClick={() => completeItem({ itemId })}
        isLoading={isLoading}
      />
    </Tooltip>
  )
}
