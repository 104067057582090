import {
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Img,
  Link,
  Progress,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  Skeleton,
  useDisclosure,
  Box
} from '@chakra-ui/react'
import {
  IconArrowRight,
  IconBell,
  IconChevronDown,
  IconChevronRight,
  IconExternalLink,
  IconGauge,
  IconPlus
} from '@tabler/icons-react'
import orderBy from 'lodash/orderBy'
import pluralize from 'pluralize'
import React, { useCallback, useMemo } from 'react'
import { PageMeta } from '../../../types/PageMeta'
import { DateTime } from '../../../types/Profile'
import { useBillingMetrics } from '../../data/use-billing-metrics'
import { openUpgradeFlow } from '../../ui/billing-banners/accounts-banner'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { MiddotDivider } from '../../ui/Middot'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import SettingsHeader from '../../ui/SettingsHeader'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import { AllEntitlements, KoalaSubscription } from '../billing/show'
import { Usage } from '../billing/v2'
import { friendlyNumber } from '../billing/v3'
import { channelLogos } from '../follow_rules/components/delivery-setup'
import { SignalType } from '../kql_definitions/components/SignalType'
import { KqlDefinition } from '../kql_definitions/types'
import { slackAlertPath, slackAlertsPath } from '../notifications/lib/path-helper'
import { OptionsMenu, teamName } from './components/DefinitionForm'
import { SlackAlert } from './types'
import { useSlackAlertStats } from '@app/components/data/use-slack-alert-stats'
import { audienceName } from './components/audience-name'
import { INTENT_TREND_SIGNALS } from '../kql_definitions/components/SelectSignalsModal'

interface Props {
  intent_signals: Record<string, KqlDefinition>
  slack_alerts: SlackAlert[]
  // stats/timestamps for each alert
  alert_stats: Record<string, number>
  timestamps: Record<string, DateTime>
  // stats/timestamps for the individual signals within an alert
  granular_stats: Record<string, number>
  granular_timestamps: Record<string, DateTime>
  page_meta: PageMeta
}

export default function Index(props: Props) {
  const alerts = props.slack_alerts
  const project = useCurrentProject()
  const metrics = useBillingMetrics({ automations: true })
  const metricsData = useMemo(() => metrics.data, [metrics.data])

  return (
    <PageLayout size="md">
      <Breadcrumb
        paths={[
          { path: projectPath('/automations/overview'), title: 'Automations' },
          { path: projectPath('/slack-alerts'), title: 'Slack Alerts' }
        ]}
      />

      <SettingsHeader>
        <HStack w="100%" justifyContent="space-between">
          <PageTitle>Slack Alerts</PageTitle>
          {alerts.length > 0 && (
            <Button
              as={Link}
              href={slackAlertsPath('/new')}
              leftIcon={<IconPlus size={14} />}
              size="sm"
              colorScheme="purple"
            >
              New Slack Alert
            </Button>
          )}
        </HStack>
      </SettingsHeader>

      {project?.automation_rate_limits_enabled &&
        !!metricsData?.entitlements?.slack_alerts_monthly &&
        metricsData.usage && (
          <SlackAlertsLimitWarning
            koala_subscription={metricsData.koala_subscription}
            entitlements={metricsData.entitlements}
            usage={metricsData.usage}
          />
        )}

      <Stack width="100%" pb="150px" marginX="auto" spacing={8}>
        {alerts.length > 0 ? (
          <SlackAlerts
            slack_alerts={props.slack_alerts}
            alert_stats={props.alert_stats}
            timestamps={props.timestamps}
          />
        ) : (
          <NotificationEmptyState />
        )}
        <TableFooter
          pageMeta={props.page_meta}
          page={props.page_meta.current_page}
          nextPath={slackAlertsPath(`?page=${props.page_meta.next_page}`)}
          prevPath={slackAlertsPath(`?page=${props.page_meta.prev_page}`)}
        />
      </Stack>
    </PageLayout>
  )
}

function NotificationEmptyState() {
  return (
    <Stack
      width="full"
      rounded="lg"
      bg="gray.50"
      border="1px solid"
      borderColor="gray.200"
      padding="40px"
      alignItems="center"
      spacing={6}
    >
      <CircleIcon icon={IconBell} iconSize={7} padding={2} colorScheme="purple" />
      <Stack alignItems="center" spacing={1}>
        <Heading size="sm">Get started with Slack Alerts</Heading>
        <Text fontSize="sm" textAlign="center">
          Alert your team about the most important intent signals in real-time.
        </Text>
      </Stack>
      <Button as={Link} href={slackAlertsPath('/new')} leftIcon={<IconPlus size={14} />} size="sm" colorScheme="purple">
        Create Slack Alert
      </Button>
    </Stack>
  )
}

interface SlackAlertsTableProps {
  slack_alerts: SlackAlert[]
  alert_stats: Record<string, number>
  timestamps: Record<string, DateTime>
}

function SlackAlerts(props: SlackAlertsTableProps) {
  const alerts = useMemo(() => {
    return orderBy(props.slack_alerts, 'slack_channel_name')
  }, [props.slack_alerts])

  return (
    <TableContainer fontSize="sm" w="100%">
      <Table size="md">
        <Tbody fontSize="sm">
          {alerts.map((alert) => (
            <SlackAlertRow
              key={alert.id}
              alert={alert}
              count={props.alert_stats[alert.id!]}
              timestamp={props.timestamps[alert.id!]}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export function SlackAlertTitle({ alert }: { alert: SlackAlert }) {
  const channelName = useMemo(() => `#${alert.slack_channel_name || alert.slack_channel_id}`, [alert])
  const routedName = useMemo(() => {
    if (alert.channel_routing === 'user' && alert.account_view?.team) {
      return 'Routed alert'
    }
  }, [alert])

  const destinationName = useMemo(() => routedName ?? channelName ?? 'Unknown channel', [routedName, channelName])

  const audience = useMemo(() => audienceName(alert), [alert])

  return (
    <Stack spacing={1}>
      <HStack>
        <Img boxSize={4} src={channelLogos.slack} />
        <Link
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          href={slackAlertPath(alert.id)}
          color={alert.enabled ? undefined : 'gray.600'}
          fontStyle={alert.enabled ? undefined : 'italic'}
        >
          {destinationName} / {audience}
        </Link>
        {routedName && alert.account_view?.team && (
          <Tag colorScheme={'purple'} size="sm">
            {teamName(alert.account_view.team)}
          </Tag>
        )}
      </HStack>
      <HStack color="gray.600" fontSize="xs" spacing={1} divider={<MiddotDivider />}>
        {!alert.enabled && <Text>Paused</Text>}
        <Text>
          Updated <TimeAgo time={alert.updated_at} />
        </Text>
        {alert.updated_by_user ? (
          <Text>Updated by {alert.updated_by_user.name || alert.updated_by_user.email}</Text>
        ) : alert.created_by_user ? (
          <Text>Created by {alert.created_by_user.name || alert.created_by_user.email}</Text>
        ) : null}
        <Text>
          {alert.any_intent
            ? 1
            : alert.any_signal
              ? 2
              : (alert.intent_triggers?.length ?? 0) + (alert.intent_changes?.length ?? 0)}{' '}
          intent{' '}
          {pluralize(
            'trigger',
            alert.any_intent
              ? 1
              : alert.any_signal
                ? 2
                : (alert.intent_triggers?.length ?? 0) + (alert.intent_changes?.length ?? 0)
          )}
        </Text>
      </HStack>
    </Stack>
  )
}

interface SlackAlertRowProps {
  alert: SlackAlert
  count?: number
  timestamp?: DateTime
}

function SlackAlertRow({ alert, count, timestamp }: SlackAlertRowProps) {
  const { isOpen, onToggle } = useDisclosure()
  const { data, isLoading } = useSlackAlertStats(alert.id!, isOpen)

  const hasTrigger = useMemo(() => {
    if (alert.any_intent || alert.any_signal) return true
    if (alert.intent_triggers?.length && alert.intent_triggers?.length > 0) return true
    if (alert.intent_changes?.length && alert.intent_changes?.length > 0) return true

    return false
  }, [alert])

  const onClick = useCallback(
    (e) => {
      if (e.ctrlKey || e.metaKey) {
        return false
      } else if (e.target.tagName === 'A') {
        return false
      } else {
        onToggle()
      }
    },
    [onToggle]
  )

  const openRowCss = {
    borderBottom: 'none',
    paddingBottom: 2
  }

  return (
    <React.Fragment>
      <Tr role="group" onClick={onClick} cursor="pointer">
        <Td width="1px" paddingX={2} {...(isOpen && openRowCss)}>
          <IconButton
            aria-label="Expand/Collapse Row"
            size="xs"
            variant="ghost"
            icon={isOpen ? <IconChevronDown size="14px" /> : <IconChevronRight size="14px" />}
            onClick={onClick}
          />
        </Td>
        <Td paddingLeft={1} {...(isOpen && openRowCss)}>
          <Flex gap={4} alignItems="center" maxWidth="550px">
            <SlackAlertTitle alert={alert} />
            <Button
              marginLeft="auto"
              flex="none"
              size="xs"
              variant="outline"
              as={Link}
              href={slackAlertPath(alert.id)}
              visibility="hidden"
              opacity={0.5}
              _groupHover={{
                opacity: 1,
                visibility: 'visible'
              }}
              transition="opacity 150ms ease-in-out"
              rightIcon={<IconArrowRight size={14} />}
            >
              Edit
            </Button>
          </Flex>
        </Td>
        <Td isNumeric {...(isOpen && openRowCss)}>
          <HStack spacing={1}>
            {count && count > 0 && (
              <Text fontSize="xs" color="gray.600">
                {count ?? ''}x
              </Text>
            )}

            {timestamp && (
              <Text color="gray.500" fontSize="xs">
                (<TimeAgo time={timestamp} />)
              </Text>
            )}

            {!count && !timestamp && <Text>--</Text>}
          </HStack>
        </Td>
        <Td width="1px" isNumeric {...(isOpen && openRowCss)} onClick={(e) => e.stopPropagation()}>
          <OptionsMenu id={alert.id!} editPath={slackAlertPath(alert.id)} />
        </Td>
      </Tr>
      {isOpen && (
        <Tr>
          <Td colSpan={4} paddingLeft={10} paddingTop={0}>
            {isLoading ? (
              <Stack>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            ) : hasTrigger ? (
              <TableContainer fontSize="sm" w="100%" rounded="md" border="1px solid" borderColor="gray.200">
                <Table size="md">
                  <Tbody fontSize="sm">
                    {(data?.active_follow_rules ?? []).map((rule, index) => {
                      const signal = rule.any_intent
                        ? { name: 'Any Activity', signal_type: 'multiple' }
                        : rule.any_signal
                          ? {
                              name: `Any ${rule.target_type.replace('Profile', 'Person')} Intent Signal`,
                              signal_type: 'multiple'
                            }
                          : data?.intent_signals[rule.kql_definition_id!]
                      const notifCount = data?.granular_stats?.[rule.id!]
                      const notifTimestamp = data?.granular_timestamps[rule.id!]
                      const isLast =
                        index === (data?.active_follow_rules ?? []).length - 1 &&
                        (alert.intent_changes?.length ?? 0) === 0

                      if (!signal) {
                        return null
                      }

                      return (
                        <Tr key={rule.id}>
                          <Td paddingLeft={4} borderBottom={isLast ? 'none' : undefined}>
                            <SignalType label={signal.name} signalType={signal.signal_type} compact />
                          </Td>

                          <Td width="1px" isNumeric borderBottom={isLast ? 'none' : undefined}>
                            <HStack spacing={1}>
                              {notifCount && (
                                <Text fontSize="xs" color="gray.600">
                                  {notifCount ?? ''}x
                                </Text>
                              )}

                              {notifTimestamp && (
                                <Text fontSize="xs" color="gray.500">
                                  (<TimeAgo time={notifTimestamp} />)
                                </Text>
                              )}

                              {!notifCount && !notifTimestamp && <Text>--</Text>}
                            </HStack>
                          </Td>
                        </Tr>
                      )
                    })}
                    {alert.intent_changes?.map((trend, index) => {
                      const trendSignal = INTENT_TREND_SIGNALS.find((s) => s.trend === trend)
                      if (!trendSignal) return null
                      const definition = trendSignal?.definition

                      const notifCount = data?.granular_stats?.[`trend_${trend}`]
                      const notifTimestamp = data?.granular_timestamps[`trend_${trend}`]
                      const isLast =
                        index === (alert.intent_changes?.length ?? 0) - 1 &&
                        (data?.active_follow_rules ?? []).length === 0

                      return (
                        <Tr key={trend}>
                          <Td paddingLeft={4} borderBottom={isLast ? 'none' : undefined}>
                            <SignalType
                              label={definition.name}
                              signalType={definition.signal_type}
                              colorScheme={trendSignal.colorScheme}
                              icon={trendSignal.icon}
                              compact
                            />
                          </Td>
                          <Td width="1px" isNumeric borderBottom={isLast ? 'none' : undefined}>
                            <HStack spacing={1}>
                              {notifCount && (
                                <Text fontSize="xs" color="gray.600">
                                  {notifCount ?? ''}x
                                </Text>
                              )}
                              {notifTimestamp && (
                                <Text fontSize="xs" color="gray.500">
                                  (<TimeAgo time={notifTimestamp} />)
                                </Text>
                              )}
                              {!notifCount && !notifTimestamp && <Text>--</Text>}
                            </HStack>
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Box fontSize="sm" w="100%" color="gray.500" rounded="md" border="1px solid" borderColor="gray.200" p={4}>
                <Text>No triggers setup yet</Text>
              </Box>
            )}
          </Td>
        </Tr>
      )}
    </React.Fragment>
  )
}

export function SlackAlertsLimitWarning(props: {
  koala_subscription?: KoalaSubscription
  entitlements?: AllEntitlements
  usage: Usage
}) {
  const limit = props.entitlements?.slack_alerts_monthly ?? 0
  const used = props.usage.slack_alerts?.current.delivered ?? 0
  const skipped = props.usage.slack_alerts?.current.limited ?? 0

  const overLimit = useMemo(() => limit && used >= limit, [limit, used])
  const hasSkipped = useMemo(() => skipped > 0, [skipped])

  const hasUnlimitedAutomations = useMemo(() => {
    return (
      props.entitlements?.automations ||
      props.entitlements?.automations_preview ||
      !props.entitlements?.slack_alerts_monthly
    )
  }, [props.entitlements])

  const progressPct = useMemo(() => {
    if (!used || !limit) {
      return 0
    }

    return Math.round((used / limit) * 100)
  }, [used, limit])

  if (hasUnlimitedAutomations) {
    return null
  }

  if (!overLimit && !hasSkipped) {
    return null
  }

  const colorScheme = progressPct >= 100 ? 'red' : progressPct >= 90 ? 'orange' : 'purple'
  const almostOut = progressPct >= 90

  return (
    <Card bg="white" alignItems="flex-start" rounded="lg" w="100%" p={0}>
      <Stack spacing={0} w="100%">
        {(almostOut || hasSkipped) && (
          <HStack
            spacing={3}
            fontSize="sm"
            paddingX={4}
            paddingY={3}
            bg="orange.50"
            color="orange.900"
            fontWeight="medium"
            roundedTop="lg"
            borderBottom="1px solid"
            borderColor="gray.200"
          >
            <CircleIcon icon={IconGauge} iconSize={5} padding={2} bg="orange.200" color="orange.900" />
            <Text>
              {almostOut
                ? `Your workspace is ${!overLimit ? 'almost ' : ''}out of Slack Alerts this month. `
                : hasSkipped
                  ? `You’re out of Slack Alert credits and have missed ${skipped} alerts this month.`
                  : ''}
              <Link variant="dotted" href={projectPath('/settings/plans')}>
                Compare plans
                <IconExternalLink size={14} />
              </Link>{' '}
              to unlock unlimited Slack Alerts.
            </Text>
          </HStack>
        )}
        <Flex
          w="100%"
          gap={[3, 4, 6, 10]}
          px={4}
          py={4}
          pl={almostOut ? 16 : 4}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack spacing={2} flex="1" minW="300px" maxW="700px" py={1}>
            <Flex gap={2} justifyContent="space-between" alignItems="baseline" lineHeight={1}>
              <Text flex="none" fontSize="sm" fontWeight="semibold">
                Slack Alert credits
              </Text>

              <Flex gap={2} alignItems="baseline" fontSize="xs">
                {skipped > 0 && (
                  <>
                    <Tooltip label="These are the number of Slack Alerts that were skipped due to your credit limit.">
                      <Text
                        cursor="help"
                        color="gray.500"
                        borderBottom="1px dotted transparent"
                        _hover={{ borderBottom: '1px dotted currentColor' }}
                      >
                        {friendlyNumber(skipped)} skipped
                      </Text>
                    </Tooltip>
                    <MiddotDivider />
                  </>
                )}

                <Flex flex="none" gap={1} alignItems="baseline">
                  <Text fontWeight="semibold">{friendlyNumber(used)}</Text>
                  <Text display={['block', 'block', 'none']} color="gray.500">
                    / {friendlyNumber(limit)}
                  </Text>
                  <Text display={['none', 'none', 'block']} color="gray.500">
                    of {friendlyNumber(limit)} alerts / mo
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Progress colorScheme={colorScheme} bg="gray.200" value={used} max={limit} rounded="md" size="xs" />

            <Flex alignItems="center" fontSize="xs" gap="1" color="gray.500">
              <Text>Credits reset on</Text>
              <TimeAgo time={props.koala_subscription?.usage_range.end} mode="calendar" />
            </Flex>
          </Stack>

          {!almostOut && (
            <Button
              flex="none"
              colorScheme={progressPct >= 90 ? 'purple' : undefined}
              variant={progressPct >= 90 ? 'solid' : 'outline'}
              size="sm"
              onClick={() => {
                openUpgradeFlow()
              }}
            >
              Unlock unlimited alerts
            </Button>
          )}
        </Flex>
      </Stack>
    </Card>
  )
}
