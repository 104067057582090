import { useQuery } from 'react-query'
import { concurrentCachedGET } from '../../lib/api'
import { PageMeta } from '../../types/PageMeta'
import { Company } from '../../types/Profile'
import { FacetFilters } from '../pages/accounts'
import { projectPath } from '../ui/ProjectsContext'
import { facetQueryString } from './use-facets'

interface CompaniesResponse {
  companies: Company[]
  page_meta: PageMeta
  meta: {
    total: number
    page: number
    per_page: number
  }
}

interface UseCompaniesOptions {
  facets?: FacetFilters
  page?: number
  per_page?: number
  enabled?: boolean
}

export function useCompanies(options: UseCompaniesOptions = {}) {
  const { facets = {}, page = 1, per_page = 25, enabled = true } = options

  return useQuery<CompaniesResponse>(
    ['companies', facets, page, per_page],
    () => {
      const queryStrings = facetQueryString(facets)
      if (page) {
        queryStrings.push(`page=${page}`)
      }
      if (per_page) {
        queryStrings.push(`per_page=${per_page}`)
      }

      const url = `${projectPath('/companies.json')}?${queryStrings.join('&')}`
      return concurrentCachedGET<CompaniesResponse>(url)
    },
    { enabled }
  )
}
