import { Box, Button, Divider, Flex, FormControl, FormLabel, Grid, Heading, Stack, Text } from '@chakra-ui/react'
import { IconFlare, IconPlus } from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import { AIAgent } from '../../../../types/AIAgent'
import { Play } from '../../../../types/Play'
import { useAIAgents } from '../../../data/use-ai-agents'
import { GrayCard } from '../../../ui/Card'
import { PartialCompany } from '../../../ui/CompanySelector'
import { PartialVisitor } from '../../../ui/VisitorSelector'
import { AgentModal } from '../../ai_agents'
import { AIAgentCard } from '../../ai_agents/components/AIAgentCard'
import { AIAgentColumns } from '../../ai_agents/components/AIAgentColumns'

interface AISetupProps {
  play: Partial<Play>
}

export function AISetup({ play }: AISetupProps) {
  const { data: agents, isLoading, refetch: refetchAgents } = useAIAgents('people')
  const myAgents = useMemo(() => agents?.my_agents ?? [], [agents])

  const [selectedAgents, setSelectedAgents] = useState<AIAgent[]>(
    (play.agent_slugs
      ?.map((slug) => myAgents.find((agent) => agent.slug === slug) ?? null)
      .filter((agent) => agent !== null) as AIAgent[]) ?? []
  )

  useEffect(() => {
    setSelectedAgents(
      (play.agent_slugs
        ?.map((slug) => myAgents.find((agent) => agent.slug === slug) ?? null)
        .filter((agent) => agent !== null) as AIAgent[]) ?? []
    )
  }, [play.agent_slugs, myAgents])

  const [selectedAgent, setSelectedAgent] = React.useState<AIAgent | null>(null)
  const [selectedCompany, setSelectedCompany] = React.useState<PartialCompany | null>(null)
  const [selectedPerson, setSelectedPerson] = React.useState<PartialVisitor | null>(null)

  return (
    <GrayCard flexDirection="column">
      <FormControl as={Stack} spacing={6}>
        <Flex alignItems="center" gap={4}>
          <Flex flex="none" alignItems="center" justifyContent="center" boxSize={6} color="gray.500">
            <IconFlare size={20} />
          </Flex>
          <Box flex="1 1 auto">
            <FormLabel cursor="pointer" mb={0}>
              AI Agent Swarm
            </FormLabel>
            <Text fontSize="13px" lineHeight="19px" color="gray.500">
              Run a swarm of agents to do the heavy lifting of account/prospect research and qualification
            </Text>
          </Box>
        </Flex>

        {selectedAgents.length > 0 && (
          <>
            <Divider />
            <Stack spacing={4}>
              <Heading fontSize="11px" textTransform="uppercase" color="gray.500">
                Selected Agents ({selectedAgents.length})
              </Heading>
              <Grid
                gridAutoRows="1fr"
                gridTemplateColumns="repeat(auto-fill, minmax(250px, 300px))"
                alignItems="stretch"
                gap={3}
                justifyContent="start"
                maxH="300px"
                overflowY="auto"
              >
                {selectedAgents.map((agent) => (
                  <AIAgentCard
                    key={agent.slug}
                    compact
                    agent={agent}
                    isLoading={{}}
                    handleCardClick={() => {
                      setSelectedAgent(agent)
                    }}
                    myAgentsSlugs={myAgents.map((agent) => agent.slug)}
                  />
                ))}
              </Grid>
            </Stack>
          </>
        )}

        <AIAgentColumns
          target="people"
          onChange={async (agent) => {
            await refetchAgents()
            setTimeout(() => {
              setSelectedAgents([agent, ...selectedAgents])
            }, 0)
          }}
          onColumnChange={(columns) => {
            const selected = columns
              .map((column) => {
                const agent = myAgents.find((agent) => agent.slug === column)
                if (!agent) {
                  return null
                }
                return agent
              })
              .filter((agent) => agent !== null) as AIAgent[]

            setSelectedAgents(selected)
          }}
          selectedColumns={selectedAgents.map((agent) => agent.slug)}
          popoverProps={{
            placement: 'right-end'
          }}
        >
          <Button isLoading={isLoading} variant="outline" bg="white" size="md" leftIcon={<IconPlus size={16} />}>
            Add Agent
          </Button>
        </AIAgentColumns>
      </FormControl>

      {selectedAgent && (
        <AgentModal
          isOpen={!!selectedAgent}
          onClose={() => setSelectedAgent(null)}
          selectedAgent={selectedAgent}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          selectedPerson={selectedPerson}
          setSelectedPerson={setSelectedPerson}
        />
      )}

      {selectedAgents.length > 0 && (
        <input type="hidden" name="play[agent_slugs]" value={selectedAgents.map((agent) => agent.slug).join(',')} />
      )}
    </GrayCard>
  )
}
