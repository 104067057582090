import {
  Box,
  Center,
  Collapse,
  Flex,
  Heading,
  IconButton,
  Link,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useUpdateEffect
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronRight, IconExternalLink } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { App } from '../../../../types/App'
import { Play, PlayItem } from '../../../../types/Play'
import { usePlay } from '../../../data/use-plays'
import { Card } from '../../../ui/Card'
import { projectPath } from '../../../ui/ProjectsContext'
import { User } from '../../../ui/UserContext'
import { PlayItemsTable } from '../../plays/components/play-items-table'

interface PlayCardProps {
  play: Play
  apps: App[]
  selectedUser?: Partial<User> | null
}

export function PlayCard({ apps, play, selectedUser }: PlayCardProps) {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })
  const { data, isLoading } = usePlay(play.id, selectedUser?.id)

  const [items, setItems] = useState<PlayItem[]>(data?.play_items ?? [])

  useUpdateEffect(() => {
    setItems(data?.play_items ?? [])
  }, [data?.play_items])

  // Remove item when the status is different from the selected status
  // If it's the same status, we don't need to remove it
  const onRemoveItem = useCallback((itemId: string, status: string) => {
    if (status !== 'pending') {
      setItems((items) => items.filter((item) => item.id !== itemId))
    }
  }, [])

  const onUpdateItem = useCallback((itemId: string, updatedItem: PlayItem) => {
    setItems((items) => items.map((item) => (item.id === itemId ? { ...item, ...updatedItem } : item)))
  }, [])

  return (
    <Card p={0}>
      <Flex
        alignItems="center"
        gap={2}
        paddingX={3}
        paddingY={4}
        // borderBottom="1px solid"
        // borderBottomColor="gray.200"
        onClick={onToggle}
        cursor="pointer"
      >
        <IconButton
          size="sm"
          variant="ghost"
          rounded="full"
          aria-label="Toggle"
          icon={isOpen ? <IconChevronDown size={16} /> : <IconChevronRight size={16} />}
          onClick={onToggle}
        />

        <Stack flex="1" spacing={0}>
          <Heading size="sm">
            {play.name} {data?.stats?.pending ? `(${data.stats.pending.toLocaleString()})` : ''}
          </Heading>
          <Text fontSize="13px" color="gray.500">
            {play.description}
          </Text>
        </Stack>

        <IconButton
          as={Link}
          size="sm"
          variant="ghost"
          rounded="full"
          aria-label="View play details"
          icon={<IconExternalLink size={16} />}
          href={
            selectedUser?.id && selectedUser.id !== 'unassigned'
              ? projectPath(`/plays/${play.id}?user=${selectedUser.id}`)
              : projectPath(`/plays/${play.id}`)
          }
          isExternal
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      </Flex>

      <Collapse in={isOpen}>
        <Box>
          {isLoading && (
            <Center height="100px">
              <Spinner />
            </Center>
          )}

          {!isLoading && (
            <PlayItemsTable
              apps={apps}
              items={items}
              recordType={play.target_type}
              displayColumns={play.display_columns!}
              status="pending"
              hideAssigneeColumn
              onRemoveItem={onRemoveItem}
              onUpdateItem={onUpdateItem}
            />
          )}
        </Box>
      </Collapse>
    </Card>
  )
}
