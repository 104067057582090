import { IconPlus, IconTrash, IconUsers } from '@tabler/icons-react'

import { IconCheck } from '@tabler/icons-react'

import { Tooltip, IconButton, Link, Icon } from '@chakra-ui/react'

import { Flex } from '@chakra-ui/react'

import { Box, Text } from '@chakra-ui/react'
import { Card } from '../../../ui/Card'
import { IconFlareFilled } from '@tabler/icons-react'
import { projectPath } from '../../../ui/ProjectsContext'
import { IconEdit } from '@tabler/icons-react'
import React from 'react'
import { IconPlayerPlay } from '@tabler/icons-react'
import { useOverflow } from '../../../ui/useOverflow'
import { titleize } from '../../accounts/facets/filter-cloud'
import { AIAgent } from '../../../../types/AIAgent'
import { BuildingIcon } from '../../../ui/icons/BuildingIcon'

type Props = {
  agent: AIAgent
  onAdd?: (agent: AIAgent) => void
  setAgentToEdit?: (agent: AIAgent) => void
  setAgentToDelete?: (agent: AIAgent) => void
  isLoading: Record<string, boolean>
  myAgentsSlugs: string[]
  handleCardClick: (agent: AIAgent) => void
  compact?: boolean
}

export const AIAgentCard = ({
  agent,
  onAdd,
  setAgentToEdit,
  setAgentToDelete,
  isLoading,
  myAgentsSlugs,
  handleCardClick,
  compact = false
}: Props) => {
  const { scrollRef, overflowTop, overflowBottom } = useOverflow([agent])

  return (
    <Card
      position="relative"
      key={agent.slug}
      textAlign="center"
      p={compact ? 3 : 5}
      gap={1}
      cursor="pointer"
      role="group"
      _hover={{ shadow: 'md', borderColor: 'purple.500' }}
      style={{ aspectRatio: compact ? 'auto' : '16 / 9' }}
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
      alignItems="stretch"
      maxW={compact ? '100%' : '300px'}
      w="100%"
    >
      <Box
        position="absolute"
        left={0}
        right={0}
        top={compact ? 4 : 8}
        height="60px"
        zIndex={10}
        bgGradient="linear(to-t, rgba(255,255,255,0.1), #FFFFFF)"
        opacity={overflowTop ? 1 : 0}
        transition="opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        pointerEvents="none"
      />
      <Box
        position="absolute"
        left={0}
        right={0}
        bottom={compact ? 4 : 11}
        height="60px"
        zIndex={10}
        bgGradient="linear(to-b, rgba(255,255,255,0.1), #FFFFFF)"
        opacity={overflowBottom ? 1 : 0}
        transition="opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
        pointerEvents="none"
      />

      <Flex flex="1 1 100%" flexDirection="column" gap={2} pb={compact ? 4 : 6}>
        <Text fontSize="xs" color="gray.500" fontWeight="medium" zIndex={20} textAlign="left">
          {agent.name}
        </Text>
        <Text
          fontSize={compact ? 'xs' : 'sm'}
          height="100%"
          maxHeight={compact ? '60px' : '100px'}
          textAlign="left"
          overflowY="auto"
          ref={scrollRef}
          position="relative"
          whiteSpace="pre-line"
        >
          {agent.question}
        </Text>
      </Flex>
      <Flex
        position="absolute"
        justifyContent="space-between"
        alignItems="center"
        bottom={compact ? 2 : 3}
        left={compact ? 2 : 3}
        right={compact ? 2 : 3}
        gap={4}
        zIndex={20}
      >
        <Flex alignItems="center" gap={1}>
          <Tooltip label={titleize(agent.target)}>
            <Flex alignItems="center" color="gray.400" _groupHover={{ color: 'purple.500' }}>
              <Icon as={agent.target === 'companies' ? BuildingIcon : IconUsers} boxSize={4} />
            </Flex>
          </Tooltip>
          {!compact && myAgentsSlugs.includes(agent.slug) && (
            <Flex alignItems="center" gap={1} color="gray.400" _groupHover={{ color: 'purple.500' }}>
              <Icon as={IconFlareFilled} boxSize={3.5} />
              <Text fontSize="xs" fontWeight="medium">
                Added
              </Text>
            </Flex>
          )}
        </Flex>
        <Flex
          p={1}
          gap={0.5}
          bg="white"
          border={compact ? 'none' : '1px solid'}
          borderColor="gray.200"
          rounded="md"
          marginLeft={compact ? 'auto' : undefined}
        >
          <Tooltip label="Test Agent">
            <IconButton
              size="xs"
              aria-label="Test"
              colorScheme={compact ? 'purple' : 'gray'}
              variant="ghost"
              onClick={() => handleCardClick(agent)}
              icon={<IconPlayerPlay size={compact ? 12 : 15} />}
            />
          </Tooltip>
          {myAgentsSlugs.includes(agent.slug) ? (
            <>
              {setAgentToEdit && (
                <Tooltip label="Edit agent">
                  <IconButton
                    size="xs"
                    colorScheme={compact ? 'purple' : 'gray'}
                    variant="ghost"
                    icon={<IconEdit size={compact ? 12 : 15} />}
                    aria-label="Edit"
                    as={Link}
                    href={projectPath(`/settings/agents/${agent.id || agent.slug}`)}
                    onClick={(e) => {
                      if (e.ctrlKey || e.metaKey || e.shiftKey) {
                        return true
                      }
                      e.preventDefault()
                      setAgentToEdit?.(agent)
                      return false
                    }}
                  />
                </Tooltip>
              )}
              {setAgentToDelete && (
                <Tooltip label="Remove agent">
                  <IconButton
                    size="xs"
                    colorScheme="red"
                    variant="ghost"
                    icon={<IconTrash size={15} />}
                    aria-label="Remove"
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setAgentToDelete?.(agent)
                    }}
                  />
                </Tooltip>
              )}
            </>
          ) : (
            <>
              {onAdd && (
                <Tooltip label="Add agent">
                  <IconButton
                    size="xs"
                    aria-label="Add Agent"
                    isDisabled={isLoading[agent.slug] || myAgentsSlugs.includes(agent.slug)}
                    variant="ghost"
                    isLoading={isLoading[agent.slug]}
                    icon={myAgentsSlugs.includes(agent.slug) ? <IconCheck size={15} /> : <IconPlus size={15} />}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      if (!isLoading[agent.slug] && !myAgentsSlugs.includes(agent.slug)) {
                        onAdd?.(agent)
                      }
                    }}
                  />
                </Tooltip>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </Card>
  )
}
