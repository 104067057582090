import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Stack,
  Text,
  Textarea,
  Tooltip
} from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { toast } from 'sonner'
import { useDismissPlayItem } from '../../../data/use-plays'

export const dismissReasons: [string, string][] = [
  ['Wrong territory', 'not_my_territory'],
  ['Not ICP', 'not_in_icp'],
  ['Bad lead', 'bad_lead'],
  ['Already working', 'already_working']
]

interface DismissButtonProps {
  itemId: string
  onSuccess?: (itemId: string, status: string) => void
}

export function DismissButton({ itemId, onSuccess }: DismissButtonProps) {
  const [feedback, setFeedback] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { mutate: dismissItem, isLoading } = useDismissPlayItem({
    onSuccess: () => {
      onSuccess?.(itemId, 'dismissed')
      setIsModalOpen(false)
    },
    onError: (error: any) => {
      toast.error('There was an issue dismissing this lead', { description: error?.body?.message || error?.message })
    }
  })

  const handleDismiss = useCallback(
    (reason: string) => {
      dismissItem({ itemId, reason, feedback: reason === 'other' ? feedback : '' })
    },
    [dismissItem, itemId, feedback]
  )

  return (
    <>
      <Menu>
        <Tooltip label="Dismiss">
          <MenuButton
            as={IconButton}
            aria-label="Dismiss"
            bg="white"
            icon={<IconX size={16} />}
            isLoading={isLoading}
            ml="-px"
          />
        </Tooltip>
        <Portal>
          <MenuList zIndex="popover">
            {dismissReasons.map(([label, reason]) => (
              <MenuItem key={reason} onClick={() => dismissItem({ itemId, reason })}>
                {label}
              </MenuItem>
            ))}
            <MenuDivider />
            <MenuItem onClick={() => setIsModalOpen(true)}>Other</MenuItem>
          </MenuList>
        </Portal>
      </Menu>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dismiss lead</ModalHeader>
          <ModalBody>
            <Stack spacing={4}>
              <Text fontSize="sm" color="gray.700">
                Totally optional, we'd love to know why you're dismissing this lead.
              </Text>
              <Textarea
                placeholder="Share your feedback..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                size="sm"
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" variant="outline" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button size="sm" variant="solid" colorScheme="purple" onClick={() => handleDismiss('other')} ml={3}>
              Dismiss
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
