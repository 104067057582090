import { Icon, IconProps } from '@chakra-ui/react'
import { IconBrandSnowflake, IconFileSpreadsheet, IconPackageExport, IconWorld } from '@tabler/icons-react'
import React from 'react'
import { DiscordIcon } from './DiscordIcon'
import { G2Icon } from './G2Icon'
import { GitHubIcon } from './GitHubIcon'
import { LinkedinBoxIcon } from './LinkedInIcon'
import { Rb2bIcon } from './Rb2bIcon'
import { SlackIcon } from './SlackIcon'

interface Props extends IconProps {
  source?: string
  size?: string | number
}

export function SourceIcon({ source, size, ...props }: Props) {
  size = size ? `${size}px` : size

  switch (source?.toLowerCase()) {
    case 'slack':
      return <SlackIcon size={size} {...props} />
    case 'discord':
      return <DiscordIcon size={size} {...props} />
    case 'linkedin':
    case 'linkedin_xt':
      return <LinkedinBoxIcon size={size} color="linkedin.700" {...props} />
    case 'github':
      return <GitHubIcon size={size} {...props} />
    case 'snowflake':
      return <Icon as={IconBrandSnowflake} boxSize={size} color="#29b5e8" {...props} />
    case 'web':
      return <Icon as={IconWorld} boxSize={size} color="gray.400" {...props} />
    case 'rb2b':
      return <Rb2bIcon size={size as string} />
    case 'g2':
      return <G2Icon size={size} {...props} />
    case 'csv':
      return <Icon as={IconFileSpreadsheet} color="green.600" boxSize={size} {...props} />
    default:
      return <Icon as={IconPackageExport} boxSize={size} {...props} />
  }
}
