import {
  AvatarGroup,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text
} from '@chakra-ui/react'
import React from 'react'
import { User } from '../../types/Invite'
import Avatar from './Avatar'

interface UsersAvatarGroupProps {
  users?: Partial<User>[]
}

export function UsersAvatarGroup(props: UsersAvatarGroupProps) {
  if (!props.users?.length) {
    return null
  }

  return (
    <Popover lazyBehavior="unmount" isLazy trigger="hover" openDelay={600}>
      <PopoverTrigger>
        <AvatarGroup size="xs" fontSize="xs" max={3} spacing={-2}>
          {props.users?.map((user) => (
            <Avatar
              key={user.email}
              src={user.image}
              name={user.name || user.email}
              borderWidth="2px"
              boxSizing="content-box"
            />
          ))}
        </AvatarGroup>
      </PopoverTrigger>
      <Portal>
        <PopoverContent minW={{ base: '100%', lg: 'max-content' }}>
          <PopoverBody bg="transparent">
            <Stack py={1} px={0} spacing={1.5}>
              {props.users?.map((user) => (
                <HStack key={user.id} spacing={2}>
                  <Avatar src={user.image} name={user.name || user.email} size="tiny" />
                  <Text fontSize="sm" fontWeight="medium">
                    {user.name || user.email}
                  </Text>
                </HStack>
              ))}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
