import { ConnectionDetail, ConnectionDetails } from '@app/components/pages/apps/components/ConnectionDetails'
import { ConnectOauthAppDialog } from '@app/components/pages/apps/components/ConnectOauthAppDialog'
import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import { HealthCheck, HealthChecks } from '@app/components/pages/apps/components/health-checks'
import { AuthenticityToken } from '@app/components/ui/AuthenticityToken'
import { ConfigurableAppActions } from '@app/components/ui/ConfigurableAppActions'
import PageDescription from '@app/components/ui/PageDescription'
import PageTitle from '@app/components/ui/PageTitle'
import { usePermission } from '@app/components/ui/PermissionsContext'
import { AppActions } from '@app/types/AppActions'
import { Project } from '@app/types/Project'
import { Badge, Box, Button, Divider, Flex, Heading, HStack, Image, Link, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { Card, LightBgCard } from '../../../ui/Card'
import PageLayout from '../../../ui/PageLayout'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'

interface Props {
  app_id: string
  project: Project
  title: string
  description: string
  logo: string
  valid?: boolean
  connected?: boolean
  actions: AppActions
  deps: {
    health_checks: HealthCheck[]
    scopes: string[]
  }
  connection_details: ConnectionDetail[]
  id: string
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })

  const hasSequenceStateScope = React.useMemo(() => {
    return props.deps.scopes.includes('sequenceStates.all')
  }, [props.deps.scopes])

  const hasMailingScope = React.useMemo(() => {
    return props.deps.scopes.includes('mailings.write') && props.deps.scopes.includes('mailings.read')
  }, [props.deps.scopes])

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/outreach'),
            title: 'Outreach'
          }
        ]}
        offscreen
      />
      <Stack>
        <HStack>
          <HStack marginRight="auto" alignItems="center" spacing={2}>
            <Image src={props.logo} maxW="6" />
            <PageTitle>{props.title}</PageTitle>
            {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
            {props.connected && !props.valid && <Badge colorScheme="orange">Requires Reconnection</Badge>}
          </HStack>

          {props.connected && <DisconnectAppDialog appTitle={'Outreach'} showRemoveCachesOption={false} />}
        </HStack>
        <PageDescription>{props.description}</PageDescription>
      </Stack>

      {!props.connected && <ConnectOauthAppDialog {...props} />}

      {props.connected && (
        <Stack spacing="12" divider={<Divider />}>
          {!hasSequenceStateScope && (
            <LightBgCard>
              <ConnectOauthAppDialog {...props} valid={false}>
                <Stack fontSize={'sm'} spacing="4">
                  <Heading size="sm" fontWeight={'semibold'}>
                    Missing Sequence Management Permissions
                  </Heading>
                  <Text>
                    Your Outreach integration is outdated and missing Sequence management permissions. Please reconnect
                    Outreach in order to add support for Sequence Management from within Koala.
                  </Text>

                  <Button colorScheme={'pink'} type="submit">
                    Reconnect Outreach
                  </Button>
                </Stack>
              </ConnectOauthAppDialog>
            </LightBgCard>
          )}

          {!hasMailingScope && (
            <LightBgCard>
              <ConnectOauthAppDialog {...props} valid={false}>
                <Stack fontSize={'sm'} spacing="4">
                  <Heading size="sm" fontWeight={'semibold'}>
                    Missing Mailing Permissions
                  </Heading>
                  <Text>
                    Your Outreach integration is outdated and missing Mailing permissions. Please reconnect Outreach in
                    order to add support for sending emails from within Koala.
                  </Text>

                  <Button colorScheme={'pink'} type="submit">
                    Reconnect Outreach
                  </Button>
                </Stack>
              </ConnectOauthAppDialog>
            </LightBgCard>
          )}

          <ConnectionDetails appTitle={'Outreach'} valid={props.valid} details={props.connection_details} />
          <HealthChecks appModule={'outreach'} healthChecks={props.deps.health_checks} />

          <Card py="8">
            <Stack spacing="4">
              <HStack>
                <Badge colorScheme={'purple'}>New</Badge>
                <Heading size="sm" fontWeight={'semibold'}>
                  Identity Tracking Outreach Plugin
                </Heading>
              </HStack>
              <Text fontSize="sm">
                Track more email identified traffic by leveraging links that are embedded in your emails. Our plugin
                automatically identifies your prospects when they visit your website from an Outreach email.
              </Text>

              <Text fontSize={'xs'}>
                Note: You need to be logged in to Outreach in order to be able to install the plugin from the App
                Marketplace.
              </Text>

              <Button
                as={Link}
                size="sm"
                isExternal
                colorScheme={'purple'}
                href="https://marketplace.outreach.io/apps/koala-id-links?store=unlisted"
              >
                Install Plugin
              </Button>
            </Stack>

            <Flex fontSize="xs" color="gray.500" pt="2" gap="1">
              <Link isExternal href={'https://getkoala.com/docs/identity-layer/identify-email-recipients'}>
                Learn more about Identified Email Tracking in our docs.
              </Link>
              <Text>No action required if you've already installed the plugin.</Text>
            </Flex>
          </Card>

          <Box
            as="form"
            w="100%"
            method="POST"
            opacity={props.connected ? 1 : 0.4}
            pointerEvents={!props.connected ? 'none' : undefined}
          >
            <AuthenticityToken />
            <input type="hidden" name="_method" value="PUT" />

            {Object.keys(props.actions).length > 0 && (
              <Stack spacing={4}>
                <Box w={'100%'}>
                  <ConfigurableAppActions appActions={props.actions} canEdit={canEditProject} />
                </Box>
                {props.connected && (
                  <Button colorScheme="purple" type="submit" w="100%" isDisabled={!canEditProject}>
                    Save
                  </Button>
                )}
              </Stack>
            )}
          </Box>
        </Stack>
      )}
    </PageLayout>
  )
}
