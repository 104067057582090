import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import pluralize from 'pluralize'
import React, { useCallback, useState } from 'react'
import { toast } from 'sonner'
import { PlayItem } from '../../../../types/Play'
import { useAssignMultiplePlayItems } from '../../../data/use-plays'
import { UserSelector } from '../../../ui/UserSelector'

interface AssignModalProps extends UseDisclosureProps {
  items: PlayItem[]
  onAssign: (items: PlayItem[]) => void
}

export function AssignModal({ items, onAssign, ...props }: AssignModalProps) {
  const disclosure = useDisclosure(props)
  const [userId, setUserId] = useState(items?.[0]?.user_id || null)
  const itemIds = items?.map((i) => i.id)

  const onClose = disclosure.onClose

  const { mutate: assignMultiple, isLoading } = useAssignMultiplePlayItems({
    onSuccess: (res) => {
      onAssign(res.play_items)
      onClose()
    },
    onError: (error: any) => {
      toast.error(`There was an issue assigning these leads`, { description: error?.body?.message || error?.message })
    }
  })

  const handleAssign = useCallback(() => {
    assignMultiple({ itemIds, userId })
  }, [itemIds, userId, assignMultiple])

  return (
    <Modal {...disclosure} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Assign {pluralize('lead', items?.length || 0)}</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Who do you want to assign this to?</FormLabel>
            <UserSelector
              selectedUserId={userId || null}
              onChange={(newAssigneeId) => setUserId(newAssigneeId)}
              allowUnassigned
              variant="outline"
              popperOptions={{ matchWidth: true }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" variant="outline" onClick={disclosure.onClose} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="purple" onClick={handleAssign} isLoading={isLoading} ml={3}>
            Assign
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
