import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import CodeEditor from '@uiw/react-textarea-code-editor'
import { format as friendlyNumber } from 'friendly-numbers'
import { format } from 'sql-formatter'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Code,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Image,
  Input,
  Link,
  ListItem,
  OrderedList,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  UnorderedList
} from '@chakra-ui/react'
import {
  IconCheck,
  IconExclamationMark,
  IconLockCheck,
  IconPlugConnected,
  IconReload,
  IconSparkles,
  IconTable,
  IconX
} from '@tabler/icons-react'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import React, { useMemo, useState } from 'react'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { LightBgCard } from '../../../ui/Card'
import CircleIcon from '../../../ui/CircleIcon'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { Toggle } from '../../accounts/components/Toggle'
import { JSONValue } from '../../kql_definitions/types'

interface Props {
  app_id: string
  title: string
  description: string
  logo: string
  settings: {
    account_url?: string
    account?: string
    organization?: string
    username?: string
    warehouse?: string
    database?: string
    schema?: string
    private_key?: string
    koala_s3_aws_key_id?: string
    koala_s3_aws_secret_key?: string
    data_syncs?: DataSync[]
    data_writes?: DataWrite[]
  }
  secrets: string[]
  connected?: boolean
  deps: {
    last_successful_syncs: Record<string, string>
    data_write_requested: boolean
    data_write_available: boolean
    data_write_models: DataWrite[]
  }
}

interface DataSync {
  id: string
  enabled: boolean
  query: string
  type: 'traits' | 'events'
  object_type: 'Account' | 'Profile'
}

interface IntegrationSync {
  sync_type: 'full' | 'incremental'
  status: string
  started_at: string
  stopped_at?: string
  results?: JSONValue
  pending_count: number
  synced_count: number
  failed_count: number
  skipped_count: number
}

interface DataWrite {
  id: string
  enabled: boolean
  model: string
  title: string
  size: string
  sync_type: 'full' | 'incremental'
  last_sync?: IntegrationSync
}

type TestResult = {
  result?: Array<Record<string, JSONValue>>
  error?: string | null
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const [isChangingPrivateKey, setIsChangingPrivateKey] = useState(false)

  const [accountUrl, setAccountUrl] = useState(props.settings['account_url'] ?? '')
  const [account, setAccount] = useState(props.settings['account'] ?? '')
  const [organization, setOrganization] = useState(props.settings['organization'] ?? '')

  const [queryInput, setQueryInput] = useState(
    'SELECT CURRENT_USER(), CURRENT_ACCOUNT(), CURRENT_REGION(), CURRENT_DATE()'
  )
  const [queryResult, setQueryResult] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [queryError, setQueryError] = useState<string | null>(null)
  const [dataSyncs, setDataSyncs] = useState<DataSync[]>(props.settings.data_syncs ?? [])

  const handleTestQuery = async () => {
    try {
      setIsLoading(true)
      const response = await post<{ result: any }>(projectPath('/apps/snowflake/query'), { query: queryInput })
      setQueryResult(JSON.stringify(response.result, null, 2))
      setQueryError(null)
    } catch (error) {
      setQueryResult(null)
      setQueryError(JSON.stringify(error, null, 2))
    } finally {
      setIsLoading(false)
    }
  }

  const handleTestDataSync = async (dataSync: DataSync) => {
    const type = dataSync.type
    return post<TestResult>(projectPath('/apps/snowflake/query'), { query: dataSync.query, type })
  }

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/snowflake'),
            title: 'Snowflake'
          }
        ]}
        offscreen
      />
      <HStack>
        <Image src={props.logo} maxW="6" />
        <PageTitle>{props.title}</PageTitle>

        {Object.keys(props.settings).length > 0 && (
          <Flex flex="1" justifyContent={'flex-end'}>
            <DisconnectAppDialog appTitle={'Snowflake'} showRemoveCachesOption={false} />
          </Flex>
        )}
      </HStack>
      <PageDescription>{props.description}</PageDescription>

      {props.connected && (
        <>
          <Toggle
            title={
              <HStack mb="2">
                <IconPlugConnected />
                <Heading size="sm" fontWeight={'semibold'}>
                  Connection Test
                </Heading>
              </HStack>
            }
          >
            <Stack as={LightBgCard} bg="gray.50" spacing="4">
              <HStack spacing="4" alignItems={'flex-start'}>
                <FormControl>
                  <FormLabel>Query</FormLabel>
                  <Textarea
                    bg="white"
                    fontSize={'sm'}
                    value={queryInput}
                    onChange={(e) => setQueryInput(e.target.value)}
                    placeholder="Enter your SQL query here..."
                    rows={5}
                    isDisabled={isLoading}
                  />
                  <FormHelperText fontSize={'xs'}>
                    Enter a SQL query to test your connection to Snowflake.
                  </FormHelperText>
                </FormControl>
                {(queryResult || queryError) && (
                  <FormControl>
                    <FormLabel>Result</FormLabel>
                    <Textarea rows={5} value={queryResult ?? queryError ?? ''} isReadOnly fontSize={'sm'} bg="white" />
                    <FormHelperText fontSize={'xs'}>
                      {queryError && (
                        <HStack>
                          <CircleIcon
                            icon={IconExclamationMark}
                            colorScheme="red"
                            p="0"
                            borderColor="red.200"
                            borderWidth="thin"
                          />
                          <Text>Your query returned an error.</Text>
                        </HStack>
                      )}
                      {queryResult && (
                        <HStack>
                          <CircleIcon
                            icon={IconCheck}
                            colorScheme="green"
                            p="0"
                            borderColor="green.200"
                            borderWidth="thin"
                          />
                          <Text>Success!</Text>
                        </HStack>
                      )}
                    </FormHelperText>
                  </FormControl>
                )}
              </HStack>

              <Button
                colorScheme="blue"
                onClick={handleTestQuery}
                mb="4"
                isDisabled={isLoading}
                loadingText="Running Query..."
                isLoading={isLoading}
                size="sm"
              >
                Run Query
              </Button>
            </Stack>
          </Toggle>
          <Divider />
        </>
      )}

      <form method="POST">
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />

        <Stack spacing="4">
          <Stack spacing="8">
            <Toggle
              defaultIsOpen={!props.connected}
              title={
                <Heading size="sm" fontWeight={'semibold'}>
                  🔐 Auth Settings
                </Heading>
              }
            >
              <LightBgCard as={Stack}>
                <Stack spacing={'4'}>
                  <FormControl id="account_url">
                    <FormLabel>Account URL</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[account_url]"
                      placeholder="https://account-organization.snowflakecomputing.com"
                      type="url"
                      required
                      value={accountUrl}
                      onChange={(e) => {
                        setAccountUrl(e.target.value)
                        const url = new URL(e.target.value)
                        const org = url.hostname.split('.')[0]

                        const urlOrganization = org?.split('-')[0]
                        const urlAccount = org?.split('-')[1]

                        if (urlOrganization && urlAccount) {
                          if (account === '' || !account) {
                            setAccount(urlAccount)
                          }

                          if (organization === '' || !organization) {
                            setOrganization(urlOrganization)
                          }
                        }
                      }}
                    />
                    <FormHelperText>
                      Your Snowflake Account URL. e.g. https://account-organization.snowflakecomputing.com
                    </FormHelperText>
                  </FormControl>

                  <FormControl id="account">
                    <FormLabel>Account</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[account]"
                      placeholder="Snowflake Account"
                      required
                      value={account}
                      onChange={(e) => {
                        setAccount(e.target.value)
                      }}
                    />
                    <FormHelperText>
                      Your Snowflake account name. This is typically the first part of your account URL before the `-`.
                      e.g.
                    </FormHelperText>
                  </FormControl>

                  <FormControl id="organization">
                    <FormLabel>Organization</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[organization]"
                      placeholder="Snowflake Organization"
                      value={organization}
                      onChange={(e) => {
                        setOrganization(e.target.value)
                      }}
                    />
                    <FormHelperText>
                      Your Snowflake organization name (if applicable). This is typically the second part of your
                      account URL after the `-`.
                    </FormHelperText>
                  </FormControl>

                  <FormControl id="username">
                    <FormLabel>Username</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[username]"
                      placeholder="Snowflake Username"
                      defaultValue={props.settings['username'] ?? ''}
                      required
                    />
                  </FormControl>

                  <FormControl id="warehouse">
                    <FormLabel>Warehouse</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[warehouse]"
                      placeholder="Snowflake Warehouse"
                      defaultValue={props.settings['warehouse'] ?? ''}
                      required
                    />
                  </FormControl>

                  <FormControl id="database">
                    <FormLabel>Database</FormLabel>
                    <Input
                      bg="gray.50"
                      w="100%"
                      name="app_instance_settings[database]"
                      placeholder="Snowflake Database"
                      defaultValue={props.settings['database'] ?? ''}
                      required
                    />
                  </FormControl>

                  <FormControl id="private_key">
                    <FormLabel>Private Key</FormLabel>
                    {!props.secrets.includes('private_key') || isChangingPrivateKey ? (
                      <Textarea
                        bg="gray.50"
                        w="100%"
                        name="app_instance_settings[private_key]"
                        placeholder="Snowflake Private Key"
                        defaultValue={props.settings['private_key'] ?? ''}
                        rows={16}
                        required
                      />
                    ) : (
                      <HStack>
                        <Input bg="gray.50" w="100%" type="password" isReadOnly value={'***********************'} />
                        <Button
                          variant={'ghost'}
                          colorScheme="purple"
                          size="sm"
                          onClick={() => setIsChangingPrivateKey(true)}
                        >
                          Change
                        </Button>
                      </HStack>
                    )}
                    <FormHelperText>Your Snowflake private key for authentication.</FormHelperText>
                  </FormControl>
                </Stack>
              </LightBgCard>
            </Toggle>

            <Divider />

            <Stack spacing="4">
              <Toggle
                title={
                  <Heading size="sm" fontWeight={'semibold'}>
                    How to set up Snowflake for Koala
                  </Heading>
                }
              >
                <OrderedList fontSize={'sm'} px="4" spacing="4">
                  <li>
                    <Text fontWeight={'semibold'} py="2">
                      Generate an RSA key pair:
                    </Text>
                    <UnorderedList spacing={'4'}>
                      <li>
                        Open a terminal and run:{' '}
                        <Code p="4" my="2" display={'block'}>
                          openssl genrsa 2048 | openssl pkcs8 -topk8 -nocrypt -inform PEM -out rsa_key.p8
                        </Code>
                      </li>
                      <li>
                        Generate the public key:{' '}
                        <Code p="4" my="2" display={'block'}>
                          openssl rsa -in rsa_key.p8 -pubout -out rsa_key.pub
                        </Code>
                      </li>
                    </UnorderedList>
                  </li>
                  <li>
                    <Text fontWeight={'semibold'} py="2">
                      Assign the public key to your Snowflake user:
                    </Text>
                    <UnorderedList spacing={'4'}>
                      <li>Log in to your Snowflake account with admin privileges</li>
                      <li>
                        Copy the contents of the public key file (rsa_key.pub), excluding the "BEGIN PUBLIC KEY" and
                        "END PUBLIC KEY" lines
                        <Code p="4" my="2" display={'block'}>
                          openssl rsa -pubin -in rsa_key.pub -outform DER | openssl base64 -A
                        </Code>
                      </li>
                      <li>
                        Execute the following SQL command in Snowflake:{' '}
                        <Code p="4" my="2" display={'block'}>
                          ALTER USER your_username SET RSA_PUBLIC_KEY='your_public_key_content';
                        </Code>
                        <Text fontSize={'sm'}>
                          <strong>Important:</strong> Snowflake does not like the % character in the public key. If you
                          have a # in your key, remove it before pasting it in.
                        </Text>
                      </li>
                    </UnorderedList>
                  </li>
                  <li>
                    <Text fontWeight={'semibold'} py="2">
                      Enter the required information in the form above:
                    </Text>
                    <UnorderedList spacing={'4'}>
                      <li>
                        Account URL: Your Snowflake account URL (e.g., https://your_account.snowflakecomputing.com)
                      </li>
                      <li>Account: Your Snowflake account name</li>
                      <li>Username: Your Snowflake username</li>
                      <li>Warehouse: The default warehouse to use</li>
                      <li>Database: The default database to use</li>
                      <li>Organization: Your Snowflake organization name (if applicable)</li>
                      <li>
                        Private Key: The contents of your private key file (rsa_key.p8)
                        <UnorderedList spacing={'2'} mt={'2'}>
                          <li>Open the rsa_key.p8 file in a text editor</li>
                          <li>
                            Copy the entire contents, including the "BEGIN PRIVATE KEY" and "END PRIVATE KEY" lines
                          </li>
                          <li>Paste the copied content into the Private Key field in Koala</li>
                          <li>
                            Alternatively, if you're using macOS, you can use the following command to copy the content
                            directly to your clipboard:{' '}
                            <Code p="4" my="2" display={'block'}>
                              pbcopy &lt; rsa_key.p8
                            </Code>
                          </li>
                        </UnorderedList>
                      </li>
                    </UnorderedList>
                  </li>
                  <li>
                    <Text fontWeight={'semibold'} py="2">
                      Click "Save" to store your Snowflake credentials securely in Koala
                    </Text>
                  </li>
                </OrderedList>
              </Toggle>
            </Stack>
            <Divider />

            {props.connected && (
              <Toggle
                defaultIsOpen={true}
                title={
                  <HStack>
                    <IconTable size={16} />
                    <Heading size="sm" fontWeight={'semibold'}>
                      Read data from Snowflake
                    </Heading>
                  </HStack>
                }
              >
                <Stack as={LightBgCard} spacing={8} mt="2">
                  <DataSyncSettings
                    dataSyncs={dataSyncs}
                    setDataSyncs={(dataSyncs) => {
                      setDataSyncs(dataSyncs)
                    }}
                    onTest={handleTestDataSync}
                    lastSuccessfulSyncs={props.deps.last_successful_syncs}
                  />
                  <Button
                    colorScheme="purple"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      setDataSyncs([
                        ...dataSyncs,
                        { enabled: true, query: '', type: 'traits', object_type: 'Profile', id: nanoid() }
                      ])
                    }}
                  >
                    Add New Model
                  </Button>
                </Stack>
              </Toggle>
            )}

            {props.connected && <DataWriteSection settings={props.settings} deps={props.deps} />}
          </Stack>
        </Stack>

        <Flex mt="8">
          <Button colorScheme="purple" type="submit" w="100%" isDisabled={!canEditProject}>
            Save
          </Button>
        </Flex>
      </form>
    </PageLayout>
  )
}

function DataSyncSettings(props: {
  dataSyncs: DataSync[]
  setDataSyncs: (dataSyncs: DataSync[]) => void
  onTest: (dataSync: DataSync) => Promise<TestResult>
  lastSuccessfulSyncs?: Record<string, string>
}) {
  return (
    <Stack spacing={8} divider={<Divider />}>
      {props.dataSyncs.length === 0 && <input type="hidden" name={`app_instance_settings[data_syncs][]`} value={''} />}

      {props.dataSyncs.map((dataSync) => (
        <DataSyncSetting
          onTest={props.onTest}
          key={dataSync.id}
          dataSync={dataSync}
          onUpdate={(updatedDataSync) => {
            const updatedDataSyncs = props.dataSyncs.map((ds) => (ds.id === updatedDataSync.id ? updatedDataSync : ds))
            props.setDataSyncs(updatedDataSyncs)
          }}
          onRemove={() => {
            const updatedDataSyncs = props.dataSyncs.filter((ds) => ds.id !== dataSync.id)
            props.setDataSyncs(updatedDataSyncs)
          }}
          lastSuccessfulSync={props.lastSuccessfulSyncs?.[dataSync.id]}
        />
      ))}
    </Stack>
  )
}

function DataSyncSetting(props: {
  dataSync: DataSync
  onUpdate: (updatedDataSync: DataSync) => void
  onRemove: () => void
  onTest: (dataSync: DataSync) => Promise<TestResult>
  lastSuccessfulSync?: string
}) {
  const handleTypeChange = (value: 'traits' | 'events') => {
    if (value === 'events' && props.dataSync.object_type === 'Account') {
      props.onUpdate({ ...props.dataSync, object_type: 'Profile', type: value })
      return
    }

    props.onUpdate({ ...props.dataSync, type: value })
  }

  const handleObjectTypeChange = (value: 'Account' | 'Profile') => {
    props.onUpdate({ ...props.dataSync, object_type: value })
  }

  const handleQueryChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onUpdate({ ...props.dataSync, query: e.target.value })
  }

  const [isLoading, setIsLoading] = useState(false)
  const [queryResult, setQueryResult] = useState<Array<Record<string, JSONValue>> | null>(null)
  const [queryError, setQueryError] = useState<string | null>(null)

  const handleTestQuery = async () => {
    setIsLoading(true)

    try {
      const result = await props.onTest(props.dataSync)
      setQueryResult(result.result || null)
      setQueryError(null)
    } catch (error) {
      setQueryResult(null)
      setQueryError(JSON.stringify(error, null, 2))
    } finally {
      setIsLoading(false)
    }
  }

  const validationResults = useMemo(() => {
    if (!queryResult) return []
    return validateResults(queryResult, props.dataSync)
  }, [queryResult, props.dataSync])

  const handleEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onUpdate({ ...props.dataSync, enabled: e.target.checked })
  }

  return (
    <Box position="relative">
      <input type="hidden" name={`app_instance_settings[data_syncs][][id]`} value={props.dataSync.id} />
      <input type="hidden" name={`app_instance_settings[data_syncs][][type]`} value={props.dataSync.type} />
      <input
        type="hidden"
        name={`app_instance_settings[data_syncs][][object_type]`}
        value={props.dataSync.object_type}
      />
      <input type="hidden" name={`app_instance_settings[data_syncs][][query]`} value={props.dataSync.query} />
      {props.dataSync.enabled && (
        <input type="hidden" name={`app_instance_settings[data_syncs][][enabled]`} value={'true'} />
      )}
      <Stack spacing={4} w="100%">
        <HStack justifyContent="space-between" w="100%">
          <HStack flex="1" w="100%" alignItems={'flex-start'}>
            <FormControl as="fieldset" flex="2">
              <FormLabel as="legend">Object Type</FormLabel>
              <RadioGroup
                size="sm"
                value={props.dataSync.object_type}
                onChange={handleObjectTypeChange}
                isDisabled={isLoading}
              >
                <Stack direction="column" spacing="0.5">
                  <Radio value="Profile">Profile</Radio>
                  <Radio value="Account">Account</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

            <FormControl as="fieldset" flex="2">
              <FormLabel as="legend">Type</FormLabel>
              <RadioGroup size="sm" value={props.dataSync.type} onChange={handleTypeChange} isDisabled={isLoading}>
                <Stack direction="column" spacing="0.5">
                  <Radio value="traits">Traits</Radio>
                  <Radio value="events">Events</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>

            <FormControl flex="1" gap="2">
              <FormLabel htmlFor={`enabled-${props.dataSync.id}`} mb="0">
                Enabled
              </FormLabel>
              <Switch
                id={`enabled-${props.dataSync.id}`}
                isChecked={props.dataSync.enabled}
                onChange={handleEnabledChange}
                colorScheme="purple"
              />
            </FormControl>
          </HStack>
        </HStack>

        {props.dataSync.type === 'events' && (
          <Toggle
            title={
              <Heading size="xs" fontWeight={'semibold'}>
                💡 Event Schema Tips
              </Heading>
            }
          >
            <Stack spacing={2} fontSize={'sm'} borderLeftWidth="3px" pt="2" pl="8" px="4" ml="10px">
              <UnorderedList spacing="2">
                <ListItem>
                  Each row must contain an <Code>event_name</Code> column to be used to identify the event.
                </ListItem>
                <ListItem>
                  Each row must contain a <Code>timestamp</Code> column. The timestamp is used as a checkpoint between
                  syncs to determine which events were sent after the last sync.
                </ListItem>
                {props.dataSync.object_type === 'Profile' && (
                  <ListItem>
                    Each row must contain an <Code>email</Code> column to identify the profile.
                  </ListItem>
                )}
                {props.dataSync.object_type === 'Account' && (
                  <ListItem>
                    Each row must contain a <Code>domain</Code> column to identify the account.
                  </ListItem>
                )}
                <ListItem>
                  (Optional) Each row can contain a <Code>message_id</Code> column to identify the event. Koala will use
                  this key to deduplicate events. Otherwise it'll use a hash of the event_name + properties + timestamp.
                </ListItem>
                <ListItem>
                  (Optional) Each row can contain a <Code>properties</Code> column to specify event properties. The
                  properties object must either be of the JSON type, or contain a JSON string.
                </ListItem>
                <ListItem>
                  (Optional) Each row can contain a <Code>source</Code> column to specify where the event was sent from.
                </ListItem>
              </UnorderedList>
            </Stack>
          </Toggle>
        )}

        <FormControl>
          <FormLabel>Query</FormLabel>
          <CodeEditor
            value={props.dataSync.query}
            onChange={handleQueryChange}
            language="sql"
            placeholder="Enter your SQL query here"
            padding={15}
            style={{
              backgroundColor: '#f5f5f5',
              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace'
            }}
          />
        </FormControl>

        <Text fontSize={'xs'}>
          Last successful sync:
          {props.lastSuccessfulSync ? dayjs(props.lastSuccessfulSync).format('MMM D, YYYY HH:mm:ss') : '--'}
        </Text>

        <Flex justifyContent={'flex-end'} gap="2">
          <Button
            size="sm"
            variant="outline"
            colorScheme="gray"
            as={Link}
            href={projectPath(`/apps/snowflake/logs?data_model_id=${props.dataSync.id}`)}
            leftIcon={<IconTable size={16} />}
            isDisabled={isLoading}
          >
            View Logs
          </Button>
          <Button
            size="sm"
            variant="outline"
            colorScheme="blue"
            onClick={handleTestQuery}
            isLoading={isLoading}
            loadingText="Testing..."
            leftIcon={<IconReload size={16} />}
          >
            Test Model
          </Button>
          <Tooltip label="Prettify SQL">
            <IconButton
              size="sm"
              variant="outline"
              colorScheme="pink"
              icon={<IconSparkles size={16} />}
              aria-label="Prettify SQL"
              onClick={() => {
                const formattedQuery = format(props.dataSync.query, {
                  language: 'snowflake',
                  keywordCase: 'upper',
                  dataTypeCase: 'upper',
                  functionCase: 'upper',
                  identifierCase: 'lower'
                })
                props.onUpdate({ ...props.dataSync, query: formattedQuery })
              }}
              isDisabled={isLoading}
            />
          </Tooltip>
        </Flex>

        {validationResults.length > 0 && <ValidationResults results={validationResults} />}
        {queryResult && <ResultTable data={queryResult} />}
        {queryError && <Textarea value={queryError} isReadOnly />}
      </Stack>
      <Tooltip label="Remove data model">
        <IconButton
          aria-label="Remove data model"
          icon={<IconX size={14} />}
          size="xs"
          variant="ghost"
          position="absolute"
          top="0"
          right="0"
          onClick={props.onRemove}
          isDisabled={isLoading}
        />
      </Tooltip>
    </Box>
  )
}

function DataWriteSection(props: { settings: Props['settings']; deps: Props['deps'] }) {
  const [isLoading, setIsLoading] = useState(false)
  const [available, setAvailable] = useState(props.deps.data_write_available)
  const [requested, setRequested] = useState(props.deps.data_write_requested)
  const [models, setModels] = useState(props.deps.data_write_models)

  const handleEnableDataWrites = async () => {
    try {
      setIsLoading(true)
      const response = await post<Props['deps']>(projectPath('/apps/snowflake/request-data-write'))
      setAvailable(response.data_write_available)
      setRequested(response.data_write_requested)
      setModels(response.data_write_models)
      toast.success(`Activation requested successfully`, {
        description: `You will be notified when Data Write is available to use`
      })
    } catch ({ message, body }) {
      toast.error(`Error requesting activation`, {
        description: (body && body['error']) || message
      })
    } finally {
      setIsLoading(false)
    }
  }

  const enabledModels = () => models.filter((m) => m.enabled)

  const handleEnableModel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModels((models) =>
      models.map((m) => {
        return m.model == event.target.value ? { ...m, enabled: event.target.checked } : m
      })
    )
  }

  return (
    <Toggle
      defaultIsOpen={true}
      title={
        <HStack>
          <IconTable size={16} />
          <Heading size="sm" fontWeight={'semibold'}>
            Send data to Snowflake
          </Heading>
        </HStack>
      }
    >
      <Stack as={LightBgCard} spacing={8} mt="2">
        <Toggle
          title={
            <Heading size="xs" fontWeight={'semibold'}>
              💡 How data is loaded into Snowflake:
            </Heading>
          }
        >
          <Stack spacing={2} fontSize={'sm'} borderLeftWidth="3px" pt="2" pl="8" px="4" ml="10px">
            <UnorderedList spacing="2">
              <ListItem>
                <b>Overview:</b> an automated replication process that send data from your Koala's workspace to
                respective tables in your Snowflake instance.
              </ListItem>
              <ListItem>
                <b>Frequency</b>: the sync process runs two times per day, at 9:30am and 9:30pm (UTC). After enabling a
                model, it will be loaded on the next sync window mentioned.
              </ListItem>
              <ListItem>
                <b>Strategy</b>: we offer two strategies: <Code>full</Code>: copy the whole data each time and{' '}
                <Code>incremental</Code>: copy just the data generated between sync windows.
              </ListItem>
              <ListItem>
                <b>Costs</b>: Koala will perform a full data dump based on the models you select. The initial data load
                will likely incur Snowflake credits. You can verify the size of the initial data dump of each model in
                order to estimate the initial data loading costs.
              </ListItem>
              <ListItem>
                <b>Requirements</b>: this process uses the{' '}
                <Link href="https://docs.snowflake.com/en/user-guide/data-load-s3" target="_blank">
                  Bulk loading from Amazon S3
                </Link>{' '}
                strategy. So, please make sure that user <Code>{props.settings.username}</Code> have granted privileges
                on <Code>{props.settings.database}</Code> to:
                <br />- <Code>CREATE SCHEMA</Code>
                <br />- <Code>CREATE | ALTER | DROP TABLE</Code> (on the schema)
                <br />- <Code>CREATE STAGE</Code>
                <br />- <Code>COPY INTO TABLE</Code> (from stage)
              </ListItem>
            </UnorderedList>
          </Stack>
        </Toggle>

        <Stack spacing={8} divider={<Divider />}>
          {!available && (
            <>
              {requested ? (
                <p>
                  Your request has been received. We are configuring the connection between your Koala data and
                  Snowflake instance. A confirmation notification will be sent within the next few hours.
                </p>
              ) : (
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="blue"
                  onClick={handleEnableDataWrites}
                  isLoading={isLoading}
                  loadingText="Requesting..."
                  leftIcon={<IconLockCheck size={16} />}
                >
                  Request Activation
                </Button>
              )}
            </>
          )}

          {available && (
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>
                      <b>Enabled</b>
                    </Th>
                    <Th>
                      <b>Model</b>
                    </Th>
                    <Th>
                      <b>Last sync</b>
                    </Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {models.map((dataWrite) => (
                    <DataWriteModel key={dataWrite.model} model={dataWrite} onChange={handleEnableModel} />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Stack>
        {enabledModels().length === 0 && (
          <input type="hidden" name={`app_instance_settings[data_writes][]`} value={''} />
        )}
      </Stack>
    </Toggle>
  )
}

function DataWriteModel(props: { model: DataWrite; onChange: (event: React.ChangeEvent<HTMLInputElement>) => void }) {
  const model = props.model

  return (
    <Tr>
      <Td>
        <FormControl flex="1" gap="2">
          <Switch
            isChecked={model.enabled}
            onChange={props.onChange}
            colorScheme="purple"
            name={`app_instance_settings[data_writes][]`}
            value={model.model}
          />
        </FormControl>
      </Td>
      <Td>
        <Stack spacing={0.5} flex="1" alignItems="flex-start" isTruncated>
          <Flex alignItems="center" maxW="100%" gap={1.5}>
            <TextEllipsis fontWeight="medium" maxW="100%" tooltip>
              {model.title}
            </TextEllipsis>
          </Flex>

          <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
            Type: {model.sync_type}, size: {model.size}
          </TextEllipsis>
        </Stack>
      </Td>
      <Td>
        {model.last_sync && (
          <>
            {model.last_sync?.status}
            <br />
            <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
              <TimeAgo time={model.last_sync?.started_at} />
            </TextEllipsis>
          </>
        )}
      </Td>
      <Td>
        {model.last_sync && (
          <>
            Loaded: {friendlyNumber(model.last_sync?.synced_count)}
            <br />
            <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
              Skipped: {friendlyNumber(model.last_sync?.skipped_count)}, Errors:{' '}
              {friendlyNumber(model.last_sync?.failed_count)}
            </TextEllipsis>
          </>
        )}
      </Td>
    </Tr>
  )
}

function ValidationResults({ results }: { results: string[] }) {
  if (results.length === 0) return null

  return (
    <Stack>
      <Alert status="error" size="sm">
        <AlertIcon />
        <AlertTitle fontSize={'sm'}>Validation Errors</AlertTitle>
        <AlertDescription fontSize={'sm'}>
          The following errors were found in the results of your query:
        </AlertDescription>
      </Alert>
      <UnorderedList p="4" fontSize={'sm'}>
        {results.map((result) => (
          <ListItem key={result} color="red.500">
            {result}
          </ListItem>
        ))}
      </UnorderedList>
    </Stack>
  )
}

function ResultTable({ data }: { data: Array<Record<string, JSONValue>> }) {
  if (!data || data.length === 0) return null

  const columns = Object.keys(data[0])

  return (
    <Stack>
      <TableContainer overflowX="auto" maxHeight="300px" overflowY="auto" sx={{ overscrollBehavior: 'contain' }}>
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={column}>{column}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, index) => (
              <Tr key={index}>
                {columns.map((column) => (
                  <Td key={column}>
                    {typeof row[column] === 'object' && row[column] !== null
                      ? JSON.stringify(row[column])
                      : row[column]}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Text fontSize={'xs'}>Showing {data.length} sample rows</Text>
    </Stack>
  )
}

function validateResults(results: Array<Record<string, JSONValue>>, dataSync: DataSync) {
  if (dataSync.type === 'traits' && dataSync.object_type === 'Profile') {
    return validateProfileTraits(results)
  }

  if (dataSync.type === 'traits' && dataSync.object_type === 'Account') {
    return validateAccountTraits(results)
  }

  if (dataSync.type === 'events' && dataSync.object_type === 'Profile') {
    return validateProfileEvents(results)
  }

  if (dataSync.type === 'events' && dataSync.object_type === 'Account') {
    return validateAccountEvents(results)
  }

  return []
}

function validateAccountEvents(results: Array<Record<string, JSONValue>>) {
  const errors: string[] = []

  const hasDomain = results.every((result) => {
    return 'domain' in result
  })

  if (!hasDomain) {
    errors.push('Account queries must contain a domain column identifier')
  }

  const hasTimestamp = results.every((result) => {
    return (
      'timestamp' in result &&
      typeof result.timestamp === 'string' &&
      dayjs(result.timestamp, 'YYYY-MM-DD HH:mm:ss', true).isValid()
    )
  })

  if (!hasTimestamp) {
    errors.push('Account queries must contain a timestamp column identifier')
  }

  const hasEventName = results.every((result) => {
    return 'event_name' in result
  })

  if (!hasEventName) {
    errors.push('Account queries must contain an event_name column identifier')
  }

  return errors
}

function validateProfileEvents(results: Array<Record<string, JSONValue>>) {
  const errors: string[] = []

  const hasEmail = results.every((result) => {
    return 'email' in result
  })

  if (!hasEmail) {
    errors.push('Profile queries must contain an email column identifier')
  }

  const hasTimestamp = results.every((result) => {
    return (
      'timestamp' in result &&
      typeof result.timestamp === 'string' &&
      dayjs(result.timestamp, 'YYYY-MM-DD HH:mm:ss', true).isValid()
    )
  })

  if (!hasTimestamp) {
    errors.push('Profile queries must contain a timestamp column identifier')
  }

  const hasEventName = results.every((result) => {
    return 'event_name' in result
  })

  if (!hasEventName) {
    errors.push('Profile queries must contain an event_name column identifier')
  }

  return errors
}

function validateAccountTraits(results: Array<Record<string, JSONValue>>) {
  const errors: string[] = []

  const hasDomain = results.every((result) => {
    return 'domain' in result
  })

  if (!hasDomain) {
    errors.push('Account queries must contain a domain column identifier')
  }

  return errors
}

function validateProfileTraits(results: Array<Record<string, JSONValue>>) {
  const errors: string[] = []

  const hasEmail = results.every((result) => {
    return 'email' in result
  })

  if (!hasEmail) {
    errors.push('Profile queries must contain an email column identifier')
  }

  return errors
}
