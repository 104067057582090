import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Flex,
  Grid,
  Image,
  LinkBox,
  LinkOverlay,
  ListItem,
  Stack,
  Text,
  UnorderedList
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react'
import React, { useCallback, useState } from 'react'
import { getFaviconUrl } from '../../../../lib/favicon'
import { AIAgent } from '../../../../types/AIAgent'
import { useCreateAIAgentMetric } from '../../../data/use-ai-agent-metrics'
import { Card } from '../../../ui/Card'
import { LoadingPhrases } from '../../../ui/LoadingPhrases'
import MarkdownText from '../../../ui/MarkdownText'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useOverflow } from '../../../ui/useOverflow'

interface Props extends BoxProps {
  response: any
  isLoading: boolean
  agent: AIAgent | null
  sourcesPerRow?: 1 | 2 | 3
  ref?: React.RefObject<HTMLDivElement>
}

export const AIAgentResponse = (props: Props) => {
  const { response, isLoading, agent, sourcesPerRow = 3, ...rest } = props

  const [showReasoning, setShowReasoning] = useState(false)
  const { scrollRef, overflowTop, overflowBottom } = useOverflow([response])

  const { mutateAsync: recordAgentMetric } = useCreateAIAgentMetric()

  const recordAgentReasoningMetric = useCallback(() => {
    if (agent?.id) {
      recordAgentMetric({
        assistant_id: agent.id,
        metric_type: 'agent_reasoning_view',
        response_key: response?.response_key,
        target_type: response?.target_type,
        target_id: response?.target_id
      })
    }
  }, [agent, recordAgentMetric, response?.response_key, response?.target_type, response?.target_id])

  if (isLoading) {
    return <LoadingPhrases isLoading={isLoading} />
  }

  return (
    <Stack spacing={8} {...rest} ref={scrollRef}>
      {response?.short_answer && (
        <>
          <Box
            position="absolute"
            left={0}
            right={0}
            top="150px"
            height="60px"
            zIndex={10}
            bgGradient="linear(to-t, rgba(255,255,255,0.1), #FFFFFF)"
            opacity={overflowTop ? 1 : 0}
            transition="opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
            pointerEvents="none"
          />
          <Box
            position="absolute"
            left={0}
            right={0}
            bottom={0}
            height="60px"
            zIndex={10}
            bgGradient="linear(to-b, rgba(255,255,255,0.1), #FFFFFF)"
            opacity={overflowBottom ? 1 : 0}
            transition="opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
            pointerEvents="none"
          />

          <Stack>
            <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
              Short Answer
            </Text>
            <MarkdownText>{response.short_answer}</MarkdownText>
          </Stack>
          <Stack>
            <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
              Long Answer
            </Text>
            <Box fontSize="15px" lineHeight={1.5}>
              <MarkdownText>{response.detailed_answer}</MarkdownText>
            </Box>
          </Stack>

          {(response.sources || []).length > 0 && (
            <Stack>
              <Text fontSize="11px" color="gray.400" textTransform="uppercase" fontWeight="medium">
                Sources
              </Text>
              <Grid gridAutoRows="1fr" gridTemplateColumns={`repeat(${sourcesPerRow}, minmax(0, 1fr))`} gap={2}>
                {response.sources.map((source) => {
                  let hostname = source.url

                  try {
                    hostname = source.url.startsWith('http')
                      ? new URL(source.url).hostname
                      : new URL(`https://${source.url}`).hostname
                  } catch (error) {
                    console.error(error)
                  }

                  return (
                    <Card
                      key={source.url}
                      as={LinkBox}
                      display="flex"
                      flexDirection="column"
                      alignSelf="stretch"
                      justifyContent="space-between"
                      p={2}
                      borderRadius="md"
                      bg="gray.50"
                    >
                      <LinkOverlay href={source.url} isExternal>
                        <Text fontSize="xs" fontWeight="medium">
                          {source.title}
                        </Text>
                      </LinkOverlay>
                      <Flex alignItems="center" gap={1}>
                        <Image width="16px" height="16px" rounded="full" src={getFaviconUrl(hostname)} />
                        <Text
                          flex="1 1 auto"
                          minW="100px"
                          fontSize="xs"
                          fontWeight="medium"
                          color="gray.500"
                          isTruncated
                        >
                          {hostname}
                        </Text>
                      </Flex>
                    </Card>
                  )
                })}
              </Grid>
            </Stack>
          )}

          {response.reasoning_steps && (
            <Stack>
              <Button
                color="gray.400"
                textTransform="uppercase"
                fontWeight="medium"
                fontSize="11px"
                size="sm"
                variant="link"
                iconSpacing={1.5}
                leftIcon={showReasoning ? <IconChevronDown size={14} /> : <IconChevronRight size={14} />}
                onClick={() => {
                  setShowReasoning((prev) => {
                    if (!prev) {
                      recordAgentReasoningMetric()
                    }
                    return !prev
                  })
                }}
                mb={2}
                display="flex"
                justifyContent="flex-start"
                marginBottom={0}
              >
                Reasoning
              </Button>
              <Collapse in={showReasoning}>
                <UnorderedList fontSize="sm" marginStart={4} spacing={1}>
                  {response.reasoning_steps.map((step) => (
                    <ListItem key={step}>
                      <Text>{step}</Text>
                    </ListItem>
                  ))}
                </UnorderedList>
              </Collapse>
            </Stack>
          )}

          <Box>
            {response.response_updated_at && (
              <Text color="gray.400" fontSize="sm" marginTop={1}>
                Last Run: <TimeAgo time={response.response_updated_at} />
              </Text>
            )}

            {agent?.model && (
              <Text color="gray.400" fontSize="sm">
                Model: {agent.model}
              </Text>
            )}
          </Box>
        </>
      )}
    </Stack>
  )
}
