import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  Link,
  Progress,
  Radio,
  RadioGroup,
  SlideFade,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import { IconFlareFilled, IconSparkles } from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import { getFlagEmoji } from '../../lib/flag-emoji'
import { Account } from '../../types/Account'
import { ProfileRecord } from '../../types/Profile'
import { useUserApps } from '../data/use-apps'
import { useFacets } from '../data/use-facets'
import { useProfileSearch } from '../data/use-profile-search'
import { profilePath } from '../pages/profiles/lib/path'
import AIRecommendationOutput, { useRecommendation, useRecommendationStatus } from './AIRecommendationOutput'
import Avatar from './Avatar'
import { LinkedinBoxIcon } from './icons'
import { loadingPhrases } from './LoadingPhrases'
import { TextEllipsis } from './text-ellipsis'
import { useCurrentUser } from './UserContext'

const ProfileList = ({ onSelect, account }: { onSelect: (person: any) => void; account: Account }) => {
  const facets = useFacets({
    range: 'month',
    facet_filters: {
      identified: 'true'
    },
    facetCloudPath: '/profiles/facet-cloud',
    sortBy: 'last_seen'
  })

  const { data, isLoading: _isLoadingProfiles } = useProfileSearch({
    accountId: account.id,
    columns: ['email', 'name', 'display_name', 'simple_location', 'title', 'company', 'linkedin_url'],
    queryString: facets.queryString
  })
  const topProfiles = useMemo(() => data?.profiles?.slice(0, 3), [data])

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th width="40px" padding={2}></Th>
          <Th>Name</Th>
        </Tr>
      </Thead>
      <Tbody>
        {topProfiles?.map((person) => (
          <Tr key={person.id} cursor="pointer" _hover={{ bg: 'gray.50' }}>
            <Td padding={2} onClick={() => onSelect(person)}>
              <Radio value={person.id} />
            </Td>
            <Td onClick={() => onSelect(person)}>
              <Flex gap={2} alignItems="center" paddingY={1} minW="250px" maxW="340px">
                <Avatar
                  size="sm"
                  name={person.name || person.display_name || 'Anonymous'}
                  src={person.image || person.avatar}
                />

                <Stack spacing={0.5} flex="1" alignItems="flex-start" isTruncated>
                  <Flex alignItems="center" gap={1.5}>
                    <Link
                      display="inline-flex"
                      href={profilePath(person)}
                      isExternal
                      _hover={{ textDecoration: 'underline' }}
                    >
                      <TextEllipsis fontWeight="medium" maxW="100%" tooltip>
                        {person.name || person.display_name || 'Anonymous'}
                      </TextEllipsis>
                    </Link>

                    <Flex alignItems="center" gap={1}>
                      {person.linkedin_url && (
                        <Tooltip label={`https://${person.linkedin_url.replace(/https?:\/\//, '')}`}>
                          <Link
                            display="flex"
                            flex="none"
                            alignItems="center"
                            color="linkedin.700"
                            isExternal
                            href={`https://${person.linkedin_url.replace(/https?:\/\//, '')}`}
                            onClick={(e) => {
                              e.stopPropagation()
                              window.ko?.track('LinkedIn Profile Visit Action', {
                                app: 'linkedin',
                                email: person.email
                              })
                            }}
                          >
                            <LinkedinBoxIcon boxSize="18px" />
                          </Link>
                        </Tooltip>
                      )}
                    </Flex>
                  </Flex>

                  {person.title && (
                    <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
                      {person.title}
                    </TextEllipsis>
                  )}

                  {!person.title && person.simple_location && (
                    <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
                      {person.simple_location} {getFlagEmoji((person.person?.geo || person.simple_geo)?.countryCode)}
                    </TextEllipsis>
                  )}
                </Stack>
              </Flex>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

interface RecommendationSidebarProps {
  account: Account
  recommendation: any
}

export function RecommendationSidebar(props: RecommendationSidebarProps): JSX.Element | null {
  const [showRecommendation, setShowRecommendation] = useState(false)
  const [loadingPhrase, setLoadingPhrase] = useState(loadingPhrases[0])
  const [showRecommendationWhenReady, setShowRecommendationWhenReady] = useState(false)
  const currentUser = useCurrentUser()
  const [selectedPerson, setSelectedPerson] = useState<ProfileRecord>()

  const { data: response, isLoading, refetch } = useRecommendation(props.account.domain)
  const jobId = (response as any)?.job_id || props.recommendation?.job_id
  const recommendation = useRecommendationStatus(jobId, props.account.domain)
  const output = props.recommendation?.output || recommendation?.output

  if (output?.json_metadata && typeof output.json_metadata === 'string') {
    try {
      output.json_metadata = JSON.parse(output.json_metadata)
    } catch (error) {
      output.json_metadata = {}
      console.error('Error parsing JSON metadata', error)
    }
  }

  const loading = isLoading || (!output && jobId)

  const { data: userApps } = useUserApps()
  const hasGmail =
    userApps?.user_apps?.some((app) => app.app_module === 'UserApps::Gmail::App' && app.connected) || false

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoadingPhrase((prev) => {
          const currentIndex = loadingPhrases.indexOf(prev)
          return loadingPhrases[(currentIndex + 1) % loadingPhrases.length]
        })
      }, 5000)

      return () => clearInterval(interval)
    }

    if (!loading && showRecommendationWhenReady) {
      setShowRecommendationWhenReady(false)
      setShowRecommendation(true)
    }
  }, [loading, showRecommendationWhenReady])

  if (!currentUser.isInternalUser) {
    return null
  }

  if (output) {
    return (
      <>
        <Button
          flex="none"
          variant="outline"
          onClick={() => setShowRecommendation(true)}
          leftIcon={<Icon as={IconFlareFilled} boxSize={5} color="purple.500" />}
          justifyContent="flex-start"
          py={6}
          ps={5}
          fontWeight="semibold"
        >
          Show Recommendation
        </Button>

        <Drawer
          size="lg"
          placement="right"
          isOpen={showRecommendation}
          preserveScrollBarGap
          onClose={() => setShowRecommendation(false)}
        >
          <DrawerOverlay zIndex="modal" />

          <DrawerContent>
            <DrawerHeader>
              <Heading size="md" display="flex" gap={2} alignItems="center">
                Recommendation Output
              </Heading>
            </DrawerHeader>

            <DrawerCloseButton />

            <Box py="4">
              <DrawerBody paddingTop={0} paddingBottom={10}>
                <AIRecommendationOutput
                  key={selectedPerson?.id}
                  output={output}
                  hasGmail={hasGmail}
                  targetEmail={selectedPerson?.email as string}
                  linkedin_url={selectedPerson?.linkedin_url as string}
                  accountId={props.account?.id}
                >
                  <Box bg="white" p={4} mb={4} rounded="lg" border="1px solid" borderColor="gray.200">
                    <Stack spacing={3}>
                      <Heading size="xs">Suggested contacts</Heading>
                      <RadioGroup value={selectedPerson?.id}>
                        <ProfileList onSelect={setSelectedPerson} account={props.account} />
                      </RadioGroup>
                    </Stack>
                  </Box>
                </AIRecommendationOutput>
              </DrawerBody>
            </Box>
          </DrawerContent>
        </Drawer>
      </>
    )
  }

  if (!output && !loading) {
    return (
      <Button
        flex="none"
        variant="outline"
        leftIcon={<IconSparkles size={16} />}
        isLoading={loading}
        onClick={() => {
          refetch()
          setShowRecommendationWhenReady(true)
        }}
        justifyContent="flex-start"
        py={6}
        ps={5}
        fontWeight="semibold"
      >
        Fetch Recommendation
      </Button>
    )
  }

  if (loading) {
    return (
      <>
        <Box height="20px">
          <SlideFade
            in={true}
            key={loadingPhrase} // Key prop triggers animation on phrase change
            transition={{ enter: { duration: 0.3, ease: 'linear' } }}
            offsetY="4px"
          >
            <Text fontSize="sm" textAlign="center" color="gray.500">
              {loadingPhrase}
            </Text>
          </SlideFade>
        </Box>
        <Progress height="3px" color="gray.200" isIndeterminate rounded="full" />
      </>
    )
  }

  return null
}
