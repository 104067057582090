import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { IconDots, IconEdit, IconPencil, IconPlus, IconTrash, IconUsers } from '@tabler/icons-react'
import React, { FormEventHandler, useState } from 'react'
import { postForm } from '../../../lib/api'
import router from '../../../lib/router'
import { Play, PlayConfigPreset, PlayTargetType } from '../../../types/Play'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import { BuildingIcon, GitHubIcon, LinkedinIcon } from '../../ui/icons'
import { MiddotDivider } from '../../ui/Middot'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import SettingsHeader from '../../ui/SettingsHeader'
import SquareIcon, { SquareIconProps } from '../../ui/SquareIcon'
import { TimeAgo } from '../../ui/TimeAgo'
import { TopBarContent } from '../../ui/TopBarContext'
import { UsersAvatarGroup } from '../../ui/UsersAvatarGroup'

interface IndexProps {
  plays: Array<Play & { pending: number }>
}

interface PlaySourceIconProps extends Omit<SquareIconProps, 'icon'> {
  targetType: PlayTargetType
  targetSource?: PlayConfigPreset
}

export function PlaySourceIcon({ targetType, targetSource, iconSize = 4, ...props }: PlaySourceIconProps) {
  if (targetSource === 'github') {
    return <SquareIcon icon={GitHubIcon} iconSize={iconSize} bg="gray.100" color="gray.800" {...props} />
  }

  if (targetSource === 'linkedin') {
    return <SquareIcon icon={LinkedinIcon} iconSize={iconSize} bg="linkedin.50" color="linkedin.700" {...props} />
  }

  if (targetType === 'Account') {
    return <SquareIcon icon={BuildingIcon} iconSize={iconSize} bg="gray.100" color="gray.800" {...props} />
  } else {
    return <SquareIcon icon={IconUsers} iconSize={iconSize} bg="gray.100" color="gray.800" {...props} />
  }
}

const rowHover = {
  bg: 'gray.50'
}

export default function Index(props: IndexProps) {
  const [isDeleting, setIsDeleting] = useState<Record<string, boolean>>({})

  return (
    <PageLayout size="full">
      <TopBarContent>
        <Breadcrumb paths={[{ path: projectPath('/plays'), title: 'Plays' }]} />
      </TopBarContent>

      <SettingsHeader>
        <Flex alignItems="center" justifyContent="space-between">
          <Stack spacing={0}>
            <PageTitle>Plays</PageTitle>
            <PageDescription>Encode and execute on repeatable sales strategies.</PageDescription>
          </Stack>
          <Button
            size="sm"
            as={Link}
            href={projectPath('/plays/new')}
            iconSpacing={1.5}
            leftIcon={<IconPlus size={16} />}
            colorScheme="purple"
          >
            New Play
          </Button>
        </Flex>
      </SettingsHeader>

      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>Play</Th>
              <Th>Pending Leads</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {props.plays.map((play) => (
              <Tr
                key={play.id}
                role="group"
                _hover={rowHover}
                opacity={isDeleting[play.id] ? 0.5 : 1}
                cursor={isDeleting[play.id] ? 'not-allowed' : undefined}
              >
                <Td>
                  <Stack spacing={1}>
                    <HStack spacing={3}>
                      <PlaySourceIcon
                        targetType={play.target_type}
                        targetSource={play.target_config?.source}
                        padding={2}
                      />
                      <Stack spacing={0}>
                        <Link href={projectPath(`/plays/${play.id}`)}>{play.name}</Link>

                        <HStack color="gray.500" fontSize="xs" spacing={1} divider={<MiddotDivider />}>
                          <Text>
                            Created <TimeAgo time={play.created_at} />
                          </Text>
                          {play.created_by_user ? (
                            <Text>{play.created_by_user.name || play.created_by_user.email}</Text>
                          ) : null}
                        </HStack>
                      </Stack>
                    </HStack>

                    {play.description && (
                      <Stack>
                        <Text
                          pt="2"
                          as="pre"
                          lineHeight="1"
                          fontSize="xs"
                          color="gray.500"
                          pl="44px"
                          whiteSpace="pre-wrap"
                          fontFamily="inherit"
                          overflowY="auto"
                          noOfLines={3}
                          sx={{
                            '&::-webkit-scrollbar': { display: 'none' },
                            scrollbarWidth: 'none'
                          }}
                        >
                          {play.description}
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                </Td>
                <Td>{play.pending.toLocaleString()}</Td>
                <Td width="1px">
                  <HStack spacing={2}>
                    {isDeleting[play.id] && <Spinner size="sm" />}

                    <Button
                      as={Link}
                      href={projectPath(`/plays/${play.id}/edit`)}
                      rightIcon={<Icon as={IconPencil} boxSize={4} />}
                      variant="outline"
                      bg="white"
                      aria-label="Edit"
                      size="sm"
                      visibility="hidden"
                      _groupHover={{ visibility: 'visible' }}
                    >
                      Edit
                    </Button>
                  </HStack>
                </Td>
                <Td width="1px">
                  <Flex justifyContent="flex-end" gap={2}>
                    <UsersAvatarGroup users={play.assignees} />

                    <OptionsMenu
                      id={play.id}
                      onConfirmDelete={(e) => {
                        if (!play.id) return
                        e.preventDefault()

                        const formData = new FormData(e.target as HTMLFormElement)
                        formData.append('_method', 'DELETE')

                        setIsDeleting({
                          [play.id]: true
                        })

                        postForm(projectPath(`/plays/${play.id}`), formData).then(() => {
                          setIsDeleting({
                            [play.id]: false
                          })
                          router.visit(projectPath('/plays'), { fetch: true })
                        })
                      }}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </PageLayout>
  )
}

interface OptionsMenuProps {
  id?: string
  onConfirmDelete?: FormEventHandler<HTMLFormElement>
}

function OptionsMenu(props: OptionsMenuProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Menu autoSelect={false} placement="bottom-end">
        <MenuButton
          as={IconButton}
          size="xs"
          aria-label="Options"
          variant="ghost"
          icon={<Icon as={IconDots} boxSize={4} />}
        />
        <MenuList zIndex="popover">
          <MenuItem icon={<IconEdit size={16} />} as="a" href={projectPath(`/plays/${props.id}`)}>
            Edit
          </MenuItem>
          <MenuDivider />
          <MenuItem color="red.500" icon={<IconTrash size={16} />} onClick={onOpen}>
            Delete Play…
          </MenuItem>
        </MenuList>
      </Menu>

      <DeleteConfirmation
        isOpen={isOpen}
        onClose={onClose}
        title="Are you sure?"
        confirmLabel="Yes, delete this Play"
        onConfirm={props.onConfirmDelete}
      >
        This will delete this play and all of its data, but it will not delete any companies or people that were
        enrolled in the play.
      </DeleteConfirmation>
    </>
  )
}
