import { Flex, Input, InputProps, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import React, { ChangeEvent, ClipboardEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import Papa from 'papaparse'
import useLatestRef from './useLatestRef'

interface TagsInputProps {
  initialTags?: string[]
  onChange: (tags: string[]) => void
  onRemove?: (tag: string) => void
  colorScheme?: string
  placeholder?: string
  inputProps?: InputProps
}

interface TagItemProps {
  tag: string
  onRemove: () => void
  colorScheme: string
}

const TagItem: React.FC<TagItemProps> = ({ tag, onRemove, colorScheme }) => {
  return (
    <Tag size="sm" variant="subtle" colorScheme={colorScheme} onClick={(e) => e.stopPropagation()}>
      <TagLabel>{tag}</TagLabel>
      <TagCloseButton
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          onRemove()
        }}
        cursor="pointer"
      />
    </Tag>
  )
}

export const TagsInput: React.FC<TagsInputProps> = ({
  initialTags = [],
  onChange,
  onRemove,
  colorScheme = 'purple',
  placeholder,
  inputProps = {}
}) => {
  const [tags, setTags] = useState<string[]>(initialTags)
  const [inputValue, setInputValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const onChangeRef = useLatestRef(onChange)
  const onRemoveRef = useLatestRef(onRemove)

  useEffect(() => {
    setTags(initialTags)
  }, [initialTags])

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }, [])

  const addTag = useCallback(
    (tag: string) => {
      if (tag.trim() && !tags.includes(tag.trim())) {
        let incoming: string[] = []
        if (tag.includes(' OR ')) {
          incoming = tag.split(' OR ')
        } else {
          incoming = [tag.trim()]
        }

        const newTags = [...tags, ...incoming].map((t) => t.trim()).filter(Boolean)
        setTags(newTags)
        onChangeRef.current(newTags)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tags]
  )

  const removeTag = useCallback(
    (index?: number) => {
      // If index is not provided, remove the last tag
      if (index === undefined) {
        index = tags.length - 1
      }

      const newTags = tags.filter((_, i) => i !== index)
      setTags(newTags)
      onRemoveRef.current?.(tags[index])
      onChangeRef.current(newTags)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tags]
  )

  const handleInputKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const value = (e.target as HTMLInputElement).value

      if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
        e.preventDefault()
        if (value.trim()) {
          addTag(value.trim())
          setInputValue('')
        }
      } else if (e.key === 'Backspace' || e.key === 'Delete') {
        if (!value) {
          // Remove the last tag when backspace/delete is pressed and input is empty
          e.preventDefault()
          removeTag()
        }
      }
    },
    [addTag, removeTag]
  )

  const handlePaste = useCallback(
    (e: ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault()
      const pastedText = e.clipboardData.getData('text')

      const pastedTags =
        Papa.parse(pastedText.replace(/, "/g, ',"'), {
          header: false,
          quotes: true,
          delimitersToGuess: [',', '\t', ' OR ', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
        }).data[0] || []

      const newTags = Array.from(new Set([...tags, ...pastedTags].map((t) => t.trim()).filter(Boolean)))

      setTags(newTags)
      onChangeRef.current(newTags)
      setInputValue('')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tags]
  )

  return (
    <Flex
      flexWrap="wrap"
      gap={1}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="md"
      p={1.5}
      w="100%"
      onClick={() => inputRef.current?.focus()}
    >
      {tags.map((tag, index) => (
        <TagItem key={tag} tag={tag} onRemove={() => removeTag(index)} colorScheme={colorScheme} />
      ))}
      <Input
        ref={inputRef}
        size="sm"
        flex="1 1 0%"
        width="auto"
        minW="min(100px, 100%)"
        variant="unstyled"
        px={1.5}
        fontSize="13px"
        fontWeight="medium"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onPaste={handlePaste}
        placeholder={placeholder ?? 'Add a tag'}
        onBlur={() => {
          if (inputValue.trim()) {
            addTag(inputValue.trim())
            setInputValue('')
          }
        }}
        {...inputProps}
      />
    </Flex>
  )
}

export default TagsInput
