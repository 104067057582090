import { Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { Engagement } from '../../types/Engagement'
import { HoverCard } from './HoverCard'

function arrayWrap<T>(item: T): Array<T> {
  if (Array.isArray(item)) {
    return item
  }

  if (item === undefined || item === null || item === '') {
    return []
  }

  return [item]
}

interface Props {
  engagement?: Engagement
}

export function LastEngagementHoverCard({ children, engagement }: React.PropsWithChildren<Props>): JSX.Element {
  if (engagement) {
    return (
      <HoverCard
        trigger="hover"
        hoverContent={
          <Stack color="gray.700" padding={2} maxW="300px">
            <Stack spacing={0}>
              <Text fontSize="xs" fontWeight="medium">
                From:
              </Text>
              <Text fontSize="13px">{engagement.metadata?.from}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text fontSize="xs" fontWeight="medium">
                To:
              </Text>
              {arrayWrap(engagement.metadata?.to)?.map((addr, idx) => (
                <Text key={`to:${addr}:${idx}`} fontSize="13px">
                  {addr}
                </Text>
              ))}
              {arrayWrap(engagement.metadata?.cc)?.map((addr, idx) => (
                <Text key={`cc:${addr}:${idx}`} fontSize="13px">
                  {addr}
                </Text>
              ))}
            </Stack>
            <Stack spacing={0}>
              <Text fontSize="xs" fontWeight="medium">
                Subject:
              </Text>
              <Text fontSize="13px">{engagement.metadata?.subject}</Text>
            </Stack>
          </Stack>
        }
      >
        {children}
      </HoverCard>
    )
  }

  return <>{children}</>
}
