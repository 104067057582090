import FilterIllustration from '@app/components/pages/accounts/components/empty-states/filter-illustration.svg'
import EmptyState from '@app/components/ui/EmptyState'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { IconBriefcase, IconBuilding, IconCoin, IconHash, IconStack2, IconTableDown, IconX } from '@tabler/icons-react'
import { ColumnDef, RowSelectionState } from '@tanstack/react-table'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import type { PageMeta } from '../../../../types/PageMeta'
import { Company } from '../../../../types/Profile'
import { UrlFilterParams } from '../../../data/use-url-filters'
import { BulkActionBar } from '../../../ui/BulkActionBar'
import { Card } from '../../../ui/Card'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { SearchIcon } from '../../../ui/icons/SearchIcon'
import { projectPath } from '../../../ui/ProjectsContext'
import { CustomTable } from '../../../ui/Table'
import { TableFooter } from '../../../ui/TableFooter'
import useUpdateEffect from '../../../ui/useUpdateEffect'
import { FacetFilters } from '../../accounts'
import { AddToListModal } from '../../lists/components/AddToListModal'
import { FilterState } from '../../personas/persona-filters'
import { FilterPopover, FilterPopoverProps } from '../../prospector/components/FilterPopover'
import { ImportListPrompt } from './ImportListPrompt'
import { RenameListModal } from './RenameListModal'

export const companyColumns: ColumnDef<Company>[] = [
  {
    id: 'company',
    accessorFn: (row) => row.name || row.domain,
    size: 300,
    header: 'Company',
    cell: ({ row, getValue }) => {
      if (!getValue()) {
        return null
      }

      return (
        <HStack spacing={2} isTruncated>
          <CompanyAvatar size="xs" name={row.original.name} domain={row.original.domain} />
          <Text fontSize="15px" minWidth="100px" lineHeight="1.2" fontWeight="semibold" isTruncated>
            {row.original.name || row.original.domain}
          </Text>
        </HStack>
      )
    }
  },
  {
    id: 'category.industry',
    accessorKey: 'category.industry',
    header: 'Industry',
    cell: ({ getValue }) => <Box isTruncated>{getValue()}</Box>
  },
  {
    id: 'metrics.employeesRange',
    accessorKey: 'metrics.employeesRange',
    header: 'Employees',
    size: 120
  },
  {
    id: 'metrics.estimatedAnnualRevenue',
    accessorKey: 'metrics.estimatedAnnualRevenue',
    header: 'Est. Revenue',
    size: 150
  },
  {
    id: 'geo',
    accessorKey: 'geo',
    header: 'Location',
    cell: ({ getValue }) => {
      const geo = getValue() as Company['geo']
      return [geo?.state, geo?.country].filter(Boolean).join(', ')
    }
  }
]

interface Props {
  companies: Company[]
  page_meta: PageMeta
  facets: UrlFilterParams
  listId: string
}

export function FilterMenu(props: React.PropsWithChildren<FilterPopoverProps>) {
  return (
    <Box paddingY={1.5} paddingX={1.5}>
      <FilterPopover {...props} showPreview isCompaniesView />
    </Box>
  )
}

export function ExploreCompaniesView(props: Props) {
  const { facets } = props
  const { facetFilters } = facets
  const listId = props.listId

  const tabs = [
    { label: 'People', href: projectPath('/prospector') },
    { label: 'Companies', href: projectPath('/prospector/companies') },
    { label: 'Saved', href: projectPath('/prospector/saved') }
  ]

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})
  const addToList = useDisclosure()
  const renameListModal = useDisclosure()

  useEffect(() => {
    // reset the selected rows when the companies change
    setSelectedRows({})
  }, [props.companies])

  const applyFilters = facets.applyFilters
  const clearFilters = facets.clearFilters

  const [searchQuery, setSearchQuery] = useState(facets.query || '')
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300)

  const selections = Object.entries(selectedRows)
    .filter(([_id, selected]) => selected)
    .map(([id]) => id)

  const onFilterChange = useCallback(
    (filters: Record<string, FilterState>) => {
      // convert the filters to the format that the url filters expect
      const facetFilters: FacetFilters = {}

      for (const [facet, filter] of Object.entries(filters)) {
        if (filter.operator === 'must') {
          facetFilters[facet] = filter.values
        } else if (filter.operator === 'must_not') {
          facetFilters[facet] = { not: filter.values }
        }
      }

      applyFilters(facetFilters)
    },
    [applyFilters]
  )

  const emptyFilters = isEmpty(facetFilters)

  useUpdateEffect(() => {
    if (debouncedSearchQuery !== facets.query) {
      facets.setQuery(debouncedSearchQuery)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery])

  const handleRenameListClick = () => {
    renameListModal.onOpen()
  }
  const [listRenamed, setListIsRenamed] = useState(false)

  const handleListUpdate = () => {
    setListIsRenamed(true)
  }

  const [selectAllMode, setSelectAllMode] = useState(false)
  const [selectionCountNumber, setSelectionCountNumber] = useState(0)

  const handleSelectionModeChange = (mode) => {
    if (mode === 'all') {
      setSelectAllMode(true)
    }

    if (mode === 'page') {
      setSelectAllMode(false)
    }
  }

  useEffect(() => {
    if (selectAllMode) {
      setSelectionCountNumber(props.page_meta.total_count)
      if (!selections.length) {
        setSelectionCountNumber(0)
      }
    } else {
      setSelectionCountNumber(selections.length || 0)
    }
  }, [props.page_meta.total_count, selectAllMode, selections.length])

  return (
    <Flex flex="1 1 auto" overflow="hidden">
      <Flex flexDirection="column">
        <Flex
          flexShrink={0}
          width="100%"
          alignItems="flex-end"
          height="49px"
          borderRight="1px solid"
          borderColor="gray.200"
        >
          <Tabs size="sm" variant="line" isManual defaultIndex={1} paddingX={0} align="center" width="100%" isFitted>
            <TabList>
              {tabs.map((tab) => (
                <Tab key={tab.label} as="a" href={tab.href}>
                  {tab.label}
                </Tab>
              ))}
            </TabList>
          </Tabs>
        </Flex>
        <Box
          bg="white"
          borderRight="1px solid"
          borderColor="gray.200"
          padding={6}
          paddingBottom={0}
          minW="320px"
          width="360px"
          overflow="auto"
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          gap={8}
          height="full"
        >
          <Stack>
            <Text fontSize="sm" fontWeight="medium">
              Filters
            </Text>

            <Card padding={0}>
              <Stack divider={<Divider />} spacing={0}>
                <FilterMenu
                  facet="company.category.industry"
                  filters={facetFilters as Record<string, FilterState>}
                  icon={IconBriefcase}
                  onChange={onFilterChange}
                >
                  Industry
                </FilterMenu>
                <FilterMenu
                  facet="company.metrics.employeesRange"
                  filters={facetFilters as Record<string, FilterState>}
                  icon={IconHash}
                  onChange={onFilterChange}
                >
                  Employees
                </FilterMenu>
                <FilterMenu
                  facet="company.metrics.estimatedAnnualRevenue"
                  filters={facetFilters as Record<string, FilterState>}
                  {...facets}
                  icon={IconCoin}
                  onChange={onFilterChange}
                >
                  Est. Revenue
                </FilterMenu>
                <FilterMenu
                  facet="company.tech"
                  filters={facetFilters as Record<string, FilterState>}
                  {...facets}
                  icon={IconStack2}
                  onChange={onFilterChange}
                >
                  Tech Stack
                </FilterMenu>
                <FilterMenu
                  facet="company.geo.city"
                  filters={facetFilters as Record<string, FilterState>}
                  {...facets}
                  icon={IconBuilding}
                  onChange={onFilterChange}
                >
                  City
                </FilterMenu>
                <FilterMenu
                  facet="company.geo.state"
                  filters={facetFilters as Record<string, FilterState>}
                  {...facets}
                  icon={IconBuilding}
                  onChange={onFilterChange}
                >
                  State
                </FilterMenu>
                <FilterMenu
                  facet="company.geo.country"
                  filters={facetFilters as Record<string, FilterState>}
                  {...facets}
                  icon={IconBuilding}
                  onChange={onFilterChange}
                >
                  Country
                </FilterMenu>
              </Stack>
            </Card>
          </Stack>

          <Box marginTop="auto" paddingTop={4} position="sticky" bottom={0} paddingBottom={6} bg="white">
            <Flex gap={3} justifyContent="space-between">
              {Object.keys(facets.facetFilters).length ? (
                <Button w="100%" variant="outline" onClick={clearFilters}>
                  Reset filters
                </Button>
              ) : null}
            </Flex>
          </Box>
        </Box>
      </Flex>
      <Flex flexDirection="column" flex="1 1 auto" minWidth="300px">
        <HStack
          paddingY={2}
          paddingX={2}
          justifyContent="space-between"
          spacing="4"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          <Box flex="1">
            <InputGroup size="sm" flex="1 1 100px">
              <InputLeftElement width="7" pointerEvents="none" color="gray.400">
                <SearchIcon boxSize={3.5} />
              </InputLeftElement>
              <Input
                size="sm"
                fontSize="13px"
                background="white"
                outline="none"
                border="none"
                roundedBottom={0}
                focusBorderColor="transparent"
                placeholder="Search"
                paddingLeft={7}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                }}
              />

              {searchQuery && (
                <InputRightElement>
                  <IconButton
                    size="xs"
                    aria-label="Clear search"
                    variant="ghost"
                    color="gray.400"
                    _hover={{ color: 'gray.600' }}
                    onClick={() => setSearchQuery('')}
                    icon={<IconX size={16} />}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </Box>

          <Flex>
            <Tooltip label="Feature under construction" placement="bottom-start">
              <Button
                isDisabled
                size="sm"
                variant="outline"
                colorScheme="lightPurple"
                leftIcon={<Icon as={IconTableDown} size={16} />}
                iconSpacing={1.5}
                mr="2"
                // TODO: here we should apply the filteredCompaniesExport to CSV onClick
              >
                Export
              </Button>
            </Tooltip>
          </Flex>
        </HStack>
        {emptyFilters && !searchQuery ? (
          <EmptyState size="md" heading={`Select a company or some filters to start prospecting`}>
            <Image h="64" width="auto" src={FilterIllustration} />
          </EmptyState>
        ) : (
          <Box display="flex" flexDirection="column" w="100%" flex="1 1 auto" maxH="100%" minH="300px">
            <CustomTable
              data={props.companies}
              columns={companyColumns}
              stickyFirstColumn
              enableRowSelection
              selectedRows={selectedRows}
              onRowSelectionChange={setSelectedRows}
              enableAllSelection
              onSelectionModeChange={(mode) => handleSelectionModeChange(mode)}
              selectAllMode={selectAllMode}
              numberOfRecords={props.page_meta.total_count}
            />
            {listId && (
              <>
                <ImportListPrompt
                  show={true}
                  onRenameListClick={() => handleRenameListClick()}
                  listId={listId}
                  listIsRenamed={listRenamed}
                />
                <RenameListModal {...renameListModal} onUpdate={() => handleListUpdate()} listId={listId} />
              </>
            )}
            <AddToListModal
              recordIds={selections}
              selectAllMode={selectAllMode}
              facets={facetFilters as FacetFilters}
              kind="account"
              {...addToList}
            />
            <TableFooter
              word="company"
              pageMeta={props.page_meta}
              page={(facets.page ?? 1) as number}
              setPage={facets.setPage}
              px={4}
              sticky
            />
          </Box>
        )}
      </Flex>
      <BulkActionBar selectionCount={selectionCountNumber} onRemoveSelection={() => setSelectedRows({})}>
        <Button variant="outline" size="sm" onClick={addToList.onOpen}>
          Add to List
        </Button>
      </BulkActionBar>
    </Flex>
  )
}
