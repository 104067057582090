import { useQuery } from 'react-query'
import { useActionCableChannel } from '../../channels/use-channel'
import { concurrentCachedGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export type AIRecommendationType = 'email' | 'session' | 'business' | 'talking_points' | 'plays'

export function usePlayItemAIRecommendations(
  itemId: string,
  { recommendationType, runId }: { recommendationType: AIRecommendationType; runId?: string },
  { enabled = true }: { enabled?: boolean } = {}
) {
  const project = useCurrentProject()
  let basePath = `/play-items/${itemId}/ai-recommendations.json`

  if (recommendationType) {
    basePath += `?recommendation_type=${recommendationType}`

    if (runId) {
      basePath += `&run_id=${runId}`
    }
  }

  const path = projectPath(basePath)

  return useQuery<any>(
    ['play-item-ai-recommendations', { projectId: project?.id, itemId, recommendationType }],
    () => concurrentCachedGET(path),
    {
      enabled
    }
  )
}

export type Message = {
  action: 'content'
  content: string
  content_type?: string
}

export type ToolUsage = {
  action: 'tool'
  tool_name: string
  tool_args: string
  content: string
}

export function usePlayItemAIRecommendationsStream(
  runId: string,
  {
    onContent,
    onTool
  }: {
    onContent: (message: Message) => void
    onTool: (message: ToolUsage) => void
  }
) {
  return useActionCableChannel('AiAgentRunChannel', { run_id: runId }, (message: { action: 'content' | 'tool' }) => {
    if (message.action === 'content') {
      onContent(message as Message)
    } else if (message.action === 'tool') {
      onTool(message as ToolUsage)
    }
  })
}
