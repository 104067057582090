import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Stack
} from '@chakra-ui/react'
import { IconAlignJustified, IconBriefcase, IconPlus, IconUser, IconX } from '@tabler/icons-react'
import React from 'react'
import { PlayTargetConfig } from '../../../../types/Play'
import { GrayCard } from '../../../ui/Card'

const isValidLinkedInUrl = (url: string): boolean => {
  if (!url) return true
  return isProfileUrl(url) || isCompanyUrl(url) || isPostUrl(url)
}

function isProfileUrl(url: string): boolean {
  const regex = /^(https?:\/\/)?(www\.)?(linkedin\.com\/in\/[a-zA-Z0-9_-]+)/
  return regex.test(url)
}

function isCompanyUrl(url: string): boolean {
  const regex = /^(https?:\/\/)?(www\.)?(linkedin\.com\/company\/[a-zA-Z0-9_-]+)/
  return regex.test(url)
}

function isPostUrl(url: string): boolean {
  const regex = /^(https?:\/\/)?(www\.)?(linkedin\.com\/(posts|feed)\/.+)/
  return regex.test(url)
}

export function LinkedinColdSource({
  targetConfig,
  onTargetTypeChange
}: {
  targetConfig: PlayTargetConfig | undefined
  targetType: 'Profile' | 'Account'
  onTargetTypeChange: (targetType: 'Profile' | 'Account') => void
}) {
  const [posts, setPosts] = React.useState<string[]>(targetConfig?.config?.urls ?? [''])

  const addPost = () => {
    setPosts([...posts, ''])
    onTargetTypeChange('Profile')
  }

  const removePost = (index: number) => {
    const newPosts = posts.filter((_, i) => i !== index)
    setPosts(newPosts.length ? newPosts : [''])
    onTargetTypeChange('Profile')
  }

  const updatePost = (index: number, value: string) => {
    const newPosts = [...posts]
    newPosts[index] = value
    setPosts(newPosts)
    onTargetTypeChange('Profile')
  }

  return (
    <GrayCard borderWidth={'thin'}>
      <FormControl>
        <FormLabel>LinkedIn Posts or Profiles</FormLabel>
        <Stack spacing="4">
          <Stack spacing={2}>
            {posts.map((post, index) => (
              <Stack key={index}>
                <HStack direction="row" alignItems="center">
                  {isProfileUrl(post) && <Icon as={IconUser} boxSize={4} />}
                  {isCompanyUrl(post) && <Icon as={IconBriefcase} boxSize={4} />}
                  {isPostUrl(post) && <Icon as={IconAlignJustified} boxSize={4} />}
                  <Input
                    value={post}
                    bg="white"
                    onChange={(e) => updatePost(index, e.target.value)}
                    size="sm"
                    placeholder="Paste LinkedIn post or profile URL here"
                    isRequired
                    isInvalid={!isValidLinkedInUrl(post)}
                  />
                  <IconButton
                    variant="ghost"
                    icon={<Icon as={IconX} />}
                    size="sm"
                    aria-label="Remove post"
                    onClick={() => removePost(index)}
                    isDisabled={posts.length === 1}
                  />
                </HStack>
              </Stack>
            ))}
          </Stack>
          <Flex justifyContent="flex-start">
            <Button
              size="sm"
              leftIcon={<Icon as={IconPlus} />}
              aria-label="Add post"
              onClick={addPost}
              colorScheme="blue"
            >
              Add new
            </Button>
          </Flex>

          {posts.some((post) => !isValidLinkedInUrl(post)) && (
            <FormErrorMessage>Please enter a valid LinkedIn post or profile URL.</FormErrorMessage>
          )}

          <FormHelperText>
            Add as many posts or profiles (people or companies) as you'd like to enroll from. Koala will import all
            comments and reactions as prospects.
          </FormHelperText>

          {posts.some((post) => isCompanyUrl(post) || isProfileUrl(post)) && (
            <FormHelperText>
              <strong>Note:</strong> Koala will constantly check and refresh the most recent 10 posts from any Company
              or Person profile.
            </FormHelperText>
          )}
        </Stack>

        {posts.length > 0 && (
          <>
            <input type="hidden" name="play[target_config][config]" value={JSON.stringify({ urls: posts })} />
            <input type="hidden" name="play[target_type]" value="Profile" />
          </>
        )}
      </FormControl>
    </GrayCard>
  )
}
