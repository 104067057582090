import { Box, Flex, FormControl, FormLabel, Switch, Text } from '@chakra-ui/react'
import { IconSparkles, IconMailSpark } from '@tabler/icons-react'
import React, { useState } from 'react'
import { Play } from '../../../../types/Play'
import { GrayCard } from '../../../ui/Card'

export function EnrichmentSetup({ play }: { play?: Partial<Play> }) {
  const [waterfallEnrich, setWaterfallEnrich] = useState(play?.prospecting_config?.waterfall_enrichment ?? true)
  const [autoEmails, setAutoEmails] = useState(play?.prospecting_config?.automatic_email_generation ?? false)

  return (
    <>
      <GrayCard>
        <FormControl>
          <Flex alignItems="center" gap={4}>
            <Flex flex="none" alignItems="center" justifyContent="center" boxSize={6} color="gray.500">
              <IconSparkles size={20} />
            </Flex>
            <Box flex="1 1 auto">
              <FormLabel cursor="pointer" mb={0}>
                Waterfall Enrichment
              </FormLabel>
              <Text fontSize="13px" lineHeight="19px" color="gray.500">
                Automatically enrich emails, titles and LinkedIn profiles from 27+ sources
              </Text>
            </Box>
            <Box flex="none">
              <Switch isChecked={waterfallEnrich} onChange={(e) => setWaterfallEnrich(e.target.checked)} />
            </Box>
          </Flex>
          {waterfallEnrich && <input type="hidden" name="play[prospecting_config][waterfall_enrichment]" value="1" />}
        </FormControl>
      </GrayCard>

      <GrayCard>
        <FormControl>
          <Flex alignItems="center" gap={4}>
            <Flex flex="none" alignItems="center" justifyContent="center" boxSize={6} color="gray.500">
              <IconMailSpark size={20} />
            </Flex>
            <Box flex="1 1 auto" w="100%">
              <FormLabel cursor="pointer" mb={0}>
                AI Email Copy
              </FormLabel>
              <Text fontSize="13px" lineHeight="19px" color="gray.500">
                Automatically generate personalized email drafts using AI (uses AI credits)
              </Text>
            </Box>
            <Box flex="none">
              <Switch isChecked={autoEmails} onChange={(e) => setAutoEmails(e.target.checked)} />
            </Box>
          </Flex>
          {autoEmails && <input type="hidden" name="play[prospecting_config][automatic_email_generation]" value="1" />}
        </FormControl>
      </GrayCard>
    </>
  )
}
