import { PageMeta } from '@app/types/PageMeta'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Select,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconCircleCheck,
  IconEdit,
  IconFilter,
  IconInfoCircle,
  IconReload,
  IconTriangleSquareCircle,
  IconCloudDownload
} from '@tabler/icons-react'
import startCase from 'lodash/startCase'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { toast } from 'sonner'
import { post, put } from '../../../../lib/api'
import dayjs, { formatDate } from '../../../../lib/dayjs'
import { formatNumber } from '../../../../lib/number-format'
import Router from '../../../../lib/router'
import { DownloadCsvIcon, DownloadCsvTooltip } from '../../../ui/DownloadCsvButtons'
import { SfOpportunityIcon2 } from '../../../ui/icons/SalesforceIcons'
import { MiddotDivider } from '../../../ui/Middot'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import SquareIcon from '../../../ui/SquareIcon'
import { TableFooter } from '../../../ui/TableFooter'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useCurrentUser } from '../../../ui/UserContext'
import { mergeParams } from '../../icps/types'
import { ContentReport, ContentReportRow } from './types'

const eventTypeLabels = {
  all: 'All events',
  page_view: 'Page View',
  form_submission: 'Form Submission',
  event: 'Custom user-level Event',
  kql_event: 'User-level Intent Signal',
  account_event: 'Custom account-level Event',
  account_kql_event: 'Account-level Intent Signal'
}

interface Props {
  page_meta: PageMeta
  metadata: Record<string, string>
  stats: Record<string, number>
  report: ContentReport
  rows: ContentReportRow[]
  params: Record<string, any>
}

const emptyArray: any[] = []
const eventMax = 5

export default function Show(props: Props) {
  const [isRefreshing, setIsRefreshing] = useState(false)
  const csvDownloadLink = mergeParams(window.location.toString(), { format: 'csv' })
  const reportName = props.report.name || `Untitled Report`
  const [showAll, setShowAll] = useState(false)
  const allStages = props.report.settings?.stage_transitions || emptyArray
  const user = useCurrentUser()
  const reportPending = ['running', 'pending'].includes(props.report.status!)
  const project = useCurrentProject()

  const stages = useMemo(() => {
    if (showAll) {
      return allStages
    } else {
      return allStages.slice(0, eventMax)
    }
  }, [allStages, showAll])

  const refreshReport = useCallback(() => {
    setIsRefreshing(true)
    post(projectPath(`/reports/contents/${props.report.id}/refresh`))
      .then((data: any) => {
        toast.success('Report updated!')
        setIsRefreshing(false)
        Router.visit(data.url)
      })
      .catch(() => {
        toast.error('There was an error refreshing the report.')
        setIsRefreshing(false)
      })
  }, [props.report.id])

  React.useEffect(() => {
    if (reportPending) {
      const interval = setInterval(() => {
        Router.visit(window.location.toString())
      }, 10000)

      return () => clearInterval(interval)
    }
  }, [reportPending])

  return (
    <PageLayout size="full">
      <Flex alignItems="center" justifyContent="space-between" w="100%" gap={4} flexWrap="wrap">
        <SettingsBreadCrumb
          paths={[
            { path: projectPath('/reports/contents'), title: 'Content Reports' },
            { path: window.location.toString(), title: reportName }
          ]}
        />
        <HStack spacing={3}>
          <Tooltip
            label={props.report.refreshable ? '' : 'Reports can only be refreshed once a day'}
            hasArrow
            arrowSize={6}
            placement="top"
          >
            <Button
              size="sm"
              aria-disabled={!props.report.refreshable}
              isLoading={isRefreshing}
              loadingText="Refreshing report data"
              variant="link"
              colorScheme={props.report.refreshable ? 'purple' : 'gray'}
              leftIcon={<IconReload size={16} />}
              onClick={props.report.refreshable ? refreshReport : undefined}
            >
              Refresh Data
            </Button>
          </Tooltip>
          <DownloadCsvTooltip>
            <Button
              as={Link}
              size="sm"
              variant="outline"
              leftIcon={<DownloadCsvIcon size={16} icon={<IconCloudDownload size={16} />} />}
              href={csvDownloadLink}
              isExternal
              isDisabled={!project?.can_export_data || isRefreshing || props.report.error || reportPending}
            >
              Download CSV
            </Button>
          </DownloadCsvTooltip>
        </HStack>
      </Flex>

      <Box rounded="xl" border="1px solid" borderColor="gray.200" p={4}>
        <PageTitle skipRendering>{reportName} | Content Report</PageTitle>

        <Flex justifyContent="space-between" gap={4}>
          <HStack spacing={3}>
            {props.report.kind === 'opportunities' ? (
              <SquareIcon icon={SfOpportunityIcon2} iconSize={7} padding={3} colorScheme="orange" rounded="xl" />
            ) : (
              <SquareIcon icon={IconTriangleSquareCircle} iconSize={7} padding={3} colorScheme="pink" rounded="xl" />
            )}

            <Stack flex="1 1 auto" spacing={0.5}>
              <HStack>
                <Text fontSize="md" fontWeight="semibold">
                  {reportName}
                </Text>
                {props.report.promoted && (
                  <Tooltip label="This report will be used to weight suggested intent signals">
                    <Badge colorScheme="gray" variant="subtle">
                      <HStack spacing={1}>
                        <span>Primary</span>
                        <Icon as={IconInfoCircle} boxSize={3.5} color="gray.500" />
                      </HStack>
                    </Badge>
                  </Tooltip>
                )}
              </HStack>

              <HStack spacing={1} wrap="wrap" divider={<MiddotDivider />} fontSize="xs" color="gray.600">
                <Text color="gray.600" fontSize="xs">
                  {props.report.user &&
                    `Created by ${props.report.user?.id === user.id ? 'you' : props.report.user?.name}`}
                </Text>
                <Text>
                  Refreshed <TimeAgo time={props.report.finished_at} />
                </Text>
              </HStack>
            </Stack>
          </HStack>
          {!props.report.error && !reportPending && <SaveReport {...props} />}
        </Flex>

        {!props.report.error && (
          <Flex justifyContent="space-between">
            <Stack spacing={1} pl={16} py={2}>
              <Text fontSize="xs" color="gray.600" fontWeight="normal">
                Conversion Events
              </Text>
              {props.report.kind === 'opportunities' && (
                <Stack spacing={1} fontSize="xs" fontWeight="semibold" pb={4}>
                  {stages.map((transition) => (
                    <Text key={`${transition.from || 'No opportunity'} → ${transition.to}`}>
                      {transition.from || 'No opportunity'} → {transition.to}
                    </Text>
                  ))}
                  {allStages.length > eventMax && (
                    <>
                      {showAll ? (
                        <Button
                          size="xs"
                          variant="link"
                          width="auto"
                          justifyContent="flex-start"
                          onClick={() => setShowAll(false)}
                          color="gray.500"
                        >
                          Show less
                        </Button>
                      ) : (
                        <Button
                          size="xs"
                          variant="link"
                          width="auto"
                          justifyContent="flex-start"
                          onClick={() => setShowAll(true)}
                          color="gray.500"
                        >
                          and {allStages.length - eventMax} more…
                        </Button>
                      )}
                    </>
                  )}
                </Stack>
              )}
              {props.report.kind === 'custom_events' && (
                <Stack spacing={1} fontSize="xs" fontWeight="semibold" pb={4}>
                  {(props.report.settings.custom_events || []).map((event, index) => (
                    <HStack key={JSON.stringify(event) || index} spacing={1}>
                      <Text>{eventTypeLabels[event.event_type]}:</Text>
                      <Text as="div">
                        <pre>
                          <code>{JSON.stringify(event.event_filter, null, 2)}</code>
                        </pre>
                      </Text>
                    </HStack>
                  ))}
                </Stack>
              )}
              <Field label="Conversion window" value={`${props.report.settings.window} days`} />
              {props.metadata && (
                <Field
                  label="Analyzed events"
                  value={`${formatNumber(props.metadata['stats.total_events'] || props.metadata['analytics.count'])}`}
                />
              )}
              {props.metadata && (
                <Field
                  label="Date range"
                  value={`${formatDate(
                    dayjs().subtract(props.report.settings.from_days_ago || 365, 'days')
                  )} - ${formatDate(props.metadata['analytics.max_timestamp'])}`}
                />
              )}
              <Field label="Created">
                <TimeAgo time={props.report.created_at} format="MMM D, YYYY" fallback="-" />
              </Field>
            </Stack>

            <StatGroup width={props.stats?.pipeline_influence ? 650 : 450} textAlign="right">
              <Stat>
                <StatLabel>Exposed Accounts</StatLabel>
                <StatNumber>{formatNumber(props.stats?.exposed_accounts || 0)}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>Converted Accounts</StatLabel>
                <StatNumber>{formatNumber(props.stats?.converted_accounts || 0)}</StatNumber>
              </Stat>
              {props.stats?.pipeline_influence && (
                <Stat>
                  <StatLabel>Pipeline Influence</StatLabel>
                  <StatNumber>${formatNumber(props.stats.pipeline_influence, { maximumFractionDigits: 0 })}</StatNumber>
                </Stat>
              )}
            </StatGroup>
          </Flex>
        )}
      </Box>
      {props.report.error || props.report.status == 'error' ? (
        <Stack maxW="400px" margin={'0 auto'} spacing="4">
          <Alert status="error">
            <AlertIcon />
            <AlertDescription fontSize={'sm'}>
              An error was detected while the report was being generated. Please try to and reach out to
              support@getkoala.com in case that doesn't work.
            </AlertDescription>
          </Alert>

          <Button
            size="sm"
            isLoading={isRefreshing}
            loadingText="Refreshing report data"
            colorScheme="purple"
            onClick={refreshReport}
          >
            Refresh report
          </Button>

          {isRefreshing && (
            <Text fontSize="sm" color="gray.600">
              Refreshing the report can take a few minutes. You can refresh this page to see the updated report when it
              becomes available.
            </Text>
          )}
        </Stack>
      ) : ['running', 'pending'].includes(props.report.status!) ? (
        <Stack maxW="400px" margin={'0 auto'} spacing="4">
          <Alert status="info">
            <AlertIcon />
            <AlertDescription fontSize={'sm'}>
              The report is currently being generated. This can take a few minutes. We're refreshing the page every 10
              seconds to keep you updated. You can also refresh the page manually.
              <Text fontWeight={'semibold'}>
                last refresh at{' '}
                {new Date().toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true
                })}
              </Text>
            </AlertDescription>
          </Alert>
        </Stack>
      ) : (
        <ReportTable {...props} />
      )}
    </PageLayout>
  )
}

function SaveReport(props: Props) {
  const report = props.report
  const [isSaving, setIsSaving] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const reportName = useRef<string>(report.name || '')

  let exampleReportName = ''
  switch (report.kind) {
    case 'custom_events':
      exampleReportName = `Demo Booked - (within ${report.settings.window} days)`
      break
    case 'opportunities':
      exampleReportName = `Closed Won and Renewals - (within ${report.settings.window} days)`
      break
    default:
      exampleReportName = `${startCase(report.kind)} - (within ${report.settings.window} days)`
  }

  const saveReport = useCallback(() => {
    if (!reportName.current) return

    setIsSaving(true)
    put(projectPath(`/reports/contents/${props.report.id}`), { content_report: { name: reportName.current } })
      .then(() => {
        setIsSaving(false)
        onClose()
        toast.success(`Report saved as '${reportName.current}'`)
        Router.visit(window.location.toString())
      })
      .catch(() => {
        toast.error('There was an error saving the report.')
      })
      .finally(() => {
        setIsSaving(false)
      })
  }, [props.report.id, onClose])

  return (
    <>
      <Button
        size="sm"
        variant="outline"
        isLoading={isSaving}
        loadingText="Saving..."
        leftIcon={<IconEdit size={16} />}
        onClick={onOpen}
      >
        Edit…
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Rename report</ModalHeader>
          <ModalBody>
            <Stack gap={2}>
              <FormControl>
                <FormLabel>Report name</FormLabel>
                <Input
                  size="sm"
                  defaultValue={report.name || ''}
                  onChange={(e) => (reportName.current = e.target.value)}
                  placeholder={`Ex: ${exampleReportName}`}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" onClick={onClose} variant="outline" mr={3}>
              Cancel
            </Button>
            <Button size="sm" colorScheme="purple" onClick={saveReport} isLoading={isSaving}>
              Update report
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function ReportTable(props: Props) {
  const setParams = useCallback((onVisitTriggered, params) => {
    const newParams = { page: '1', ...params }
    const url = mergeParams(window.location.toString(), newParams)
    Router.visit(url)
    onVisitTriggered()
  }, [])

  const filters = {
    statsig: useRef<string>(props.params.statsig),
    eventTypes: useRef<string>(props.params.event_types),
    eventValue: useRef<string>(props.params.event_value),
    minExposed: useRef<string>(props.params.min_exposed),
    minConverted: useRef<string>(props.params.min_converted)
  }

  const currentSort: any = {}
  props.params.sort_by?.split(',')?.forEach((sortBy) => {
    const [column, direction] = sortBy.split('-')
    currentSort[column] = direction
  })

  const sorting = {
    confidence: useRef<string>(currentSort.confidence),
    eventType: useRef<string>(currentSort.event_type),
    eventValue: useRef<string>(currentSort.event_value),
    exposedAccounts: useRef<string>(currentSort.exposed_accounts),
    convertedAccounts: useRef<string>(currentSort.converted_accounts),
    conversionRate: useRef<string>(currentSort.conversion_rate),
    impact: useRef<string>(currentSort.impact),
    pipelineInfluence: useRef<string>(currentSort.pipeline_influence)
  }

  return (
    <TableContainer overflowX={'auto'} overflowY="auto">
      <Table mb={5} size="sm">
        <Thead>
          <Tr>
            <Th>
              <HStack>
                <Text>Event Type</Text>
                <Popover isLazy lazyBehavior="keepMounted">
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <IconButton
                          aria-label="Filter and / or sort event type column"
                          variant="ghost"
                          size="xs"
                          icon={<IconFilter size={14} />}
                        />
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent width="350px" shadow="xl" color="gray.700">
                          <PopoverHeader>
                            <Heading size="xs">Event Type</Heading>
                          </PopoverHeader>
                          <PopoverBody paddingY={4}>
                            <FormControl>
                              <FormLabel>Filter</FormLabel>
                              <CheckboxGroup
                                defaultValue={filters.eventTypes.current?.split(',')}
                                onChange={(v) => (filters.eventTypes.current = v.join(','))}
                              >
                                <Stack>
                                  <Checkbox value="page_view">
                                    <Text fontSize="sm">Page View</Text>
                                  </Checkbox>
                                  <Checkbox value="form_submission">
                                    <Text fontSize="sm">Form Submission</Text>
                                  </Checkbox>
                                  <Checkbox value="event">
                                    <Text fontSize="sm">Custom user-level Event</Text>
                                  </Checkbox>
                                  <Checkbox value="kql_event">
                                    <Text fontSize="sm">User-level Intent Signal</Text>
                                  </Checkbox>
                                  <Checkbox value="account_event">
                                    <Text fontSize="sm">Custom account-level Event</Text>
                                  </Checkbox>
                                  <Checkbox value="account_kql_event">
                                    <Text fontSize="sm">Account-level Intent Signal</Text>
                                  </Checkbox>
                                </Stack>
                              </CheckboxGroup>
                            </FormControl>
                            <FormControl mt={3}>
                              <FormLabel>Sort</FormLabel>
                              <Select
                                size="sm"
                                defaultValue={currentSort.event_type}
                                onChange={(e) => (sorting.eventType.current = e.target.value)}
                              >
                                <option></option>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                              </Select>
                            </FormControl>
                          </PopoverBody>
                          <PopoverFooter textAlign="right" borderTop="none">
                            <Button
                              colorScheme="purple"
                              size="sm"
                              onClick={() => {
                                setParams(onClose, {
                                  event_types: filters.eventTypes.current,
                                  sort_by: sorting.eventType.current
                                    ? `event_type-${sorting.eventType.current}`
                                    : undefined
                                })
                              }}
                            >
                              Apply
                            </Button>
                          </PopoverFooter>
                        </PopoverContent>
                      </Portal>
                    </>
                  )}
                </Popover>
              </HStack>
            </Th>
            <Th>
              <HStack>
                <Text>Event Value</Text>
                <Popover isLazy lazyBehavior="keepMounted">
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <IconButton
                          aria-label="Filter and / or sort event value column"
                          variant="ghost"
                          size="xs"
                          icon={<IconFilter size={14} />}
                        />
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent width="350px" shadow="xl" color="gray.700">
                          <PopoverHeader>
                            <Heading size="xs">Event Value</Heading>
                          </PopoverHeader>
                          <PopoverBody paddingY={4}>
                            <FormControl>
                              <FormLabel>Filter</FormLabel>
                              <Input
                                defaultValue={props.params.event_value}
                                onChange={(e) => (filters.eventValue.current = e.target.value)}
                                placeholder="Example: Demo Booked, /docs"
                              />
                            </FormControl>
                            <FormControl mt={3}>
                              <FormLabel>Sort</FormLabel>
                              <Select
                                size="sm"
                                defaultValue={currentSort.event_value}
                                onChange={(e) => (sorting.eventValue.current = e.target.value)}
                              >
                                <option></option>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                              </Select>
                            </FormControl>
                          </PopoverBody>
                          <PopoverFooter textAlign="right" borderTop="none">
                            <Button
                              colorScheme="purple"
                              size="sm"
                              onClick={() =>
                                setParams(onClose, {
                                  event_value: filters.eventValue.current,
                                  sort_by: sorting.eventValue.current
                                    ? `event_value-${sorting.eventValue.current}`
                                    : undefined
                                })
                              }
                            >
                              Apply
                            </Button>
                          </PopoverFooter>
                        </PopoverContent>
                      </Portal>
                    </>
                  )}
                </Popover>
              </HStack>
            </Th>
            <Th isNumeric>
              <HStack justifyContent="flex-end">
                <Text>Exp. Accounts</Text>
                <Popover isLazy lazyBehavior="keepMounted">
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <IconButton
                          aria-label="Filter and / or sort exposed accounts column"
                          variant="ghost"
                          size="xs"
                          icon={<IconFilter size={14} />}
                        />
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent width="350px" shadow="xl" color="gray.700">
                          <PopoverHeader>
                            <Heading size="xs">Exposed Accounts</Heading>
                            <Text mt={2} fontSize="sm" color="gray.500">
                              The number of distinct accounts that got exposed to each event
                            </Text>
                          </PopoverHeader>
                          <PopoverBody paddingY={4}>
                            <FormControl>
                              <FormLabel>Minimum Exposed Accounts</FormLabel>
                              <Select
                                size="sm"
                                defaultValue={props.params.min_exposed}
                                onChange={(e) => (filters.minExposed.current = e.target.value)}
                              >
                                <option>5</option>
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                              </Select>
                            </FormControl>
                            <FormControl mt={3}>
                              <FormLabel>Sort</FormLabel>
                              <Select
                                size="sm"
                                defaultValue={currentSort.exposed_accounts}
                                onChange={(e) => (sorting.exposedAccounts.current = e.target.value)}
                              >
                                <option></option>
                                <option value="desc">Highest to lowest</option>
                                <option value="asc">Lowest to highest</option>
                              </Select>
                            </FormControl>
                          </PopoverBody>
                          <PopoverFooter textAlign="right" borderTop="none">
                            <Button
                              colorScheme="purple"
                              size="sm"
                              onClick={() =>
                                setParams(onClose, {
                                  min_exposed: filters.minExposed.current,
                                  sort_by: sorting.exposedAccounts.current
                                    ? `exposed_accounts-${sorting.exposedAccounts.current}`
                                    : undefined
                                })
                              }
                            >
                              Apply
                            </Button>
                          </PopoverFooter>
                        </PopoverContent>
                      </Portal>
                    </>
                  )}
                </Popover>
              </HStack>
            </Th>
            <Th isNumeric>
              <HStack justifyContent="flex-end">
                <Text>Conv. Accounts</Text>
                <Popover isLazy lazyBehavior="keepMounted">
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <IconButton
                          aria-label="Filter and / or sort converted accounts column"
                          variant="ghost"
                          size="xs"
                          icon={<IconFilter size={14} />}
                        />
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent width="350px" shadow="xl" color="gray.700">
                          <PopoverHeader>
                            <Heading size="xs">Converted Accounts</Heading>
                            <Text mt={2} fontSize="sm" color="gray.500">
                              The number of distinct accounts that triggered a conversion for each event.
                            </Text>
                          </PopoverHeader>
                          <PopoverBody paddingY={4}>
                            <FormControl>
                              <FormLabel>Minimum Converted Accounts</FormLabel>
                              <Select
                                size="sm"
                                defaultValue={props.params.min_converted}
                                onChange={(e) => (filters.minConverted.current = e.target.value)}
                              >
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                              </Select>
                            </FormControl>
                            <FormControl mt={3}>
                              <FormLabel>Sort</FormLabel>
                              <Select
                                size="sm"
                                defaultValue={currentSort.converted_accounts}
                                onChange={(e) => (sorting.convertedAccounts.current = e.target.value)}
                              >
                                <option></option>
                                <option value="desc">Highest to lowest</option>
                                <option value="asc">Lowest to highest</option>
                              </Select>
                            </FormControl>
                          </PopoverBody>
                          <PopoverFooter textAlign="right" borderTop="none">
                            <Button
                              colorScheme="purple"
                              size="sm"
                              onClick={() =>
                                setParams(onClose, {
                                  min_converted: filters.minConverted.current,
                                  sort_by: sorting.convertedAccounts.current
                                    ? `converted_accounts-${sorting.convertedAccounts.current}`
                                    : undefined
                                })
                              }
                            >
                              Apply
                            </Button>
                          </PopoverFooter>
                        </PopoverContent>
                      </Portal>
                    </>
                  )}
                </Popover>
              </HStack>
            </Th>
            <Th isNumeric>
              <HStack justifyContent="flex-end">
                <Text>Conv. Rate</Text>
                <Popover isLazy lazyBehavior="keepMounted">
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <IconButton
                          aria-label="Sort conversion rate column"
                          variant="ghost"
                          size="xs"
                          icon={<IconFilter size={14} />}
                        />
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent width="350px" shadow="xl" color="gray.700">
                          <PopoverHeader>
                            <Heading size="xs">Conversion Rate</Heading>
                            <Text mt={2} fontSize="sm" color="gray.500">
                              The percentage of distinct accounts converted over distinct exposed accounts. An account
                              can only convert once.
                            </Text>
                          </PopoverHeader>
                          <PopoverBody paddingY={4}>
                            <FormControl>
                              <FormLabel>Sort</FormLabel>
                              <Select
                                size="sm"
                                defaultValue={currentSort.conversion_rate}
                                onChange={(e) => (sorting.conversionRate.current = e.target.value)}
                              >
                                <option></option>
                                <option value="desc">Highest to lowest</option>
                                <option value="asc">Lowest to highest</option>
                              </Select>
                            </FormControl>
                          </PopoverBody>
                          <PopoverFooter textAlign="right" borderTop="none">
                            <Button
                              colorScheme="purple"
                              size="sm"
                              onClick={() =>
                                setParams(onClose, {
                                  sort_by: sorting.conversionRate.current
                                    ? `conversion_rate-${sorting.conversionRate.current}`
                                    : undefined
                                })
                              }
                            >
                              Apply
                            </Button>
                          </PopoverFooter>
                        </PopoverContent>
                      </Portal>
                    </>
                  )}
                </Popover>
              </HStack>
            </Th>
            <Th isNumeric>
              <HStack justifyContent="flex-end">
                <Text>Confidence</Text>
                <Popover isLazy lazyBehavior="keepMounted">
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <IconButton
                          aria-label="Sort by confidence"
                          variant="ghost"
                          size="xs"
                          icon={<IconFilter size={14} />}
                        />
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent width="350px" shadow="xl" color="gray.700">
                          <PopoverHeader>
                            <Heading size="xs">Confidence Level</Heading>
                            <Text mt={2} fontSize="sm" color="gray.500">
                              The confidence that the result is statistically significant. Keep in mind the
                              exposed/converted sample size as you look at this (higher is better). To know statistical
                              significance you need at least 5 conversions.
                            </Text>
                          </PopoverHeader>
                          <PopoverBody paddingY={4}>
                            <Stack spacing={4}>
                              <FormControl>
                                <FormLabel>Statistical Significance</FormLabel>
                                <Select
                                  size="sm"
                                  defaultValue={props.params.statsig}
                                  onChange={(e) => (filters.statsig.current = e.target.value)}
                                >
                                  <option value="">all</option>
                                  <option value="true">only statistically significant</option>
                                </Select>
                              </FormControl>
                              <FormControl>
                                <FormLabel>Sort</FormLabel>
                                <Select
                                  size="sm"
                                  defaultValue={currentSort.confidence}
                                  onChange={(e) => (sorting.confidence.current = e.target.value)}
                                >
                                  <option></option>
                                  <option value="desc">Highest to lowest</option>
                                  <option value="asc">Lowest to highest</option>
                                </Select>
                              </FormControl>
                            </Stack>
                          </PopoverBody>
                          <PopoverFooter textAlign="right" borderTop="none">
                            <Button
                              colorScheme="purple"
                              size="sm"
                              onClick={() =>
                                setParams(onClose, {
                                  statsig: filters.statsig.current,
                                  sort_by: sorting.confidence.current
                                    ? `confidence-${sorting.confidence.current}`
                                    : undefined
                                })
                              }
                            >
                              Apply
                            </Button>
                          </PopoverFooter>
                        </PopoverContent>
                      </Portal>
                    </>
                  )}
                </Popover>
              </HStack>
            </Th>
            <Th>
              <HStack>
                <Text>Impact</Text>
                <Popover isLazy lazyBehavior="keepMounted">
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <IconButton
                          aria-label="Sort conversion rate column"
                          variant="ghost"
                          size="xs"
                          icon={<IconFilter size={14} />}
                        />
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent width="350px" shadow="xl" color="gray.700">
                          <PopoverHeader>
                            <Heading size="xs">Impact</Heading>
                            <Text mt={2} fontSize="sm" color="gray.500">
                              Conversion rate normalized against the baseline conversion rate of visiting the homepage.
                              <br />
                              If an impact score is 7x, you can read this as:
                              <em>
                                {' '}
                                Someone who consumed this content is 7x more likely to convert than the average homepage
                                visitor
                              </em>
                              .
                            </Text>
                          </PopoverHeader>
                          <PopoverBody paddingY={4}>
                            <FormControl>
                              <FormLabel>Sort</FormLabel>
                              <Select
                                size="sm"
                                defaultValue={currentSort.impact}
                                onChange={(e) => (sorting.impact.current = e.target.value)}
                              >
                                <option></option>
                                <option value="desc">Highest to lowest</option>
                                <option value="asc">Lowest to highest</option>
                              </Select>
                            </FormControl>
                          </PopoverBody>
                          <PopoverFooter textAlign="right" borderTop="none">
                            <Button
                              colorScheme="purple"
                              size="sm"
                              onClick={() =>
                                setParams(onClose, {
                                  sort_by: sorting.impact.current ? `impact-${sorting.impact.current}` : undefined
                                })
                              }
                            >
                              Apply
                            </Button>
                          </PopoverFooter>
                        </PopoverContent>
                      </Portal>
                    </>
                  )}
                </Popover>
              </HStack>
            </Th>
            {props.report.kind !== 'custom_events' && (
              <Th isNumeric>
                <HStack justifyContent="flex-end">
                  <Text>Pipeline Influence</Text>
                  <Popover isLazy lazyBehavior="keepMounted">
                    {({ onClose }) => (
                      <>
                        <PopoverTrigger>
                          <IconButton
                            aria-label="Sort pipeline influence column"
                            variant="ghost"
                            size="xs"
                            icon={<IconFilter size={14} />}
                          />
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent width="350px" shadow="xl" color="gray.700">
                            <PopoverHeader>
                              <Heading size="xs">Pipeline Influence</Heading>
                              <Text mt={2} fontSize="sm" color="gray.500">
                                The total pipeline of account opportunities that got influenced by this event.
                                <br />
                                Note that this is not meant to be attribution (there is no first touch or last touch
                                model), but rather give signal on pipeline influence.
                              </Text>
                            </PopoverHeader>
                            <PopoverBody paddingY={4}>
                              <FormControl>
                                <FormLabel>Sort</FormLabel>
                                <Select
                                  size="sm"
                                  defaultValue={currentSort.pipeline_influence}
                                  onChange={(e) => (sorting.pipelineInfluence.current = e.target.value)}
                                >
                                  <option></option>
                                  <option value="desc">Highest to lowest</option>
                                  <option value="asc">Lowest to highest</option>
                                </Select>
                              </FormControl>
                            </PopoverBody>
                            <PopoverFooter textAlign="right" borderTop="none">
                              <Button
                                colorScheme="purple"
                                size="sm"
                                onClick={() =>
                                  setParams(onClose, {
                                    sort_by: sorting.pipelineInfluence.current
                                      ? `pipeline_influence-${sorting.pipelineInfluence.current}`
                                      : undefined
                                  })
                                }
                              >
                                Apply
                              </Button>
                            </PopoverFooter>
                          </PopoverContent>
                        </Portal>
                      </>
                    )}
                  </Popover>
                </HStack>
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {props.rows.map((row, idx) => (
            <Tr key={idx} _hover={{ bg: 'gray.50' }}>
              <Td>{eventTypeLabels[row.event_type]}</Td>
              <Td>
                <TextEllipsis tooltip maxW={props.report.kind === 'custom_events' ? 420 : 235}>
                  {row.event_value}
                </TextEllipsis>
              </Td>
              <Td isNumeric width={165}>
                {formatNumber(row.exposed_accounts, { maximumFractionDigits: 0 })}
              </Td>
              <Td isNumeric width={175}>
                {formatNumber(row.converted_accounts, { maximumFractionDigits: 0 })}
              </Td>
              <Td isNumeric width={140}>
                {(row.conversion_rate * 100).toFixed(2)}%
              </Td>
              <Td width="1px" isNumeric color={row.statsig ? 'green.500' : 'gray.400'}>
                <HStack justifyContent="flex-end">
                  {row.statsig && (
                    <Tooltip label="This impact score is statistically significant">
                      <IconCircleCheck size={16} />
                    </Tooltip>
                  )}
                  <Text fontWeight={row.statsig ? 'medium' : undefined}>
                    {row.confidence && row.exposed_accounts >= 5 && row.converted_accounts >= 5
                      ? `${(Math.floor(row.confidence * 1000) / 10).toFixed(1)}%`
                      : '-'}
                  </Text>
                </HStack>
              </Td>
              <Td width={155}>
                <HStack>
                  <ImpactRange value={row.impact} />
                  <span>{row.impact.toFixed(2)}x</span>
                </HStack>
              </Td>
              {props.report.kind !== 'custom_events' && (
                <Td isNumeric width={190}>
                  {row.pipeline_influence
                    ? `$${formatNumber(row.pipeline_influence, { maximumFractionDigits: 0 })}`
                    : '-'}
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <TableFooter
        pageMeta={props.page_meta}
        page={props.page_meta.current_page}
        nextPath={mergeParams(window.location.toString(), {
          page: String(props.page_meta.next_page)
        })}
        prevPath={mergeParams(window.location.toString(), {
          page: String(props.page_meta.prev_page)
        })}
      />
    </TableContainer>
  )
}

interface ImpactRangeProps {
  value: number
}

// See https://getkoala.slack.com/archives/C056GQSB0M7/p1689772710858569 for more
// <1x     = nothing
// 1x - 2x = 1 dot
// 2x - 4x = 2 dots
// 4x - 6x = 3 dots
// 6x - 8x = 4 dots
// 8x+     = 5 dots
function ImpactRange(props: ImpactRangeProps) {
  const value = Math.floor(props.value)
  const colors = [
    value < 1 ? '#E2E8F0' : '#FEEBCB',
    value < 2 ? '#E2E8F0' : '#FBD38D',
    value < 4 ? '#E2E8F0' : '#F6AD55',
    value < 6 ? '#E2E8F0' : '#ED8936',
    value >= 8 ? '#E53E3E' : '#E2E8F0'
  ]
  return (
    <Flex rounded="base" mr={1} overflow="hidden">
      <Box boxSize={3} bg={colors[0]} />
      <Box boxSize={3} bg={colors[1]} />
      <Box boxSize={3} bg={colors[2]} />
      <Box boxSize={3} bg={colors[3]} />
      <Box boxSize={3} bg={colors[4]} />
    </Flex>
  )
}

type FieldProps = FlexProps & { label: string; value?: React.ReactNode | null }

function Field(props: React.PropsWithChildren<FieldProps>) {
  const { label, value, children, ...rest } = props
  if (!value && !children) {
    return null
  }

  return (
    <Flex gap={2} fontSize="xs" fontWeight="semibold" {...rest}>
      <Text color="gray.600" fontWeight="normal">
        {label}
      </Text>
      {children ?? <Text>{value ?? '—'}</Text>}
    </Flex>
  )
}
