import { Icon, IconProps, Square, SquareProps } from '@chakra-ui/react'
import { Icon as TablerIcon } from '@tabler/icons-react'
import React from 'react'

export interface SquareIconProps extends SquareProps {
  colorScheme?: string
  icon: TablerIcon | React.ElementType
  iconSize?: IconProps['boxSize']
}

export default function SquareIcon({ colorScheme = 'gray', icon, iconSize, ...props }: SquareIconProps) {
  return (
    <Square flex="none" bg={`${colorScheme}.50`} color={`${colorScheme}.500`} padding={1} rounded="md" {...props}>
      <Icon as={icon} boxSize={iconSize || 5} />
    </Square>
  )
}
