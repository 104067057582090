import {
  Box,
  ButtonGroup,
  Checkbox,
  Flex,
  HStack,
  HTMLChakraProps,
  Icon,
  Link,
  Skeleton,
  Stack,
  Table,
  TableColumnHeaderProps,
  TableContainer,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  Wrap
} from '@chakra-ui/react'
import { Icon as TablerIcon, IconMail, IconPlus, IconUser } from '@tabler/icons-react'
import ms from 'ms'
import pluralize from 'pluralize'
import React, { useMemo } from 'react'
import { useMedia } from 'react-use'
import { flatGet } from '../../../../lib/flatGet'
import { formatFriendlyCurrency } from '../../../../lib/number-format'
import { Account } from '../../../../types/Account'
import { App } from '../../../../types/App'
import { FieldDefinition } from '../../../../types/FieldDefinition'
import { PlayItem } from '../../../../types/Play'
import { ProfileRecord } from '../../../../types/Profile'
import { Project } from '../../../../types/Project'
import { FacetParams } from '../../../data/use-facets'
import { PersistedFieldDefinition, useFieldDefinitionsIndexedByDataSource } from '../../../data/use-field-definitions'
import { useFieldMappings } from '../../../data/use-field-mappings'
import { UrlFilterParams } from '../../../data/use-url-filters'
import { BubbleTag } from '../../../ui/BubbleTag'
import {
  ColumnInfo,
  ColumnSelectorDropdown,
  defaultAccountColumns,
  useTableDisplayMode
} from '../../../ui/ColumnSelector'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { CompanyBubble } from '../../../ui/CompanyBubble'
import { Iconify } from '../../../ui/Iconify'
import { useCurrentProject } from '../../../ui/ProjectsContext'
import { SortableHeader } from '../../../ui/Table'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { blocked, RedactedAccountCell, useEntitlements } from '../../../ui/useEntitlements'
import { useOverflow } from '../../../ui/useOverflow'
import { AccountCTA } from '../../accounts/components/AccountCTA'
import { AccountTrends } from '../../accounts/components/AccountTrends'
import { HighlightedField } from '../../accounts/components/DetailsV2/SummaryCard'
import { IntentSummary } from '../../accounts/components/Intent/IntentSummary'
import { getItemDisplay } from '../../accounts/facets/categories'
import { accountPath } from '../../accounts/lib/account-path'
import { AIColumnCell, AIColumnHeader, useAgentStore } from '../../ai_agents/components/AIAgentColumns'
import { AllEntitlements } from '../../billing/show'
import { CompleteButton } from '../../plays/components/CompleteButton'
import { QuickDismissButton } from '../../plays/components/QuickDismissButton'
import { ResetButton } from '../../plays/components/ResetButton'
import { IntentSignalCell } from '../../profiles/components/profile-feed'
import { HighlightedAccount } from '../types'
import { Breakdown, LetterGrade } from './breakdown'
import { TrendCell } from './trend-cell'

export interface Column {
  id: string
  Title: React.FC<HTMLChakraProps<'th'>>
  Cell: React.FC<{ account: HighlightedAccount } & HTMLChakraProps<'td'>>
}

const defaultColumnKeys = (project?: Project) => {
  if (project?.scoring_enabled) {
    return ['FitScore', 'IntentScore', 'Trend', 'Visitors', 'LastVisit', 'KQL']
  } else {
    return ['IntentScore', 'Trend', 'Visitors', 'LastVisit', 'KQL']
  }
}

export type AccountSortParam =
  | 'overall_grade'
  | 'fit_grade'
  | 'intent_grade'
  | 'intent_score'
  | 'intent_trend'
  | 'page_views'
  | 'focus_time'
  | 'visitors'
  | 'last_seen'
  | 'latest_intent'

function getRangeHeader(range) {
  switch (range) {
    case 'day':
      return '24h'
    case 'week':
      return '7d'
    default:
      return '30d'
  }
}

const rowHover = {
  bg: [undefined, 'gray.50']
}

const columnRenderers = {
  FitScore: {
    Td: (props: CellRendererProps) => {
      const account = props.account
      return (
        <Td>
          {!!account.fit_grade && (
            <HStack>
              <LetterGrade
                value={account.fit_grade}
                label={account.fit_grade_letter}
                fontSize="md"
                textAlign="center"
              />
              <Breakdown domain={account.domain} />
            </HStack>
          )}
        </Td>
      )
    }
  },

  IntentScore: {
    Td: (props: CellRendererProps) => {
      const account = props.account
      return (
        <Td py={2}>
          <Box flex="none" display="flex" overflow="hidden">
            <IntentSummary accountId={account.id} intent={account.intent} />
          </Box>
        </Td>
      )
    }
  },

  Trend: {
    Td: (props: CellRendererProps) => {
      const account = props.account
      return (
        <Td>
          <AccountTrends account={account} />
        </Td>
      )
    }
  },

  Visitors: {
    Th: ({ columnId, columnTitle, columnIcon, columnType, intentRange, ...props }: HeaderRendererProps) => {
      return (
        <SortableHeader
          info={`The number of active visitors that are known or anonymous in the past ${intentRange}`}
          {...props}
        >
          Visitors ({getRangeHeader(intentRange)})
        </SortableHeader>
      )
    },
    Td: (props: CellRendererProps) => {
      const account = props.account
      const intentRange = props.intentRange ?? 'week'
      const total = account.visitor_stats?.visitors?.[intentRange] ?? 0
      const known = account.visitor_stats?.identified?.[intentRange] ?? 0
      const anons = Math.max(0, total - known)

      return (
        <Td role="group">
          {account.visitor_stats && (
            <HStack spacing={4}>
              {known > 0 && (
                <Tooltip
                  label={`${known.toLocaleString()} identified ${pluralize(
                    'visitor',
                    known
                  )} seen in the past ${intentRange}`}
                  openDelay={400}
                  hasArrow
                  arrowSize={6}
                >
                  <HStack spacing={1}>
                    <Icon as={IconMail} boxSize={4} color="gray.400" />
                    <Text fontWeight="medium">{known.toLocaleString()}</Text>
                  </HStack>
                </Tooltip>
              )}
              {anons > 0 && (
                <Tooltip
                  label={`${anons.toLocaleString()} anonymous ${pluralize(
                    'visitor',
                    anons
                  )} seen in the past ${intentRange}`}
                  openDelay={400}
                  hasArrow
                  arrowSize={6}
                >
                  <HStack spacing={1}>
                    <Icon as={IconUser} boxSize={4} color="gray.400" />
                    <Text>{anons.toLocaleString()}</Text>
                  </HStack>
                </Tooltip>
              )}
              {!known && !anons && <Text color="gray.400">—</Text>}
            </HStack>
          )}
        </Td>
      )
    }
  },

  LastVisit: {
    Td: (props: CellRendererProps) => {
      const account = props.account

      return (
        <Td color={account.last_seen_at ? undefined : 'gray.400'}>
          <TimeAgo time={account.last_seen_at} fallback="—" />
        </Td>
      )
    }
  },

  KQL: {
    Td: (props: CellRendererProps) => {
      const account = props.account
      const sortedKQLs = account.latest_intent_signals ?? []

      return (
        <Td>
          <IntentSignalCell feed={sortedKQLs} />
        </Td>
      )
    }
  },

  'company.linkedin_url': {
    Td: (props: CellRendererProps) => {
      const account = props.account
      const linkedinUrl = account.company?.linkedin_url

      return (
        <Td width="1px">
          {linkedinUrl && (
            <RedactedAccountCell showLock={false} element={account} entitlements={props.entitlements}>
              <Link variant="dotted" href={linkedinUrl} isExternal>
                {linkedinUrl.split('/').reverse()[0]}
              </Link>
            </RedactedAccountCell>
          )}
        </Td>
      )
    }
  },
  focus_time_trend: {
    Th: (props: HeaderRendererProps) => {
      return <SortableHeader isNumeric>Session Time ({getRangeHeader(props.intentRange)})</SortableHeader>
    },
    Td: ({ account, intentRange }: CellRendererProps) => {
      return (
        <Td isNumeric>
          {Boolean(account.focus_time_trend?.[intentRange]?.current?.value) && (
            <TrendCell stats={account.focus_time_trend} format={(val) => (val ? ms(val) : '—')} range={intentRange} />
          )}
        </Td>
      )
    }
  },
  'hubspot_data.crm_fields.hs_object_id': {
    Td: (props: CellRendererProps) => {
      const account = props.account
      const externalId = (account as any).hubspot_data?.crm_fields?.hs_object_id
      const record = account.crm_records?.hubspot_company || null

      return (
        <Td minWidth="140px" maxWidth="300px">
          {externalId && record?.external_id === externalId ? (
            <Link variant="dotted" href={record?.permalink} isExternal>
              {externalId}
            </Link>
          ) : (
            externalId
          )}
        </Td>
      )
    }
  },
  'salesforce_data.crm_fields.Id_id': {
    Td: (props: CellRendererProps) => {
      const account = props.account
      const externalId = (account as any).salesforce_data?.crm_fields?.Id_id
      const record = account.crm_records?.salesforce_account || null

      return (
        <Td minWidth="140px" maxWidth="300px">
          {externalId && record?.external_id === externalId ? (
            <Link variant="dotted" href={record?.permalink} isExternal>
              {externalId}
            </Link>
          ) : (
            externalId
          )}
        </Td>
      )
    }
  },
  page_views_trend: {
    Th: (props: HeaderRendererProps) => {
      return (
        <SortableHeader columnKey={props.columnKey} onRemoveColumn={props.onRemoveColumn}>
          Page Views ({getRangeHeader(props.intentRange)})
        </SortableHeader>
      )
    },
    Td: ({ account, intentRange }: CellRendererProps) => {
      return (
        <Td isNumeric>
          {account.page_views_trend && (
            <TrendCell
              stats={account.page_views_trend}
              format={(val) => val?.toLocaleString() || ''}
              range={intentRange}
            />
          )}
        </Td>
      )
    }
  }
}

interface HeaderRendererProps extends TableColumnHeaderProps {
  columnId: string
  columnKey: string
  columnTitle: string
  columnIcon?: TablerIcon | string | typeof Icon
  columnType?: string
  intentRange: 'day' | 'week' | 'month'
  sortBy?: string
  currentSort?: string
  fieldDefinition?: FieldDefinition
  onSortChange?: (sortBy: string | undefined) => void
  onRemoveColumn?: (column: string) => void
  onFilterColumn?: (column: string) => void
}

function HeaderRenderer(props: HeaderRendererProps) {
  const ThRenderer = columnRenderers[props.columnId]?.Th
  if (ThRenderer) {
    return <ThRenderer {...props} />
  }

  const { columnId, columnKey, columnTitle, columnIcon, columnType, intentRange, fieldDefinition, ...rest } = props
  const isNumeric = !!columnType && ['float', 'long', 'number', 'double'].includes(columnType)
  const sortable = rest.sortBy || (!!columnType && !['object', 'nested', 'binary'].includes(columnType))
  const sortBy = sortable ? rest.sortBy || columnKey : undefined

  return (
    <SortableHeader
      columnKey={columnKey}
      isNumeric={isNumeric}
      {...rest}
      sortBy={sortBy}
      fieldDefinition={fieldDefinition}
      recordType="account"
      columnTitle={columnTitle}
      columnType={columnType}
    >
      <Flex gap={1} alignItems="center" isTruncated>
        {columnIcon && <Iconify icon={columnIcon} size={15} flex="none" />}
        <TextEllipsis maxW="100%" tooltip>
          {columnTitle}
        </TextEllipsis>
      </Flex>
    </SortableHeader>
  )
}

export function uniqueAndCount(arr: string[]) {
  // Count occurrences
  const countMap = new Map()
  for (const item of arr) {
    countMap.set(item, (countMap.get(item) || 0) + 1)
  }

  // Transform to desired format
  return Array.from(countMap, ([value, count]) => [value, count])
}

interface CellRendererProps {
  columnId: string
  columnKey: string
  columnTitle?: string
  columnIcon?: TablerIcon | string | typeof Icon
  columnType?: string
  isLoading?: boolean
  intentRange: 'day' | 'week' | 'month'
  facetParams?: FacetParams | UrlFilterParams
  account: HighlightedAccount
  entitlements?: AllEntitlements
  isComfy?: boolean
  fieldDefinition?: FieldDefinition
}

function CellRenderer(props: CellRendererProps) {
  const { columnKey, columnType, account, isLoading, isComfy, fieldDefinition } = props
  const isNumeric = !!columnType && ['float', 'long', 'number', 'double'].includes(columnType)
  const isDate = columnType === 'date'

  if (isLoading) {
    return (
      <Td minW="140px" maxW="300px" isNumeric={isNumeric}>
        <Skeleton height="16px" rounded="base" startColor="gray.50" endColor="gray.200" />
      </Td>
    )
  }

  const CustomRenderer = columnRenderers[props.columnId]?.Td
  if (CustomRenderer) {
    return <CustomRenderer {...props} />
  }

  let value = flatGet(account, columnKey)
  if (Array.isArray(value) && value.length === 1 && isNumeric) {
    value = value[0]
  }

  const isLongText = typeof value === 'string' && value.length > 100

  let minW = isComfy ? '140px' : '140px'
  const maxW = isComfy ? '600px' : '300px'

  if (isLongText && isComfy) {
    minW = '450px'
  }

  const TagCollectionParent = isComfy ? Wrap : Flex

  return (
    <Td minW={minW} maxW={maxW} isNumeric={isNumeric} p={0}>
      <Box
        minW={minW}
        maxW={maxW}
        whiteSpace={isComfy ? undefined : 'nowrap'}
        overflowX={isComfy ? undefined : 'hidden'}
        overflowY={isComfy ? 'auto' : undefined}
        textOverflow={isComfy ? 'clip' : 'ellipsis'}
        maxH={isComfy ? '300px' : undefined}
        py={isComfy ? 3 : 2}
        px={2}
      >
        <RedactedAccountCell showLock={false} element={account} entitlements={props.entitlements}>
          {fieldDefinition && value && (
            <HighlightedField field={fieldDefinition as PersistedFieldDefinition} record={account} fieldValue={value} />
          )}
          {(!fieldDefinition || !value) && (
            <>
              {isDate ? (
                <TimeAgo time={value} mode="full" />
              ) : typeof value === 'string' ? (
                value
              ) : Array.isArray(value) ? (
                <TagCollectionParent flex="1 1 auto" gap={1} isTruncated={!isComfy}>
                  {uniqueAndCount(
                    value.filter((v) => ['string', 'boolean', 'number'].includes(typeof v) && v !== '').sort()
                  ).map(([item, count], index) => (
                    <BubbleTag
                      key={JSON.stringify({ item, index })}
                      title={typeof item === 'number' ? item.toLocaleString() : item?.toString()}
                      variant="subtleBorder"
                      value={item}
                    >
                      <TagLabel isTruncated minWidth="10px" maxWidth={isComfy ? '100%' : '180px'}>
                        {count > 1 && <>{count}× </>}{' '}
                        {typeof item === 'number' ? item.toLocaleString() : item?.toString()}
                      </TagLabel>
                    </BubbleTag>
                  ))}
                </TagCollectionParent>
              ) : typeof value === 'number' && isNumeric && columnKey.endsWith('_currency') ? (
                formatFriendlyCurrency(value, { truncateFractionDigits: true })
              ) : typeof value === 'number' && isNumeric ? (
                value.toLocaleString()
              ) : value === null || value === undefined ? null : (
                JSON.stringify(value)
              )}
            </>
          )}
        </RedactedAccountCell>
      </Box>
    </Td>
  )
}

interface Props {
  accounts: HighlightedAccount[] | ProfileRecord[]
  range?: 'day' | 'week' | 'month' | 'all' | 'any'
  extraColumns?: Column[]
  columns?: Array<string>
  apps?: App[]
  compact?: boolean
  sticky?: boolean
  onSortChange?: (sort_param: string | null | undefined) => void
  sortingBy?: string
  facetParams?: FacetParams | UrlFilterParams
  onClaim?: (acc: Account) => void
  onRefresh?: () => void
  canAddColumns?: boolean
  loadingColumns?: string[]
  onColumnChange?: (columns: Array<string | ColumnInfo>) => void
  onColumnRemove?: (column: string) => void
  onFilterColumn?: (column: string) => void

  onAIColumnChange?: (columns: string[]) => void
  aiColumns?: string[]

  openLinksInNewTab?: boolean
  noGrays?: boolean
  showActions?: boolean
  selected?: any[]
  getCheckboxProps?: (props: Record<string, any>) => any
  onSelectAll?: () => void

  companyCellMode?: 'name-only' | 'task-mode'

  // task mode actions
  onCompleteItem?: (itemId: string, status: string) => void
  onDismissItem?: (itemId: string, status: string) => void
  onResetItem?: (itemId: string, status: string) => void
  onUpdateItem?: (itemId: string, updatedItem: PlayItem) => void
}

function rangeDefault(range) {
  switch (range) {
    case 'day':
    case 'week':
    case 'month':
      return range
    case 'all':
    case 'any':
      return 'month'
    default:
      return 'week'
  }
}

const cellWidths = {
  'name-only': '300px',
  'task-mode': '500px'
}

export function AccountList(props: Props) {
  const project = useCurrentProject()
  const entitlements = useEntitlements()
  const companyCellMode = props.companyCellMode || 'name-only'

  const domains = useMemo(() => {
    return props.accounts.map((record) => record.domain || record.company?.domain).filter(Boolean)
  }, [props.accounts])

  const {
    tasks,
    runningTasks,
    setRunningTasks,
    responses: aiResponses,
    setResponses,
    loadingCache,
    refetchAgents
  } = useAgentStore({
    domains: domains,
    agentSlugs: props.aiColumns ?? [],
    target: 'companies'
  })

  const apps = props.apps
  const accounts = props.accounts as HighlightedAccount[]
  const intentRange = rangeDefault(props.range)

  const columnsToDisplay = useMemo(() => {
    const cols = props.columns ?? defaultColumnKeys(project)
    return cols.filter((col) => {
      // dont show fit score column unless enabled for their workspace
      if (col === 'FitScore' && entitlements?.icp_scoring === false) {
        return false
      }

      if (col === 'auto_icp_account_score.fit_grade_letter' && entitlements?.icp_scoring === false) {
        return false
      }

      return true
    })
  }, [project, props.columns, entitlements])

  const mappings = useFieldMappings('/accounts/facet-cloud', {
    cached: true,
    enabled: true
  })

  const displayedColumns: ColumnInfo[] = useMemo(() => {
    return columnsToDisplay.map((column) => {
      return (
        defaultAccountColumns.find((c) => c.id === column || c.key === column) ||
        getItemDisplay(
          column,
          apps || [],
          'account',
          mappings.data?.find((m) => m.facet === column)
        )
      )
    })
  }, [columnsToDisplay, apps, mappings.data])

  const largeEnoughScreen = useMedia('(min-width: 768px) and (min-height: 600px)')
  const stickyColumn = !props.compact && props.sticky !== false && largeEnoughScreen
  const facetMappings = props.facetParams?.facetMappings ?? {}

  const { scrollRef, overflowLeft, overflowTop } = useOverflow()

  const [mode] = useTableDisplayMode()
  const isComfy = useMemo(() => mode === 'comfy', [mode])

  const fieldDefsResponse = useFieldDefinitionsIndexedByDataSource('account', {
    enabled: true
  })

  return (
    <TableContainer
      ref={scrollRef}
      position="relative"
      className={`${overflowLeft ? 'scrolled' : ''} ${overflowTop ? 'scrolled-y' : ''}`.trim() || undefined}
      borderTop="1px solid"
      borderColor="border.lightest"
      overflowY="auto"
      fontSize="sm"
      w="100%"
      sx={{ overscrollBehaviorX: 'contain' }}
    >
      <Table variant="bordered" size={isComfy ? 'md' : 'sm'} w="100%" height="1px">
        {!props.compact && (
          <Thead className="sticky-header">
            <Tr height="100%">
              <Th
                className={stickyColumn ? 'sticky-column' : undefined}
                height="38px"
                minW={cellWidths[companyCellMode] || '300px'}
                maxW={cellWidths[companyCellMode] || '300px'}
                paddingLeft={[2.5, 4]}
              >
                <Flex alignItems="center" gap={2}>
                  {props.onSelectAll && (
                    <Checkbox
                      size="md"
                      marginRight={1}
                      isChecked={props.selected?.length === accounts.length && accounts.length > 0}
                      isIndeterminate={(props.selected?.length || 0) > 0 && props.selected?.length !== accounts.length}
                      onChange={props.onSelectAll}
                    />
                  )}
                  <Text>Company</Text>
                </Flex>
              </Th>

              {props.aiColumns?.map((c) => (
                <Th key={c} minW="140px" maxW="300px">
                  <AIColumnHeader
                    column={c}
                    tasks={tasks.filter((t) => t.agent.slug === c)}
                    runningTasks={runningTasks}
                    loadingCache={loadingCache}
                  />
                </Th>
              ))}

              {displayedColumns.map((column) => (
                <HeaderRenderer
                  key={column.key + ':header'}
                  columnId={column.id || column.key}
                  columnKey={column.key}
                  columnTitle={fieldDefsResponse?.[column.key]?.label || column.label}
                  columnIcon={column.icon}
                  columnType={column.type || facetMappings[column.key]?.type}
                  intentRange={intentRange}
                  sortBy={column.sortBy}
                  currentSort={props.sortingBy}
                  onSortChange={props.onSortChange}
                  onRemoveColumn={props.onColumnRemove}
                  onFilterColumn={props.onFilterColumn}
                  px={[2, 3]}
                  fieldDefinition={fieldDefsResponse?.[column.key]}
                />
              ))}

              {props.extraColumns?.map((c) => <c.Title key={c.id} />)}
              {props.canAddColumns && (
                <Th color="gray.500" _hover={{ bg: 'gray.50', color: 'gray.600' }}>
                  <ColumnSelectorDropdown
                    audienceKind="account"
                    apps={apps}
                    selectedColumns={props.columns}
                    onChange={props.onColumnChange}
                  >
                    <Flex
                      as="button"
                      type="button"
                      alignItems="center"
                      width="100%"
                      height="100%"
                      gap={1}
                      fontSize="13px"
                      fontWeight="medium"
                    >
                      <Icon as={IconPlus} boxSize={4} />
                      Add column
                    </Flex>
                  </ColumnSelectorDropdown>
                </Th>
              )}
            </Tr>
          </Thead>
        )}
        <Tbody bg="white">
          {accounts.map((account) => {
            const item = account.item

            return (
              <Tr
                {...blocked(props.noGrays ? undefined : entitlements, account)}
                key={account.domain}
                role="group"
                className={props.selected?.includes(account.id) ? 'selected-row' : undefined}
                bg={props.selected?.includes(account.id) ? 'purple.50' : undefined}
                _hover={props.selected?.includes(account.id) ? undefined : rowHover}
              >
                {account.company ? (
                  <Td
                    width="1px"
                    minW={cellWidths[companyCellMode] || '300px'}
                    maxW={cellWidths[companyCellMode] || '300px'}
                    paddingLeft={[2.5, 4]}
                    className={stickyColumn ? 'sticky-column' : undefined}
                  >
                    <Flex alignItems="center" gap={2.5} height="100%">
                      {props.getCheckboxProps && (
                        <Checkbox size="md" marginRight={1} {...props.getCheckboxProps({ value: account.id })} />
                      )}

                      {companyCellMode === 'task-mode' && item ? (
                        <Flex flex="1" minW="400px" alignItems="center" justifyContent="space-between" gap={4} py={1}>
                          <Flex flex="1" alignItems="center" gap={2.5} isTruncated>
                            <CompanyAvatar
                              domain={account.company?.domain}
                              name={account.company?.name || account.company?.domain}
                              flex="none"
                              size="sm"
                            />

                            <Stack spacing={0.5} isTruncated>
                              <Flex alignItems="center" gap={2} isTruncated>
                                <Link
                                  display="inline-flex"
                                  href={accountPath(account)}
                                  isExternal
                                  _hover={{ textDecoration: 'underline' }}
                                >
                                  <TextEllipsis fontSize="sm" fontWeight="medium" maxW="100%" tooltip>
                                    {account.company?.name || account.company?.domain}
                                  </TextEllipsis>
                                </Link>
                              </Flex>
                              {account.company?.name && (
                                <Text fontSize="13px" color="gray.500" isTruncated>
                                  {account.company?.domain}
                                </Text>
                              )}
                            </Stack>
                          </Flex>

                          <Flex alignItems="center" gap={2} ml="auto">
                            <ButtonGroup size="sm" variant="outline" isAttached>
                              {item.status === 'completed' ? (
                                <ResetButton itemId={item.id} onSuccess={props.onResetItem} />
                              ) : (
                                <CompleteButton itemId={item.id} onSuccess={props.onCompleteItem} />
                              )}
                              {item.status === 'dismissed' ? (
                                <ResetButton itemId={item.id} onSuccess={props.onResetItem} />
                              ) : (
                                <QuickDismissButton itemId={item.id} onSuccess={props.onDismissItem} />
                              )}
                            </ButtonGroup>
                          </Flex>
                        </Flex>
                      ) : (
                        <>
                          <RedactedAccountCell
                            element={account}
                            entitlements={props.noGrays ? undefined : entitlements}
                            flexProps={{
                              gap: 2
                            }}
                          >
                            <CompanyBubble
                              name={account.company.name}
                              domain={account.domain || account.company.domain}
                              href={accountPath(account)}
                              target={props.openLinksInNewTab ? '_blank' : undefined}
                              fontWeight="semibold"
                              _groupHover={{
                                background: props.selected?.includes(account.id) ? 'rgba(255,255,255,0.65)' : 'white',
                                shadow: 'sm',
                                '& .hover-icon': {
                                  display: 'flex',
                                  opacity: 1
                                }
                              }}
                            />
                          </RedactedAccountCell>

                          {props.showActions !== false && account.domain && (
                            <Flex marginLeft="auto">
                              <AccountCTA domain={account.domain} onClaim={props.onClaim} onRefresh={props.onRefresh} />
                            </Flex>
                          )}
                        </>
                      )}
                    </Flex>
                  </Td>
                ) : (
                  <Td
                    position={stickyColumn ? 'sticky' : undefined}
                    left={0}
                    zIndex={1}
                    bg="white"
                    paddingLeft={[0, 3]}
                  ></Td>
                )}

                {props.aiColumns?.map((c) => {
                  const domain = account.domain || account.company?.domain

                  if (loadingCache) {
                    return (
                      <Td key={c + account.domain} minW="140px" maxW="300px">
                        <Skeleton height="16px" rounded="base" startColor="gray.50" endColor="gray.200" />
                      </Td>
                    )
                  }

                  const task = tasks.find((t) => t.agent.slug === c && t.domain === domain)

                  if (!task) {
                    return (
                      <Td key={c + account.domain} minW="140px" maxW="300px">
                        <Text color="gray.400">&mdash;</Text>
                      </Td>
                    )
                  }

                  return (
                    <Td key={task.id} minW="140px" maxW="300px">
                      <AIColumnCell
                        task={task}
                        isRunning={runningTasks[task.id]}
                        setRunningTasks={setRunningTasks}
                        response={aiResponses[task.id]}
                        setResponses={setResponses}
                        account={account}
                        refetchAgents={refetchAgents}
                      />
                    </Td>
                  )
                })}

                {displayedColumns.map((column) => {
                  return (
                    <CellRenderer
                      key={column.key + ':' + account.id}
                      columnId={column.id || column.key}
                      columnKey={column.key}
                      columnTitle={column.label}
                      columnIcon={column.icon}
                      columnType={column.type || facetMappings[column.key]?.type}
                      isLoading={props.loadingColumns?.includes(column.key)}
                      account={account}
                      facetParams={props.facetParams}
                      intentRange={intentRange}
                      entitlements={props.noGrays ? undefined : entitlements}
                      isComfy={isComfy}
                      fieldDefinition={fieldDefsResponse?.[column.key]}
                    />
                  )
                })}

                {props.extraColumns?.map((c) => {
                  return <c.Cell key={c.id + account.id} account={account} />
                })}

                {props.canAddColumns && (
                  <Td>
                    <Box minW="200px" />
                  </Td>
                )}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
