import React from 'react'
import { Stack, HStack, Text, Icon, Image, Flex, StackProps, Box } from '@chakra-ui/react'
import { useEffect, useRef } from 'react'
import { IconSearch, IconWorld, IconBrain, IconClock, IconPlayerPlay } from '@tabler/icons-react'
import { Message } from '@app/components/data/use-ai-recommendations'

interface ToolUsage {
  tool_name: 'search_engine' | 'web_scraper' | 'pdf_reader'
  tool_args: { url: string } | string
  content: string
}
import { getFaviconUrl } from '../../../../lib/favicon'

export type StartEvent = {
  action: 'start'
  content: string
}

type Event = Message | ToolUsage | StartEvent

interface ResearchProgressProps extends StackProps {
  toolEvents: Event[]
  onlyLast?: boolean
  maxEvents?: number
  showTitle?: boolean
}

const isToolUsage = (event: Event): event is ToolUsage => {
  return 'tool_name' in event
}

const displayContent = (event: Event) => {
  if (isToolUsage(event) && (event.tool_name === 'web_scraper' || event.tool_name === 'pdf_reader')) {
    let hostname = ''
    try {
      const url = typeof event.tool_args === 'object' ? event.tool_args?.url : event.tool_args
      hostname = url?.startsWith('http')
        ? new URL(url).hostname.replace(/^www\./, '')
        : new URL(`https://${url}`).hostname.replace(/^www\./, '')
      return (
        <Flex alignItems="center">
          <Text as="span" mr={1}>
            Visiting
          </Text>
          <Flex alignItems="center" gap={1} bg="gray.100" px={2} py={0.5} borderRadius="full">
            <Image width="12px" height="12px" rounded="full" src={getFaviconUrl(hostname)} />
            <Text as="span">{hostname}</Text>
          </Flex>
        </Flex>
      )
    } catch (error) {
      return event.content
    }
  }
  return event.content
}

export function ResearchProgress({
  toolEvents,
  onlyLast,
  maxEvents,
  showTitle,
  ...hstackProps
}: ResearchProgressProps) {
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [toolEvents])

  const getToolConfig = (event: Event) => {
    // Content type events
    if ('content_type' in event) {
      switch (event.content_type) {
        case 'queue':
          return { icon: IconClock, color: 'blue.500' }
        case 'start':
          return { icon: IconPlayerPlay, color: 'green.500' }
      }
    }

    // Tool events
    if (!isToolUsage(event)) {
      return { icon: IconBrain, color: 'orange.400' }
    }

    switch (event.tool_name) {
      case 'search_engine':
        return { icon: IconSearch, color: 'blue.400' }
      case 'web_scraper':
        return { icon: IconWorld, color: 'green.400' }
      case 'pdf_reader':
        return { icon: IconWorld, color: 'purple.400' }
      default:
        return { icon: IconBrain, color: 'orange.400' }
    }
  }

  if (onlyLast) {
    const lastEvent = toolEvents[toolEvents.length - 1]
    if (!lastEvent) return null

    const config = getToolConfig(lastEvent)
    return (
      <HStack spacing={2} alignItems="center" width="100%" {...hstackProps}>
        <Icon as={config.icon} color={config.color} flexShrink={0} />
        <Text fontStyle="italic" fontSize="sm" color="gray.700" isTruncated>
          {displayContent(lastEvent)}
        </Text>
      </HStack>
    )
  }

  return (
    <Box bg="gray.50" p={4} borderRadius="md">
      {showTitle && (
        <Text fontSize="sm" fontWeight="medium" mb={2} color="gray.700">
          Research Progress
        </Text>
      )}
      <Stack
        ref={scrollRef}
        spacing={2}
        height="100px"
        overflowY="auto"
        css={{
          '&::-webkit-scrollbar': {
            width: '4px'
          },
          '&::-webkit-scrollbar-track': {
            width: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'var(--chakra-colors-gray-300)',
            borderRadius: '24px'
          }
        }}
      >
        {(maxEvents ? toolEvents.slice(-maxEvents) : toolEvents).map((event, index) => {
          const config = getToolConfig(event)
          return (
            <HStack key={index} spacing={2} alignItems="center" width="100%" {...hstackProps}>
              <Icon as={config.icon} color={config.color} flexShrink={0} />
              <Text fontSize="xs" color="gray.700" isTruncated fontStyle="italic">
                {displayContent(event)}
              </Text>
            </HStack>
          )
        })}
      </Stack>
    </Box>
  )
}
