import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Switch,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconBell,
  IconBellRingingFilled,
  IconCheck,
  IconCircle,
  IconCircleDashed,
  IconDotsVertical,
  IconFlare,
  IconSettings,
  IconTrash,
  IconX
} from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { PlaySourceIcon } from '.'
import router from '../../../lib/router'
import { Apps } from '../../../types/App'
import { PageMeta } from '../../../types/PageMeta'
import { DisplayColumn, Play, PlayItem } from '../../../types/Play'
import { useUpdatePlay } from '../../data/use-plays'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { ColumnManagementModal } from '../../ui/ColumnSelector'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import { MiddotDivider } from '../../ui/Middot'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SegmentedControl } from '../../ui/SegmentedControl'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import { TopBarContent } from '../../ui/TopBarContext'
import { useCurrentUser } from '../../ui/UserContext'
import { UserSelector } from '../../ui/UserSelector'
import useUpdateEffect from '../../ui/useUpdateEffect'
import { SearchBar } from '../accounts/facets/search-bar'
import { AIAgentColumns } from '../ai_agents/components/AIAgentColumns'
import { mergeParams } from '../icps/types'
import { PlayItemsTable } from './components/play-items-table'

interface Props {
  play: Play
  play_items: PlayItem[]
  stats: {
    total: number
    pending: number
    completed: number
    dismissed: number
  }
  status?: 'pending' | 'completed' | 'dismissed' | 'snoozed'
  assignee_id?: string
  page_meta: PageMeta
  apps: Apps
}

export default function Show({ apps: appsObject, play, play_items, stats, status, assignee_id, page_meta }: Props) {
  const deletionModal = useDisclosure()
  const user = useCurrentUser()

  const params = new URLSearchParams(window.location.search)
  const selectedStatus = status || params.get('status') || 'pending'
  const statCount = stats[selectedStatus] || 0

  const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(
    play.notification_config?.email?.enabled ?? false
  )
  const [assigneeId, setAssigneeId] = useState<string | null>(assignee_id || null)
  const [searchQuery, setSearchQuery] = useState('')

  const { mutate: updatePlay } = useUpdatePlay()

  const [items, setItems] = useState<PlayItem[]>(play_items ?? [])

  // Remove item when the status is different from the selected status
  // If it's the same status, we don't need to remove it
  const onRemoveItem = useCallback(
    (itemId: string, status: string) => {
      if (status !== selectedStatus) {
        setItems((items) => items.filter((item) => item.id !== itemId))
      }
    },
    [selectedStatus]
  )

  const onUpdateItem = useCallback((itemId: string, updatedItem: PlayItem) => {
    setItems((items) => items.map((item) => (item.id === itemId ? { ...item, ...updatedItem } : item)))
  }, [])

  const changeStatus = useCallback((status: string) => {
    const url = mergeParams(window.location.toString(), { status, page: '1' })
    router.visit(url)
  }, [])

  const onChangeNotifications = useCallback(
    (enabled: boolean) => {
      setEmailNotificationsEnabled(enabled)

      updatePlay(
        { playId: play.id, attributes: { notification_config: { email: { enabled } } } },
        {
          onSuccess: () => {
            toast.success(enabled ? 'Notifications enabled' : 'Notifications disabled')
          },
          onError: () => {
            toast.error('Failed to update notifications')
            // revert the change
            setEmailNotificationsEnabled(play.notification_config?.email?.enabled ?? false)
          }
        }
      )
    },
    [play.id, play.notification_config?.email?.enabled, updatePlay]
  )

  const [displayColumns, setDisplayColumns] = useState<DisplayColumn[]>(play.display_columns ?? [])

  useUpdateEffect(() => {
    setDisplayColumns(play.display_columns ?? [])
  }, [play.display_columns])

  const onUpdateColumns = useCallback(
    (columns: DisplayColumn[]) => {
      const updoots = columns.map((c) => ({
        id: c.id,
        type: c.type
      }))

      updatePlay(
        { playId: play.id, attributes: { display_columns: updoots } },
        {
          onSuccess: (res) => {
            setDisplayColumns(res.display_columns ?? [])
            toast.success('Columns updated')

            // refetch the play items!
            router.visit(window.location.toString(), { fetch: true })
          },
          onError: () => {
            toast.error('Failed to update columns')
          }
        }
      )
    },
    [play.id, updatePlay]
  )

  // Update items for incoming play_items changes
  useUpdateEffect(() => {
    setItems(play_items || [])
  }, [play_items])

  useUpdateEffect(() => {
    if (assigneeId !== assignee_id) {
      const path = mergeParams(window.location.toString(), { user: assigneeId || '' })
      router.visit(path)
    }
  }, [assigneeId, user?.id, assignee_id])

  const aiColumns = useMemo(() => {
    return play.display_columns?.filter((column) => column.type === 'agent')?.map((column) => column.id) ?? []
  }, [play.display_columns])

  const regColumns = useMemo(() => {
    return play.display_columns?.filter((column) => column.type === 'field')?.map((column) => column.id) ?? []
  }, [play.display_columns])

  const apps = useMemo(() => Object.values(appsObject), [appsObject])

  return (
    <PageLayout size="full">
      <TopBarContent>
        <Breadcrumb
          paths={[
            { path: projectPath('/plays'), title: 'Plays' },
            { path: projectPath(`/plays/${play.id}`), title: play.name, isCurrentPage: true }
          ]}
        />
      </TopBarContent>

      <PageTitle skipRendering>Play - {play.name}</PageTitle>

      <Flex gap={4} alignItems="flex-start" justifyContent="space-between" flexWrap="wrap">
        <Stack flex="1" minW="400px" spacing={2}>
          <Stack spacing={3}>
            <Flex alignItems="flex-start" gap={3}>
              <PlaySourceIcon
                targetType={play.target_type}
                targetSource={play.target_config?.source}
                iconSize={5}
                padding={3}
                rounded="lg"
              />

              <Flex flexDirection="column" gap={0}>
                <Heading flex="none" size="md" lineHeight={1.4}>
                  {play.name}
                </Heading>

                <HStack color="gray.600" fontSize="xs" spacing={1.5} divider={<MiddotDivider />}>
                  <Text>
                    Updated <TimeAgo time={play.updated_at} />
                  </Text>
                  {play.updated_by_user ? (
                    <Text>Updated by {play.updated_by_user.name || play.updated_by_user.email}</Text>
                  ) : play.created_by_user ? (
                    <Text>Created by {play.created_by_user.name || play.created_by_user.email}</Text>
                  ) : null}
                </HStack>
              </Flex>
            </Flex>

            {play.description && (
              <Text fontSize="sm" color="gray.600" noOfLines={2}>
                {play.description}
              </Text>
            )}
          </Stack>
        </Stack>

        <HStack flex="none">
          <Flex flex="none" alignItems="center" gap={1} ml="auto">
            <Text fontSize="sm" color="gray.600">
              Assignee:
            </Text>
            <UserSelector selectedUserId={assigneeId} onChange={setAssigneeId} allowUnassigned />
          </Flex>

          <Button
            size="sm"
            leftIcon={<IconSettings size={15} />}
            iconSpacing={1.5}
            as={Link}
            href={projectPath(`/plays/${play.id}/edit`)}
            variant="outline"
          >
            Edit
          </Button>

          <NotificationsPopover isEnabled={emailNotificationsEnabled} onChange={onChangeNotifications} />

          <Menu size="sm" autoSelect={false} placement="bottom-end">
            <MenuButton
              as={IconButton}
              aria-label="Actions"
              icon={<IconDotsVertical size={16} />}
              variant="ghost"
              size="sm"
            />
            <MenuList zIndex="popover">
              <MenuItem icon={<IconTrash size={16} />} color="red.500" onClick={deletionModal.onOpen}>
                Delete Play
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>

      <Grid width="100%" templateColumns={['repeat(1, 1fr)', 'repeat(auto-fit, minmax(120px, 1fr))']} gap={2}>
        <Stat label="Total" value={stats.total} />
        <Stat label="Pending" value={stats.pending} />
        <Stat label="Completed" value={stats.completed} />
        <Stat label="Dismissed" value={stats.dismissed} />
      </Grid>

      <Card p={0} flex="1" display="flex" flexDirection="column" overflow="hidden" maxH="95vh">
        <Flex
          flex="none"
          justifyContent="space-between"
          alignItems="center"
          gap={6}
          paddingX={4}
          paddingY={4}
          borderBottom="1px solid"
          borderBottomColor="gray.200"
        >
          <Heading size="sm">
            {play.target_type === 'Account' ? 'Companies' : 'People'} ({statCount.toLocaleString()})
          </Heading>
        </Flex>

        <Flex flex="none" alignItems="center" gap={4} paddingX={4} paddingY={2.5}>
          <SegmentedControl size="sm">
            <Button
              isActive={selectedStatus === 'pending'}
              iconSpacing={1.5}
              leftIcon={<StatusIcon status="pending" />}
              onClick={() => changeStatus('pending')}
            >
              Pending
            </Button>
            <Button
              isActive={selectedStatus === 'completed'}
              iconSpacing={1.5}
              leftIcon={<StatusIcon status="completed" />}
              onClick={() => changeStatus('completed')}
            >
              Completed
            </Button>
            <Button
              isActive={selectedStatus === 'dismissed'}
              iconSpacing={1.5}
              leftIcon={<StatusIcon status="dismissed" />}
              onClick={() => changeStatus('dismissed')}
            >
              Dismissed
            </Button>
          </SegmentedControl>

          <Box flex="none" minW="300px">
            <SearchBar size="sm" value={searchQuery} onChange={setSearchQuery} inputProps={{ isDisabled: true }} />
          </Box>

          <Flex alignItems="center" gap={2} marginLeft="auto">
            <AIAgentColumns
              onColumnChange={(agentSlugs) => {
                // Preserve existing columns (mix of field and agent columns)
                const existingColumns = displayColumns.filter((c) => {
                  return c.type === 'field' || agentSlugs.includes(c.id)
                })

                const existingSlugs = existingColumns.filter((c) => c.type === 'agent').map((c) => c.id)

                // Append net-new agent columns
                const newColumns = agentSlugs.filter((id) => !existingSlugs.includes(id))
                const newAiColumns = newColumns.map((id) => ({
                  id,
                  type: 'agent' as const
                }))

                onUpdateColumns([...existingColumns, ...newAiColumns])
              }}
              selectedColumns={aiColumns}
              target={play.target_type === 'Account' ? 'companies' : 'people'}
            >
              <Button
                colorScheme={'purple'}
                variant="ghost"
                leftIcon={<Icon as={IconFlare} color="purple.500" boxSize={4} />}
                size="sm"
              >
                Add AI Agent
              </Button>
            </AIAgentColumns>

            <ColumnManagementModal
              audienceKind={play.target_type === 'Account' ? 'account' : 'profile'}
              apps={apps}
              initialColumns={regColumns}
              onApply={(columns) => {
                // TODO actually preserve the order of all columns (ai and reg)

                // Keep all existing AI agent columns
                const agentColumns = displayColumns.filter((c) => c.type === 'agent')

                // Create new field columns
                const fieldColumns = columns.map((c) => ({
                  id: c.key,
                  type: 'field' as const
                }))

                // Combine both types of columns
                onUpdateColumns([...agentColumns, ...fieldColumns])
              }}
            />
          </Flex>
        </Flex>

        <Box display="flex" flexDirection="column" flex="1 1 auto" maxH="100%" minH="300px">
          <PlayItemsTable
            apps={apps}
            items={items}
            recordType={play.target_type}
            displayColumns={displayColumns}
            onUpdateColumns={onUpdateColumns}
            status={selectedStatus}
            assigneeId={assigneeId}
            onRemoveItem={onRemoveItem}
            onUpdateItem={onUpdateItem}
          />

          {items.length > 0 && (
            <TableFooter
              pageMeta={page_meta}
              page={page_meta.current_page}
              nextPath={mergeParams(window.location.toString(), {
                page: (page_meta.current_page + 1).toString()
              })}
              prevPath={mergeParams(window.location.toString(), {
                page: (page_meta.current_page - 1).toString()
              })}
              px={4}
              sticky
            />
          )}
        </Box>
      </Card>

      <DeleteConfirmation
        isOpen={deletionModal.isOpen}
        onClose={deletionModal.onClose}
        title="Delete Play"
        deletePath={projectPath(`/plays/${play.id}`)}
        confirmLabel="Yes, delete this play"
      >
        Are you sure you want to delete this play?
      </DeleteConfirmation>
    </PageLayout>
  )
}

function Stat(props: { label: string; value?: number | null }) {
  return (
    <Card px={3} py={3}>
      <Flex flex="1 1 0%" gap={2} direction="column" justifyContent="space-between">
        <Stack spacing={1.5}>
          <Heading size="xs" fontWeight="normal" color="gray.600">
            {props.label}
          </Heading>
          <Text fontSize="xl" fontWeight="semibold" lineHeight={1}>
            {(props.value || 0).toLocaleString()}
          </Text>
        </Stack>
      </Flex>
    </Card>
  )
}

interface NotificationsPopoverProps {
  isEnabled: boolean
  onChange: (enabled: boolean) => void
}

function NotificationsPopover({ isEnabled, onChange }: NotificationsPopoverProps) {
  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button
          size="sm"
          leftIcon={isEnabled ? <IconBellRingingFilled size={15} /> : <IconBell size={15} />}
          iconSpacing={1.5}
          colorScheme={isEnabled ? 'lightPurple' : 'gray'}
          variant="outline"
          aria-label="Notification Settings"
        >
          Notifications
        </Button>
      </PopoverTrigger>
      <PopoverContent width="320px">
        <PopoverArrow />
        <PopoverBody padding={4}>
          <Stack spacing={4}>
            <Stack spacing={0.5}>
              <Text fontSize="15px" fontWeight="semibold">
                Notifications
              </Text>
              <Text fontSize="13px" color="gray.500">
                Enable email notifications for your team.
              </Text>
            </Stack>
            <FormControl>
              <Flex alignItems="center" gap={2}>
                <FormLabel htmlFor="email-digest" mb="0" flex="1" fontSize="sm">
                  New assigned leads
                </FormLabel>
                <Switch id="email-digest" isChecked={isEnabled} onChange={(e) => onChange(e.target.checked)} />
              </Flex>
            </FormControl>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

function StatusIcon(props: { status: string }) {
  switch (props.status) {
    case 'completed':
      return <CircleIcon icon={IconCheck} iconSize={3.5} size={5} colorScheme="green" />
    case 'dismissed':
      return <CircleIcon icon={IconX} iconSize={3.5} size={5} colorScheme="red" />
    case 'pending':
      return <Icon as={IconCircleDashed} boxSize={5} color="gray.300" />
    default:
      return <Icon as={IconCircle} boxSize={5} color="gray.400" />
  }
}
