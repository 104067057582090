import {
  Box,
  ButtonGroup,
  Checkbox,
  Divider,
  Flex,
  Heading,
  HStack,
  HTMLChakraProps,
  Icon,
  IconButton,
  Link,
  Skeleton,
  SkeletonCircle,
  Stack,
  Table,
  TableColumnHeaderProps,
  TableContainer,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  Wrap
} from '@chakra-ui/react'
import { Icon as TablerIcon, IconClock, IconPlus, IconSparkles } from '@tabler/icons-react'
import { AnimatePresence, motion } from 'framer-motion'
import { Easing } from 'framer-motion/types/types'
import { format } from 'friendly-numbers'
import ms from 'ms'
import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMedia } from 'react-use'
import { toast } from 'sonner'
import { concurrentGET } from '../../../../lib/api'
import { getFlagEmoji } from '../../../../lib/flag-emoji'
import { flatGet } from '../../../../lib/flatGet'
import { formatFriendlyCurrency } from '../../../../lib/number-format'
import { App } from '../../../../types/App'
import { FieldDefinition } from '../../../../types/FieldDefinition'
import { PlayItem } from '../../../../types/Play'
import { DateTime, ProfileRecord } from '../../../../types/Profile'
import { Project } from '../../../../types/Project'
import { useEnrichProfile } from '../../../data/use-enrich-profile'
import { FacetParams } from '../../../data/use-facets'
import { PersistedFieldDefinition, useFieldDefinitionsIndexedByDataSource } from '../../../data/use-field-definitions'
import { useFieldMappings } from '../../../data/use-field-mappings'
import { UrlFilterParams } from '../../../data/use-url-filters'
import Avatar from '../../../ui/Avatar'
import { BubbleTag } from '../../../ui/BubbleTag'
import { Card } from '../../../ui/Card'
import {
  ColumnInfo,
  ColumnSelectorDropdown,
  defaultProfileColumns,
  useTableDisplayMode
} from '../../../ui/ColumnSelector'
import { CompanyBubble } from '../../../ui/CompanyBubble'
import DoubleAvatar from '../../../ui/DoubleAvatar'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { Iconify } from '../../../ui/Iconify'
import { LinkedinBoxIcon, SourceIcon } from '../../../ui/icons'
import { PersonBubble } from '../../../ui/PersonBubble'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'
import { SortableHeader } from '../../../ui/Table'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { UrlHoverControls } from '../../../ui/UrlHoverCard'
import { blocked, RedactedAccountCell, useEntitlements } from '../../../ui/useEntitlements'
import { useOverflow } from '../../../ui/useOverflow'
import { useCurrentUser } from '../../../ui/UserContext'
import { HighlightedField } from '../../accounts/components/DetailsV2/SummaryCard'
import { getItemDisplay } from '../../accounts/facets/categories'
import { accountPath } from '../../accounts/lib/account-path'
import { AIColumnCell, AIColumnHeader, useAgentStore } from '../../ai_agents/components/AIAgentColumns'
import { AllEntitlements } from '../../billing/show'
import { uniqueAndCount } from '../../icps/icp/account-list'
import { Breakdown, LetterGrade, Trendline } from '../../icps/icp/breakdown'
import { TrendCell } from '../../icps/icp/trend-cell'
import { AutoICPAccountScore, HighlightedAccount, Trend } from '../../icps/types'
import { CompleteButton } from '../../plays/components/CompleteButton'
import { EmailButton } from '../../plays/components/EmailButton'
import { QuickDismissButton } from '../../plays/components/QuickDismissButton'
import { ResetButton } from '../../plays/components/ResetButton'
import { WaterfallButton } from '../../plays/components/WaterfallButton'
import { profilePath } from '../lib/path'
import { IntentSignalCell } from './profile-feed'
import { isOnline } from './profile-live-list'

export type HighlightedProfile = ProfileRecord &
  Partial<AutoICPAccountScore> & {
    id: string
    company: HighlightedAccount['company']
    focus_time_trend?: Trend
    page_views_trend?: Trend
    latest_intent_signals?: Array<{
      id: string
      name: string
      last_triggered_at: DateTime
    }>
    limited?: boolean

    // for play items
    item?: PlayItem
  }

export interface Column {
  id: string
  Title: React.FC<HTMLChakraProps<'th'>>
  Cell: React.FC<{ profile: HighlightedProfile } & HTMLChakraProps<'td'>>
}

export interface ProfileListProps {
  facetParams?: FacetParams | UrlFilterParams
  profiles: HighlightedProfile[]
  range?: 'day' | 'week' | 'month' | 'all' | 'any'
  columns?: string[]
  playEnrollmentColumns?: Column[]
  apps?: App[]
  compact?: boolean
  sticky?: boolean
  canAddColumns?: boolean
  loadingColumns?: string[]
  onColumnChange?: (columns: Array<string | ColumnInfo>) => void
  onColumnRemove?: (column: string) => void
  onSortChange?: (sort_param: string | undefined) => void
  sortingBy?: string
  noGrays?: boolean
  onAIColumnChange?: (columns: string[]) => void
  aiColumns?: string[]
  isLive?: boolean
  isLoading?: boolean
  rowCount?: number
  selected?: any[]
  getCheckboxProps?: (props: Record<string, any>) => any
  onSelectAll?: () => void
  openLinksInNewTab?: boolean
  personCellMode?: 'name-only' | 'person-summary' | 'task-mode'

  // task mode actions
  onCompleteItem?: (itemId: string, status: string) => void
  onDismissItem?: (itemId: string, status: string) => void
  onResetItem?: (itemId: string, status: string) => void
  onUpdateItem?: (itemId: string, updatedItem: PlayItem) => void
}

function getRangeHeader(range) {
  switch (range) {
    case 'day':
      return '24h'
    case 'week':
      return '7d'
    case 'month':
      return '30d'
    default:
      return 'All time'
  }
}

const defaultColumnKeys = (project?: Project) => {
  if (project?.scoring_enabled) {
    return ['Company', 'FitScore', 'SessionTime', 'Activity', 'LastVisit', 'KQL']
  } else {
    return ['Company', 'SessionTime', 'Activity', 'LastVisit', 'KQL']
  }
}

const rowHover = {
  bg: [undefined, 'gray.50']
}

function TitleCell(props: CellRendererProps) {
  const [refreshLoadingState, setRefreshLoadingState] = useState<Record<string, boolean>>({})
  const [profile, setProfile] = useState<ProfileRecord>(props.profile)

  const [waterfallEnrichment, setWaterfallEnrichment] = useState<string | null>(null)

  const enrichProfile = useEnrichProfile({
    onSuccess: (profile) => {
      if (profile.title) {
        toast.success('Visitor enriched')
      } else {
        setWaterfallEnrichment('not_found')
      }
      setProfile(profile)

      if (profile.title) {
        props.onEnrichProfile?.(profile)
      }
    },
    onSettled: () => {
      setRefreshLoadingState((prev) => ({ ...prev, [profile.id]: false }))
    }
  })

  const onRefreshProfile = useCallback(
    (profile: ProfileRecord) => {
      setRefreshLoadingState((prev) => ({ ...prev, [profile.id]: true }))

      enrichProfile.mutate({
        id: profile.id,
        app: 'Koala',
        personKey: 'koala_person'
      })
    },
    [enrichProfile]
  )

  return (
    <Flex>
      <Stack>
        {profile.title && (
          <TextEllipsis maxW="200px" tooltip>
            {profile.title}
          </TextEllipsis>
        )}
        {props.onEnrichProfile && !profile.title && profile.email && waterfallEnrichment === null && (
          <HelpTooltip
            placement="right"
            trigger={
              <IconButton
                variant="ghost"
                size="xs"
                icon={<Icon color="gray.500" as={IconSparkles} size="12" />}
                isLoading={refreshLoadingState[profile.id]}
                onClick={() => {
                  onRefreshProfile(profile)
                }}
                aria-label="Waterfall Enrich"
              ></IconButton>
            }
          >
            <Stack fontSize="xs">
              <HStack>
                <Icon color="purple.500" as={IconSparkles} size="12" />
                <Heading size="xs">Waterfall Enrich</Heading>
              </HStack>
              <Text>
                Koala will attempt to find the linkedin profile, title, and other public information about this person.
                You can expect higher match rates for work emails.
              </Text>
            </Stack>
          </HelpTooltip>
        )}
        {waterfallEnrichment === 'not_found' && (
          <Text fontSize="xs" color="gray.500">
            Could not find title
          </Text>
        )}
      </Stack>
    </Flex>
  )
}

const columnRenderers = {
  Title: {
    Td: (props: CellRendererProps) => {
      return (
        <Td>
          <TitleCell {...props} />
        </Td>
      )
    }
  },
  Company: {
    Th: (_props: HeaderRendererProps) => {
      return <Th>Company</Th>
    },
    Td: ({ profile, entitlements, openLinksInNewTab }: CellRendererProps) => {
      return (
        <Td height="1px" width="1px" minW="200px" maxW="200px" px={[2, 3]}>
          <Box maxW="200px">
            <RedactedAccountCell
              showLock={false}
              element={profile}
              entitlements={entitlements}
              flexProps={{
                gap: 2
              }}
            >
              {profile.company && (
                <CompanyBubble
                  name={profile.company.name}
                  domain={profile.company.domain}
                  href={accountPath({ company: profile.company })}
                  target={openLinksInNewTab ? '_blank' : undefined}
                  maxW="180px"
                />
              )}
            </RedactedAccountCell>
          </Box>
        </Td>
      )
    }
  },
  FitScore: {
    Td: ({ profile }: CellRendererProps) => {
      return (
        <Td>
          {(profile.auto_icp_account_score?.fit_grade || profile.fit_grade) && (
            <HStack spacing={2} alignItems="center">
              <LetterGrade
                value={(profile.fit_grade || profile.auto_icp_account_score?.fit_grade)!}
                label={profile.fit_grade_letter || profile.auto_icp_account_score?.fit_grade_letter}
                fontSize="md"
                textAlign="center"
              />
              {profile.company?.domain && <Breakdown domain={profile.company.domain} />}
            </HStack>
          )}
        </Td>
      )
    }
  },
  Online: {
    Th: () => {
      return <Th>Most recent page</Th>
    },
    Td: ({ profile }: CellRendererProps) => {
      return (
        <Td minW="360">
          <Stack w="100%" spacing={1}>
            {profile.currently_viewing && (
              <Card p={0} bg={profile.status !== 'offline' ? `white` : 'gray.50'} shadow="none">
                <UrlHoverControls url={profile.currently_viewing.url}>
                  <HStack fontSize="xs" w="100%" justifyContent={'space-between'} p={1.5}>
                    <HStack>
                      {(isOnline(profile, 5) || profile.status !== 'offline') && (
                        <Box
                          rounded="base"
                          bg="red.500"
                          color="white"
                          fontSize="10px"
                          fontWeight="semibold"
                          textTransform="uppercase"
                          px={1}
                        >
                          LIVE
                        </Box>
                      )}
                      <Text
                        fontSize="xs"
                        color="gray.500"
                        flexWrap="nowrap"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        maxW={'350px'}
                      >
                        {profile.currently_viewing.path === '/'
                          ? profile.currently_viewing.url
                          : profile.currently_viewing.path}
                      </Text>
                    </HStack>

                    <HStack
                      spacing={2}
                      divider={<Divider height="14px" bg="gray.600" orientation="vertical" />}
                      css={{ '[data-url-controls]:hover &': { visibility: 'hidden' } }}
                    >
                      {(profile.currently_viewing.focus_time || 0) > 30_000 && (
                        <HStack spacing="1" color="gray.500">
                          <IconClock size="12" />
                          <Text>{ms(profile.currently_viewing.focus_time!, { long: true })}</Text>
                        </HStack>
                      )}

                      <TimeAgo time={profile.last_seen_at} canToggle={false} />
                    </HStack>
                  </HStack>
                </UrlHoverControls>
              </Card>
            )}
          </Stack>
        </Td>
      )
    }
  },
  SessionTime: {
    Th: (props: HeaderRendererProps) => {
      const intentHeader = getRangeHeader(props.intentRange || 'month')

      return (
        <SortableHeader
          sortBy="focus_time"
          columnKey={props.columnKey}
          currentSort={props.currentSort}
          onSortChange={props.onSortChange}
          onRemoveColumn={props.onRemoveColumn}
        >
          Session Time ({intentHeader})
        </SortableHeader>
      )
    },
    Td: ({ profile, intentRange }: CellRendererProps) => {
      return (
        <Td isNumeric>
          <Flex alignItems="center" gap={4}>
            {Boolean(profile.focus_time_trend?.[intentRange || 'month']?.current?.value) && (
              <Box flex="1 1 50%">
                <TrendCell
                  stats={profile.focus_time_trend}
                  format={(val) => (val ? ms(val) : '—')}
                  range={intentRange || 'month'}
                />
              </Box>
            )}
            {profile.page_views_trend && (
              <Box flex="1 1 50%">
                <TrendCell
                  stats={profile.page_views_trend}
                  format={(val) => (val ? `${format(val)} ${pluralize('page', val)}` : '')}
                  range={intentRange || 'month'}
                  textProps={{
                    color: profile.focus_time_trend?.[intentRange || 'month']?.current?.value ? 'gray.500' : undefined
                  }}
                />
              </Box>
            )}
          </Flex>
        </Td>
      )
    }
  },
  Activity: {
    Th: (props: HeaderRendererProps) => {
      const intentHeader = getRangeHeader(props.intentRange || 'month')
      return <Th>Activity ({intentHeader})</Th>
    },
    Td: ({ profile, intentRange }) => {
      return (
        <Td isNumeric>
          {profile.focus_time_trend && (
            <Box flex="none" display="flex" overflow="hidden" w="100%">
              <Trendline
                color="blue"
                range={intentRange}
                trend={profile.focus_time_trend}
                width={160}
                svgWidth={160}
                height={24}
                svgHeight={24}
              />
            </Box>
          )}
        </Td>
      )
    }
  },
  LastVisit: {
    Td: ({ profile }: CellRendererProps) => {
      return (
        <Td>
          <Text fontSize="sm">
            <TimeAgo time={profile.last_seen_at?.toString()} fallback="—" />
          </Text>
        </Td>
      )
    }
  },
  KQL: {
    Td: ({ profile }: CellRendererProps) => {
      const sortedKQLs = profile.latest_intent_signals ?? []

      return (
        <Td minW="100px" maxW="250px">
          <IntentSignalCell feed={sortedKQLs} />
        </Td>
      )
    }
  },
  sources: {
    Td: (props: CellRendererProps) => {
      const sources: string[] = Array.isArray(props.profile.sources)
        ? props.profile.sources
        : [props.profile.sources].filter(Boolean)

      return (
        <Td width="1px">
          <Flex alignItems="center" gap={1}>
            {sources.map((source) => (
              <SourceIcon key={source} source={source} size={18} />
            ))}
          </Flex>
        </Td>
      )
    }
  },
  'company.linkedin_url': {
    Td: (props: CellRendererProps) => {
      const linkedinUrl = props.profile.company?.linkedin_url

      return (
        <Td width="1px">
          {linkedinUrl && (
            <RedactedAccountCell
              showLock={false}
              element={props.profile}
              type="Visitor"
              entitlements={props.entitlements}
            >
              <Link variant="dotted" href={linkedinUrl} isExternal>
                {linkedinUrl.split('/').reverse()[0]}
              </Link>
            </RedactedAccountCell>
          )}
        </Td>
      )
    }
  },
  linkedin_url: {
    Td: (props: CellRendererProps) => {
      const linkedinUrl = props.profile?.linkedin_url

      return (
        <Td width="1px">
          {linkedinUrl && (
            <RedactedAccountCell
              showLock={false}
              element={props.profile}
              type="Visitor"
              entitlements={props.entitlements}
            >
              <Link variant="dotted" href={linkedinUrl} isExternal>
                {linkedinUrl.split('/').reverse()[0]}
              </Link>
            </RedactedAccountCell>
          )}
        </Td>
      )
    }
  },
  twitter_url: {
    Td: (props: CellRendererProps) => {
      const twitterUrl = props.profile?.twitter_url

      return (
        <Td width="1px">
          {twitterUrl && (
            <RedactedAccountCell
              showLock={false}
              element={props.profile}
              type="Visitor"
              entitlements={props.entitlements}
            >
              <Link variant="dotted" href={twitterUrl} isExternal>
                {twitterUrl.split('/').reverse()[0]}
              </Link>
            </RedactedAccountCell>
          )}
        </Td>
      )
    }
  },
  'hubspot_data.crm_fields.hs_object_id': {
    Td: (props: CellRendererProps) => {
      const profile = props.profile
      const externalId = (profile as any).hubspot_data?.crm_fields?.hs_object_id
      const record = profile.crm_records?.hubspot_company || null

      return (
        <Td minWidth="140px" maxWidth="300px">
          {externalId && record?.external_id === externalId ? (
            <Link variant="dotted" href={record?.permalink} isExternal>
              {externalId}
            </Link>
          ) : (
            externalId
          )}
        </Td>
      )
    }
  },
  'salesforce_data.crm_fields.Id_id': {
    Td: (props: CellRendererProps) => {
      const profile = props.profile
      const externalId = (profile as any).salesforce_data?.crm_fields?.Id_id
      const record = profile.crm_records?.salesforce_account || null

      return (
        <Td minWidth="140px" maxWidth="300px">
          {externalId && record?.external_id === externalId ? (
            <Link variant="dotted" href={record?.permalink} isExternal>
              {externalId}
            </Link>
          ) : (
            externalId
          )}
        </Td>
      )
    }
  }
}

interface HeaderRendererProps extends TableColumnHeaderProps {
  columnId: string
  columnKey: string
  columnTitle: string
  columnIcon?: TablerIcon | string | typeof Icon
  columnType?: string
  intentRange?: 'day' | 'week' | 'month'
  sortBy?: string
  currentSort?: string
  fieldDefinition?: FieldDefinition
  onSortChange?: (sortBy: string | undefined) => void
  onRemoveColumn?: (column: string) => void
}

function HeaderRenderer(props: HeaderRendererProps) {
  const ThRenderer = columnRenderers[props.columnId]?.Th
  if (ThRenderer) {
    return <ThRenderer {...props} />
  }

  const { columnId, columnKey, columnTitle, columnIcon, columnType, intentRange, fieldDefinition, ...rest } = props
  const isNumeric = !!columnType && ['float', 'long', 'number', 'double'].includes(columnType)
  const sortable = rest.sortBy || (!!columnType && !['object', 'nested', 'binary'].includes(columnType))
  const sortBy = sortable ? rest.sortBy || columnKey : undefined

  return (
    <SortableHeader
      columnKey={columnKey}
      isNumeric={isNumeric}
      {...rest}
      sortBy={sortBy}
      fieldDefinition={fieldDefinition}
      recordType="profile"
      columnTitle={columnTitle}
      columnType={columnType}
    >
      <Flex gap={1} alignItems="center" isTruncated>
        {columnIcon && <Iconify icon={columnIcon} size={15} flex="none" />}
        <TextEllipsis maxW="100%" tooltip>
          {columnTitle}
        </TextEllipsis>
      </Flex>
    </SortableHeader>
  )
}

interface CellRendererProps {
  columnId: string
  columnKey: string
  columnTitle?: string
  columnIcon?: TablerIcon | string | typeof Icon
  columnType?: string
  isLoading?: boolean
  intentRange?: 'day' | 'week' | 'month'
  profile: any
  entitlements?: AllEntitlements
  compact?: boolean
  isComfy?: boolean
  fieldDefinition?: FieldDefinition
  openLinksInNewTab?: boolean
  onEnrichProfile?: (profile: ProfileRecord) => void
}

function CellRenderer(props: CellRendererProps) {
  const { columnKey, columnType, profile, isLoading, isComfy, fieldDefinition } = props
  const isNumeric = !!columnType && ['float', 'long', 'number', 'double'].includes(columnType)
  const isDate = columnType === 'date'

  if (isLoading) {
    return (
      <Td minW="140px" maxW="300px" isNumeric={isNumeric}>
        <Skeleton height="16px" rounded="base" startColor="gray.50" endColor="gray.200" />
      </Td>
    )
  }

  const CustomRenderer = columnRenderers[props.columnId]?.Td
  if (CustomRenderer) {
    return <CustomRenderer {...props} onEnrichProfile={props.onEnrichProfile} />
  }

  let value = flatGet(profile, columnKey)
  if (Array.isArray(value) && value.length === 1 && isNumeric) {
    value = value[0]
  }

  const isLongText = typeof value === 'string' && value.length > 100
  let minW = '140px'
  const maxW = isComfy ? '600px' : '300px'

  if (isLongText && isComfy) {
    minW = '450px'
  }

  const TagCollectionParent = isComfy ? Wrap : Flex

  return (
    <Td minW={minW} maxW={maxW} isNumeric={isNumeric} p={0}>
      <Box
        minW={minW}
        maxW={maxW}
        whiteSpace={isComfy ? undefined : 'nowrap'}
        overflowX={isComfy ? undefined : 'hidden'}
        overflowY={isComfy ? 'auto' : undefined}
        textOverflow={isComfy ? 'clip' : 'ellipsis'}
        maxH={isComfy ? '300px' : undefined}
        py={isComfy ? 3 : 2}
        px={2}
      >
        <RedactedAccountCell showLock={false} element={profile} type="Visitor" entitlements={props.entitlements}>
          {fieldDefinition && value && (
            <HighlightedField field={fieldDefinition as PersistedFieldDefinition} record={profile} fieldValue={value} />
          )}

          {(!fieldDefinition || !value) && (
            <>
              {isDate ? (
                <TimeAgo time={value} mode="full" />
              ) : typeof value === 'string' ? (
                value
              ) : Array.isArray(value) ? (
                <TagCollectionParent flex="1 1 auto" gap={1} isTruncated>
                  {uniqueAndCount(
                    value.filter((v) => ['string', 'boolean', 'number'].includes(typeof v) && v !== '')
                  ).map(([item, count], index) => (
                    <BubbleTag
                      key={JSON.stringify({ item, index })}
                      title={typeof item === 'number' ? item.toLocaleString() : item?.toString()}
                      variant="subtleBorder"
                      value={item}
                    >
                      <TagLabel isTruncated minWidth="10px" maxWidth={isComfy ? '100%' : '180px'}>
                        {count > 1 && <>{count}× </>}{' '}
                        {typeof item === 'number' ? item.toLocaleString() : item?.toString()}
                      </TagLabel>
                    </BubbleTag>
                  ))}
                </TagCollectionParent>
              ) : typeof value === 'number' && isNumeric && columnKey.endsWith('_currency') ? (
                formatFriendlyCurrency(value, { truncateFractionDigits: true })
              ) : typeof value === 'number' && isNumeric ? (
                value.toLocaleString()
              ) : value === null || value === undefined ? null : (
                JSON.stringify(value)
              )}
            </>
          )}
        </RedactedAccountCell>
      </Box>
    </Td>
  )
}

function rangeDefault(range) {
  switch (range) {
    case 'day':
    case 'week':
    case 'month':
      return range
    case 'all':
    case 'any':
      return 'month'
    default:
      return 'week'
  }
}

const personCellWidths = {
  'name-only': '300px',
  'person-summary': '340px',
  'task-mode': '500px'
}

export function ProfileList(props: ProfileListProps) {
  const project = useCurrentProject()
  const entitlements = useEntitlements()
  const apps = props.apps
  const personCellMode = props.personCellMode ?? 'name-only'

  const user = useCurrentUser()
  const waterfallEnabled = useMemo(
    () => user.email === 'netto@getkoala.com' || user.email === 'matt@getkoala.com' || user.email === 'jon@sanity.io',
    [user]
  )

  const [profiles, setProfiles] = useState(props.profiles)
  useEffect(() => {
    setProfiles(props.profiles)
  }, [props.profiles])

  const {
    tasks,
    runningTasks,
    setRunningTasks,
    setResponses,
    responses: aiResponses,
    loadingCache,
    refetchAgents
  } = useAgentStore({
    domains: props.profiles.map((p) => p.company?.domain).filter(Boolean) as string[],
    profileIds: props.profiles.map((p) => p.id),
    agentSlugs: props.aiColumns ?? [],
    target: 'people'
  })

  const intentRange = rangeDefault(props.range)

  const columnsToDisplay = useMemo(() => {
    const cols = props.columns ?? defaultColumnKeys(project)
    return cols.filter((col) => {
      // dont show fit score column unless enabled for their workspace
      if (col === 'FitScore' && entitlements?.icp_scoring === false) {
        return false
      }

      if (col === 'auto_icp_account_score.fit_grade_letter' && entitlements?.icp_scoring === false) {
        return false
      }

      return true
    })
  }, [project, props.columns, entitlements])

  const mappings = useFieldMappings('/profiles/facet-cloud', {
    enabled: true,
    cached: true
  })
  const displayedColumns: ColumnInfo[] = useMemo(() => {
    return columnsToDisplay.map((column) => {
      return (
        defaultProfileColumns.find((c) => c.id === column || c.key === column) ||
        getItemDisplay(
          column,
          apps || [],
          'profile',
          mappings.data?.find((m) => m.facet === column)
        )
      )
    })
  }, [columnsToDisplay, apps, mappings.data])

  const largeEnoughScreen = useMedia('(min-width: 768px) and (min-height: 600px)')
  const stickyColumn = !props.compact && props.sticky !== false && largeEnoughScreen
  const facetMappings = props.facetParams?.facetMappings ?? {}

  const { scrollRef, overflowLeft, overflowTop } = useOverflow()
  const [mode] = useTableDisplayMode()
  const isComfy = useMemo(() => mode === 'comfy', [mode])

  const fieldDefsResponse = useFieldDefinitionsIndexedByDataSource('profile', {
    enabled: true
  })

  const numExtraColumns =
    displayedColumns.length + (props.playEnrollmentColumns?.length || 0) + (props.canAddColumns ? 1 : 0)

  const onEnrichProfile = useCallback(
    (profile: ProfileRecord) => {
      const columns = displayedColumns.map((c) => c.key).join(',')

      let path = projectPath(`/profiles.json?range=any&facets[id]=${profile.id}`)
      if (columns) {
        path += `&columns=${columns}`
      }

      concurrentGET<ProfileListProps>(path).then((res) => {
        const refreshedProfile = res.profiles[0]
        setProfiles((profiles) => profiles.map((p) => (p.id === profile.id ? refreshedProfile : p)))
      })
    },
    [displayedColumns]
  )

  return (
    <TableContainer
      ref={scrollRef}
      position="relative"
      className={`${overflowLeft ? 'scrolled' : ''} ${overflowTop ? 'scrolled-y' : ''}`.trim() || undefined}
      borderTop="1px solid"
      borderColor="border.lightest"
      overflowY="auto"
      fontSize="sm"
      w="100%"
      sx={{ overscrollBehaviorX: 'contain' }}
    >
      <Table variant="bordered" size={isComfy ? 'md' : 'sm'} w="100%" h="1px">
        {!props.compact && (
          <Thead className="sticky-header">
            <Tr>
              <Th
                className={stickyColumn ? 'sticky-column' : undefined}
                height="38px"
                minW={personCellWidths[personCellMode] || '300px'}
                maxW={personCellWidths[personCellMode] || '300px'}
                paddingLeft={[2.5, 4]}
              >
                <Flex alignItems="center" gap={2}>
                  {props.onSelectAll && (
                    <Checkbox
                      size="md"
                      marginRight={1}
                      isChecked={props.selected?.length === profiles.length && profiles.length > 0}
                      isIndeterminate={(props.selected || []).length > 0 && props.selected?.length !== profiles.length}
                      onChange={props.onSelectAll}
                    />
                  )}
                  <Text>{personCellMode === 'person-summary' ? 'Person' : 'Name'}</Text>
                </Flex>
              </Th>

              {props.playEnrollmentColumns?.map((c) => <c.Title key={c.id} />)}

              {props.aiColumns?.map((c) => (
                <Th key={c} minW="140px" maxW="300px">
                  <AIColumnHeader
                    column={c}
                    tasks={tasks.filter((t) => t.agent.slug === c)}
                    runningTasks={runningTasks}
                    loadingCache={loadingCache}
                  />
                </Th>
              ))}

              {displayedColumns.map((column) => (
                <HeaderRenderer
                  key={`${column.label}:${column.id || column.key}:header`}
                  columnId={column.id || column.key}
                  columnKey={column.key}
                  columnTitle={fieldDefsResponse?.[column.key]?.label || column.label}
                  columnIcon={column.icon}
                  columnType={column.type || facetMappings[column.key]?.type}
                  intentRange={intentRange}
                  sortBy={column.sortBy}
                  currentSort={props.sortingBy}
                  onSortChange={props.onSortChange}
                  onRemoveColumn={props.onColumnRemove}
                  px={[2, 3]}
                  fieldDefinition={fieldDefsResponse?.[column.key]}
                />
              ))}

              {props.canAddColumns && (
                <Th color="gray.500" _hover={{ bg: 'gray.50', color: 'gray.600' }}>
                  <ColumnSelectorDropdown
                    audienceKind="profile"
                    apps={apps}
                    selectedColumns={props.columns}
                    onChange={props.onColumnChange}
                  >
                    <Flex
                      as="button"
                      type="button"
                      alignItems="center"
                      width="100%"
                      height="100%"
                      gap={1}
                      fontSize="13px"
                      fontWeight="medium"
                    >
                      <Icon as={IconPlus} boxSize={4} />
                      Add column
                    </Flex>
                  </ColumnSelectorDropdown>
                </Th>
              )}
            </Tr>
          </Thead>
        )}
        <AnimatePresence exitBeforeEnter presenceAffectsLayout>
          <Tbody bg="white" position="relative">
            {props.isLoading
              ? Array.from({ length: props.rowCount ?? 10 }).map((_, i) => (
                  <Tr key={`loading-${i}`} height={personCellMode === 'person-summary' ? '61px' : '47px'}>
                    <Td
                      minW={personCellWidths[personCellMode] || '300px'}
                      maxW={personCellWidths[personCellMode] || '300px'}
                      paddingLeft={[2.5, 4]}
                    >
                      <Flex alignItems="center" gap={2.5} height="100%">
                        {Array.isArray(props.selected) && <Checkbox size="md" marginRight={1} isDisabled />}
                        <Flex alignItems="center" gap={2} py={1}>
                          <SkeletonCircle size="8" startColor="gray.50" endColor="gray.200" />
                          <Skeleton
                            width="210px"
                            height="16px"
                            rounded="base"
                            startColor="gray.50"
                            endColor="gray.200"
                          />
                        </Flex>
                      </Flex>
                    </Td>
                    {Array.from({ length: numExtraColumns }).map((_, j) => (
                      <Td key={`loading-${i}-${j}`}>
                        <Skeleton height="16px" rounded="base" startColor="gray.50" endColor="gray.200" />
                      </Td>
                    ))}
                  </Tr>
                ))
              : profiles.map((profile, i) => {
                  const displayName = profile.name?.trim() || profile.display_name?.trim()
                  const delay = Math.min(Math.random() * i * 0.25, 1)
                  const hasName = Boolean(profile.name || profile.full_name)
                  const item = profile.item

                  return (
                    <Tr
                      as={motion.tr}
                      key={profile.id}
                      initial={
                        props.isLive
                          ? {
                              height: 0,
                              opacity: 0
                            }
                          : undefined
                      }
                      animate={
                        props.isLive
                          ? {
                              height: '1px',
                              opacity: 1,
                              transition: {
                                duration: 0.2,
                                ease: 'easeIn',
                                delay
                              }
                            }
                          : undefined
                      }
                      exit={
                        props.isLive
                          ? {
                              height: 0,
                              opacity: 0,
                              transition: {
                                duration: 0.2,
                                ease: 'easeOut',
                                delay
                              }
                            }
                          : undefined
                      }
                      width="100%"
                      height="47px"
                      {...blocked(props.noGrays ? undefined : entitlements, profile)}
                      role="group"
                      className={props.selected?.includes(profile.id) ? 'selected-row' : undefined}
                      bg={props.selected?.includes(profile.id) ? 'purple.50' : undefined}
                      _hover={props.selected?.includes(profile.id) ? undefined : rowHover}
                    >
                      <Td
                        width="1px"
                        minW={personCellWidths[personCellMode] || '300px'}
                        maxW={personCellWidths[personCellMode] || '300px'}
                        height={personCellMode === 'person-summary' || personCellMode === 'task-mode' ? '61px' : '47px'}
                        paddingLeft={[2.5, 4]}
                        className={stickyColumn ? 'sticky-column' : undefined}
                      >
                        <Flex alignItems="center" gap={2.5} height="100%">
                          {props.getCheckboxProps && (
                            <Checkbox size="md" marginRight={1} {...props.getCheckboxProps({ value: profile.id })} />
                          )}
                          {personCellMode === 'person-summary' ? (
                            <Flex gap={2} alignItems="center" paddingY={1} minW="250px" maxW="340px">
                              <Avatar
                                size="sm"
                                name={displayName || 'Anonymous'}
                                src={profile.image || profile.avatar}
                              />

                              <Stack spacing={0.5} flex="1" alignItems="flex-start" isTruncated>
                                <Flex alignItems="center" gap={1.5}>
                                  <Link
                                    display="inline-flex"
                                    href={profilePath(profile)}
                                    isExternal={props.openLinksInNewTab}
                                    _hover={{ textDecoration: 'underline' }}
                                  >
                                    <TextEllipsis fontWeight="medium" maxW="100%" tooltip>
                                      {displayName || 'Anonymous'}
                                    </TextEllipsis>
                                  </Link>

                                  <Flex alignItems="center" gap={1}>
                                    {profile.linkedin_url && (
                                      <Tooltip label={`https://${profile.linkedin_url.replace(/https?:\/\//, '')}`}>
                                        <Link
                                          display="flex"
                                          flex="none"
                                          alignItems="center"
                                          color="linkedin.700"
                                          isExternal
                                          href={`https://${profile.linkedin_url.replace(/https?:\/\//, '')}`}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            window.ko?.track('LinkedIn Profile Visit Action', {
                                              app: 'linkedin',
                                              email: profile.email
                                            })
                                          }}
                                        >
                                          <LinkedinBoxIcon boxSize="18px" />
                                        </Link>
                                      </Tooltip>
                                    )}
                                  </Flex>
                                </Flex>

                                {profile.title && (
                                  <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
                                    {profile.title}
                                  </TextEllipsis>
                                )}

                                {!profile.title && profile.simple_location && (
                                  <TextEllipsis fontSize="xs" color="gray.600" maxW="100%" tooltip>
                                    {profile.simple_location}{' '}
                                    {getFlagEmoji((profile.person?.geo || profile.simple_geo)?.countryCode)}
                                  </TextEllipsis>
                                )}
                              </Stack>
                            </Flex>
                          ) : personCellMode === 'task-mode' && item ? (
                            <Flex flex="1" minW="400px" alignItems="center" justifyContent="space-between" gap={4}>
                              <Flex flex="1" alignItems="center" gap={2.5} isTruncated>
                                <DoubleAvatar
                                  domain={profile.company?.domain}
                                  name={profile.name || profile.full_name || profile.display_name}
                                  email={profile.email}
                                  src={
                                    profile.image ||
                                    (profile.koala_person?.id
                                      ? projectPath(`/prospects/${profile.koala_person.id}/avatar`)
                                      : undefined)
                                  }
                                />

                                <Stack spacing={0.5} isTruncated>
                                  <Flex alignItems="center" gap={2} isTruncated>
                                    <Link
                                      display="inline-flex"
                                      href={profilePath(profile)}
                                      isExternal
                                      _hover={{ textDecoration: 'underline' }}
                                    >
                                      <TextEllipsis fontSize="sm" fontWeight="medium" maxW="100%" tooltip>
                                        {profile.name ||
                                          profile.full_name ||
                                          profile.display_name ||
                                          profile.email ||
                                          'Anonymous'}
                                      </TextEllipsis>
                                    </Link>

                                    {profile.linkedin_url && (
                                      <Tooltip label={`https://${profile.linkedin_url.replace(/https?:\/\//, '')}`}>
                                        <Link
                                          display="flex"
                                          flex="none"
                                          alignItems="center"
                                          color="linkedin.700"
                                          isExternal
                                          href={`https://${profile.linkedin_url.replace(/https?:\/\//, '')}`}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            window.ko?.track('LinkedIn Profile Visit Action', {
                                              app: 'linkedin',
                                              email: profile.email
                                            })
                                          }}
                                        >
                                          <LinkedinBoxIcon boxSize="18px" />
                                        </Link>
                                      </Tooltip>
                                    )}
                                  </Flex>
                                  <Text fontSize="13px" color="gray.500" isTruncated>
                                    {hasName
                                      ? profile.title || profile.email || profile.simple_location
                                      : profile.title || profile.simple_location}
                                  </Text>
                                </Stack>
                              </Flex>

                              <Flex alignItems="center" gap={2} ml="auto">
                                {profile.email && (item.status === 'pending' || item.status === 'snoozed') && (
                                  <EmailButton
                                    buttonText="Composer"
                                    record={profile}
                                    itemId={item.id}
                                    onEmailSent={props.onCompleteItem}
                                  />
                                )}

                                {!profile.email &&
                                  profile.linkedin_url &&
                                  (item.status === 'pending' || item.status === 'snoozed') && (
                                    <WaterfallButton
                                      record={profile}
                                      playItem={item}
                                      itemId={item.id}
                                      onSuccess={(updatedRecord) => {
                                        props.onUpdateItem?.(item.id, {
                                          ...item,
                                          record: updatedRecord
                                        })
                                      }}
                                    />
                                  )}
                                <ButtonGroup size="sm" variant="outline" isAttached>
                                  {item.status === 'completed' ? (
                                    <ResetButton itemId={item.id} onSuccess={props.onResetItem} />
                                  ) : (
                                    <CompleteButton itemId={item.id} onSuccess={props.onCompleteItem} />
                                  )}
                                  {item.status === 'dismissed' ? (
                                    <ResetButton itemId={item.id} onSuccess={props.onResetItem} />
                                  ) : (
                                    <QuickDismissButton itemId={item.id} onSuccess={props.onDismissItem} />
                                  )}
                                </ButtonGroup>
                              </Flex>
                            </Flex>
                          ) : (
                            <RedactedAccountCell
                              element={profile}
                              entitlements={props.noGrays ? undefined : entitlements}
                              type="Visitor"
                              flexProps={{
                                gap: 2
                              }}
                            >
                              <Box width="250px">
                                <PersonBubble
                                  name={displayName || 'Anonymous'}
                                  email={profile.email}
                                  src={profile.avatar || profile.image}
                                  linkedinUrl={profile.linkedin_url}
                                  href={profilePath(profile)}
                                  target={props.openLinksInNewTab ? '_blank' : undefined}
                                  fontWeight={displayName ? 'medium' : 'normal'}
                                  color={displayName ? undefined : 'gray.500'}
                                  waterfallEnabled={waterfallEnabled}
                                  showAvatar={false}
                                  _groupHover={{
                                    background: props.selected?.includes(profile.id)
                                      ? 'rgba(255,255,255,0.65)'
                                      : 'white',
                                    shadow: 'sm',
                                    '& .hover-icon': {
                                      display: 'flex',
                                      opacity: 1
                                    }
                                  }}
                                />
                              </Box>
                            </RedactedAccountCell>
                          )}
                        </Flex>
                      </Td>

                      {props.playEnrollmentColumns?.map((c) => {
                        return <c.Cell key={c.id + profile.id} profile={profile} />
                      })}

                      {props.aiColumns?.map((c) => {
                        if (loadingCache) {
                          return (
                            <Td key={c + profile.id} minW="140px" maxW="300px">
                              <Skeleton height="16px" rounded="base" startColor="gray.50" endColor="gray.200" />
                            </Td>
                          )
                        }

                        const task = tasks.find((t) => {
                          return (
                            (t.agent.slug === c && t.agent.target === 'people' && t.profileId === profile.id) ||
                            (t.agent.slug === c &&
                              t.agent.target === 'companies' &&
                              t.domain === profile.company?.domain)
                          )
                        })

                        if (!task) {
                          return (
                            <Td key={c} minW="140px" maxW="300px">
                              <Text color="gray.400">&mdash;</Text>
                            </Td>
                          )
                        }
                        return (
                          <Td key={task.id} minW="140px" maxW="300px">
                            <AIColumnCell
                              task={task}
                              isRunning={!!runningTasks[task.id]}
                              setRunningTasks={setRunningTasks}
                              setResponses={setResponses}
                              response={aiResponses[task.id]}
                              profile={profile}
                              refetchAgents={refetchAgents}
                            />
                          </Td>
                        )
                      })}

                      {displayedColumns.map((column) => {
                        return (
                          <CellRenderer
                            key={`${column.label}:${column.id || column.key}:row:${profile.id}`}
                            columnId={column.id || column.key}
                            columnKey={column.key}
                            columnTitle={column.label}
                            columnIcon={column.icon}
                            columnType={column.type || facetMappings[column.key]?.type}
                            isLoading={props.loadingColumns?.includes(column.key)}
                            profile={profile}
                            intentRange={intentRange}
                            entitlements={props.noGrays ? undefined : entitlements}
                            compact={props.compact}
                            isComfy={isComfy}
                            fieldDefinition={fieldDefsResponse?.[column.key]}
                            openLinksInNewTab={props.openLinksInNewTab}
                            onEnrichProfile={waterfallEnabled ? onEnrichProfile : undefined}
                          />
                        )
                      })}

                      {props.canAddColumns && (
                        <Td>
                          <Box minW="200px" />
                        </Td>
                      )}
                    </Tr>
                  )
                })}
          </Tbody>
        </AnimatePresence>
      </Table>
    </TableContainer>
  )
}

export type StaggerOrigin = 'first' | 'last' | 'center' | number

export type StaggerOptions = {
  startDelay?: number
  from?: StaggerOrigin
  ease?: Easing
}

export function getOriginIndex(from: StaggerOrigin, total: number) {
  if (from === 'first') {
    return 0
  } else {
    const lastIndex = total - 1
    return from === 'last' ? lastIndex : lastIndex / 2
  }
}
