import { Box, Button, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'sonner'
import { put } from '../../../lib/api'
import { AutosizedTextarea } from '../../ui/AutosizedTextarea'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'

interface Props {
  settings: any
}

export default function Show({ settings }: Props) {
  const [signalIds, setSignalIds] = useState(settings.signal_ids || [])
  const [isSaving, setIsSaving] = useState(false)

  const coreValuePropRef = useRef<HTMLTextAreaElement>(null)
  const personaGuidelinesRef = useRef<HTMLTextAreaElement>(null)
  const playInstructionsRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    setSignalIds(settings.signal_ids || [])
  }, [settings.signal_ids])

  const handleSave = useCallback(async () => {
    try {
      setIsSaving(true)
      await put(projectPath('/settings/ai-instructions'), {
        instructions: {
          props_and_features: coreValuePropRef.current?.value,
          play_instructions: playInstructionsRef.current?.value,
          persona_guidelines: personaGuidelinesRef.current?.value,
          signal_ids: signalIds
        }
      })
      toast.success('Inbox settings updated successfully')
    } catch (error) {
      toast.error('Failed to update inbox settings')
    } finally {
      setIsSaving(false)
    }
  }, [signalIds])

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb paths={[{ title: 'AI Instructions', path: projectPath('/settings/ai-instructions') }]} />

      <PageTitle skipRendering>AI Instructions</PageTitle>

      <Stack spacing={8} marginTop={8}>
        <Flex flexDirection="column" gap={1}>
          <Heading size="xs">Core value props</Heading>
          <Text fontSize="sm" color="gray.500">
            Describe the core value props for your business, product or service. This will help guide Koala AI reasoning
            and suggested responses.
          </Text>

          <AutosizedTextarea
            ref={coreValuePropRef}
            size="sm"
            defaultValue={settings.props_and_features || ''}
            autoComplete="off"
            name="instructions[core_value_prop]"
            id="instructions[core_value_prop]"
            placeholder="Example: Our automated reconciliation software helps finance teams who struggle with month-end close. We reduce 40+ hours of manual work to 2 hours through AI-powered matching and custom rules engines."
            minRows={5}
            maxRows={24}
            marginTop={2}
          />
        </Flex>

        <Flex flexDirection="column" gap={1}>
          <Heading size="xs">Persona guidelines</Heading>
          <Text fontSize="sm" color="gray.500">
            How do you talk with different buyer personas? What value props are more important to them?
          </Text>

          <AutosizedTextarea
            ref={personaGuidelinesRef}
            size="sm"
            defaultValue={settings.persona_guidelines || ''}
            autoComplete="off"
            name="instructions[persona_guidelines]"
            id="instructions[persona_guidelines]"
            minRows={5}
            maxRows={24}
            marginTop={2}
          />
        </Flex>

        <Flex flexDirection="column" gap={1}>
          <Heading size="xs">Plays</Heading>
          <Text fontSize="sm" color="gray.500">
            Fine-tune Koala's AI recommendations by encoding your GTM strategy as "plays". You can describe the
            topics/signals and how you'd coach your reps to handle them. Koala's AI agents will recommend the best play
            to use, coach reps on the approach and suggest the next best action.
          </Text>

          <AutosizedTextarea
            ref={playInstructionsRef}
            size="sm"
            defaultValue={settings.play_instructions || ''}
            autoComplete="off"
            placeholder="When played a demo video or see the pricing page..."
            name="instructions[play_instructions]"
            id="instructions[play_instructions]"
            minRows={5}
            maxRows={24}
            marginTop={2}
          />
        </Flex>

        <Box>
          <Button size="md" colorScheme="purple" onClick={handleSave} isLoading={isSaving}>
            Save changes
          </Button>
        </Box>
      </Stack>
    </PageLayout>
  )
}
