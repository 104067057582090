import { projectPath } from '../ui/ProjectsContext'
import { post } from '../../lib/api'
import { useMutation } from 'react-query'

interface CreateAIAgentMetricParams {
  assistant_id?: string
  metric_type: string
  properties?: Record<string, unknown>
  response_key?: string
  target_type?: string
  target_id?: string
}

export const agentMetricOrigin = () => {
  const currentUrl = window.location.href

  if (currentUrl.includes('/accounts')) {
    return 'accounts'
  }

  if (currentUrl.includes('/people') || currentUrl.includes('/profiles')) {
    return 'profiles'
  }

  if (currentUrl.includes('/agents')) {
    return 'agents'
  }

  if (currentUrl.includes('/mission-control')) {
    return 'mission-control'
  }

  if (currentUrl.includes('/plays')) {
    return 'plays'
  }

  if (currentUrl.includes('/inbox')) {
    return 'inbox'
  }

  return null
}

export function useCreateAIAgentMetric() {
  return useMutation<void, unknown, CreateAIAgentMetricParams>((params) => {
    params.properties = { ...(params.properties || {}), origin: agentMetricOrigin() }

    return post(projectPath(`/settings/agents/record-metric`), {
      metric: { ...params, agent_id: undefined }
    })
  })
}
