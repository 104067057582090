import { Text } from '@chakra-ui/react'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { toast } from 'sonner'
import dayjs from '../../../../lib/dayjs'
import { Engagement } from '../../../../types/Engagement'
import { PlayItem } from '../../../../types/Play'
import {
  ToolUsage,
  usePlayItemAIRecommendations,
  usePlayItemAIRecommendationsStream
} from '../../../data/use-ai-recommendations'
import { useMailbox } from '../../../data/use-mailbox'
import { useOutreachSingleSendSettings } from '../../../data/use-outreach-single-send'
import { useCompletePlayItem } from '../../../data/use-plays'
import { EmailComposerPopup, EmailProps } from '../../../ui/EmailComposer'
import { LastEngagementHoverCard } from '../../../ui/LastEngagement'
import { TimeAgo } from '../../../ui/TimeAgo'
import { WarningMessage } from '../../../ui/WarningMessage'

interface EmailButtonProps {
  record: PlayItem['record']
  itemId: string
  draftEmail?: EmailProps
  buttonText?: string
  onEmailSent?: (itemId: string, status: string) => void
}

export type RecommendedPlay = {
  play?: string
  name?: string
  reasoning?: string[]
}

export function EmailButton({ record, itemId, buttonText, draftEmail, onEmailSent }: EmailButtonProps) {
  const [isOpen, setIsOpen] = useState(false)
  const mailbox = useMailbox()
  const { isLoading: isOutreachLoading, outreachEnabled } = useOutreachSingleSendSettings()

  const runId = useRef<string>(crypto.randomUUID())

  const { data: cachedSuggestedEmails, isLoading: isLoadingSuggestedEmails } = usePlayItemAIRecommendations(
    itemId,
    {
      recommendationType: 'email',
      runId: runId.current
    },
    {
      enabled: isOpen
    }
  )

  const [contentStream, setContentStream] = useState('')
  const [toolStream, setToolStream] = useState<ToolUsage[]>([])
  const [emailStrategy, setEmailStrategy] = useState<string>()
  const [recommendedPlay, setRecommendedPlay] = useState<RecommendedPlay>()

  usePlayItemAIRecommendationsStream(runId.current, {
    onContent: (msg: { content: string; content_type?: string }) => {
      if (msg.content_type != null) return
      setContentStream((ct) => {
        const newContent = `${ct}${msg.content}`

        // Extract strategy if we haven't yet
        if (!emailStrategy) {
          const strategyMatch = newContent.match(/<email_strategy>([\s\S]*?)<\/email_strategy>/)
          if (strategyMatch?.[1]) {
            setEmailStrategy(strategyMatch[1].trim())
          }
        }

        // Extract recommended play if we haven't yet
        if (!recommendedPlay) {
          const playMatch = newContent.match(/<recommended_play>([\s\S]*?)<\/recommended_play>/)
          if (playMatch?.[1]) {
            const playContent = playMatch[1]
            setRecommendedPlay({
              play: playContent.match(/<play>(.*?)<\/play>/m)?.[1]?.trim(),
              name: playContent.match(/<name>(.*?)<\/name>/m)?.[1]?.trim(),
              reasoning:
                playContent
                  .match(/<reasoning>[\s\S]*?-\s*(.*?)(?:\n|$)/gm)
                  ?.map((r) => r.replace(/.*?-\s*/, '').trim()) || []
            })
          }
        }

        return newContent
      })
    },
    onTool: (msg: ToolUsage) => {
      setToolStream((ts) => [...ts, msg])
    }
  })

  const emailsFromStream = useMemo(() => {
    const emails: Array<{
      to?: string
      subject?: string
      body?: string
      isComplete?: boolean
    }> = []

    // Extract complete emails
    const completeEmailMatches = contentStream.matchAll(/<email>([\s\S]*?)<\/email>/g)
    for (const match of completeEmailMatches) {
      const emailContent = match[1]
      const email: Record<string, string | boolean> = { isComplete: true }

      const toMatch = emailContent.match(/<to>(.*?)<\/to>/m)
      const subjectMatch = emailContent.match(/<subject>(.*?)<\/subject>/m)
      const bodyMatch = emailContent.match(/<body>([\s\S]*?)<\/body>/m)

      if (toMatch?.[1]) email.to = toMatch[1].trim()
      if (subjectMatch?.[1]) email.subject = subjectMatch[1].trim()
      if (bodyMatch?.[1]) {
        email.body = bodyMatch[1]
          .trim()
          .replace(/\n+/g, '\n\n')
          .replace(/\n{3,}/g, '\n\n')
      }

      if (Object.keys(email).length > 1) {
        emails.push(email)
      }
    }

    // Extract partial email being written
    const lastEmailStartIndex = contentStream.lastIndexOf('<email>')
    if (lastEmailStartIndex !== -1 && !contentStream.slice(lastEmailStartIndex).includes('</email>')) {
      const partialContent = contentStream.slice(lastEmailStartIndex)
      const email: Record<string, string | boolean> = { isComplete: false }

      const toMatch = partialContent.match(/<to>(.*?)(?:<\/to>|$)/s)
      const subjectMatch = partialContent.match(/<subject>(.*?)(?:<\/subject>|$)/s)
      const bodyStartMatch = partialContent.match(/<body>(.*?)$/s)

      if (toMatch?.[1]) email.to = toMatch[1].trim()
      if (subjectMatch?.[1]) email.subject = subjectMatch[1].trim()
      if (bodyStartMatch?.[1]) {
        email.body = bodyStartMatch[1]
          .trim()
          .replace(/\n+/g, '\n\n')
          .replace(/\n{3,}/g, '\n\n')
      }

      if (Object.keys(email).length > 1) {
        emails.push(email)
      }
    }

    return {
      emails,
      isComplete: contentStream.includes('</emails>')
    }
  }, [contentStream])

  const suggestedEmails = useMemo(() => {
    if (
      cachedSuggestedEmails?.recommendations?.email?.emails &&
      cachedSuggestedEmails.recommendations.email.emails.length > 0
    ) {
      return cachedSuggestedEmails.recommendations.email.emails
    }

    return emailsFromStream.emails
  }, [cachedSuggestedEmails, emailsFromStream])

  const { mutate: completeItem, isLoading } = useCompletePlayItem({
    onSuccess: () => {
      onEmailSent?.(itemId, 'completed')
    },
    onError: (error: any) => {
      toast.error('There was an issue marking this lead as complete', {
        description: error?.body?.message || error?.message
      })
    }
  })

  const canSendEmail = useMemo(() => {
    return outreachEnabled || mailbox.data?.connected
  }, [outreachEnabled, mailbox.data?.connected])

  const handleEmailSent = useCallback(() => {
    completeItem({ itemId })
  }, [itemId, completeItem])

  if (!canSendEmail || isOutreachLoading || mailbox.isLoading) {
    return null
  }

  // TODO: also support SFDC/HS last sales activity dates
  const lastActivity = record.last_activity as Engagement | undefined
  const lastActivityDate = record.last_activity_date as string | undefined

  return (
    <EmailComposerPopup
      email={{ to: record.email, ...draftEmail }}
      suggestedEmails={suggestedEmails}
      loadingSuggestions={suggestedEmails.length === 0 && isLoadingSuggestedEmails}
      toolStream={toolStream}
      stream={emailStrategy ?? contentStream}
      showSuggestions
      gmailConnected={mailbox.data?.connected ?? false}
      outreachEnabled={outreachEnabled ?? false}
      accountId={record.account_id}
      disabled={isLoading}
      onEmailSent={handleEmailSent}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      buttonText={buttonText}
      warningMessage={
        lastActivity && messagedRecently(lastActivity) ? (
          <WarningMessage>
            Emailed with{' '}
            <LastEngagementHoverCard engagement={lastActivity}>
              <Text as="span" fontWeight="semibold" textDecoration="underline">
                {record.name || record.email}
              </Text>
            </LastEngagementHoverCard>{' '}
            <TimeAgo time={lastActivity.created_at} />. Are you sure you want to send another message?
          </WarningMessage>
        ) : lastActivityDate && messagedRecently(lastActivityDate) ? (
          <WarningMessage>
            Last touched <TimeAgo time={lastActivityDate} />. Are you sure you want to send a message?
          </WarningMessage>
        ) : undefined
      }
    />
  )
}

function messagedRecently(lastActivity?: Engagement | string) {
  if (typeof lastActivity === 'string') {
    return dayjs(lastActivity).isAfter(dayjs().subtract(90, 'day'))
  }

  if (lastActivity?.created_at) {
    return dayjs(lastActivity.created_at).isAfter(dayjs().subtract(90, 'day'))
  }

  return false
}
